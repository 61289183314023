
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import TableView from '../../../../contents/AntTableResponsive';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, ldatepdf, lpowerdbypdf } from '../../../utils/pdf';
import { FilePdfOutlined } from '@ant-design/icons';
import ReactExport from "react-export-excel";
import { SelectSection } from '../../../select/SelectSection';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectGroup } from '../../../select/SelectGroup';
import { SelectCategory } from '../../../select/SelectCategory';
import { SelectGroupbySection } from '../../../select/SelectGroupbySection';
require('jspdf-autotable');

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

export default function SubjectWiseReport() {

    const [subjectWiseReportForm] = Form.useForm();
    const fetchgetStudentListBySubjectWise = useStoreActions((state) => state.student.fetchgetStudentListBySubjectWise);
    const getStudentListBySubjectWise = useStoreState((state) =>  state.student.getStudentListBySubjectWise);
    const [academicYear, setAcademicYear] = useState<any>();
    const [section, setSection] = useState<any>();
    const [group, setgroup] = useState<any>();
    const [subject, setSubject] = useState<any>();
    const fetchlistGroupbyClassConfig = useStoreActions((state) => state.student.fetchlistGroupbyClassConfig); 
    const fetchsubjectConfigurationListByClasssIdAndGroupId = useStoreActions((state) => state.student.fetchsubjectConfigurationListByClasssIdAndGroupId);
    const subjectConfigurationListByClasssIdAndGroupId = useStoreState((state) =>  state.student.subjectConfigurationListByClasssIdAndGroupId);
    const loading = useStoreState((state) =>  state.student.loading);
   
    const formSubmit = (value) => {
        value.groupId= value.groupId.groupId;
        fetchgetStudentListBySubjectWise(value);
    }

    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true  },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true  },
        { title: 'Registration No.', dataIndex: 'registrationNo', key: 'registrationNo', showOnResponse: true, showOnDesktop: true  },
    ];

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Subject Wise list of Subject-${$(".subjectSelect").text()} and Group-${$(".classSelectBySection").text()} and Section-${$(".sectionSelect").text()} and Academic Year-${subjectWiseReportForm.getFieldValue("academicYear")}`;
        var detailsx = `Subject Wise list of Subject: ${$(".subjectSelect").text()} and Group: ${$(".classSelectBySection").text()} and Section: ${$(".sectionSelect").text()} and Academic Year: ${subjectWiseReportForm.getFieldValue("academicYear")}`;
        pdfDataL(doc, "");
        doc.text(detailsx, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);            
        };


        var col = [
            "Student ID",
            "Student Name",
            "Roll",
            "Registration No.",

        ].filter(Boolean);

        var rows:any = [];


        getStudentListBySubjectWise.forEach(element => {
            var temp:any = [ 
                element.customStudentId, 
                element.studentName, 
                element.studentRoll,
                element.registrationNo,

            ];
            rows.push(temp);
        });
       

            let first = doc.autoTable.previous;
            doc.autoTable(col, rows, {
                startY: 45,
                showHeader: "firstPage",
                theme: 'grid',
                styles: {
                    overflow: 'linebreak',
                },
                headerStyles: {
                    // fillColor: [105, 105, 105],
                    // textColor: [255, 255, 255],
                    // fontSize: 10
                    lineWidth: .01,
                    lineColor: [224, 224, 224]
                },
 
                addPageContent: pageContent,
            });

  
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    return(
        <>
            <Card title="Subject Wise Student List">
            
                <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={subjectWiseReportForm}
                        >
                          <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5}} xl={{ span: 5 }}>
                                <Form.Item
                                    name="academicYear"
                                    label="Academic Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select academic year" },
                                    ]}
                                >
                                    <SelectAcademicYear onChange={e => setAcademicYear(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="classConfigId"
                                    label="Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                   <SelectClassConfig onChange={e => { setSection(e); setgroup(null); fetchlistGroupbyClassConfig(e) }} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="groupId"
                                    label="Group"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select group" },
                                    ]}
                                >
                                    <SelectGroupbySection selected={group} onChange={(e) => {
                                        console.log(e)
                                         setgroup(e.groupId);
                                         setSubject(null);
                                         let payload=  {
                                            classConfigurationId: section,
                                            groupId: e.groupId,
                                         } 
                                         fetchsubjectConfigurationListByClasssIdAndGroupId(payload);
                                         }} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                            <Form.Item
                                        name="subjectId"
                                        label="Select Subject"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select subject" },
                                        ]}
                                    >
                                        <Select placeholder="Select Subject"  className="subjectSelect" style={{ width: "100%" }} value={subject} onChange={(e) => setSubject(e)}>
                                            {subjectConfigurationListByClasssIdAndGroupId ? (
                                                    subjectConfigurationListByClasssIdAndGroupId.map((type, idx) => (
                                                    <Select.Option key={type.subjectId} value={type.subjectId}>
                                                        {type.subjectName}
                                                    </Select.Option>
                                                    ))
                                                ) : (
                                                    <Select.Option value="fetching">Fetching Subject</Select.Option>
                                                )}
                                        </Select>
                                    </Form.Item>
                            </Col>
        
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} style={{marginTop:30}}>
                                        <Button type="primary" htmlType="submit" className="mt-0" icon={<SearchOutlined />} >
                                                Search
                                        </Button>
                                    </Col>
                        </Row>

                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: getStudentListBySubjectWise.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns:studentListColumn,
                                rowKey:"identificationId",
                                dataSource: getStudentListBySubjectWise,
                                filterData: getStudentListBySubjectWise,
                                pagination: true,
                                bordered: true,                           
                            }}
                            mobileBreakPoint={768}
                        />
                        {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                    </Col>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>
                        <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Subject Wise Report`}
                            >
                                <ExcelSheet data={getStudentListBySubjectWise} name="Subject Wise Report">
                                    <ExcelColumn label="Student ID" value="customStudentId" />
                                    <ExcelColumn label="Student Name" value="studentName" />
                                    <ExcelColumn label="Roll" value="studentRoll" />
                                    <ExcelColumn label="Registration No." value="registrationNo" />
                                </ExcelSheet>
                            </ExcelFile>
                            <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportPdf()} >PDF Download</Button>
                        </Space>
                    </Col>
                </Row>

            </Card>
        </>
    )
}
