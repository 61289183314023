import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker, Skeleton } from 'antd';
import { EditOutlined, SaveOutlined, SearchOutlined, SettingFilled } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import moment from 'moment';
import Table from "../../../../contents/AntTableResponsive";
import { tuple } from 'antd/lib/_util/type';
import { SelectDistrict } from '../../../select/SelectDistrict';
import { SelectThana } from '../../../select/SelectThana';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectDistrict2 } from '../../../select/SelectDistrict2';
import { SelectThana2 } from '../../../select/SelectThana2';
import { SelectAcademicYear2 } from '../../../select/SelectAcademicYear2';

export default function AddressInfoUpdate() {

    const { Option } = Select;
    const [searchStudentForm] = Form.useForm();
    const fetchacademicYearList = useStoreActions((state) => state.student.fetchacademicYearList);
    const academicYearList = useStoreState((state) => state.student.academicYearList);
    const fetchClassConfigList = useStoreActions((state) => state.student.fetchClassConfigList);
    const classConfigList = useStoreState((state) => state.student.classConfigList);
    const fetchStudentListByClassConfigId = useStoreActions((state) => state.student.fetchStudentListByClassConfigId);
    const fetchShiftList = useStoreActions((state) => state.student.fetchShiftList);
    const studentListByClassConfigId = useStoreState((state) => state.student.studentListByClassConfigId);
    const updateStudentAddressInfo = useStoreActions((state) => state.student.updateStudentAddressInfo);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [studentId, setSelectedStudentId] = useState<any>();
    const [disttrict, setDistrict] = useState<any>();
    const [thana, setThana] = useState<any>();    
    const [disttrict2, setDistrict2] = useState<any>();
    const [thana2, setThana2] = useState<any>();
    const [updateForm] = Form.useForm();
    const thanaListFetch = useStoreActions((state) => state.common.thanaListFetch);
    const thanaListFetch2= useStoreActions((state) => state.common.thanaListFetch2);
    const loading = useStoreState((state) =>  state.student.loading);
    useEffect(() => {
        fetchacademicYearList();
        fetchClassConfigList();
        fetchShiftList();
    }, []);

    const searchStudentList = (value) => {
        fetchStudentListByClassConfigId(value);
    }
    const columns = [
        {
            title: 'ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'District',
            dataIndex: 'presentDistrictName',
            key: 'presentDistrictName',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <>
                Present : {record?.presentDistrictName} <br />
                Permanent : {record?.permanentDistrictName}
                </>
            )

        },
        {
            title: 'Thana Name',
            dataIndex: 'presentThanaName',
            key: 'presentThanaName',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <>
                Present : {record?.presentThanaName} <br />
                Permanent : {record?.permanentThanaName}
                </>
            )
        },
        {
            title: 'Village',
            dataIndex: 'presentVillege',
            key: 'presentVillege',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <>
                Present : {record?.presentVillege} <br />
                Permanent : {record?.permanentVillege}
                </>
            )
        },
        {
            title: 'PostOffice',
            dataIndex: 'presentPostOffice',
            key: 'presentPostOffice',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <>
                Present : {record?.presentPostOffice} <br />
                Permanent : {record?.permanentPostOffice}
                </>
            )
        },
        {
            title: 'Mailing Address',
            dataIndex: 'mailingAddress',
            key: 'mailingAddress',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Button type='primary' icon={<EditOutlined />}
                    onClick={() => {
                        setIsModalVisible(true);
                        setSelectedStudentId(record.studentId);
                        setDistrict(record.presentDistrictId);
                        setDistrict2(record.permanentDistrictId);
                        thanaListFetch(record.presentDistrictId);
                        thanaListFetch2(record.permanentDistrictId);

                        updateForm.setFieldsValue({
                            presentVillege: record.presentVillege,
                            permanentVillege: record.permanentVillege,
                            presentPostOffice: record.presentPostOffice,
                            permanentPostOffice: record.permanentPostOffice,
                            mailingAddress: record.mailingAddress,
                            permanentDistrictId: record.permanentDistrictId,
                            presentDistrictId: record.presentDistrictId,
                            // thanaId: record.thanaId,
                        });
                        setTimeout(() => {
                            setThana(record.presentThanaId);
                            setThana2(record.permanentThanaId);
                            updateForm.setFieldsValue({
                                permanentThanaId: record.permanentThanaId,
                                presentThanaId: record.presentThanaId,
                            });

                        }, 1000);
                    }}
                />
            ),
        },


    ];

    const districsIdStore = (val) => {
        setDistrict(val);
    }    
    const districsIdStore2 = (val) => {
        setDistrict2(val);
    }

    const updateFormInfo = (value) => {
        console.log(value);
        let postData = {
            "mailingAddress": value.mailingAddress,
            "permanentPostOffice": value.permanentPostOffice,
            "presentPostOffice": value.presentPostOffice,
            "studentId": studentId,
            "presentThanaId": value.presentThanaId,
            "permanentThanaId": value.permanentThanaId,
            "presentVillege": value.presentVillege,
            "permanentVillege": value.permanentVillege,
        }
        console.log(postData);
        let payload = {
            key: { classconfigid: section },
            value: postData
        }
        updateStudentAddressInfo(payload);
        setIsModalVisible(false);
    }

    const [section, setSection] = useState<any>();

    return (
        <>
            <Card title="Student Address Update">
                <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={searchStudentList}
                            form={searchStudentForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear2 />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="classconfigid"
                                        label="Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig onChange={e => setSection(e)} style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{rows:10}} />
                {studentListByClassConfigId !== null &&
                <>
                        <Row className="m-t-mo-30">
                            <Col span="24">
                                <Table
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: studentListByClassConfigId,
                                        filterData: studentListByClassConfigId,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "key",
                                    }}
                                    mobileBreakPoint={768}

                                />
                            </Col>
                        </Row>
                    </>}
                <Modal
                    title="Address Info Update"
                    visible={isModalVisible}
                    //  onOk={handleOk}
                    okButtonProps={{ form: 'update', htmlType: 'submit' }}
                    onCancel={() => setIsModalVisible(false)}
                    cancelText="Close"
                    okText="Update"
                    centered
                    width={'50%'}
                >
                    <Form
                        layout="vertical"
                        id="update"
                        onFinish={updateFormInfo}
                        form={updateForm}
                   
                    >
                        <Row>
                        <Col span={24}>
                                <Form.Item
                                    name="mailingAddress"
                                    label="Mailing Address"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Write email" },
                                    ]}
                                >
                                    <Input.TextArea style={{height: '60px'}}
                                        placeholder="Mailing Address"
                                    />

                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Row>
                                <Col span={12}>
                                <Form.Item
                                    name="presentVillege"
                                    label="Present Village"
                                    className="title-Text"
                                    // rules={[
                                    //     { required: true, message: "Write village name" },
                                    // ]}
                                >
                                    <Input
                                        placeholder="Village name"
                                    />

                                </Form.Item>
                            </Col>  
                            <Col span={12}>
                                <Form.Item
                                    name="permanentVillege"
                                    label="Permanent Village"
                                    className="title-Text"
                                    // rules={[
                                    //     { required: true, message: "Write village name" },
                                    // ]}
                                >
                                    <Input
                                        placeholder="Village name"
                                    />

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="presentPostOffice"
                                    label="Present Post Office"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Write post office" },
                                    ]}
                                >
                                    <Input
                                        placeholder="Write post office"
                                    />

                                </Form.Item>
                            </Col>                          
                            <Col span={12}>
                                <Form.Item
                                    name="permanentPostOffice"
                                    label="Permanent Post Office"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Write post office" },
                                    ]}
                                >
                                    <Input
                                        placeholder="Write post office"
                                    />

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="presentDistrictId"
                                    label="Present District"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Select district name" },
                                    ]}
                                >
                                    <SelectDistrict selected={disttrict} onChange={e => { districsIdStore(e); setThana(null) }} />

                                </Form.Item>
                            </Col>                           
                            <Col span={12}>
                                <Form.Item
                                    name="permanentDistrictId"
                                    label="Permanent District"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Select district name" },
                                    ]}
                                >
                                    <SelectDistrict2 selected={disttrict2} onChange={e => { districsIdStore2(e); setThana2(null) }} />

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="presentThanaId"
                                    label="Present Thana"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Select thana" },
                                    ]}
                                >
                                    <SelectThana selected={thana} onChange={e => setThana(e)} />

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="permanentThanaId"
                                    label="Permanent Thana"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Select thana" },
                                    ]}
                                >
                                    <SelectThana2 selected={thana2} onChange={e => setThana2(e)} />

                                </Form.Item>
                            </Col>
                                </Row>
                            </Col>
                         


     
                        </Row>
                    </Form>
                </Modal>
            </Card>
        </>
    )
}