
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';

import { SelectGroupConfigByClass } from '../../../select/SelectGroupConfigByClass';

export default function TaughtListReport() {

    const [groupWiseReportForm] = Form.useForm();
    const downloadTaughtList = useStoreActions((state) => state.student.downloadTaughtList);
    const fetchGroupList = useStoreActions((state) =>  state.exam.fetchGroupList);
    const loading = useStoreState((state) =>  state.student.loading);

    const formSubmit = (value) => {
       // downloadTaughtList(value.groupId);
       let url=localStorage.getItem("url")+"/student/report/taught/list/download?groupConfigId="+value.groupId+"&access_token="+encodeURIComponent(localStorage.getItem("tok") as any);;
       window.open(url, "_blank");
    }



    const groupListByClassId = (e) => {
        fetchGroupList(e);        
    }

    return(
        <>
            <Card title="Taught List">
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 20, offset:6 }} xl={{ span: 20, offset:6 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={groupWiseReportForm}
                        >
                            <Row>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="classId"
                                        label="Select Class"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select class" },
                                        ]} 
                                    >
                                        <SelectClass onChange={(e) => groupListByClassId(e)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="groupId"
                                        label="Select Group"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select group" },
                                        ]} 
                                    >
                                        <SelectGroupConfigByClass />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<DownloadOutlined />} >
                                            Download
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />

            </Card>
        </>
    )
}
