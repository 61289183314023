import { DeleteOutlined, EditOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, notification, Popconfirm, Row, Skeleton, Space, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import xlsxParser from 'xlsx-parse-json';
import TableView from '../../../../contents/AntTableResponsive';
import { Typography } from 'antd';

export default function MultipleRegistration(props) {

    const { Title } = Typography;
    const [form] = Form.useForm();
    const doMultipleStaffRegistration = useStoreActions((state) => state.staff.doMultipleStaffRegistration);
    const [fileList, setFileList] = useState<any>([]);
    const [tableData, settableData] = useState<any>([]);
    const loading = useStoreState((state) =>  state.staff.loading);

    const onChangeFile = ({ fileList: newFileList }) => {
        // console.log(newFileList);

        newFileList[0].status = "done"
        xlsxParser
            .onFileSelection(newFileList[0]?.originFileObj)
            .then(data => {
                console.log(data?.Sheet1)
                let temp = data?.Sheet1?.map(function (item, index) {
                    return {
                        hrId: item['HR ID'],
                        key:index,
                        staffName: item['HR Name'],
                        gender: item['Gender'],
                        religion: item['Religion'],
                        fatherName: item["Father's Name"],
                        motherName: item["Mother's Name"],
                        mobileNumber: item['Mobile No.'],
                    }
                })
                settableData(temp)
            });

    };

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
       // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    const columns = [


        {
            title: 'Id',
            dataIndex: 'hrId',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        },      
        {
            title: 'Staff Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Religion',
            dataIndex: 'religion',
            key: 'religion',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Father's Name",
            dataIndex: 'fatherName',
            key: 'fatherName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Mother's Name",
            dataIndex: 'motherName',
            key: 'motherName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Mobile No.',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
            showOnResponse: true,
            showOnDesktop: true
        },
    ]


    return (
        <>
            <Card title="Staff Registration Excel" >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={10} xl={10}> </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Upload
                            listType="text"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            fileList={fileList}
                            onChange={onChangeFile}
                            style={{ maxWidth: 220 }}

                        >
                            {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload Excel File</Button></>}
                        </Upload>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={1} xl={1}></Col>
                    <Col xs={24} sm={24} md={24} lg={3} xl={3}>
                        <Button type="primary" onClick={() => window.open('https://res.cloudinary.com/dnyoqhb7g/raw/upload/v1632892456/Student-excel/Hr-excel/hr_multiple_registration.xlsx', '_blank')}>Download Sample File</Button>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={1} xl={1}> </Col>

                </Row>
                <Skeleton loading={loading} paragraph={{rows:10}}/>
                <br />
                {tableData?.length > 0 &&
                <>
                    <TableView
                        antTableProps={{
                            showHeader: true,
                            rowSelection:rowSelection,
                            columns,
                            rowKey:"key",
                            dataSource: tableData,
                            filterData: tableData,
                            pagination: false,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                    <Button icon={<SaveOutlined/>} type="primary" style={{ float: "right", marginTop:10 }} onClick ={()=> {
                        if (selectedValue.length===0){
                            notification.error({message:"Please select"});
                            return
                        }
                        doMultipleStaffRegistration(selectedValue);
                        setselectedRowKeys([]);
                        setselectedValue([]);
                        settableData([]);
                    }}>Save</Button>
                    </>
                
                }
            </Card>
        </>
    )
}
