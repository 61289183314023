
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, EditOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectClassConfig } from '../../../select/SelectClassConfig';


export default function StudentEnableDisable() {

    const { Option } = Select;
    const [sectionWiseReportForm] = Form.useForm();
    const fetchStudentEnableDisableList = useStoreActions((state) => state.student.fetchStudentEnableDisableList);
    const studentEnableDisableList = useStoreState((state) =>  state.student.studentEnableDisableList);
    const storeEditableStore = useStoreActions((state) => state.student.storeEditableStore);
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [selectedValue, setselectedValue] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [enableDisbleForm] = Form.useForm();
    const [formData, setFormData] = useState<any>();
    const [studentStatus, setStudentStatus] = useState<any>(false);    
    const loading = useStoreState((state) =>  state.student.loading);

    const formSubmit = (value) => {
        let postData:any = {
            academicYear: value.yearId,
            classconfigid: value.sectionId
        }
        fetchStudentEnableDisableList(postData);
        setFormData(postData);
    }

    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true  },
        { title: 'Group', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Status', dataIndex: 'studentStatus', key: 'studentStatus', showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
            <>
                {record.studentStatus == true ? 'Enable' : 'Disable'}
            </>
        )  },
        { title: 'Disable Reason', dataIndex: 'disableReason', key: 'disableReason', showOnResponse: true, showOnDesktop: true  },
    ];

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const changeStatusMethod = (value) => {
        let postData:any = {
            "disableNote": studentStatus == 'true' ? "" : value.statusReason,
            "identificationIds": selectedRowKeys,
            "studentStatus": studentStatus
        }
        let details:any = {
            payload: postData,
            search:formData
        }
        storeEditableStore(details);
        setIsModalVisible(false);
    }

    return(
        <>
            <Card title="Student Enable Disable">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={sectionWiseReportForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="yearId"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]} 
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="sectionId"
                                        label="Select Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]} 
                                    >
                                        <SelectClassConfig />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{rows:10}}/>
                <Row style={{ display: studentEnableDisableList.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                rowSelection: rowSelection,
                                columns:studentListColumn,
                                rowKey:"identificationId",
                                dataSource: studentEnableDisableList,
                                filterData: studentEnableDisableList,
                                pagination: false,
                                bordered: true,                           
                            }}
                            mobileBreakPoint={768}
                        />
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}} style={{ display: selectedRowKeys.length > 0 ? 'block' : 'none' }}>
                        <Button type="primary" icon={<EditOutlined />} style={{ float: 'right' }} onClick={(e) => setIsModalVisible(true)}>
                            Update
                        </Button>
                    </Col>
                </Row>
            </Card>
            <Modal
                title="Student Enable/Disable"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
                maskClosable={false}
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={changeStatusMethod}
                    form={enableDisbleForm}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="statusTypeValue"
                                label="Status Type"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select disable" },
                                ]}
                            >
                                <Select placeholder="select type" onChange={(e) => setStudentStatus(e)}>
                                    <Option value="true">Enable</Option>
                                    <Option value="false">Disable</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ display: studentStatus == 'false' ? 'block' : 'none'}}>
                            <Form.Item
                                name="statusReason"
                                label="Diasable Reason"
                                className="title-Text"
                            >
                                <Input placeholder="Write reason" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}
