import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Table, Tag } from 'antd';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';


export default function Section() {
    const createsectionList = useStoreActions((state) => state.student.createsectionList);
    const updatesectionList = useStoreActions((state) => state.student.updatesectionList);
    const deletesectionList = useStoreActions((state) => state.student.deletesectionList);
    const fetchsectionList = useStoreActions((state) => state.student.fetchsectionList);
    const sectionList = useStoreState((state) => state.student.sectionList);
    const [createForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const loading = useStoreState((state) =>  state.student.loading);

    ///modal
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedStatus, setselectedStatus] = useState<any>();
    const [updateId, setUpdateid] = useState<any>();

    useEffect(() => {
        fetchsectionList();
    }, [])

    const createSubmitForm = (value) => {

        let postData = {
            name: value.name,
            serial: value.serial,
            status: 1
        }
        createsectionList(postData);
        createForm.resetFields();
    }

    /////////////

    const updateSubmitForm = (value) => {
        let updatePostData = {
            id: updateId,
            name: value.nameUpdate,
            serial: value.serialUpdate,
            status: value.status == true ? 1 : 0
        }
        updatesectionList(updatePostData);
        setIsModalVisible(false);
        updateForm.resetFields();
    }



    const success = () => {
        message.success('This is a success message');
    };

    const columns = [
        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <>
                    <span>{record.status == 1 ? 'Enable' : 'Disable'}</span>
                </>
            )
        },
        {
            title: 'Action',
            key: 'ledgerId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setselectedStatus(record.status);
                            setUpdateid(record.id);
                            updateForm.setFieldsValue({
                                serialUpdate: record.serial,
                                nameUpdate: record.name,
                                status: record.status,
                            });
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deletesectionList(record?.id)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        }
    ];
    return (
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                    <Form
                        layout="vertical"
                        id="sessionInfo"
                        onFinish={createSubmitForm}
                        form={createForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Form.Item
                                    name="name"
                                    label="Section Name"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write section" },
                                    ]}
                                >
                                    <Input placeholder="Section Name" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Form.Item
                                    name="serial"
                                    label="Serial Number"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write serial number" },
                                    ]}
                                >
                                    <InputNumber placeholder="Serial number" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                        Save
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{ rows: 10 }} />
            <Row className="m-t-mo-30">
                <Col span="24">
                    <div className="datatable-responsive-demo">
                        {sectionList?.length > 0 &&
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: sectionList,
                                    filterData: sectionList,
                                    pagination: true,
                                    bordered: true,
                                    rowKey: "id",
                                }}
                                mobileBreakPoint={768}
                            />
                        }
                    </div>
                </Col>
            </Row>
            <Modal
                title="Update"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
                maskClosable={false}
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateSubmitForm}
                    form={updateForm}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="nameUpdate"
                                label="Section Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write name" },
                                ]}
                            >
                                <Input placeholder="Section Name" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="serialUpdate"
                                label="Serial Number"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write serial number" },
                                ]}
                            >
                                <InputNumber placeholder="Enter serial number" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="status"
                                label="Status:"
                                valuePropName="checked"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input serial" },
                                ]}
                            >
                                <Checkbox>{selectedStatus == 0 ? 'Disable' : 'Enable'}</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )

}