import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectGender } from '../../../select/SelectGender';
import { SelectReligion } from '../../../select/SelectReligion';
import { SelectBloodGroup } from '../../../select/SelectBloodGroup';



export default function SingleRegistration(props) {

    const [form] = Form.useForm();
    const saveSingleStaffRegistration = useStoreActions((state) => state.staff.saveSingleStaffRegistration);

    const formSubmit = (value) => {
        saveSingleStaffRegistration(value);
        form.resetFields();
    }
  
    return (
        <>
            <Card title="Staff Registration" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="staffName"
                                label="Staff Name "
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert staff name" },
                                ]}
                            >
                                <Input placeholder="Staff Name" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="fatherName"
                                label="Father Name "
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert father name" },
                                ]}
                            >
                                <Input placeholder="Father Name" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="motherName"
                                label="Mother Name "
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert mother name" },
                                ]}
                            >
                                <Input placeholder="Mother Name" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="gender"
                                label="Gender "
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select gender" },
                                ]}
                            >
                                <SelectGender/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="religion"
                                label="Religion"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select religion" },
                                ]}
                            >
                                <SelectReligion/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="bloodGroup"
                                label="Blood Group"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select blood group" },
                                ]}
                            >
                                <SelectBloodGroup/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="mobileNumber"
                                label="Mobile Number "
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert mobile number" },
                                ]}
                            >
                                <Input placeholder="Mobile Number" />
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="staffAddress"
                                label="Address"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert address" },
                                ]}
                            >
                                <Input.TextArea placeholder="Address" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                        <Col xs={24} sm={24} md={24} lg={20} xl={20}> </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" style={{float:"right"}}>
                                <Button type="primary" className='mt-0' htmlType="submit" icon={<SaveOutlined />}>
                                    Save
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>



            </Card>
        </>
    )
}
