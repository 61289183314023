import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag} from 'antd';
import TableView from '../../../../contents/AntTableResponsive';
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import Column from 'rc-table/lib/sugar/Column';
import { render } from '@testing-library/react';
import AssignList from './forthSubjectAssign/AssignList';
import AssignedList from './forthSubjectAssign/AssignedList';

export default function ExamFortSubject (){
    const { TabPane } = Tabs;

    return(
        <>
            <Card title="Exam Fourth Subject Assign">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Assign" key="1">
                            <AssignList />
                        </TabPane>
                        <TabPane tab="Assigned List" key="2">
                            <AssignedList />
                        </TabPane>
                    </Tabs>
                </Card>
        </>
    )
}