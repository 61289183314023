import { Button, Card, Col, DatePicker, Form, Input, Popconfirm, Row, Select, Skeleton, Space } from 'antd'
import { DeleteOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
let transactionid = '';
export default function Voucher(props) {

    const voucherInfo = useStoreState((state) => state.ledger.voucherInfo);
    const check = useStoreState((state) => state.ledger.check);
    const fetchvoucherInfo = useStoreActions((state) => state.ledger.fetchvoucherInfo);
    const deletevoucher = useStoreActions((state) => state.ledger.deletevoucher);
    const loading = useStoreState((state) => state.ledger.loading);

    const [deleteForm] = Form.useForm();

    const [voucher, setvoucher] = useState<any>()

    const deleteFormSubmit = (value) => {

        let postData = {
            voucherId: value.voucher,
        }
        setvoucher(postData)
        fetchvoucherInfo(postData);
    };
    useEffect(() => {
        var table = $('#tbl')
        let option = "";
        table.empty();
        if (check == true) {
            transactionid = voucherInfo.trnId
        }
        if (voucherInfo?.list?.length > 0) {
            option = option + "<thead><th>Ledger Name</th><th style='text-align: center'>Debit </th><th style='text-align: center'>Credit</th></tr></thead>";
            option = option + "<tbody>";

            var list = voucherInfo.list;

            for (var i = 0; i < list.length; i++) {

                option = option + "<tr><td>&nbsp;&nbsp;&nbsp;" + list[i].ledgerName + "</td><td style='text-align: right'>" + list[i].debit + "</td><td style='text-align: right'>" + list[i].credit + "</td></tr>";

            }

            option = option + "</tbody>";

            option = option + "<tfoot>";
            option = option + "<tr><th style='text-align: right'>Total</th><th style='text-align: right'>" + voucherInfo.totalDebit + "</th><th style='text-align: right'>" + voucherInfo.totalCredit + "</th></tr>";
            option = option + "</tfoot>";

            table.append(option);
        }
    }, [check])

    console.log(voucherInfo)

    return (
        <>
            <Card title="Delete Voucher" >
                <Form
                    layout="vertical"
                    onFinish={deleteFormSubmit}
                    id="basic-info"
                    form={deleteForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="voucher"
                                label="Voucher Id"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input voucher id" },
                                ]}
                            >
                                <Input
                                    placeholder="Voucher Id"
                                />

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <br />

                {check == true &&
                    <>
                        <Row gutter={8}>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <span style={{ fontWeight: "bold" }}>Voucher ID</span>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                {voucherInfo.voucherId}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <span style={{ fontWeight: "bold" }}>Voucher Type</span>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                {voucherInfo.voucherType}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <span style={{ fontWeight: "bold" }}>Trn. Amount</span>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                {voucherInfo.trnAmount}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <span style={{ fontWeight: "bold" }}>Trn. Date</span>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                {voucherInfo.trnDate}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <span style={{ fontWeight: "bold" }}>Entry By</span>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                {voucherInfo.entryBy}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <span style={{ fontWeight: "bold" }}>Note</span>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                {voucherInfo.note}
                            </Col>

                        </Row>
                        <br />

                        <div className="table-responsive">
                            <table id="tbl" className="table table-bordered custom-table table-striped text-left">
                            </table>
                        </div>
                        <Popconfirm
                            title="Are you sure you want to delete this?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deletevoucher({
                                id: voucherInfo.trnId,
                                data: voucher
                            })}
                        >
                            <Button danger icon={<DeleteOutlined />} style={{ float: "right" }} >Delete Voucher</Button>
                        </Popconfirm>
                    </>
                }

            </Card>
        </>
    )
}
