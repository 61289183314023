import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Skeleton, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";


export default function StaffSettings(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const designationList = useStoreState((state) => state.staff.designationList);
    const fetchdesignationList = useStoreActions((state) => state.staff.fetchdesignationList);
    const createDesignation = useStoreActions((state) => state.staff.createDesignation);
    const updateDesignation = useStoreActions((state) => state.staff.updateDesignation);
    const deleteDesignation = useStoreActions((state) => state.staff.deleteDesignation);
    const loading = useStoreState((state) =>  state.staff.loading);

    useEffect(() => {
        fetchdesignationList();
    }, [])

    const formSubmit = (value) => {
        value.status=1;
        createDesignation(value);
        form.resetFields();
    }    
    
    const updateFomrSubmit = (value) => {
        let postdata ={
            id: id,
            name: value.name,
            serial: value.serial,
            status: value.status===true?1:0
        }
        updateDesignation(postdata);
        setIsModalVisible(false);
    }

    const [id, setId] = useState<any>();

    const columns = [

        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Designation',
            dataIndex: 'name',
            key: 'name',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Status',
            key: 'status',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                        <span>{record?.status===1?"Enabled":"Disabled"}</span>
            ),
        },
        {
            title: 'Action',
            key: 'id',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setId(record.id);
                            updateForm.setFieldsValue({
                                name: record.name,
                                status: record.status===1?true:false,
                                serial: record.serial,
                            });
                        }}
                        />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteDesignation(record?.id)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <>
            <Card title="Staff Designation" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="name"
                                label="Designation Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input Designation name" },
                                ]}
                            >

                                <Input placeholder="Write Designation Name" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="serial"
                                label="Serial"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input serial" },
                                ]}
                            >

                                <Input placeholder="Please input serial" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                                    Save
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>
                {designationList=== null && <Skeleton loading={loading} paragraph={{rows:10}}/>}
                {designationList!== null && !loading &&

                <div >
                    {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: designationList,
                            filterData: designationList,
                            pagination: false,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                </div>
}
            </Card>

            <Modal
                title="Update Staff Designation"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >

                    <Form.Item
                        name="name"
                        label="Designation Name:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please input Designation name" },
                        ]}
                    >
                        <Input placeholder='Designation Name' />
                    </Form.Item>


                    <Form.Item
                        name="serial"
                        label="Serial:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please input serial" },
                        ]}
                    >
                        <Input placeholder='Note' />
                    </Form.Item>
                    
                    <Form.Item
                        name="status"
                        label="Status:"
                        valuePropName="checked"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please input serial" },
                        ]}
                    >
                       <Checkbox >Status</Checkbox>
                    </Form.Item>
                </Form>

            </Modal>
        </>
    )
}
