import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification } from 'antd'
import { useStoreActions, useStoreState } from '../../../../../store/hooks/easyPeasy';
import TableView from '../../../../../contents/AntTableResponsive';
import { DeleteOutlined, EditOutlined, FilePdfOutlined, SaveOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { SelectClass } from '../../../../select/SelectClass';
import { render } from '@testing-library/react';
import { SelectClassConfig } from '../../../../select/SelectClassConfig';


export default function GeneralExam(props) {

    const [createForm] = Form.useForm();
    const { Option } = Select;

    const fetchSemesterListByClassConfigIdForGeneralExamProcess = useStoreActions((state) => state.exam.fetchSemesterListByClassConfigIdForGeneralExamProcess);
    const semesterListByClassConfigIdForGeneralExamProcess = useStoreState((state) => state.exam.semesterListByClassConfigIdForGeneralExamProcess);
    const processgeneralExam = useStoreActions((state) => state.exam.processgeneralExam);
    const onChangeClassList = (e) => {
        fetchSemesterListByClassConfigIdForGeneralExamProcess(e);
    }

    const processSubmit = (value) => {
        console.log('value', value);
        let payload:any = {
            examConfigId: value.examConfig,
            section: value.section,
        }
        console.log('payload', payload);
        
        processgeneralExam(payload);
    }

    return(
        <>
            <Card title="General Exam Result Process">
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                    <Form
                        layout="vertical"
                        id="sessionInfo"
                        onFinish={processSubmit}
                        form={createForm}
                    >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                    <Form.Item
                                        name="section"
                                        label="Select Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig onChange={(e) =>{ onChangeClassList(e); createForm.setFieldsValue({examConfig:null})}} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                    <Form.Item
                                        name="examConfig"
                                        label="Select Exam"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select exam" },
                                        ]}
                                    >
                                        <Select placeholder="Semester list" style={{ width: "100%" }}>
                                            {semesterListByClassConfigIdForGeneralExamProcess ? (
                                                    semesterListByClassConfigIdForGeneralExamProcess.map((type, idx) => (
                                                    <Option key={type.examConfigId} value={type.examConfigId}>
                                                        {type.examName}
                                                    </Option>
                                                    ))
                                                ) : (
                                                    <Option value="fetching">Fetching Exam</Option>
                                                )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SettingOutlined />} >
                                            Process
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </>
    )
}