import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Space } from 'antd'
import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
require('jspdf-autotable');

var getColumns = function () {
    return [
        { title: "Ledger Name", dataKey: "ledgerName" },
        { title: "Debit Amount", dataKey: "debit" },
        { title: "Credit Amount", dataKey: "credit" },
    ];
};

var getColumnsTotal = function () {
    return [
        { title: "Total", dataKey: "total" },
        { title: "", dataKey: "totalDebit" },
        { title: "", dataKey: "totalCredit" },
    ];
};

var getColumnsGrand = function () {
    return [
        { title: "Grand Total", dataKey: "grandTotal" },
        { title: "", dataKey: "grandTotalDebit" },
        { title: "", dataKey: "grandTotalCredit" },
    ];
};
let fd = '';
let td = '';
export default function IncomeStatement(props) {

    const incomeStatement = useStoreState((state) => state.ledger.incomeStatement);
    const check = useStoreState((state) => state.ledger.check);
    const fetchincomeStatement = useStoreActions((state) => state.ledger.fetchincomeStatement);
    const loading = useStoreState((state) =>  state.ledger.loading);

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD")
        }
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");
        fetchincomeStatement(postData);
    };

    useEffect(() => {
        var table = $('#tbl')
        let option = "";
        let datas = incomeStatement;
        if (incomeStatement?.incomeList!==undefined) {
            option = option + "<thead><th>Ledger Name</th><th style='text-align: center'>Debit </th><th style='text-align: center'>Credit</th></tr></thead>";
            option = option + "<tbody>";

            option = option + "<tr><th colspan='3'>Income </th></tr>";

            var incomelist = datas.incomeList;

            for (var i = 0; i < incomelist.length; i++) {

                option = option + "<tr><td>&nbsp;&nbsp;&nbsp;" + incomelist[i].ledgerName + "</td><td style='text-align: right'>" + incomelist[i].debitAmount + "</td><td style='text-align: right'>" + incomelist[i].creditAmount + "</td></tr>";

            }

            option = option + "<tr><th style='text-align: right'>Total Income</th><th style='text-align: right'></th><th style='text-align: right'>" + datas.totalIncome + "</th></tr>";

            option = option + "<tr><th colspan='3'>Expense </th></tr>";

            option = option + "</tbody>";

            var expenseList = datas.expenseList;

            for (var i = 0; i < expenseList.length; i++) {

                option = option + "<tr><td>&nbsp;&nbsp;&nbsp;" + expenseList[i].ledgerName + "</td><td style='text-align: right'>" + expenseList[i].debitAmount + "</td><td style='text-align: right'>" + expenseList[i].creditAmount + "</td></tr>";

            }

            option = option + "<tr><th style='text-align: right'>Total Expense</th><th style='text-align: right'>" + datas.totalExpense + "</th><th style='text-align: right'></th></tr>";


            option = option + "<tr><th colspan='3'>Profit & Loss </th></tr>";

            var profitloss = datas.profitLosList;

            for (var i = 0; i < profitloss.length; i++) {

                option = option + "<tr><td>&nbsp;&nbsp;&nbsp;" + profitloss[i].ledgerName + "</td><td style='text-align: right'>" + profitloss[i].debitAmount + "</td><td style='text-align: right'>" + profitloss[i].creditAmount + "</td></tr>";

            }



            option = option + "<tfoot>";
            option = option + "<tr><th style='text-align: right'> </th><th style='text-align: right'>" + datas.totalDebit + "</th><th style='text-align: right'>" + datas.totalCredit + "</th></tr>";
            option = option + "</tfoot>";
            table.append(option);
        }
    }, [check])

    function exportPdf() {
        var doc = new jsPDF("p", "mm", "a4");

        var details = `Income Statements from ${fd} to ${td}`;
        pdfDataL(doc, "");
        doc.text(details, 105, 40, 'center');


        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };

        let first = doc.autoTable.previous;
        doc.autoTable([
            { title: "Ledger Name", dataKey: "ledgerName" },
            { title: "Debit", dataKey: "debitAmount" },
            { title: "Credit", dataKey: "creditAmount" },
        ], incomeStatement.incomeList, {
            startY: 45,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 75
                },
                debitAmount: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                },
                creditAmount: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                }
            },
            drawRow: function (row, data) {
                if (row.index === 0) {
                    doc.setFontSize(10);
                    doc.rect(data.settings.margin.left, row.y, data.table.width, 7, "S");
                    doc.autoTableText(" " + "Income", data.settings.margin.left, (row.y + row.height / 2) - 1,
                        {
                            halign: "left",
                            valign: "top",
                            fontSize: 10
                        }
                    );
                    data.cursor.y += 7;
                }
            },
            addPageContent: pageContent,
        });
        if (incomeStatement?.incomeList.length > 0) {
            doc.autoTable([
                { title: "", dataKey: "b1" },
                { title: "", dataKey: "b2" },
                { title: "", dataKey: "total" },
            ], [
                {
                    b1: "Total",
                    b2: "",
                    total: incomeStatement.totalIncome
                }
            ], {
                startY: doc.autoTable.previous.finalY + 0,
                showHeader: "never",
                theme: 'grid',

                columnStyles: {
                    b1: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 75
                    },
                    b2: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 53.4
                    },
                    total: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 53.4
                    }
                },

                // addPageContent: pageContent,
            });

        }

        doc.autoTable([
            { title: "Ledger Name", dataKey: "ledgerName" },
            { title: "Debit", dataKey: "debitAmount" },
            { title: "Credit", dataKey: "creditAmount" },
        ], incomeStatement.expenseList, {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 75
                },
                debitAmount: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                },
                creditAmount: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                }
            },
            drawRow: function (row, data) {
                if (row.index === 0) {
                    doc.setFontSize(10);
                    doc.rect(data.settings.margin.left, row.y, data.table.width, 7, "S");
                    doc.autoTableText(" " + "Expense", data.settings.margin.left, (row.y + row.height / 2) - 1,
                        {
                            halign: "left",
                            valign: "top",
                            fontSize: 10
                        }
                    );
                    data.cursor.y += 7;
                }
            },
            // addPageContent: pageContent,
        });
        if (incomeStatement?.expenseList.length > 0) {
            doc.autoTable([
                { title: "", dataKey: "b1" },
                { title: "", dataKey: "b2" },
                { title: "", dataKey: "total" },
            ], [
                {
                    b1: "Total",
                    b2: "",
                    total: incomeStatement.totalExpense
                }
            ], {
                startY: doc.autoTable.previous.finalY + 0,
                showHeader: "never",
                theme: 'grid',

                columnStyles: {
                    b1: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 75
                    },
                    b2: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 53.4
                    },
                    total: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 53.4
                    }
                },

                // addPageContent: pageContent,
            });

        }

        doc.autoTable([
            { title: "Ledger Name", dataKey: "ledgerName" },
            { title: "Debit", dataKey: "debitAmount" },
            { title: "Credit", dataKey: "creditAmount" },
        ], incomeStatement.profitLosList, {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 75
                },
                debitAmount: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                },
                creditAmount: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                }
            },
            drawRow: function (row, data) {
                if (row.index === 0) {
                    doc.setFontSize(10);
                    doc.rect(data.settings.margin.left, row.y, data.table.width, 7, "S");
                    doc.autoTableText(" " + "Profit & Loss", data.settings.margin.left, (row.y + row.height / 2) - 1,
                        {
                            halign: "left",
                            valign: "top",
                            fontSize: 10
                        }
                    );
                    data.cursor.y += 7;
                }
            },
            // addPageContent: pageContent,
        });
        if (incomeStatement?.profitLosList.length > 0) {
            doc.autoTable([
                { title: "", dataKey: "b1" },
                { title: "", dataKey: "b2" },
                { title: "", dataKey: "total" },
            ], [
                {
                    b1: "",
                    b2: incomeStatement.totalDebit,
                    total: incomeStatement.totalCredit
                }
            ], {
                startY: doc.autoTable.previous.finalY + 0,
                showHeader: "never",
                theme: 'grid',

                columnStyles: {
                    b1: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 75
                    },
                    b2: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 53.4
                    },
                    total: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 53.4
                    }
                },

                // addPageContent: pageContent,
            });

        }
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }

        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");
    }

    return (
        <>
            <Card title="Income Statement" >
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>
                <Skeleton loading={loading} paragraph={{rows:10}} />
                {incomeStatement?.incomeList!==undefined &&
                    <>
                        <div className="table-responsive">
                            <table id="tbl" className="table table-bordered custom-table table-striped text-left">
                            </table>
                        </div>
                        <Button type="primary" onClick={exportPdf} icon={<FilePdfOutlined />} style={{ float: "right" }}>Download PDF</Button>
                    </>
                }
            </Card>
        </>
    )
}
