import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import SessionYear from '../Students/settings/SessionYear.page';
import { SelectAcademicYear } from '../../select/SelectAcademicYear';
import TransferCertificateAdd from './TransferCertificateAdd';
import TransferCertificateDownload from './TransferCertificateDownload';

export default function TransferCertificate() {

    const { TabPane } = Tabs;

    return(
        <>
            <Card title="TC Download">
                <Row>
                    <Col span={24}>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Add" key="1">
                                <TransferCertificateAdd />
                            </TabPane>
                            <TabPane tab="Download" key="2">
                                <TransferCertificateDownload />
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </Card>
        </>
    )
}