import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';
import { DeleteOutlined, DownCircleFilled, DownloadOutlined, EditOutlined, FilePdfOutlined, SaveOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { SelectClass } from '../../../select/SelectClass';
import { render } from '@testing-library/react';
import { SelectClassConfig } from '../../../select/SelectClassConfig';


export default function GrandFinalMarkSheetDownload(props) {

    const [createForm] = Form.useForm();

    const downloadGrandFinalMarkSheet = useStoreActions((state) =>  state.exam.downloadGrandFinalMarkSheet)

    const grandFinalMarkSheetSheetDownload = (value) => {
        let payload:any = {
            classConfigId: value.section,
            startRoll: value.fromRoll,
            endRoll: value.toRoll
        }
        downloadGrandFinalMarkSheet(payload)
    }

    return(
        <>
            <Card title="Grand Final Mark Sheet Download">
                <Form
                    layout="vertical"
                    id="sessionInfo"
                    onFinish={grandFinalMarkSheetSheetDownload}
                    form={createForm}
                >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="section"
                                    label="Select Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectClassConfig />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="fromRoll"
                                    label="From Roll"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Enter from roll" },
                                    ]}
                                >
                                    <InputNumber placeholder="from roll" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="toRoll"
                                    label="To Roll"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Enter to roll" },
                                    ]}
                                >
                                    <InputNumber placeholder="to roll" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Button className="pull-right" type="primary" htmlType="submit" icon={<DownloadOutlined />} >
                                    Download
                                </Button>
                            </Col>
                        </Row>
                </Form>
            </Card>
        </>
    )
}