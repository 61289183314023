
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import TableView from '../../../../contents/AntTableResponsive';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf, ldatepdf } from '../../../utils/pdf';
import { FilePdfOutlined } from '@ant-design/icons';
import ReactExport from "react-export-excel";
import { SelectSection } from '../../../select/SelectSection';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { v4 as uuidv4 } from "uuid";
require('jspdf-autotable');


const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

export default function SectionWiseSummaryReport() {

    const fetchSectionWiseSummaryReport = useStoreActions((state) => state.student.fetchSectionWiseSummaryReport);
    const sectionWiseSummaryListReport = useStoreState((state) =>  state.student.sectionWiseSummaryListReport);
    const loading = useStoreState((state) =>  state.student.loading);

    useEffect(() => {
        fetchSectionWiseSummaryReport();
    }, []);

    
    const studentListColumn = [
        { title: 'Class Name', dataIndex: 'className', key: 'className', showOnResponse: true, showOnDesktop: true  },
        { title: 'Shift Name', dataIndex: 'shiftName', key: 'shiftName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Section Name', dataIndex: 'sectionName', key: 'sectionName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Total Student', dataIndex: 'totalStudent', key: 'totalStudent', showOnResponse: true, showOnDesktop: true  },
    ];

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Section Wise Student Summary`;
        pdfDataL(doc, "");
        doc.text(details, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);            
        };


        var col = [
            "Class Name",
            "Shift Name",
            "Section Name",
            "Total Student",
        ].filter(Boolean);

        var rows:any = [];


        sectionWiseSummaryListReport.forEach(element => {
            var temp:any = [ 
                element.className, 
                element.shiftName, 
                element.sectionName,
                element.totalStudent,
            ];
            rows.push(temp);
        });
       

            let first = doc.autoTable.previous;
            doc.autoTable(col, rows, {
                startY: 45,
                showHeader: "firstPage",
                // theme: 'grid',
                styles: {
                    overflow: 'linebreak',
                },
                // headerStyles: {
                //      fillColor: [211,211,211],
                //      textColor: [0, 0, 0],
                //     // fontSize: 10
                //     lineWidth: .01,
                //     lineColor: [224, 224, 224]
                // },
                columnStyles: {
                    guardianMobile: {
                        halign: "left",
                        fontSize: 8,
                        columnWidth: 250
                    }
                },
                addPageContent: pageContent,
            });

  
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    return(
        <>
            <Card title="Section Wise Student Summary">
            <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: sectionWiseSummaryListReport.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns:studentListColumn,
                                rowKey: uuidv4(),
                                dataSource: sectionWiseSummaryListReport,
                                filterData: sectionWiseSummaryListReport,
                                pagination: true,
                                bordered: true,                           
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                        {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                    </Col>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>
                        <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Section Wise Student Summary`}
                            >
                                <ExcelSheet data={sectionWiseSummaryListReport} name="Section Wise Student Summary">
                                    <ExcelColumn label="Class Name" value="className" />
                                    <ExcelColumn label="Shift Name" value="shiftName" />
                                    <ExcelColumn label="Section Name" value="sectionName" />
                                    <ExcelColumn label="Total Student" value="totalStudent" />
                                </ExcelSheet>
                            </ExcelFile>
                            <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportPdf()} >PDF Download</Button>
                        </Space>
                    </Col>
                </Row>

            </Card>
        </>
    )
}
