import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../../../store/hooks/easyPeasy';
import { Table, Tag} from 'antd';
import TableView from '../../../../../contents/AntTableResponsive';
import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import Column from 'rc-table/lib/sugar/Column';
import { render } from '@testing-library/react';
import { SelectClass } from '../../../../select/SelectClass';
import markSheetTemplate1 from '../../exam-template/mark-sheet-template-1.png';
import markSheetTemplate2 from '../../exam-template/mark-sheet-template-2.png';
import markSheetTemplate3 from '../../exam-template/mark-sheet-template-3.png';
import generalExamTemplate1 from '../../exam-template/general-exam-template-1.jpg';
import Meta from 'antd/lib/card/Meta';
import TextArea from 'rc-textarea';

export default function RemarksTemplate(){

    const { TextArea } = Input;
    const { Option } = Select;
    const [remarkTemplateUpdateForm] = Form.useForm();
    const [remarkTemplateForm] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const remarkTemplateSave = useStoreActions((state) => state.exam.remarkTemplateSave);
    const fetchExamRemarksList = useStoreActions((state) => state.exam.fetchExamRemarksList);
    const examRemarksListArray = useStoreState((state) =>  state.exam.examRemarksListArray);
    const remarkTemplateUpdate = useStoreActions((state) =>  state.exam.remarkTemplateUpdate);
    const deleteRemarkTemplateDelete = useStoreActions((state) =>  state.exam.deleteRemarkTemplateDelete);
    const [examRemarksList, setExamRemarksList] = useState([]);
    const [examRemarksId, setExamRemarksId] = useState("");

    useEffect(() => {
        fetchExamRemarksList()
        setExamRemarksList(examRemarksListArray)
        
    }, [])

    const templateCofigColumn = [
        { title: 'Remarks Template Name', dataIndex: 'remarksTitle', key: 'remarksTitle', showOnResponse: true, showOnDesktop: true},  
        { title: 'Remarks', dataIndex: 'remarks', key: 'remarks', showOnResponse: true, showOnDesktop: true},  
        { title: 'Edit', dataIndex: 'templateName', key: 'templateName', showOnResponse: true, showOnDesktop: true,
        render: (text, record, index) => (<>
                <Button type='primary' icon={<EditOutlined />} onClick={() => {
                    setIsModalVisible(true);
                    setExamRemarksId(record.id)
                    remarkTemplateUpdateForm.setFieldsValue({
                        templateNameUpdate: record.remarksTitle,
                        remarksUpdate: record.remarks,
                    });
                }} />
            </>
        ),
        }, 
        { title: 'Delete', dataIndex: 'marksheetHeader', key: 'marksheetHeader', showOnResponse: true, showOnDesktop: true,
            render: (text, record, index) => (<>
                <Popconfirm
                    title="Are you sure to delete this?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deleteRemarkTemplateDelete(record?.id)}
                >
                    <Tooltip title="Delete">
                        <Button danger icon={<DeleteOutlined />} />
                    </Tooltip>
                </Popconfirm>
            </>
            )
        },

    ]


    const remarksTemplateSave = (value) => {
        
        let postData = {
            "remarks": value.remarks,
            "remarksTitle": value.templateName
        }
        remarkTemplateSave(postData);
    }

    const remarkUpdateSubmit = (value) => {
        let postData = {
            "id": examRemarksId,
            "remarks": value.remarksUpdate,
            "remarksTitle": value.templateNameUpdate
        }
        remarkTemplateUpdate(postData);  
        setIsModalVisible(false);  
        fetchExamRemarksList()
        setExamRemarksList(examRemarksListArray)    
    }

    

    useEffect(() => {
        setExamRemarksList(examRemarksListArray)
    },[examRemarksListArray])

    return(
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
                    <Form
                        layout="vertical"
                        id="sessionYearInfo"
                        onFinish={remarksTemplateSave}
                        form={remarkTemplateForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                <Form.Item
                                    name="templateName"
                                    label="Remarks Template Name"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write remarks template name" },
                                    ]}
                                >
                                    <Input placeholder="Write remarks template name"  />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                <Form.Item
                                    name="remarks"
                                    label="Remarks"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write remarks" },
                                    ]}
                                >
                                    <TextArea rows={6}>
                                    </TextArea>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="mt-0 float-right" >Save</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <br />
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}} lg={{ span: 24}} xl={{ span: 24}}>
                <TableView
                        antTableProps={{
                            showHeader: true,
                            columns:templateCofigColumn,  
                            rowKey:"id",
                            dataSource: examRemarksList,
                            filterData: examRemarksList,
                            pagination: true,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                </Col>
            </Row>
            <Modal
                title="Exam Remarks List Edit"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={remarkUpdateSubmit}
                    form={remarkTemplateUpdateForm}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="templateNameUpdate"
                                label="Remarks Template Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write remarks template name" },
                                ]}
                            >
                                <Input placeholder="Write remarks template name"  />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="remarksUpdate"
                                label="Remarks"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write remarks" },
                                ]}
                            >
                                <TextArea rows={6}>
                                </TextArea>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}