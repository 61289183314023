import React from 'react'
import { Card, Tabs } from 'antd';
import MonthlyDueDetailsSection from './MonthlyDueDetailsSection';
import MonthlyDueDetailsInstitute from './MonthlyDueDetailsInstitute';


export default function StaffIdMappingWrapper(props) {

    const { TabPane } = Tabs;

    return (
        <>
            <Card title="Monthly Due Details">
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Section Wise" key="1">
                        <MonthlyDueDetailsSection />
                    </TabPane>
                    <TabPane tab="Institute Wise" key="2">
                        <MonthlyDueDetailsInstitute />
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )
}