import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';
import TableView from '../../../../contents/AntTableResponsive';
import { SaveOutlined } from '@ant-design/icons';


export default function ExamGrade(props) {

  const { Option } = Select;
  const fetchShortCodeClassList = useStoreActions((state) => state.exam.fetchShortCodeClassList);
  const fetchGradeList = useStoreActions((state) => state.exam.fetchGradeList);
  const updateExamGrade = useStoreActions((state) => state.exam.updateExamGrade);
  const createExamGrade = useStoreActions((state) => state.exam.createExamGrade);
  const shortCodeClasslist = useStoreState((state) => state.exam.shortCodeClasslist);
  const gradeTablelist = useStoreState((state) => state.exam.gradeTablelist);
  const fetchSubjectList = useStoreActions((state) => state.exam.fetchSubjectList);
  const loading = useStoreState((state) => state.exam.loading);

  const [shortCodeSelections, setShortCodeSelections] = useState([]);
  const [selectedExamGradeValue, setselectedExamGradeValue] = useState<any>([]);


  const [classIdSelect, setClassIdSelect] = useState("");


  const [gradeListTableData, setGradeListableData] = useState([])

  const staticRablecolumns = [
    { title: 'Title', dataIndex: 'gradeName', key: 'gradeName', showOnResponse: true, showOnDesktop: true },
    { title: 'Total Marks', dataIndex: 'gradeRange', key: 'totalMarks', showOnResponse: true, showOnDesktop: true },

  ];

  const staticTableData = [
    { key: '1', gradeName: 'A+', gradeRange: '80-100', gradePoint: 5, numberHigh: 100, numberLow: 80, pointHigh: 5, pointLow: 5 },
    { key: '2', gradeName: 'A', gradeRange: '70-79', gradePoint: 4, numberHigh: 79.99, numberLow: 70, pointHigh: 4.99, pointLow: 4 },
    { key: '3', gradeName: 'A-', gradeRange: '60-69', gradePoint: 3.5, numberHigh: 69.99, numberLow: 60, pointHigh: 3.99, pointLow: 3.5 },
    { key: '4', gradeName: 'B', gradeRange: '50-59', gradePoint: 3, numberHigh: 59.99, numberLow: 50, pointHigh: 3.49, pointLow: 3 },
    { key: '5', gradeName: 'C', gradeRange: '40-49', gradePoint: 2, numberHigh: 49.99, numberLow: 40, pointHigh: 2.99, pointLow: 2 },
    { key: '6', gradeName: 'D', gradeRange: '33-39', gradePoint: 1, numberHigh: 39.99, numberLow: 33, pointHigh: 1.99, pointLow: 1 },
    { key: '7', gradeName: 'F', gradeRange: '00-32', gradePoint: 0, numberHigh: 32.99, numberLow: 0, pointHigh: 0, pointLow: 0 },
  ];

  const shortCodeList = [
    { title: 'Class', dataIndex: 'className', key: 'id', showOnResponse: true, showOnDesktop: true },
    { title: 'Grade', dataIndex: 'gradeName', key: 'id', showOnResponse: true, showOnDesktop: true },
    {
      title: 'Grade Range', dataIndex: 'gradeRange', key: 'id', showOnResponse: true, showOnDesktop: true,
      render: (text, record, index) => (
        <Input defaultValue={record.gradeRange} name="gradeRange" className="gradeRange" onChange={(e) => onchangeGradeRangeUpdate(text, record, e.target.value, e.target.name, index)} />
      ),
    },
    {
      title: 'Action', dataIndex: 'gradeRange', key: 'id', showOnResponse: true, showOnDesktop: true,
      render: (text, record, index) => (
        <Button type="primary" htmlType="button" icon={<SaveOutlined />} onClick={(e) => updateGradeRange(text, record, index)}>Update</Button>
      ),
    },

  ];

  useEffect(() => {
    fetchShortCodeClassList();
    fetchGradeList();
    fetchSubjectList();
    setGradeListableData(gradeTablelist);
  }, []);

  const onchangeGradeRangeUpdate = (text, record, val, fieldName, index) => {
    gradeTablelist.map((item: any) => {
      if (item.id == record.id) {
        item[fieldName] = val
      }
    });
  }

  const onSelectChangeExamGrade = (selectedRowKeys, value) => {
    setShortCodeSelections(selectedRowKeys);
    setselectedExamGradeValue(value);
  };


  const examGradeRowSelection = {
    selectedRowKeys: shortCodeSelections,
    onChange: onSelectChangeExamGrade,
  };

  const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
  const [selectedValue, setselectedValue] = useState<any>([]);

  const onSelectChange = (selectedRowKeys, value) => {
    setselectedRowKeys(selectedRowKeys);
    setselectedValue(value);
    // console.log(value)
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  const saveExamGrade = () => {
    var postData = {
      classId: classIdSelect,
      gradeDetails: selectedValue.map(function (item) {
        delete item.key;
        return item;
      })
    };
    createExamGrade(postData);
    setselectedRowKeys([]);
    setselectedValue([]);

  }

  const oncahngeClassId = (val) => {
    setClassIdSelect(val);
  }



  const updateGradeRange = (text, record, index) => {
    let gradeIdGet;
    let gradeRangeGet;
    gradeTablelist.map((item: any) => {
      if (item.id == record.id) {
        return (
          gradeIdGet = item.id,
          gradeRangeGet = item.gradeRange
        )
      }
    });
    console.log('postData', gradeListTableData);
    let postData = {
      gradeId: gradeIdGet,
      gradeRange: gradeRangeGet
    }
    console.log('postData', postData);

    updateExamGrade(postData);
  }

  return (
    <>
      <Row>
        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }}>
          <Card className="p-0" title="Select Class" bordered={false}>
            <Row>
              <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                <Select placeholder="Select Class" style={{ width: "100%" }} onChange={(val) => oncahngeClassId(val)}>
                  {shortCodeClasslist ? (
                    shortCodeClasslist.map((type, idx) => (
                      <Option key={type.id} value={type.id}>
                        {type.name}
                      </Option>
                    ))
                  ) : (
                    <Option value="fetching">Fetching Class</Option>
                  )}
                </Select>
              </Col>
            </Row>
            < Skeleton loading={loading} paragraph={{ rows: 10 }} />
            <br />
            <TableView
              antTableProps={{
                showHeader: true,
                rowSelection: rowSelection,
                columns: staticRablecolumns,
                rowKey: "key",
                dataSource: staticTableData,
                filterData: staticTableData,
                pagination: false,
                bordered: true
              }}
              mobileBreakPoint={768}
            />
            <br />
            <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="float-right" onClick={() => saveExamGrade()}>Save</Button>
          </Card>
        </Col>
        < Skeleton loading={loading} paragraph={{ rows: 10 }} />
        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 16 }}>
          <Card className="p-0" title="Grade List" bordered={false}>
            <TableView
              antTableProps={{
                showHeader: true,
                columns: shortCodeList,
                rowKey: "shortCodeId",
                dataSource: gradeTablelist,
                filterData: gradeTablelist,
                pagination: false,
                bordered: true
              }}
              mobileBreakPoint={768}
            />
          </Card>
        </Col>
      </Row>
    </>
  )
}