import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Space, Table } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf, ldatepdf, pdfGenerate } from '../../../utils/pdf';
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';

import TableResponsive from "../../../../contents/AntTableResponsive";
import ReactExport from "react-export-excel";
import Text from 'antd/lib/typography/Text';
require('jspdf-autotable');



let fd = '';
let td = '';
let totalsum = '';

let totalfeeCollection = 0;
let totalfineCollection = 0;
let totaltotalCollection = 0;
export default function FeeHeadCollectionfo(props) {
    const feeHeadsubHeadCollectionReport = useStoreState((state) => state.saccount.feeHeadsubHeadCollectionReport);
    const fetchfeeHeadsubHeadCollectionReport = useStoreActions((state) => state.saccount.fetchfeeHeadsubHeadCollectionReport);
    const loading = useStoreState((state) =>  state.saccount.loading);
    

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
        }
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");
        fetchfeeHeadsubHeadCollectionReport(postData);
    };

    const columns = [

        {
            title: 'Fee Head',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
            showOnResponse: true,
            showOnDesktop: true,
        },        
        {
            title: 'Fee Sub Head',
            dataIndex: 'feeSubHeadName',
            key: 'feeSubHeadName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Fee Collection',
            dataIndex: 'feeCollection',
            key: 'feeCollection',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Fine Collection',
            dataIndex: 'fineCollection',
            key: 'fineCollection',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Total Collection',
            dataIndex: 'totalCollection',
            key: 'totalCollection',
            showOnResponse: true,
            showOnDesktop: true,
        },
    ];

    function exportPdf() {
        var details = `Fee Head Collection  ${fd} to ${td}`;

        var doc = new jsPDF("l", "mm", "a4");


        pdfDataL(doc, details);

        var col = ["Fee Head", "Fee Sub Head", "Fee Collection", "Fine Collection", "Total Collection",];

        var rows: any = [];

        var temp: any = []


        feeHeadsubHeadCollectionReport.forEach(element => {
            var temp = [element.feeHeadName, element.feeSubHeadName,  element.feeCollection, element.fineCollection,
            element.totalCollection];
            rows.push(temp);
        });

        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };

        doc.autoTable(col, rows, {
            headerStyles: {
                lineWidth: .01,
                lineColor: [224, 224, 224],
                overflow: 'linebreak'
            },
            columnStyles: {
                0: { overflow: 'linebreak', columnWidth: 60, halign: "left" },
                1: { overflow: 'linebreak', columnWidth: 60, halign: "left" },
                2: { overflow: 'linebreak', columnWidth: 48, halign: "right" },
                3: { overflow: 'linebreak', columnWidth: 48, halign: "right" },
                4: { overflow: 'linebreak', columnWidth: 48, halign: "right"  }
            },
            theme: "grid",
            startY: 45,
            addPageContent: pageContent
        });
        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
            { title: "", dataKey: "b3" },
            { title: "", dataKey: "b4" },
            { title: "", dataKey: "b5" },
        ], [
            {
                b1: "Grand Total:",
                b2: "",
                b3: totalfeeCollection,
                b4: totalfineCollection,
                b5: totaltotalCollection,
            }
        ], {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',

            columnStyles: {
                b1: {
                    halign: "left",
                    fontStyle: 'bold',
                    columnWidth: 60
                },
                b2: {
                    halign: "left",
                    columnWidth: 60
                },
                b3: {
                    halign: "right",
                    columnWidth: 48
                },                
                b4: {
                    halign: "right",
                    columnWidth: 48
                },                
                b5: {
                    halign: "right",
                    columnWidth: 48
                }
            },

            // addPageContent: pageContent,
        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }

        doc.save(details + ".pdf");

    }

    return (
        <>
            <>
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>


                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>

                { < Skeleton loading={loading} paragraph={{rows:10}} />}
                {feeHeadsubHeadCollectionReport?.length > 0 && !loading && 
                    <>
                        <TableResponsive
                            antTableProps={{
                                showHeader: true,
                                columns: columns,
                                dataSource: feeHeadsubHeadCollectionReport,
                                filterData: feeHeadsubHeadCollectionReport,
                                pagination: true,
                                bordered: true,
                                rowKey: "masterId",
                                summary: function () {
                                     totalfeeCollection = 0;
                                     totalfineCollection = 0;
                                     totaltotalCollection = 0;

                                    feeHeadsubHeadCollectionReport?.forEach(({ feeCollection, fineCollection, totalCollection }) => {
                                        totalfeeCollection += feeCollection;
                                        totalfineCollection += fineCollection;
                                        totaltotalCollection += totalCollection;
                                    });

                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0}>
                                                    Grand Total
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}>
                                                   
                                                </Table.Summary.Cell>                                               
                                                 <Table.Summary.Cell index={2}>
                                                    <Text type="danger" strong>{totalfeeCollection}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={3}>
                                                    <Text type="danger" strong>{totalfineCollection}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={4}>
                                                    <Text type="danger" strong>{totaltotalCollection}</Text>
                                                </Table.Summary.Cell>


                                            </Table.Summary.Row>

                                        </>
                                    );
                                }
                            }}
                            mobileBreakPoint={768}

                        />
                        <Space style={{ float: 'right' }} size="middle">

                            <Button type="primary" icon={<FilePdfOutlined />} onClick={exportPdf} > Download PDF </Button>
                        </Space>
                    </>
                }
            </>
        </>
    )
}
