import { ContainerOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, notification, Popconfirm, Row, Select, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';


const { Option } = Select;

export default function PayslipTemplate(props) {

    const [form] = Form.useForm();

    const defaultPayslipTemplateList = useStoreState((state) => state.payslip.defaultPayslipTemplateList);
    const fetchdefaultPayslipTemplateList = useStoreActions((state) => state.payslip.fetchdefaultPayslipTemplateList);
    const viewPayslipTemplate = useStoreState((state) => state.payslip.viewPayslipTemplate);
    const fetchviewPayslipTemplate = useStoreActions((state) => state.payslip.fetchviewPayslipTemplate);
    const savePayslipTemplate = useStoreActions((state) => state.payslip.savePayslipTemplate);


    useEffect(() => {
        fetchdefaultPayslipTemplateList();
        fetchviewPayslipTemplate();
    }, [])

    const formSubmit = (value) => {
        console.log(value);
        savePayslipTemplate(value.payslipDefaultId);
    }





    return (
        <Card title="Payslip Template">
            <Row gutter={8}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form
                        layout="vertical"
                        onFinish={formSubmit}
                        id="basic-info"
                        form={form}
                    >
                        <Row gutter={8}>

                            <Col xs={24} sm={24} md={24} lg={5} xl={5}>  </Col>
                            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                <Form.Item
                                    name="payslipDefaultId"
                                    label="Template List"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select template" },
                                    ]}
                                >

                                    <Select placeholder="Select Template">
                                        {defaultPayslipTemplateList ? (
                                            defaultPayslipTemplateList.map((type, idx) => (
                                                <Option key={type.payslipDefaultId} value={type.payslipDefaultId}>
                                                    {type.payslipTemplateName}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching Template</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                                        Save
                                    </Button>
                                </Space>
                            </Col>

                        </Row>
                    </Form>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div>
                        <strong>Payslip Template Name:</strong>  {viewPayslipTemplate?.payslipTemplateName} <br />
                        <strong>Payslip Template Note:</strong>  {viewPayslipTemplate?.payslipTemplateNote} <br />
                    </div>
                </Col>


            </Row>
        </Card>
    )
}
