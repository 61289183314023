import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { createFeeHead, createFeeSubHead, createSinglePayslip, createWaiver, deleteAbsentFineRecord, deleteFeeAmountConfigurationUrl, deletefeeFineLedgerConfiguration, deleteFeeHead, deleteFeeHeadLedgerConfiguration, deletefeeInvoiceList, deleteFeeSubHead, deletefeeSubHeadLedgerConfiguration, deletestudentFeeHeadLedgerConfiguration, deleteStudentWaiver, deleteWaiver, deleteyearWiseFeeHeadTime, downloadInvoice, downloadPayslip, feeheadDeleteSave, fetchabsentFineRecordStudentList, fetchabsentFineRecordStudentListForInput, fetchallPaidReport, fetchclassPaidReport, fetchdateToDateClassSingleFeeHeadCollection, fetchfeeAmountConfigurationList, fetchfeeFineLedgerConfigurationList, fetchfeeHeadCollectionReport, fetchfeeHeadCollectionReportclass, fetchfeeheadDeletedList, fetchfeeHeadLedgerConfigurationList, fetchfeeHeadList, fetchfeeHeadsubHeadollectionReport, fetchfeeInvoiceList, fetchfeeSubHeadConfigurationList, fetchfeeSubHeadLedgerConfigurationList, fetchfeesubHeadList, fetchmonthlydueReport, fetchofpsconfigurationView, fetchsectionDueReport, fetchsectionDueReportMonth, fetchsectionDueReportMonth2, fetchsectionPaidReport, fetchshowMultipleSectionDateToDateTotalPaidDetails, fetchshowStudentFeeHeadPaymentView, fetchspgStatement, fetchstudentCollecionView, fetchstudentDeletedFeeSubHeadList, fetchstudentListForFeeSubHeadDelete, fetchstudentWaiverList, fetchwaiverList, fetchyearWiseFeeHeadTime, moneyReceiptDownload, reAssignStudentFeeSubHead, saveAbsentFineRecord, saveFeeAmountConfiguration, savefeeFineLedgerConfiguration, saveFeeHeadLedgerConfiguration, savefeeSubHeadLedgerConfiguration, saveFeeSubHeadTimeConfig, saveFeeSubheadTimeConfiguration, saveofpsconfigurationView, savestudentCollecionView, saveStudentWaiver, showsectionTotalPaidDetails, showsectionTotalPaidDetailsDateToDate, studentFeeSubHeadDelete, updateAbsentFineRecord, updateFeeAmountConfiguration, updateFeeHead, updateFeeSubHead, updateStudentWaiver, updateWaiver } from '../../../http/saccount/saccount';
import FileSaver from 'file-saver'
export interface Saccount {

	createFeeHead: Thunk<Saccount, any>
	updateFeeHead: Thunk<Saccount, any>
	deleteFeeHead: Thunk<Saccount, any>
	feeHeadList: any;
	setfeeHeadList: Action<Saccount, any>;
	fetchfeeHeadList: Thunk<Saccount>

	createFeeSubHead: Thunk<Saccount, any>
	updateFeeSubHead: Thunk<Saccount, any>
	deleteFeeSubHead: Thunk<Saccount, any>
	feeSubHeadList: any;
	setfeeSubHeadList: Action<Saccount, any>;
	fetchfeesubHeadList: Thunk<Saccount>

	createWaiver: Thunk<Saccount, any>
	updateWaiver: Thunk<Saccount, any>
	deleteWaiver: Thunk<Saccount, any>
	waiverList: any;
	setwaiverList: Action<Saccount, any>;
	fetchwaiverList: Thunk<Saccount>


	feeHeadLedgerConfigurationList: any;
	setfeeHeadLedgerConfigurationList: Action<Saccount, any>;
	fetchfeeHeadLedgerConfigurationList: Thunk<Saccount>
	saveFeeHeadLedgerConfiguration: Thunk<Saccount, any>
	deleteFeeHeadLedgerConfiguration: Thunk<Saccount, any>

	feeSubHeadLedgerConfigurationList: any;
	setfeeSubHeadLedgerConfigurationList: Action<Saccount, any>;
	fetchfeeSubHeadLedgerConfigurationList: Thunk<Saccount>
	savefeeSubHeadLedgerConfiguration: Thunk<Saccount, any>
	deletefeeSubHeadLedgerConfiguration: Thunk<Saccount, any>


	feeFineLedgerConfigurationList: any;
	setfeeFineLedgerConfigurationList: Action<Saccount, any>;
	fetchfeeFineLedgerConfigurationList: Thunk<Saccount>
	savefeeFineLedgerConfiguration: Thunk<Saccount, any>
	deletefeeFineLedgerConfiguration: Thunk<Saccount, any>

	feeSubHeadConfigurationList: any;
	setfeeSubHeadConfigurationList: Action<Saccount, any>;
	fetchfeeSubHeadConfigurationList: Thunk<Saccount>

	feeSubHeadConfigurationList2: any;
	setfeeSubHeadConfigurationList2: Action<Saccount, any>;
	fetchfeeSubHeadConfigurationList2: Thunk<Saccount>

	yearWiseFeeHeadTime: any;
	setyearWiseFeeHeadTime: Action<Saccount, any>;
	fetchyearWiseFeeHeadTime: Thunk<Saccount, any>

	deleteyearWiseFeeHeadTime: Thunk<Saccount>

	feeAmountConfigurationList: any;
	setfeeAmountConfigurationList: Action<Saccount, any>;
	fetchfeeAmountConfigurationList: Thunk<Saccount>
	saveFeeAmountConfiguration: Thunk<Saccount, any>
	updateFeeAmountConfiguration: Thunk<Saccount, any>
	deleteFeeAmountConfiguration: Thunk<Saccount, any>

	saveFeeSubHeadTimeConfig: Thunk<Saccount, any>
	saveFeeSubheadTimeConfiguration: Thunk<Saccount, any>

	ofpsconfigurationView: any;
	setofpsconfigurationView: Action<Saccount, any>;
	fetchofpsconfigurationView: Thunk<Saccount>
	saveofpsconfigurationView: Thunk<Saccount, any>


	studentWaiverList: any;
	setstudentWaiverList: Action<Saccount, any>;
	setstudentWaiverListtab: Action<Saccount>;
	fetchstudentWaiverList: Thunk<Saccount>
	saveStudentWaiver: Thunk<Saccount, any>
	updateStudentWaiver: Thunk<Saccount, any>
	deleteStudentWaiver: Thunk<Saccount, any>

	feeheadDeleteSave: Thunk<Saccount, any>

	studentListForFeeSubHeadDelete: any;
	setstudentListForFeeSubHeadDelete: Action<Saccount, any>;
	fetchstudentListForFeeSubHeadDelete: Thunk<Saccount, any>	
	
	absentFineRecordStudentListForInput: any;
	setabsentFineRecordStudentListForInput: Action<Saccount, any>;
	fetchabsentFineRecordStudentListForInput: Thunk<Saccount, any>	
	saveAbsentFineRecord: Thunk<Saccount, any>
	
	absentFineRecordStudentList: any;
	setabsentFineRecordStudentList: Action<Saccount, any>;
	fetchabsentFineRecordStudentList: Thunk<Saccount, any>	
	updateAbsentFineRecord: Thunk<Saccount, any>	
	deleteAbsentFineRecord: Thunk<Saccount, any>	

	studentFeeSubHeadDelete: Thunk<Saccount, any>	
	
	studentDeletedFeeSubHeadList: any;
	setstudentDeletedFeeSubHeadList: Action<Saccount, any>;
	fetchstudentDeletedFeeSubHeadList: Thunk<Saccount, any>	

	reAssignStudentFeeSubHead: Thunk<Saccount, any>

	deletestudentFeeHeadLedgerConfiguration: Thunk<Saccount, any>

	feeheadDeletedList: any;
	setfeeheadDeletedList: Action<Saccount, any>;
	fetchfeeheadDeletedList: Thunk<Saccount>

	studentCollecionView: any;
	setstudentCollecionView: Action<Saccount, any>;
	fetchstudentCollecionView: Thunk<Saccount, any>	
	
	studentCollecionView2: any;
	setstudentCollecionView2: Action<Saccount, any>;
	fetchstudentCollecionView2: Thunk<Saccount, any>

	checkmanualFee: boolean;
	setcheckmanualFee: Action<Saccount, any>;	
	checkmanualFee2: boolean;
	setcheckmanualFee2: Action<Saccount, any>;

	savestudentCollecionView: Thunk<Saccount, any>

	sectionPaidReport: any;
	setsectionPaidReport: Action<Saccount, any>;
	fetchsectionPaidReport: Thunk<Saccount, any>	
	
	classPaidReport: any;
	setclassPaidReport: Action<Saccount, any>;
	fetchclassPaidReport: Thunk<Saccount, any>

	allPaidReport: any;
	setallPaidReport: Action<Saccount, any>;
	fetchallPaidReport: Thunk<Saccount, any>


	sectionDueReport: any;
	setsectionDueReport: Action<Saccount, any>;
	fetchsectionDueReport: Thunk<Saccount, any>		
	
	sectionDueReport2: any;
	setsectionDueReport2: Action<Saccount, any>;
	fetchsectionDueReport2: Thunk<Saccount, any>		
			
	
	sectionDueReportMonth: any;
	setsectionDueReportMonth: Action<Saccount, any>;
	fetchsectionDueReportMonth: Thunk<Saccount, any>	
	fetchsectionDueReportMonth2: Thunk<Saccount, any>	
	
	monthlydueReport: any;
	setmonthlydueReport: Action<Saccount, any>;
	fetchmonthlydueReport: Thunk<Saccount, any>

	feeHeadCollectionReport: any;
	setfeeHeadCollectionReport: Action<Saccount, any>;
	fetchfeeHeadCollectionReport: Thunk<Saccount, any>		
	
	feeHeadsubHeadCollectionReport: any;
	setfeeHeadsubHeadCollectionReport: Action<Saccount, any>;
	fetchfeeHeadsubHeadCollectionReport: Thunk<Saccount, any>			
	
	showStudentFeeHeadPaymentView: any;
	setshowStudentFeeHeadPaymentView: Action<Saccount, any>;
	fetchshowStudentFeeHeadPaymentView: Thunk<Saccount, any>;		
	showsectionTotalPaidDetails: Thunk<Saccount, any>;		

	showMultipleSectionDateToDateTotalPaidDetails: any;
	setshowMultipleSectionDateToDateTotalPaidDetails: Action<Saccount, any>;
	fetchshowMultipleSectionDateToDateTotalPaidDetails: Thunk<Saccount, any>;
	
	spgStatement: any;
	setspgStatement: Action<Saccount, any>;
	fetchspgStatement: Thunk<Saccount, any>	
	
	feeHeadCollectionReportClass: any;
	setfeeHeadCollectionReportClass: Action<Saccount, any>;
	fetchfeeHeadCollectionReportclass: Thunk<Saccount, any>
	fetchdateToDateClassSingleFeeHeadCollection: Thunk<Saccount, any>

	feeInvoiceList: any;
	setfeeInvoiceList: Action<Saccount, any>;
	fetchfeeInvoiceList: Thunk<Saccount, any>

	deletefeeInvoiceList: Thunk<Saccount, any>

	downloadInvoice: Thunk<Saccount, any>

	createSinglePayslip: Thunk<Saccount, any>
	 
    loading: boolean;
    setLoading: Action<Saccount, boolean>;

	showStudentFeeHeadPaymentViewDateToDate: any;
	setshowStudentFeeHeadPaymentViewDateToDate: Action<Saccount, any>;
	showsectionTotalPaidDetailsDateToDate: Thunk<Saccount, any>;	

}

export const saccountStore: Saccount = {

	feeHeadList: null,
	fetchfeeHeadList: thunk(async (actions) => {
		actions.setLoading(true);
		const response = await fetchfeeHeadList();
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			actions.setfeeHeadList(body?.item);
		} else {
			actions.setLoading(false);
		}
	}),

	loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload;
	}),

	setfeeHeadList: action((state, payload) => {
		state.feeHeadList = payload;
	}),

	createFeeHead: thunk(async (actions, payload) => {
		const response = await createFeeHead(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeHeadList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	updateFeeHead: thunk(async (actions, payload) => {
		const response = await updateFeeHead(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeHeadList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	deleteFeeHead: thunk(async (actions, payload) => {
		const response = await deleteFeeHead(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeHeadList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	feeSubHeadList: null,
	fetchfeesubHeadList: thunk(async (actions) => {
		actions.setLoading(true);
		const response = await fetchfeesubHeadList();
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			actions.setfeeSubHeadList(body?.item);
		} else {
			actions.setLoading(false);
		}
	}),

	setfeeSubHeadList: action((state, payload) => {
		state.feeSubHeadList = payload;
	}),

	createFeeSubHead: thunk(async (actions, payload) => {
		const response = await createFeeSubHead(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeesubHeadList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	updateFeeSubHead: thunk(async (actions, payload) => {
		const response = await updateFeeSubHead(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeesubHeadList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	deleteFeeSubHead: thunk(async (actions, payload) => {
		const response = await deleteFeeSubHead(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeesubHeadList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	waiverList: null,
	fetchwaiverList: thunk(async (actions) => {
		actions.setLoading(true);
		const response = await fetchwaiverList();
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			//console.log(body)
			actions.setwaiverList(body?.item);
		} else {
			actions.setLoading(false);
		}
	}),

	setwaiverList: action((state, payload) => {
		state.waiverList = payload;
	}),

	createWaiver: thunk(async (actions, payload) => {
		const response = await createWaiver(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchwaiverList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	updateWaiver: thunk(async (actions, payload) => {
		const response = await updateWaiver(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchwaiverList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	deleteWaiver: thunk(async (actions, payload) => {
		const response = await deleteWaiver(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchwaiverList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	feeHeadLedgerConfigurationList: null,
	fetchfeeHeadLedgerConfigurationList: thunk(async (actions) => {
		actions.setLoading(true);
		const response = await fetchfeeHeadLedgerConfigurationList();
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			//console.log(body)
			actions.setfeeHeadLedgerConfigurationList(body?.item);
		} else {
			actions.setLoading(false);

		}
	}),

	setfeeHeadLedgerConfigurationList: action((state, payload) => {
		state.feeHeadLedgerConfigurationList = payload;
	}),


	saveFeeHeadLedgerConfiguration: thunk(async (actions, payload) => {
		const response = await saveFeeHeadLedgerConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeHeadLedgerConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	deleteFeeHeadLedgerConfiguration: thunk(async (actions, payload) => {
		const response = await deleteFeeHeadLedgerConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeHeadLedgerConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	feeSubHeadLedgerConfigurationList: null,
	fetchfeeSubHeadLedgerConfigurationList: thunk(async (actions) => {
		actions.setLoading(true);
		const response = await fetchfeeSubHeadLedgerConfigurationList();
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			//console.log(body)
			actions.setfeeSubHeadLedgerConfigurationList(body?.item);
		} else {
			actions.setLoading(false);
		}
	}),

	setfeeSubHeadLedgerConfigurationList: action((state, payload) => {
		state.feeSubHeadLedgerConfigurationList = payload;
	}),


	savefeeSubHeadLedgerConfiguration: thunk(async (actions, payload) => {
		const response = await savefeeSubHeadLedgerConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeSubHeadLedgerConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	deletefeeSubHeadLedgerConfiguration: thunk(async (actions, payload) => {
		const response = await deletefeeSubHeadLedgerConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeSubHeadLedgerConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	feeFineLedgerConfigurationList: null,
	fetchfeeFineLedgerConfigurationList: thunk(async (actions) => {
		actions.setLoading(true);
		const response = await fetchfeeFineLedgerConfigurationList();
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			//console.log(body)
			actions.setfeeFineLedgerConfigurationList(body?.item);
		} else {
			actions.setLoading(false);
		}
	}),

	setfeeFineLedgerConfigurationList: action((state, payload) => {
		state.feeFineLedgerConfigurationList = payload;
	}),


	savefeeFineLedgerConfiguration: thunk(async (actions, payload) => {
		const response = await savefeeFineLedgerConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeFineLedgerConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	deletefeeFineLedgerConfiguration: thunk(async (actions, payload) => {
		const response = await deletefeeFineLedgerConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeFineLedgerConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	///
	feeSubHeadConfigurationList: null,
	fetchfeeSubHeadConfigurationList: thunk(async (actions, payload) => {
		const response = await fetchfeeSubHeadConfigurationList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setfeeSubHeadConfigurationList(body?.item);
		} else {
			actions.setfeeSubHeadConfigurationList(null);
		}
	}),

	setfeeSubHeadConfigurationList: action((state, payload) => {
		state.feeSubHeadConfigurationList = payload;
	}),

	feeSubHeadConfigurationList2: null,
	fetchfeeSubHeadConfigurationList2: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchfeeSubHeadConfigurationList(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			actions.setfeeSubHeadConfigurationList2(body?.item);
		} else {
			actions.setfeeSubHeadConfigurationList2(null);
			actions.setLoading(false);
		}
	}),

	setfeeSubHeadConfigurationList2: action((state, payload) => {
		state.feeSubHeadConfigurationList2 = payload;
	}),

	///

	yearWiseFeeHeadTime: null,
	fetchyearWiseFeeHeadTime: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchyearWiseFeeHeadTime(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			actions.setyearWiseFeeHeadTime(body?.item);
		} else {
			actions.setyearWiseFeeHeadTime(null);
			actions.setLoading(false);
		}
	}),

	setyearWiseFeeHeadTime: action((state, payload) => {
		state.yearWiseFeeHeadTime = payload;
	}),

	deleteyearWiseFeeHeadTime: thunk(async (actions, payload) => {
		const response = await deleteyearWiseFeeHeadTime(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			//actions.fetchfeeAmountConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	///Fee amount
	feeAmountConfigurationList: null,
	fetchfeeAmountConfigurationList: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchfeeAmountConfigurationList(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			actions.setfeeAmountConfigurationList(body?.item);
		} else {
			actions.setLoading(false);
		}
	}),

	setfeeAmountConfigurationList: action((state, payload) => {
		state.feeAmountConfigurationList = payload;
	}),

	saveFeeAmountConfiguration: thunk(async (actions, payload) => {
		const response = await saveFeeAmountConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			//actions.fetchfeeAmountConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	updateFeeAmountConfiguration: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await updateFeeAmountConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeAmountConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
			actions.setLoading(false);
		}
	}),

	deleteFeeAmountConfiguration: thunk(async (actions, payload) => {
		const response = await deleteFeeAmountConfigurationUrl(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			// actions.fetchfeeAmountConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	///

	///Fee amount
	ofpsconfigurationView: null,
	fetchofpsconfigurationView: thunk(async (actions, payload) => {
		const response = await fetchofpsconfigurationView();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setofpsconfigurationView(body?.item);
		} else {

		}
	}),

	setofpsconfigurationView: action((state, payload) => {
		state.ofpsconfigurationView = payload;
	}),

	saveofpsconfigurationView: thunk(async (actions, payload) => {
		const response = await saveofpsconfigurationView(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
				actions.fetchofpsconfigurationView();
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			//actions.fetchfeeAmountConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),
	///Fee Waiver


	saveStudentWaiver: thunk(async (actions, payload) => {
		const response = await saveStudentWaiver(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	///Fee amount
	studentWaiverList: null,
	fetchstudentWaiverList: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchstudentWaiverList(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			actions.setstudentWaiverList(body?.item);
		} else {
			actions.setLoading(false);
		}
	}),

	setstudentWaiverList: action((state, payload) => {
		state.studentWaiverList = payload;
	}),

	setstudentWaiverListtab: action((state, payload) => {
		state.studentWaiverList = null;
	}),



	updateStudentWaiver: thunk(async (actions, payload) => {
		const response = await updateStudentWaiver(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	deleteStudentWaiver: thunk(async (actions, payload) => {
		const response = await deleteStudentWaiver(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchstudentWaiverList(payload);
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	feeheadDeleteSave: thunk(async (actions, payload) => {
		const response = await feeheadDeleteSave(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	feeheadDeletedList: null,
	fetchfeeheadDeletedList: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchfeeheadDeletedList(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.length > 0) {
			actions.setfeeheadDeletedList(body?.item);
			} else {
				actions.setfeeheadDeletedList(null);
				notification.error({ message: "No data found" })
			}
		} else {
			actions.setLoading(false);
		}
	}),

	setfeeheadDeletedList: action((state, payload) => {
		state.feeheadDeletedList = payload;
	}),


	studentListForFeeSubHeadDelete: [],
	fetchstudentListForFeeSubHeadDelete: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchstudentListForFeeSubHeadDelete(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.length > 0) {
			actions.setstudentListForFeeSubHeadDelete(body?.item);
			} else {
				actions.setstudentListForFeeSubHeadDelete([]);
				notification.error({ message: "No data found" })
			}
		} else {
			actions.setLoading(false);
		}
	}),

	setstudentListForFeeSubHeadDelete: action((state, payload) => {
		state.studentListForFeeSubHeadDelete = payload;
	}),	
	
	absentFineRecordStudentListForInput: [],
	fetchabsentFineRecordStudentListForInput: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchabsentFineRecordStudentListForInput(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.length > 0) {
				body?.item?.forEach((element, index) => {
                    element.index=index;
                });
                actions.setabsentFineRecordStudentListForInput(body?.item);
			} else {
				actions.setabsentFineRecordStudentListForInput([]);
				notification.error({ message: "No data found" })
			}
		} else {
			actions.setLoading(false);
		}
	}),

	setabsentFineRecordStudentListForInput: action((state, payload) => {
		state.absentFineRecordStudentListForInput = payload;
	}),

	saveAbsentFineRecord: thunk(async (actions, payload) => {
		const response = await saveAbsentFineRecord(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),	

	absentFineRecordStudentList: [],
	fetchabsentFineRecordStudentList: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchabsentFineRecordStudentList(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.length > 0) {
                actions.setabsentFineRecordStudentList(body?.item);
			} else {
				actions.setabsentFineRecordStudentList([]);
				notification.error({ message: "No data found" })
			}
		} else {
			actions.setLoading(false);
		}
	}),

	setabsentFineRecordStudentList: action((state, payload) => {
		state.absentFineRecordStudentList = payload;
	}),

	updateAbsentFineRecord: thunk(async (actions, payload) => {
		const response = await updateAbsentFineRecord(payload.payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message });
				actions.fetchabsentFineRecordStudentList(payload.data)
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),	

	deleteAbsentFineRecord: thunk(async (actions, payload) => {
		const response = await deleteAbsentFineRecord(payload.payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message });
				actions.fetchabsentFineRecordStudentList(payload.data)
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),	

	studentFeeSubHeadDelete: thunk(async (actions, payload) => {
		const response = await studentFeeSubHeadDelete(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),	
	
	studentDeletedFeeSubHeadList: [],
	fetchstudentDeletedFeeSubHeadList: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchstudentDeletedFeeSubHeadList(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.length > 0) {
			actions.setstudentDeletedFeeSubHeadList(body?.item);
			} else {
				actions.setstudentDeletedFeeSubHeadList([]);
				notification.error({ message: "No data found" })
			}
		} else {
			actions.setLoading(false);
		}
	}),

	setstudentDeletedFeeSubHeadList: action((state, payload) => {
		state.studentDeletedFeeSubHeadList = payload;
	}),

	reAssignStudentFeeSubHead: thunk(async (actions, payload) => {
		const response = await reAssignStudentFeeSubHead(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),



	deletestudentFeeHeadLedgerConfiguration: thunk(async (actions, payload) => {
		const response = await deletestudentFeeHeadLedgerConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeheadDeletedList(payload);
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	checkmanualFee: false,

	setcheckmanualFee: action((state, payload) => {
		state.checkmanualFee = payload;
	}),	
	
	checkmanualFee2: false,

	setcheckmanualFee2: action((state, payload) => {
		state.checkmanualFee2 = payload;
	}),

	studentCollecionView: null,
	fetchstudentCollecionView: thunk(async (actions, payload) => {
		actions.setLoading(true);
		actions.setcheckmanualFee(false);
		const response = await fetchstudentCollecionView(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			actions.setstudentCollecionView(body?.item);
			actions.setcheckmanualFee(true);
		} else {
			actions.setcheckmanualFee(false);
			actions.setLoading(false);
		}
	}),

	setstudentCollecionView: action((state, payload) => {
		state.studentCollecionView = payload;
	}),	
	
	studentCollecionView2: null,
	fetchstudentCollecionView2: thunk(async (actions, payload) => {
		actions.setLoading(true);
		actions.setcheckmanualFee2(false);
		const response = await fetchstudentCollecionView(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			actions.setstudentCollecionView2(body?.item);
			actions.setcheckmanualFee2(true);
		} else {
			actions.setcheckmanualFee2(false);
			actions.setLoading(false);
		}
	}),

	setstudentCollecionView2: action((state, payload) => {
		state.studentCollecionView2 = payload;
	}),

	savestudentCollecionView: thunk(async (actions, payload) => {
		const response = await savestudentCollecionView(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			console.log(body)
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
				const downloadFile = await moneyReceiptDownload(body.item);
				const file = await downloadFile.blob();
				var blob = new Blob([file], { type: "application/octet-stream" });
				var fileName = "Fee Collection.pdf";
				FileSaver.saveAs(blob, fileName);


			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	createSinglePayslip: thunk(async (actions, payload) => {
		const response = await createSinglePayslip(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
				const downloadFile = await downloadPayslip(body.item);
				const file = await downloadFile.blob();
				var blob = new Blob([file], { type: "application/octet-stream" });
				var fileName = "Payslip.pdf";
				FileSaver.saveAs(blob, fileName);


			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	saveFeeSubHeadTimeConfig: thunk(async (actions, payload) => {
		const response = await saveFeeSubHeadTimeConfig(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			//actions.fetchfeeAmountConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),	
	
	saveFeeSubheadTimeConfiguration: thunk(async (actions, payload) => {
		const response = await saveFeeSubheadTimeConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			//actions.fetchfeeAmountConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	sectionPaidReport: null,
	fetchsectionPaidReport: thunk(async (actions, payload) => {
		actions.setLoading(true)
		const response = await fetchsectionPaidReport(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setLoading(false)
			if (body?.item?.length > 0) {
				actions.setsectionPaidReport(body?.item);
			} else {
				actions.setsectionPaidReport(null);
				notification.warn({ message: "No data found" })
				actions.setLoading(false)
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	setsectionPaidReport: action((state, payload) => {
		state.sectionPaidReport = payload;
	}),	
	
	classPaidReport: null,
	fetchclassPaidReport: thunk(async (actions, payload) => {
		actions.setLoading(true)
		const response = await fetchclassPaidReport(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setLoading(false)
			if (body?.item?.length > 0) {
				actions.setclassPaidReport(body?.item);
			} else {
				actions.setclassPaidReport(null);
				notification.warn({ message: "No data found" })
				actions.setLoading(false)
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	setclassPaidReport: action((state, payload) => {
		state.classPaidReport = payload;
	}),

	allPaidReport: null,
	fetchallPaidReport: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchallPaidReport(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setallPaidReport(body?.item);
			} else {
				actions.setallPaidReport(null);
				notification.warn({ message: "No data found" })
			}

		} else {
			notification.error({ message: "Something went wrong" })
			actions.setLoading(false);
		}
	}),

	setallPaidReport: action((state, payload) => {
		state.allPaidReport = payload;
	}),

	sectionDueReport: null,
	fetchsectionDueReport: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchsectionDueReport(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setsectionDueReport(body?.item);
			} else {
				actions.setsectionDueReport(null);
				notification.warn({ message: "No data found" })
				actions.setLoading(false);
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),	
	
	setsectionDueReport: action((state, payload) => {
		state.sectionDueReport = payload;
	}),	
	
	sectionDueReport2: null,
	fetchsectionDueReport2: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchsectionDueReport(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setsectionDueReport2(body?.item);
			} else {
				actions.setsectionDueReport2(null);
				notification.warn({ message: "No data found" })
				actions.setLoading(false);
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),	
	
	setsectionDueReport2: action((state, payload) => {
		state.sectionDueReport2 = payload;
	}),	
	
	sectionDueReportMonth: null,
	fetchsectionDueReportMonth: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchsectionDueReportMonth(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setsectionDueReportMonth(body?.item);
			} else {
				actions.setsectionDueReportMonth(null);
				notification.warn({ message: "No data found" })
				actions.setLoading(false);
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),	
	fetchsectionDueReportMonth2: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchsectionDueReportMonth2(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setsectionDueReportMonth(body?.item);
			} else {
				actions.setsectionDueReportMonth(null);
				notification.warn({ message: "No data found" })
				actions.setLoading(false);
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	setsectionDueReportMonth: action((state, payload) => {
		state.sectionDueReportMonth = payload;
	}),
	
	monthlydueReport: null,
	fetchmonthlydueReport: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchmonthlydueReport(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.classDueDetails?.length > 0) {
				actions.setmonthlydueReport(body?.item);
			} else {
				actions.setmonthlydueReport(null);
				notification.warn({ message: body?.message })
				actions.setLoading(false);
			}


		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	setmonthlydueReport: action((state, payload) => {
		state.monthlydueReport = payload;
	}),


	feeHeadCollectionReport: null,
	fetchfeeHeadCollectionReport: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchfeeHeadCollectionReport(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setfeeHeadCollectionReport(body?.item);
			} else {
				actions.setfeeHeadCollectionReport(null);
				notification.warn({ message: "No data found" })
				actions.setLoading(false);
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	setfeeHeadCollectionReport: action((state, payload) => {
		state.feeHeadCollectionReport = payload;
	}),	
		
	
	feeHeadsubHeadCollectionReport: null,
	fetchfeeHeadsubHeadCollectionReport: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchfeeHeadsubHeadollectionReport(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setfeeHeadsubHeadCollectionReport(body?.item);
			} else {
				actions.setfeeHeadsubHeadCollectionReport(null);
				notification.warn({ message: "No data found" })
				actions.setLoading(false);
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	setfeeHeadsubHeadCollectionReport: action((state, payload) => {
		state.feeHeadsubHeadCollectionReport = payload;
	}),	
	
	showStudentFeeHeadPaymentView: null,
	fetchshowStudentFeeHeadPaymentView: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchshowStudentFeeHeadPaymentView(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.studentList?.length > 0) {
				actions.setshowStudentFeeHeadPaymentView(body?.item);
			} else {
				actions.setshowStudentFeeHeadPaymentView(null);
				notification.warn({ message: "No data found" })
				actions.setLoading(false);
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),	
	showsectionTotalPaidDetails: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await showsectionTotalPaidDetails(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.studentList?.length > 0) {
				actions.setshowStudentFeeHeadPaymentView(body?.item);
			} else {
				actions.setshowStudentFeeHeadPaymentView(null);
				notification.warn({ message: "No data found" })
				actions.setLoading(false);
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	setshowStudentFeeHeadPaymentView: action((state, payload) => {
		state.showStudentFeeHeadPaymentView = payload;
	}),	
	
	showMultipleSectionDateToDateTotalPaidDetails: null,
	fetchshowMultipleSectionDateToDateTotalPaidDetails: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchshowMultipleSectionDateToDateTotalPaidDetails(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if ( body?.messageType===0) {
				notification.warn({ message: body?.message});
				return;
			}
			if (body?.item?.studentList?.length > 0) {
				actions.setshowMultipleSectionDateToDateTotalPaidDetails(body?.item);
			} else {
				actions.setshowMultipleSectionDateToDateTotalPaidDetails(null);
				notification.warn({ message: "No data found" })
				actions.setLoading(false);
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	setshowMultipleSectionDateToDateTotalPaidDetails: action((state, payload) => {
		state.showMultipleSectionDateToDateTotalPaidDetails = payload;
	}),	

	showStudentFeeHeadPaymentViewDateToDate: null,
	
	showsectionTotalPaidDetailsDateToDate: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await showsectionTotalPaidDetailsDateToDate(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.studentList?.length > 0) {
				actions.setshowStudentFeeHeadPaymentViewDateToDate(body?.item);
			} else {
				actions.setshowStudentFeeHeadPaymentViewDateToDate(null);
				notification.warn({ message: "No data found" })
				actions.setLoading(false);
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	setshowStudentFeeHeadPaymentViewDateToDate: action((state, payload) => {
		state.showStudentFeeHeadPaymentViewDateToDate = payload;
	}),
	
	spgStatement: null,
	fetchspgStatement: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchspgStatement(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.messageType===0){
				notification.warn({ message: body.message});
				actions.setspgStatement(null);
				return
			}
			if (body?.item?.length > 0) {
				actions.setspgStatement(body?.item);
			} else {
				actions.setspgStatement(null);
				notification.warn({ message: "No data found" })
				actions.setLoading(false);
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	setspgStatement: action((state, payload) => {
		state.spgStatement = payload;
	}),	
	
	feeHeadCollectionReportClass: null,
	fetchfeeHeadCollectionReportclass: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchfeeHeadCollectionReportclass(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setfeeHeadCollectionReportClass(body?.item);
			} else {
				actions.setfeeHeadCollectionReportClass(null);
				notification.warn({ message: "No data found" })
			}

		} else {
			notification.error({ message: "Something went wrong" })
			actions.setLoading(false);
		}
	}),	
	fetchdateToDateClassSingleFeeHeadCollection: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchdateToDateClassSingleFeeHeadCollection(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setfeeHeadCollectionReportClass(body?.item);
			} else {
				actions.setfeeHeadCollectionReportClass(null);
				notification.warn({ message: "No data found" })
			}

		} else {
			notification.error({ message: "Something went wrong" })
			actions.setLoading(false);
		}
	}),

	setfeeHeadCollectionReportClass: action((state, payload) => {
		state.feeHeadCollectionReportClass = payload;
	}),

	downloadInvoice: thunk(async (actions, payload) => {

		const response = await downloadInvoice(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.blob();
			var blob = new Blob([body], { type: "application/octet-stream" });
			var fileName = "Money Receipt.pdf";
			FileSaver.saveAs(blob, fileName);
		} else {
			const body = await response.json();
			notification.error({ message: body.message })
		}

	}),

	feeInvoiceList: null,
	fetchfeeInvoiceList: thunk(async (actions, payload) => {
		const response = await fetchfeeInvoiceList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item!=null) {
				actions.setfeeInvoiceList(body?.item);
			} else {
				actions.setfeeInvoiceList(null);
				notification.warn({ message: "No data found" })
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	setfeeInvoiceList: action((state, payload) => {
		state.feeInvoiceList = payload;
	}),

	deletefeeInvoiceList: thunk(async (actions, payload) => {
		const response = await deletefeeInvoiceList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.setfeeInvoiceList(null);
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


}
