import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import FileSaver from 'file-saver'
import { activeInstiuteUrl, classGradeListUrl, deActiveInstiuteUrl, fetchofpsTransactionProblemCheck, fetchofpsTransactionProblemView, fetchStudentCount, gradeDelete, instituteDataUpdateUrl, instituteListPag, instituteListPag2, instituteListUrl, jumpInstiuteUrl, partnerDeleteUrl, partnerInfoSaveUrl, partnerInfoUpdateUrl, partnerListUrl, solveOfpsTransactionProblem, solveOfpsTransactionProblemDoublePayment } from '../../../http/customerSupport/customerSupport';
import { fetchlistGroupbyClassConfig } from '../../../http/student/student';

export interface CustomerSupport {
    
    fetchInstiuteList: Thunk<CustomerSupport>;
    setInstiuteist: Action<CustomerSupport, any>;
    instiuteList: any;    
    
    fetchofpsinstiuteList2: Thunk<CustomerSupport, any>;
    fetchofpsinstiuteList: Thunk<CustomerSupport, any>;
    setofpsinstiuteList: Action<CustomerSupport, any>;
    ofpsinstiuteList: any;

    submitDeactiveInstitute: Thunk<CustomerSupport>;
    submitActiveInstitute: Thunk<CustomerSupport>;
    //****//
    fetchJumpInstiute: Thunk<CustomerSupport>;
    //****//
    fetchClassGradeList: Thunk<CustomerSupport, any>;
    setClassGradeList: Action<CustomerSupport, any>;
    classGradeList: any;
    instituteDataUpdate: Thunk<CustomerSupport>,
    gradeDelete: Thunk<CustomerSupport, any>,

    ofpsTransactionProblemView: any;
    setofpsTransactionProblemView: Action<CustomerSupport, any>;
    fetchofpsTransactionProblemView: Thunk<CustomerSupport, any>;

    ofpsTransactionProblemCheck: any;
    setofpsTransactionProblemCheck: Action<CustomerSupport, any>;
    fetchofpsTransactionProblemCheck: Thunk<CustomerSupport, any>;

    solveOfpsTransactionProblem: Thunk<CustomerSupport, any>;
    solveOfpsTransactionProblemDoublePayment: Thunk<CustomerSupport, any>;
    isofps: boolean;
    setisofps: Action<CustomerSupport, any>;

    fetchStudentCount: Thunk<CustomerSupport>;
    setstudentCount: Action<CustomerSupport, any>;
    studentCount: any;
    
    savePartnerinformation: Thunk<CustomerSupport>;
    fetchPartnerList: Thunk<CustomerSupport>;
    partnerList: any; 
    setPartnerList: Action<CustomerSupport, any>;
    updatePartnerinformation: Thunk<CustomerSupport>;

    deletePartnerInformation: Thunk<CustomerSupport, any>
}

export const customerSupport: CustomerSupport = {
    instiuteList: [],
    ofpsinstiuteList: [],
    studentCount: [],
    classGradeList: [],
    partnerList:[],
    fetchInstiuteList: thunk(async (actions) => {
        const response = await instituteListUrl();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setInstiuteist(body.item);
        } else {
            const body = await response.json();
            actions.setInstiuteist([]);
            notification.error({ message: body.message })
        }
    }),    
    fetchofpsinstiuteList: thunk(async (actions, payload) => {
        const response = await instituteListPag(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setofpsinstiuteList(body.item);
        } else {
            const body = await response.json();
            actions.setofpsinstiuteList([]);
            notification.error({ message: body.message })
        }
    }),     
    fetchofpsinstiuteList2: thunk(async (actions, payload) => {
        const response = await instituteListPag2(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setofpsinstiuteList(body.item);
        } else {
            const body = await response.json();
            actions.setofpsinstiuteList([]);
            notification.error({ message: body.message })
        }
    }),    
    fetchStudentCount: thunk(async (actions) => {
        const response = await fetchStudentCount();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setstudentCount(body.item);
        } else {
            const body = await response.json();
            actions.setstudentCount([]);
            notification.error({ message: body.message })
        }
    }),
    submitDeactiveInstitute: thunk(async (actions, payload) => {
        const response = await deActiveInstiuteUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.fetchInstiuteList();
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    submitActiveInstitute: thunk(async (actions, payload) => {
        const response = await activeInstiuteUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.fetchInstiuteList();
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setInstiuteist: action((state, payload) => {
        state.instiuteList = payload;
    }),    
    
    setofpsinstiuteList: action((state, payload) => {
        state.ofpsinstiuteList = payload;
    }),    
    setstudentCount: action((state, payload) => {
        state.studentCount = payload;
    }),
    fetchJumpInstiute: thunk(async (actions, payload) => {
        const response = await jumpInstiuteUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            window.location.replace('/');
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    fetchClassGradeList: thunk(async (actions, payload) => {
        const response = await classGradeListUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setClassGradeList(body.item);
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setClassGradeList: action((state, payload) => {
        state.classGradeList = payload;
    }),

    instituteDataUpdate: thunk(async (actions, payload: any) => {
        const response = await instituteDataUpdateUrl(payload.data);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body?.messageType===1){
                notification.success({ message: body.message })
                //actions.fetchClassGradeList(payload.classConfig);
            }
            else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),   
    gradeDelete: thunk(async (actions, payload: any) => {
        const response = await gradeDelete(payload.data);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body?.messageType===1){
                notification.success({ message: body.message })
                actions.fetchClassGradeList(payload.classConfig);
            }
            else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    ofpsTransactionProblemView: [],
    fetchofpsTransactionProblemView: thunk(async (actions, payload) => {
        const response = await fetchofpsTransactionProblemView(payload);
        if (response.status === 200) {
            const body: any = await response.json();
            if (body.item?.length>0) {
                actions.setofpsTransactionProblemView(body.item);
            }  else {
                actions.setofpsTransactionProblemView([]);
                notification.warn({ message: "No data found" })

            }
        } else {
            //const body = await response.json();
            actions.setofpsTransactionProblemView([]);
        }
    }),
    setofpsTransactionProblemView: action((state, payload) => {
        state.ofpsTransactionProblemView = payload;
    }),
    isofps:false,
  
    setisofps: action((state, payload) => {
        state.isofps = payload;
    }),
    
    ofpsTransactionProblemCheck: {},
    fetchofpsTransactionProblemCheck: thunk(async (actions, payload) => {
        const response = await fetchofpsTransactionProblemCheck(payload);
        if (response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                actions.setofpsTransactionProblemCheck(body.item);
                actions.setisofps(true);
                setTimeout(() => {
                    actions.setisofps(false);
                }, 1000);
            } else {
                actions.setisofps(false);
                notification.warn({ message: body.message })
                actions.setofpsTransactionProblemCheck({});
            }
        } else {
            //const body = await response.json();
            actions.setisofps(false);
            actions.setofpsTransactionProblemCheck({});
        }
    }),
    setofpsTransactionProblemCheck: action((state, payload) => {
        state.ofpsTransactionProblemCheck = payload;
    }),
    solveOfpsTransactionProblem: thunk(async (actions, payload) => {
        const response = await solveOfpsTransactionProblem(payload);
        if (response.status === 201 || response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }
        } else {
            //const body = await response.json();
            notification.error({ message: "Something went wrong" })
        }
    }),    
    solveOfpsTransactionProblemDoublePayment: thunk(async (actions, payload) => {
        const response = await solveOfpsTransactionProblemDoublePayment(payload);
        if (response.status === 201 || response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }
        } else {
            //const body = await response.json();
            notification.error({ message: "Something went wrong" })
        }
    }),
    savePartnerinformation:thunk(async (actions, payload) => {
        const response = await partnerInfoSaveUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body.messageType == 1){
                notification.success({ message: body.message });
            }else{
                notification.error({ message: body.message });
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),
    fetchPartnerList: thunk(async (actions, payload) => {
        const response = await partnerListUrl();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setPartnerList(body.item);
        } else {
            const body = await response.json();
            actions.setPartnerList([]);
            notification.error({ message: body.message })
        }
    }),
    setPartnerList: action((state, payload) => {
        state.partnerList = payload;
    }),
    updatePartnerinformation:thunk(async (actions, payload) => {
        const response = await partnerInfoUpdateUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body.messageType == 1){
                notification.success({ message: body.message });
                actions.fetchPartnerList();
            }else{
                notification.error({ message: body.message });
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),
    deletePartnerInformation: thunk(async (actions, payload) => {
		const response = await partnerDeleteUrl(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
                actions.fetchPartnerList();
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),
}
