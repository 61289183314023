
import { Card, Tabs } from 'antd';
import React, { useEffect, useState } from 'react'
import StudentList from './FeeHeadDelete/StudentList.page';
import AssignedList from './FeeHeadDelete/AssignedList.page';

const { TabPane } = Tabs;

export default function FeeHeadDelete(props) {



    return (
        <Card title="Fee Head Delete" >
            <Tabs defaultActiveKey="1" size={"large"} style={{ marginBottom: 32 }}>
                <TabPane tab="Deleted List" key="1">
                    <StudentList/>
                </TabPane>
                <TabPane tab="Assigned List" key="2">
                    <AssignedList/>
                </TabPane>

            </Tabs>
        </Card>
    )
}
