import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { smsSearchReportUrl, searchClassStudentIthAcademicYear, sectionDueListUrl, sendDueListSms, sendInstiuteSms, sendStaffSms, sendStudentSms, smsBlanceurl, smsTemplate, staffListForSendSms, staffSmsCount, templateSaveUrl, templateUpdateUrl, smsSearchFeePaidSmsReportUrl, sendFeePaidBillSmsUrl, fetchstudentRegistrationListByClassConfigurations, deleteSmsTemplate, sendManyToManySms } from '../../../http/sms/sms';

export interface Sms{
    fetchStaffList: Thunk<Sms>;
    setStaffist: Action<Sms, any>;
    staffList:any;
    fetchSMSCount: Thunk<Sms>;
    setSMSCount: Action<Sms, any>;
    staffCount:any;
    sendStaffSms: Thunk<Sms, any>;
    /*****/
    fetchStudentByAcademicYear: Thunk<Sms>;
    setStudentByAcademicYear: Action<Sms, any>;
    studentByAcademicYear:any;
    /******/
    fetchSmsTemplate: Thunk<Sms>;
    setSmsTemplate: Action<Sms, any>;
    smsTemplateList:any;
    sendStudentSms: Thunk<Sms, any>;
    /*****/
    fetchSmsTemplateForInstiute: Thunk<Sms>;
    setSmsTemplateForInstiute: Action<Sms, any>;
    smsTemplateListForInstiute:any;
    sendStudentSmsForInstiute: Thunk<Sms, any>;
    /********/
    fetchSectionDueSmsReportList: Thunk<Sms>;
    setSectionDueSmsReportList: Action<Sms, any>;
    sectionDueSmsReportList:any;
    sendSectionDueSmsReportList: Thunk<Sms, any>;
    sendManyToManySms: Thunk<Sms, any>;
    /******/
    saveTemplateSms: Thunk<Sms>;
    updateTemplateSms: Thunk<Sms, any>;
    deleteSmsTemplate: Thunk<Sms, any>;
    /******/
    fetchSmsBlance: Thunk<Sms>;
    setSmsBlance: Action<Sms, any>;
    smsBlance:any;
    /*******/
    fetchSmsReportData: Thunk<Sms>;
    setGeneralSmsData: Action<Sms, any>;
    generalSmsData:any;
    setStaffSmsData: Action<Sms, any>;
    staffSmsData:any;
    setStudentSmsData: Action<Sms, any>;
    studentSmsData:any;

    /*******/
    fetchFeePaidBillSmsReportData: Thunk<Sms>;
    setFeePaidBillSmsReportData: Action<Sms, any>;
    feePaidBillSmsReportData:any;
    sendFeePaidBillSms: Thunk<Sms>;
    /*****/
    studentRegistrationListByClassConfigurations: any;
    setstudentRegistrationListByClassConfigurations: Action<Sms, any>;
    fetchstudentRegistrationListByClassConfigurations: Thunk<Sms,any>;

    /******/
    //loader section
    loading: boolean;
    setLoading: Action<Sms, boolean>;

}

export const smsStore: Sms ={
    staffList:[],
    staffCount:"",
    studentByAcademicYear:[],
    smsTemplateList:[],
    smsTemplateListForInstiute:[],
    sectionDueSmsReportList:[],
    smsBlance:[],
    generalSmsData:[],
    staffSmsData:[],
    studentSmsData:[],
    feePaidBillSmsReportData:[],


    loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload;
	}),
    fetchSMSCount:thunk(async (actions) => {
        const response = await staffSmsCount();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setSMSCount(body.item);
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),


    setSMSCount: action((state, payload) => {
        state.staffCount = payload;
    }),

    fetchStaffList:thunk(async (actions) => {
        actions.setLoading(true);
        const response = await staffListForSendSms();
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setStaffist(body.item);
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
            actions.setLoading(false);
        }
    }),
    setStaffist: action((state, payload) => {
        state.staffList = payload;
    }),
    sendStaffSms:thunk(async (actions, payload) => {
        
        const response = await sendStaffSms(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body.messageType == 1){
                notification.success({ message: body.message })
                actions.fetchStaffList();
                actions.fetchSMSCount();
            }else{
                notification.error({ message: body.message })
            }
            
        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }), 
    fetchStudentByAcademicYear:thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await searchClassStudentIthAcademicYear(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setStudentByAcademicYear(body.item);
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
            actions.setLoading(false);
        }
    }),
    setStudentByAcademicYear: action((state, payload) => {
        state.studentByAcademicYear = payload;
    }),
    fetchSmsTemplate:thunk(async (actions, ) => {
        const response = await smsTemplate();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setSmsTemplate(body.item);
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setSmsTemplate: action((state, payload) => {
        state.smsTemplateList = payload;
    }),
    sendStudentSms:thunk(async (actions, payload) => {
        const response = await sendStudentSms(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body.messageType == 1){
                notification.success({ message: body.message })
                actions.fetchStaffList();
                actions.fetchSMSCount();
            }else{
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }), 
    fetchSmsTemplateForInstiute:thunk(async (actions, ) => {
        actions.setLoading(true);
        const response = await smsTemplate();
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setSmsTemplateForInstiute(body.item);
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
            actions.setLoading(false);
        }
    }),
    setSmsTemplateForInstiute: action((state, payload) => {
        state.smsTemplateListForInstiute = payload;
    }),
    sendStudentSmsForInstiute:thunk(async (actions, payload) => {
        const response = await sendInstiuteSms(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body.messageType == 1){
                notification.success({ message: body.message })
                actions.fetchStaffList();
                actions.fetchSMSCount();
            }else{
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }), 
    fetchSectionDueSmsReportList:thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await sectionDueListUrl(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setSectionDueSmsReportList(body.item);
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
            actions.setLoading(false);
        }
    }),
    setSectionDueSmsReportList: action((state, payload) => {
        state.sectionDueSmsReportList = payload;
    }),

    sendSectionDueSmsReportList:thunk(async (actions, payload) => {
        const response = await sendDueListSms(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body.messageType == 1){
                notification.success({ message: body.message })
            }else{
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),     
    
    sendManyToManySms:thunk(async (actions, payload) => {
        const response = await sendManyToManySms(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body.messageType == 1){
                notification.success({ message: body.message })
            }else{
                notification.error({ message: body.message })
            }
        } else {
            notification.error({ message: "Something went wrong" })
        }
    }), 
    saveTemplateSms:thunk(async (actions, payload) => {
        const response = await templateSaveUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body.messageType == 1){
                notification.success({ message: body.message });
                actions.fetchSmsTemplate();
            }else{
                notification.error({ message: body.message });
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),
    updateTemplateSms:thunk(async (actions, payload) => {
        const response = await templateUpdateUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body.messageType == 1){
                notification.success({ message: body.message });
                actions.fetchSmsTemplate();
            }else{
                notification.error({ message: body.message });
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),    
    deleteSmsTemplate:thunk(async (actions, payload) => {
        const response = await deleteSmsTemplate(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body.messageType == 1){
                notification.success({ message: body.message });
                actions.fetchSmsTemplate();
            }else{
                notification.error({ message: body.message });
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),
    fetchSmsBlance:thunk(async (actions, payload) => {
        const response = await smsBlanceurl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setSmsBlance(body.item);
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setSmsBlance: action((state, payload) => {
        state.smsBlance = payload;
    }),
    fetchSmsReportData:thunk(async (actions, payload:any) => {
        actions.setLoading(true);
        const response = await smsSearchReportUrl(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            if(payload.msgType == "general"){
                actions.setGeneralSmsData(body.item);
            }else if(payload.msgType == "staff"){
                actions.setStaffSmsData(body.item);
            }else{
                actions.setStudentSmsData(body.item);
            }
            
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
            actions.setLoading(false);
        }
    }),
    setGeneralSmsData: action((state, payload) => {
        state.generalSmsData = payload;
    }),
    setStaffSmsData: action((state, payload) => {
        state.staffSmsData = payload;
    }),
    setStudentSmsData: action((state, payload) => {
        state.studentSmsData = payload;
    }),

    fetchFeePaidBillSmsReportData:thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await smsSearchFeePaidSmsReportUrl(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            actions.setFeePaidBillSmsReportData(body.item);
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
            actions.setLoading(false);
        }
    }), 
    sendFeePaidBillSms:thunk(async (actions, payload) => {
        const response = await sendFeePaidBillSmsUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body.messageType == 1){
                notification.success({ message: body.message });
            }else{
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setFeePaidBillSmsReportData: action((state, payload) => {
        state.feePaidBillSmsReportData = payload;
    }),
    studentRegistrationListByClassConfigurations:[],
    
    fetchstudentRegistrationListByClassConfigurations:thunk(async (actions, payload) => {
        actions.setLoading(true);
        const response = await fetchstudentRegistrationListByClassConfigurations(payload);
        if (response.status === 201 || response.status === 200) {
            actions.setLoading(false);
            const body = await response.json();
            if (body?.item?.length>0){
                actions.setstudentRegistrationListByClassConfigurations(body.item);
            } else {
                notification.error({ message: "No Data Found" })
                actions.setstudentRegistrationListByClassConfigurations(body.item);
            }
           
            
        } else {
            notification.error({ message: "Something went wrong" })
            actions.setLoading(false);
        }
    }),
    setstudentRegistrationListByClassConfigurations: action((state, payload) => {
        state.studentRegistrationListByClassConfigurations = payload;
    }),
}