import { Button, Card, Col, DatePicker, Form, Row, Select, Space, Table } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { DownloadOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import TableView from '../../../../contents/AntTableResponsive';

export default function VoucherTemplate(props) {

    const voucherTemplate = useStoreState((state) => state.ledger.voucherTemplate);
    const fetchvoucherTemplate = useStoreActions((state) => state.ledger.fetchvoucherTemplate);

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
            trnType: value?.trnType,
        }

        fetchvoucherTemplate(postData);
    };



    const column = [
        { title: 'Id', dataIndex: 'voucherId', key: 'voucherId', showOnResponse: true, showOnDesktop: true },
        { title: 'Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Grade', dataIndex: 'examGrade', key: 'examGrade', showOnResponse: true, showOnDesktop: true },
        { title: 'GPA', dataIndex: 'examGpa', key: 'examGpa', showOnResponse: true, showOnDesktop: true },
        { title: 'Passing Year', dataIndex: 'passingYear', key: 'passingYear', showOnResponse: true, showOnDesktop: true },
        { title: 'Board', dataIndex: 'examBoard', key: 'examBoard', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll No.', dataIndex: 'boardRoll', key: 'boardRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Reg No.', dataIndex: 'boardregNo', key: 'boardregNo', showOnResponse: true, showOnDesktop: true },
        { title: 'Session', dataIndex: 'examSession', key: 'examSession', showOnResponse: true, showOnDesktop: true },
        {
            title: 'Action', dataIndex: 'testimonialId', key: 'testimonialId', showOnResponse: true, showOnDesktop: true,
            render: (text, record: any, index) => (
                <Space size="middle">

                    <Button type='primary' icon={<DownloadOutlined />} onClick={() => {
                        // setIsDownloadModalVisible(true);
                        // setUpdateTestimonialId(record.testimonialId);
                    }} />

                </Space>
            ),
        },

    ];


    return (
        <>
            <Card title="Voucher Template" >
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="trnType"
                                label="Transaction Type"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select transaction type" },
                                ]}
                            >

                                <Select placeholder="Select Transaction Type" style={{ width: '100%' }}>
                                    <Select.Option value="Payment">Payment</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>
                {voucherTemplate?.length > 0 &&
                    <>


                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: column,
                                rowKey: "trnId",
                                dataSource: voucherTemplate,
                                filterData: voucherTemplate,
                                pagination: true,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />

                    </>
                }
            </Card>
        </>
    )
}
