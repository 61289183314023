import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';
import { DeleteOutlined, DownCircleFilled, DownloadOutlined, EditOutlined, FilePdfOutlined, SaveOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { SelectClass } from '../../../select/SelectClass';
import { render } from '@testing-library/react';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';


export default function GeneralMarkSheetDownload(props) {

    const [createForm] = Form.useForm();
    const { Option } = Select;

    const fetchSemesterListByClassConfigIdForGeneralMarkSheet = useStoreActions((state) => state.exam.fetchSemesterListByClassConfigIdForGeneralMarkSheet);
    const semesterListByClassConfigIdForGeneralMarkSheet = useStoreState((state) => state.exam.semesterListByClassConfigIdForGeneralMarkSheet);
    const downloadGeneralMarkSheet = useStoreActions((state) =>  state.exam.downloadGeneralMarkSheet)
    const onChangeClassList = (e) => {
        fetchSemesterListByClassConfigIdForGeneralMarkSheet(e);
    }

    const generalMarkSheetSheetDownload = (value) => {
        let payload:any = {
            examConfigId: value.examConfig,
            classConfigId: value.section,
            startRoll: value.fromRoll,
            endRoll: value.toRoll,
            academicYear: value.academicYear,

        }
        downloadGeneralMarkSheet(payload)
    }

    return(
        <>
            <Card title="General Mark Sheet Download">
                <Form
                    layout="vertical"
                    id="sessionInfo"
                    onFinish={generalMarkSheetSheetDownload}
                    form={createForm}
                >
                        <Row>
                            
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="academicYear"
                                    label="Academic Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select academic year" },
                                    ]}
                                >
                                    <SelectAcademicYear/>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="section"
                                    label="Select Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectClassConfig onChange={(e) =>{createForm.setFieldsValue({examConfig:null}); onChangeClassList(e)}} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="examConfig"
                                    label="Select Exam"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select exam" },
                                    ]}
                                >
                                    <Select placeholder="Semester list" style={{ width: "100%" }}>
                                        {semesterListByClassConfigIdForGeneralMarkSheet ? (
                                                semesterListByClassConfigIdForGeneralMarkSheet.map((type, idx) => (
                                                <Option key={type.examConfigId} value={type.examConfigId}>
                                                    {type.examName}
                                                </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Subject</Option>
                                            )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="fromRoll"
                                    label="From Roll"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Enter from roll" },
                                    ]}
                                >
                                    <InputNumber placeholder="from roll" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4}} xl={{ span: 4 }}>
                                <Form.Item
                                    name="toRoll"
                                    label="To Roll"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Enter to roll" },
                                    ]}
                                >
                                    <InputNumber placeholder="to roll" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                <Button className="pull-right mt-0" type="primary" htmlType="submit" icon={<DownloadOutlined />} >
                                    Download
                                </Button>
                            </Col>
                        </Row>
                </Form>
            </Card>
        </>
    )
}