import React, { useCallback, useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Table, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, TimePicker, DatePicker, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import TableView from '../../../contents/AntTableResponsive';
import moment from 'moment';
export default function TakeAttendanceSave() {

    const takeAttendance = useStoreActions((state) => state.staff.takeAttendance);
    const fetchstaffBasicInfoListReport = useStoreActions((state) => state.staff.fetchstaffBasicInfoListReport);
    const staffBasicInfoListReport = useStoreState((state) => state.staff.staffBasicInfoListReport);
    const loading = useStoreState((state) => state.staff.loading);

    useEffect(() => {
        fetchstaffBasicInfoListReport();
    }, []);

    const [tableData, setTableData] = useState<any>([]);

    useEffect(() => {
        setTableData(staffBasicInfoListReport?.map((item, index) => ({ index: index, customStaffId: item.customStaffId, staffId: item.staffId, staffName: item.staffName, inTime: '', outTime: '', attendanceStatus: 1 })));
    }, [staffBasicInfoListReport]);

    const [attendanceDate, setattendanceDate] = useState<any>(moment(new Date(), 'DD/MM/YYYY'));

    const columns = [
        {
            title: 'ID',
            dataIndex: 'customStaffId',
            key: 'customStaffId',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'In-Time ( HH : MM AM/PM )',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => {
                return (
                    <>
                        <Input type="time" className="ant-input" onChange={onchangeValue("inTime", record, record.index)} />
                    </>
                )

            }
        },
        {
            title: 'Out-Time ( HH : MM AM/PM )',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => {
                return (
                    <Input type="time" className="ant-input" onChange={onchangeValue("outTime", record, record.index)}></Input>
                )

            }
        },
        {
            title: 'Check for Absent',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => {
                return (
                    <Checkbox checked={record?.attendanceStatus == '2' ? true : false} onChange={onchangeAbsent("attendanceStatus", record, record.index)} />
                )

            }
        },
    ];

    const onchangeValue: any =
        useCallback((key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...tableData];
            newData[index][key] = e.target.value;
            setTableData(newData);
        }, [tableData]);

    const onchangeAbsent: any =
        useCallback((key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...tableData];
            newData[index][key] = e.target.checked === true ? '2' : '1';
            setTableData(newData);
        }, [tableData]);

    return (
        <>
            <Card title="Staff Attendance Input">
                <div style={{ textAlign: "center", marginBottom: 20, marginTop: 10 }}>
                    <DatePicker
                        style={{ width: 300, height: 40 }}
                        defaultValue={attendanceDate}
                        placeholder="Select Date"
                        format={"DD/MM/YYYY"}
                        onChange={(e) => setattendanceDate(e)}
                    />
                </div>
                <Row className="m-t-mo-30">
                    <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                    <Col span={24}>
                        <div className="datatable-responsive-demo">
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: tableData,
                                    filterData: tableData,
                                    pagination: true,
                                    bordered: true,
                                    rowKey: "staffId",

                                }}
                                mobileBreakPoint={768}
                            />
                        </div>
                        <Space style={{ float: "right" }} size={'middle'}>
                            <Button type="primary" onClick={() => takeAttendance({
                                attendanceDate: moment(attendanceDate).format('YYYY-MM-DD'),
                                details: tableData
                            })}>Save</Button>
                        </Space>
                    </Col>
                </Row>
            </Card>
        </>
    )
}