import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag} from 'antd';


export default function Exam(props) {

    const createExam = useStoreActions((state) => state.exam.createExam);
    const fetchExamList = useStoreActions((state) => state.exam.fetchExamList);
    const examList = useStoreState((state) => state.exam.examList);
    const updateExam = useStoreActions((state) => state.exam.updateExam);
    const deleteExam = useStoreActions((state) => state.exam.deleteExam);
    const [examForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const loading = useStoreState((state) =>  state.exam.loading);

    const examSettingForm = (value) => {
        let postData = {
            name: value.examName,
            serial: value.serialNumber,
            status: 1
        }
        createExam(postData);
        examForm.resetFields();
    }
    

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedStatus, setselectedStatus] = useState<any>();
    const [updateExamId, setupdateExamId] = useState<any>();

    const updateExamForm = (value) => {
        let updatePostData = {
            id:updateExamId,
            name: value.name,
            serial: value.serial,
            status: value.status == true ? 1 : 0
        }
        updateExam(updatePostData);
        setIsModalVisible(false);
        updateForm.resetFields();
        fetchExamList();
    }

    useEffect(() => {
        fetchExamList();
        
    },[])

    const columns = [
        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Exam Name',
            dataIndex: 'name',
            key: 'name',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <>
                   <span>{ record.status == 1 ? 'Enable' : 'Disable'}</span>
                </>
            )
        },
        {
            title: 'Action',
            key: 'ledgerId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                     <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setselectedStatus(record.status);
                            setupdateExamId(record.id);
                            updateForm.setFieldsValue({
                                serial: record.serial,
                                name: record.name,
                                status: record.status,
                            });
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteExam(record?.id)}
                    >
                        <Tooltip title="Delete">
                            <Button danger  icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        }
      ];

    return(
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                    <Form
                        layout="vertical"
                        id="examInfo"
                        onFinish={examSettingForm}
                        form={examForm}
                    >
                        <Row>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 10}} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Form.Item
                                        name="examName"
                                        label="Exam Name"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write exam name" },
                                        ]}
                                    >
                                    <Input placeholder="Enter exam name" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 10}} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Form.Item
                                        name="serialNumber"
                                        label="Serial Number"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write serial number" },
                                        ]}
                                    >
                                    <InputNumber placeholder="Enter serial number" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 4}} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                        Save
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Skeleton loading ={loading} paragraph={{rows:10}} />
            <Row className="m-t-mo-30">
                <Col span="24">
                    <div className="datatable-responsive-demo">
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}
                        <Table bordered={true} dataSource={examList} columns={columns} className="p-datatable-responsive-demo" />
                    </div>
                </Col>
            </Row>
            <Modal
                title="Edit"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
                destroyOnClose
            >
                <Form
                        layout="vertical"
                        id="update"
                        onFinish={updateExamForm}
                        form={updateForm}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                        name="name"
                                        label="Exam Name"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write Exam name" },
                                        ]}
                                    >
                                    <Input placeholder="Enter Exam name" />
                                </Form.Item>
                            </Col> 
                            <Col span={24}>
                                <Form.Item
                                        name="serial"
                                        label="Serial Number"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write serial number" },
                                        ]}
                                    >
                                    <InputNumber placeholder="Enter serial number" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                    <Form.Item
                                        name="status"
                                        label="Status:"
                                        valuePropName="checked"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please input serial" },
                                        ]}
                                    >
                                    <Checkbox>Enable</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
            </Modal>
        </>
    )
}