import { Layout, Menu, Breadcrumb } from "antd";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useStoreActions, useStoreState } from "./store/hooks/easyPeasy";
import {
  AppstoreOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { ROUTES } from "./contents/routes";
import TopNavBar from "./topNavbar";
import { Sidebar } from "./layouts/sidebar/Sidebar";
import { Link } from "react-router-dom";
import Users from "./container/pages/user/Users";

import Dashboard from './container/pages/dashboard/Dashboard.page';

import LedgerList from "./container/pages/GeneralAccounts/ledger/LedgerList";
import Receive from "./container/pages/GeneralAccounts/transaction/Receive";
import Payment from "./container/pages/GeneralAccounts/transaction/Payment";
import Contra from "./container/pages/GeneralAccounts/transaction/Contra";
import Journal from "./container/pages/GeneralAccounts/transaction/Journal";
import Voucher from "./container/pages/GeneralAccounts/delete/Voucher";
import TrialBalance from "./container/pages/GeneralAccounts/report/TrialBalance";
import BalanceSheet from "./container/pages/GeneralAccounts/report/BalanceSheet";
import IncomeStatement from "./container/pages/GeneralAccounts/report/IncomeStatement";
import JournalR from "./container/pages/GeneralAccounts/report/Journal";
import FundsFlow from "./container/pages/GeneralAccounts/report/FundsFlow";
import CashSummary from "./container/pages/GeneralAccounts/report/CashSummary";
import AccountsBookTab from "./container/pages/GeneralAccounts/report/AccountsBookTab";
import UserWiseTransaction from "./container/pages/GeneralAccounts/report/UserWiseTransaction";
import VoucherTemplate from "./container/pages/GeneralAccounts/report/VoucherTemplate";

import FeeHead from "./container/pages/StudentAccounts/settings/FeeHead.page";
import FeeSubHead from "./container/pages/StudentAccounts/settings/FeeSubHead.page";
import FeeWaiver from "./container/pages/StudentAccounts/settings/FeeWaiver.page";
import FeeHeadLedger from "./container/pages/StudentAccounts/configuration/FeeHeadLedger.page";
import FeeSubHeadLedger from "./container/pages/StudentAccounts/configuration/FeeSubHeadLedger.page";
import OFPS from "./container/pages/StudentAccounts/configuration/OFPS";
import FeeFineLedger from "./container/pages/StudentAccounts/configuration/FeeFineLedger.page";
import FeeAmountConfig from "./container/pages/StudentAccounts/configuration/FeeAmountConfig.page";
import FeeSubHeadTimeConfig from "./container/pages/StudentAccounts/configuration/FeeSubHeadTimeConfig.page";
import FeeWaiverConfig from "./container/pages/StudentAccounts/configuration/FeeWaiverConfig.page";
import FeeHeadDelete from "./container/pages/StudentAccounts/configuration/FeeHeadDelete.page";
import FeesCollection from "./container/pages/StudentAccounts/feescollection/FeesCollection.page";
import SectionPaidInfo from "./container/pages/StudentAccounts/report/SectionPaidInfo";
import ClassPaidInfo from "./container/pages/StudentAccounts/report/ClassPaidInfo";
import AllPaidInfo from "./container/pages/StudentAccounts/report/AllPaidInfo";
import SectionDueDetails from "./container/pages/StudentAccounts/report/SectionDueDetails";
import MonthlyDueDetails from "./container/pages/StudentAccounts/report/MonthlyDueDetails";
import FeeHeadCollection from "./container/pages/StudentAccounts/report/FeeHeadCollection";
import SpgStatement from "./container/pages/StudentAccounts/report/SpgStatement";
import FeeHeadCollectionClass from "./container/pages/StudentAccounts/report/FeeHeadCollectionClass";
import Invoice from "./container/pages/StudentAccounts/delete/Invoice";
import AttendanceFineWrapper from "./container/pages/StudentAccounts/configuration/AttendanceFineWrapper";


import StudentWrapper from "./container/pages/Students/settings/StudentWrapper.page";
import CalssConfiguration from "./container/pages/Students/configuration/ClassConfiguration.page";
import GroupConfiguration from "./container/pages/Students/configuration/GroupConfiguration.page";
import MultipleRegistrationWrapper from "./container/pages/Students/registration/MultipleRegistrationWrapper";
import MultipleRegistrationExeclWrapper from "./container/pages/Students/registration/MultipleRegistrationExeclWrapper";
import StudentMigration from "./container/pages/Students/registration/StudentMigration";
import PhotoUpdate from "./container/pages/Students/registration/PhotoUpdate";
import AcademicInfo from "./container/pages/Students/registration/AcademicInfo"; //AddressInfoUpdate
import AddressInfoUpdate from "./container/pages/Students/registration/AddressInfoUpdate";

import StaffSettings from "./container/pages/Staff/settings/StaffSettings.page";
import SingleRegistration from "./container/pages/Staff/registration/SingleRegistration.page";
import MultipleRegistrationStaff from "./container/pages/Staff/registration/MultipleRegistration.page";
import StaffUpdate from "./container/pages/Staff/update/StaffUpdate.page";
import BasicUpdate from "./container/pages/Students/registration/BasicUpdate";
import StaffAssign from "./container/pages/Staff/assign/StaffAssign.page";
import StaffList from "./container/pages/Staff/report/StaffList.page";
import StaffIdCardTemplate from "./container/pages/Staff/idCard/StaffIdCardTemplate";
import MarkProcessClass from "./container/pages/Exam/configuration/MarkProcessClass";
import ExamSettingsWrapper from "./container/pages/Exam/setting/ExamSettingsWrapper";
import ExamSubject from "./container/pages/Exam/configuration/ExamSubject";
import Exam from "./container/pages/Exam/setting/Exam";
import ExamConfiguration from "./container/pages/Exam/configuration/ExamConfiguration";
// import Mark from "./container/pages/Exam/configuration/Mark";/
import  MarMarkConfigk from "./container/pages/Exam/configuration/MarkConfigPage"
import GrandFinalMark from "./container/pages/Exam/configuration/GrandFinalMark";
import ExamFortSubject from "./container/pages/Exam/configuration/ExamFortSubject";
import AdmitCardInstruction from "./container/pages/Exam/configuration/AdmitCardInstruction";
import ExamTemplate from "./container/pages/Exam/configuration/template/ExamTemplate";
import AdmitCardSeatPlan from "./container/pages/Exam/configuration/AdmitCardSeatPlan";
import MarkInputForm from "./container/pages/Exam/configuration/MarkInputForm";
import MarkUpdateForm from "./container/pages/Exam/configuration/MarkUpdateForm";
import MarkDelete from "./container/pages/Exam/configuration/MarkDelete";
import GeneralExam from "./container/pages/Exam/configuration/process/GeneralExam";
import GrandFinal from "./container/pages/Exam/configuration/process/GrandFinal";
import MeritPostion from "./container/pages/Exam/configuration/process/MeritPostion";
import ExamRemarks from "./container/pages/Exam/configuration/ExamRemarks";
import TabulationSheet from "./container/pages/Exam/configuration/TabulationSheet";
import GeneralMarkSheetDownload from "./container/pages/Exam/configuration/GeneralMarkSheetDownload";
import GrandFinalMarkSheetDownload from "./container/pages/Exam/configuration/GrandFinalMarkSheetDownload";
import ExamReportSectionWiseUninputtedSubjects from "./container/pages/Exam/reports/ExamReportSectionWiseUninputtedSubjects";
import ExamReportSectionWiseResult from "./container/pages/Exam/reports/ExamReportSectionWiseResult";
import SectionWiseMeritList from "./container/pages/Exam/reports/SectionWiseMeritList";
import ClassWiseMeritList from "./container/pages/Exam/reports/ClassWiseMeritList";
import ClassWiseFailedList from "./container/pages/Exam/reports/ClassWiseFailedList";
import SectionWiseFailedList from "./container/pages/Exam/reports/SectionWiseFailedList";
import SectionWiseGradingSummary from "./container/pages/Exam/reports/SectionWiseGradingSummary";
import SectionWisePassFailSummary from "./container/pages/Exam/reports/SectionWisePassFailSummary";
import ResultPublicationWrapper from "./container/pages/Exam/configuration/ResultPublicationWrapper";


import Sms from "./container/pages/Sms/DynamicSms";
import DynamicSms from "./container/pages/Sms/DynamicSms";
import StaffSms from "./container/pages/Sms/StaffSms";
import ClassWiseSms from "./container/pages/Sms/studentSms/ClassWiseSms";
import SectionWiseSms from "./container/pages/Sms/studentSms/SectionWiseSms";
import ExamResultSms from "./container/pages/Sms/studentSms/ExamResultSms";
import InstiuteSms from "./container/pages/Sms/studentSms/InstiuteSms";
import SectionDueSms from "./container/pages/Sms/studentSms/SectionDueSms";
import SmsTemplates from "./container/pages/Sms/SmsTemplates";
import PurchaseSms from "./container/pages/Sms/PurchaseSms";
import SmsBalance from "./container/pages/Sms/report/SmsBalance";
import GeneralMessageList from "./container/pages/Sms/report/GeneralMessageList";
import StaffMessageList from "./container/pages/Sms/report/StaffMessageList";
import StudentMessageList from "./container/pages/Sms/report/StudentMessageList";
import InstituteUpdate from "./container/pages/masterSetting/InstituteUpdate";
import DefaultSignature from "./container/pages/masterSetting/DefaultSignature";
import UsersList from "./container/pages/masterSetting/UsersList";
import PayableBill from "./container/pages/masterSetting/PayableBill";
import PaidBill from "./container/pages/masterSetting/PaidBill";
import Tetimonial from "./container/pages/tesimonial/Tetimonial";
import TransferCertificate from "./container/pages/tesimonial/TransferCertificate";
import InstiuteList from "./container/pages/customerSupport/InstiuteList";
import InstituteJump from "./container/pages/customerSupport/InstituteJump";
import ExamGradeUpdate from "./container/pages/customerSupport/ExamGradeUpdate";
import StudentDelete from "./container/pages/Students/delete/StudentDelete";
import IdCardTemplate from "./container/pages/Students/idCard/IdCardTemplate";
import IdCardTemplateDownload from "./container/pages/Students/idCard/IdCardTemplateDownload";
import ClassWiseReport from "./container/pages/Students/reports/ClassWiseReport";
import SectionWiseReport from "./container/pages/Students/reports/SectionWiseReport";
import SubjectWiseReport from "./container/pages/Students/reports/SubjectWiseReport";
import GroupWiseReport from "./container/pages/Students/reports/GroupWiseReport";
import TaughtListReport from "./container/pages/Students/reports/TaughtListReport";
import SectionWiseSummaryReport from "./container/pages/Students/reports/SectionWiseSummaryReport";
import StudentOverViewReportReport from "./container/pages/Students/reports/StudentOverViewReportReport";
import MoneyReciptTemplate from "./container/pages/StudentAccounts/settings/MoneyReciptTemplate";
import ClassRoutine from "./container/pages/routine/ClassRoutine";
import ExamRoutine from "./container/pages/routine/ExamRoutine";
import TakeAttendanceSave from "./container/pages/StaffAttendance/TakeAttendanceSave";
import UpdateAttendance from "./container/pages/StaffAttendance/UpdateAttendance";
import TakeSingleLeave from "./container/pages/StaffAttendance/TakeSingleLeave";
import StaffTimeConfig from "./container/pages/StaffAttendance/StaffTimeConfig";
import StaffIdMappingWrapper from "./container/pages/StaffAttendance/StaffIdMappingWrapper";
import StaffAttendanceTimeConfigReport from "./container/pages/StaffAttendance/StaffAttendanceTimeConfigReport";
import StaffAttendanceDateWiseReport from "./container/pages/StaffAttendance/StaffAttendanceDateWiseReport";
import StaffAttendanceMonthWiseReport from "./container/pages/StaffAttendance/StaffAttendanceMonthWiseReport";
import StaffAttendanceDateWiseRemark from "./container/pages/StaffAttendance/StaffAttendanceDateWiseRemark";
import HolidayWrapper from "./container/pages/StaffAttendance/holiday/HolidayWrapper";
import StaffFileUpload from "./container/pages/StaffAttendance/StaffFileUpload";
import TakePeriod from "./container/pages/Students/attendance/TakePeriod.page";
import PeriodTimeConfiguration from "./container/pages/Students/attendance/PeriodTimeConfiguration.page";
import DeviceIdMapping from "./container/pages/Students/attendance/DeviceIdMapping.page";
import TakeAttendance from "./container/pages/Students/attendance/TakeAttendance.page";
import TakeAttendanceUpdate from "./container/pages/Students/attendance/TakeAttendanceUpdate.page";
import ExamAttendance from "./container/pages/Students/attendance/ExamAttendance";
import LeaveInput from "./container/pages/Students/attendance/LeaveInput.page";
import DailySectionReport from "./container/pages/Students/attendance/DailySectionReport.page";
import SectionWiseDailySummary from "./container/pages/Students/attendance/SectionWiseDailySummary.page";
import SectionWiseMonthlyDetails from "./container/pages/Students/attendance/SectionWiseMonthlyDetails.page";
import SectionWiseMonthlyDetailsSummary from "./container/pages/Students/attendance/SectionWiseMonthlyDetailsSummary.page";
import StudentFileUpload from "./container/pages/Students/attendance/StudentFileUpload";
import SingleSearchStudent from "./container/pages/Students/search/SingleSearchStudent";
// import logo from "../assets/images/shebaShikkhaWhiteLogo.png";
import logo from "../assets/images/mainlogo.svg";
import StudentIDUpdate from "./container/pages/Students/Update/StudentIDUpdate";
import StudentEnableDisable from "./container/pages/Students/Update/StudentEnableDisable";
import StudentMigrationGeneral from "./container/pages/Students/migration/StudentMigrationGeneral";
import StudentMigrationMerit from "./container/pages/Students/migration/StudentMigrationMerit";
import OfpsProblemView from "./container/pages/customerSupport/OFPSProblem/OfpsProblemView";
import FeeDiscardWrapper from "./container/pages/StudentAccounts/configuration/FeeDiscardWrapper";
import FeeSubHeadDiscardWrapper from "./container/pages/StudentAccounts/configuration/FeeSubHeadDiscardWrapper";
import PaySlipCreateSingle from "./container/pages/PaySlip/PaySlipCreateSingle";
import CreatePaySlipBatch from "./container/pages/PaySlip/CreatePaySlipBatch.page";
import CollectPaySlip from "./container/pages/PaySlip/CollectPaySlip.page";
import PayslipTemplate from "./container/pages/PaySlip/PayslipTemplate.page";
import { Footer } from "antd/lib/layout/layout";
import MarkConfig from "./container/pages/Exam/configuration/MarkConfigPage";
import MarkConfigPage from "./container/pages/Exam/configuration/MarkConfigPage";
import OnlineAdmissionSectionWise from "./container/pages/onlineAdmissaion/OnlineAdmissionSectionWise";
import OnlineAdmissionGroupWise from "./container/pages/onlineAdmissaion/OnlineAdmissionGroupWise";
import OnlineAdmissionPaymentStatusWise from "./container/pages/onlineAdmissaion/OnlineAdmissionPaymentStatusWise";
import FeePaidSms from "./container/pages/Sms/studentSms/FeePaidSms";
import InstiuteStudentCount from "./container/pages/customerSupport/InstiuteStudentCount";
import OFPSInstiuteList from "./container/pages/customerSupport/OFPSInstiuteList";
import CategoryWiseReport from "./container/pages/Students/reports/CategoryWiseReport";
import PayslipDateUpdate from "./container/pages/PaySlip/PayslipDateUpdate";
import Profile from './container/pages/signup/Profile.page';
import EmptyPage from "./container/pages/sbl/EmptyPage";
import AttendanceDevice from "./container/pages/Students/attendance/AttendanceDevice";
import PartnerInformation from "./container/pages/customerSupport/PartnerInformation";

const { Header, Content, Sider } = Layout;
// Application
export default function Container() {
  const user = useStoreState((state) => state.auth.user);
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => setCollapsed(!collapsed);
  var isMobile = false; //initiate as false
  if (
    /iP(hone|od)|android.+mobile|BlackBerry|IEMobile/i.test(navigator.userAgent)
  ) {
    isMobile = true;
  }
  const onCollapse = (collapsed) => {
    //console.log("hello")
    //console.log(collapsed);
    setCollapsed(collapsed);
  };
  const fetchInstituteInfo = useStoreActions((state) => state.auth.fetchInstituteInfo);
  useEffect(() => {
    fetchInstituteInfo();
  }, []);
  const d = new Date();
  let year = d.getFullYear();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        id="mySider"
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        breakpoint="lg"
        onBreakpoint={(broken) => {
          // console.log(broken);
        }}
        trigger={null}
        collapsedWidth={isMobile ? 0 : 80}
        width="230"
        style={{
          height: "100vh",
          overflow: "auto",
          position: isMobile ? "fixed" : "sticky",
          left: 0,
          top: 0,
          zIndex: 1000,
        }}
      >
        <div className="logo">
          {!collapsed && (
            <>
          <Link to={ROUTES.DEFAULT} className="nav-text"><img src={logo} style={{ marginLeft: '-10px'}}/></Link>  
            </>
          )}
          {collapsed && (
            <div style={{ marginLeft: 15 }}>
              <AppstoreOutlined />
            </div>
          )}
          {/* {!collapsed && <span style={{color:"white", fontSize:12}}> {user?.instituteName}</span>} */}
        </div>
        {user && (
          <div className="sidebar-wrapper" id="style-4">
            {" "}
            <Sidebar />
          </div>
        )}
      </Sider>
      <Layout className="site-layout">
      <Header className="site-layout-background" style={{ position: 'fixed', zIndex: 1}} id="topFixedBar">
        {/* <Header className="site-layout-background"> */}
          <TopNavBar value={{ collapsed: collapsed, toggle: toggle }} />
        </Header>
        <Content className="p-15" style={{ marginTop: "64px" }}>
        {/* <Content className="p-15"> */}
          <Switch>
            <Route exact path="/" component={Dashboard} />
            //////////***************** Students *************//////////////////////
            <Route exact path={'/' + ROUTES.STUDENT_WRAPPER} component={StudentWrapper} />
            <Route exact path={'/' + ROUTES.STUDENT_CLASS_CONFIG} component={CalssConfiguration} />
            <Route exact path={'/' + ROUTES.CLASS_SEMESTER_CONFIG} component={GroupConfiguration} />
            <Route exact path={'/' + ROUTES.STUDENT_MULTIPLE_REGISTRATION} component={MultipleRegistrationWrapper} />
            <Route exact path={'/' + ROUTES.STUDENT_MULTIPLE_REGISTRATION_EXCEL} component={MultipleRegistrationExeclWrapper} />
            <Route exact path={'/' + ROUTES.STUDENT_BASIC_UPDATE} component={BasicUpdate} />
            <Route exact path={'/' + ROUTES.STUDENT_MIGRATION} component={StudentMigration} /> 
            <Route exact path={'/' + ROUTES.STUDENT_PHOTO_UPDATE} component={PhotoUpdate} />
            <Route exact path={'/' + ROUTES.STUDENT_ACADEMICINFO_UPDATE} component={AcademicInfo} />  
            <Route exact path={'/' + ROUTES.STUDENT_ADDRESS_UPDATE} component={AddressInfoUpdate} /> 
            <Route exact path={'/' + ROUTES.STUDENT_REGISTRATION_DELETE} component={StudentDelete} />  
            <Route exact path={'/' + ROUTES.STUDENT_ID_CARD_TEMPLATE} component={IdCardTemplate} /> 
            <Route exact path={'/' + ROUTES.STUDENT_ID_CARD_TEMPLATE_DOWNLOAD} component={IdCardTemplateDownload} />
            <Route exact path={'/' + ROUTES.CLASS_WISE_REPORT} component={ClassWiseReport} /> 
            <Route exact path={'/' + ROUTES.SECTION_WISE_STUDENT_REPORT} component={SectionWiseReport} /> 
            <Route exact path={'/' + ROUTES.SUBJECT_WISE_SUMMARY_REPORT} component={SubjectWiseReport} /> 
            <Route exact path={'/' + ROUTES.GROUP_WISE_STUDENT_REPORT} component={GroupWiseReport} />
            <Route exact path={'/' + ROUTES.TAUGHT_LIST_STUDENT_REPORT} component={TaughtListReport} />
            <Route exact path={'/' + ROUTES.SECTION_WISE_SUMMARY_REPORT} component={SectionWiseSummaryReport} />  
            <Route exact path={'/' + ROUTES.SECTION_OVERVIEW_REPORT} component={StudentOverViewReportReport} /> 
            <Route exact path={'/' + ROUTES.MONEY_RECIPT_TEMPLATE} component={MoneyReciptTemplate} />
            <Route exact path={'/' + ROUTES.INITIAL_SETUP_PERIOD} component={TakePeriod} />
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_PERIOD_TIME_CONFIGURATION} component={PeriodTimeConfiguration} /> 
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_DEVICE} component={AttendanceDevice} />
            <Route exact path={'/' + ROUTES.STUDENT_DEVICE_ID_MAPPING} component={DeviceIdMapping} />
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_INPUT} component={TakeAttendance} />
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_UPDATE} component={TakeAttendanceUpdate} /> 
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_EXAM} component={ExamAttendance} /> 
            <Route exact path={'/' + ROUTES.STUDENT_LEAVE_INPUT} component={LeaveInput} /> 
            <Route exact path={'/' + ROUTES.DAILY_ATTENSION_REPORT_DAILY_SECTION} component={DailySectionReport} /> 
            <Route exact path={'/' + ROUTES.DAILY_ATTENSION_REPORT_SECTION_WISE_DAILY_SUMMARY} component={SectionWiseDailySummary} />  
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_REPORT_MONTHLY_SECTION_DETAILS} component={SectionWiseMonthlyDetailsSummary} /> 
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_REPORT_MONTHLY_SECTION_SUMMARY} component={SectionWiseMonthlyDetails} /> 
            <Route exact path={'/' + ROUTES.STUDENT_ATTENDANCE_MACHINE_INPUT} component={StudentFileUpload} /> 
            <Route exact path={'/' + ROUTES.STUDENT_SINGLE_SEARCH} component={SingleSearchStudent} />
            <Route exact path={'/'+ ROUTES.STUDENT_UPDATE_ID} component={StudentIDUpdate} />  
            <Route exact path={'/'+ ROUTES.STUDENT_ENABLED_DISABLED} component={StudentEnableDisable} /> 
            <Route exact path={'/'+ ROUTES.GENERAL_STUDENT_MIGRATION} component={StudentMigrationGeneral} />
            <Route exact path={'/'+ ROUTES.MERIT_STUDENT_MIGRATION} component={StudentMigrationMerit} /> 
            <Route exact path={'/'+ ROUTES.STUDENT_REPORT_BASIC_INFO_CATEGORY_WISE_REPORT} component={CategoryWiseReport} />
            //////////***************** Students *************//////////////////////


            //////////***************** Staff *************//////////////////////
            <Route exact path={'/' + ROUTES.STAFF_SETTINGS} component={StaffSettings} />
            <Route exact path={'/' + ROUTES.STAFF_SETTINGS} component={StaffSettings} />
            <Route exact path={'/' + ROUTES.STAFF_SINGLE_REGISTRATION} component={SingleRegistration} />
            <Route exact path={'/' + ROUTES.STAFF_MULTIPLE_REGISTRATION} component={MultipleRegistrationStaff} />
            <Route exact path={'/' + ROUTES.STAFF_UPDATE} component={StaffUpdate} />
            <Route exact path={'/' + ROUTES.STAFF_ASSIGN} component={StaffAssign} />
            <Route exact path={'/' + ROUTES.STAFF_LIST} component={StaffList} />
            <Route exact path={'/' + ROUTES.STAFF_ID_TEMP} component={StaffIdCardTemplate} />
            //////////***************** Staff *************//////////////////////
           
            //////////***************** Staff Attedance *************//////////////////////
            <Route exact path={'/' + ROUTES.HR_ATTENDANCE_TAKE} component={TakeAttendanceSave} />
            <Route exact path={'/' + ROUTES.HR_ATTENDANCE_UPDATE} component={UpdateAttendance} />
            <Route exact path={'/' + ROUTES.HR_SINGLE_LEAVE} component={TakeSingleLeave} />
            <Route exact path={'/' + ROUTES.HR_TIME_CONFIG} component={StaffTimeConfig} />
            <Route exact path={'/' + ROUTES.HR_TD_MAPPING} component={StaffIdMappingWrapper} />
            <Route exact path={'/' + ROUTES.HR_REPORT_TIME_CONFIG} component={StaffAttendanceTimeConfigReport} />
            <Route exact path={'/' + ROUTES.HR_REPORT_DATE_WISE} component={StaffAttendanceDateWiseReport} />
            <Route exact path={'/' + ROUTES.HR_REPORT_MONTH_WISE} component={StaffAttendanceMonthWiseReport} />
            <Route exact path={'/' + ROUTES.HR_REMARK_DATE_WISE} component={StaffAttendanceDateWiseRemark} />
            <Route exact path={'/' + ROUTES.HOLIDAY} component={HolidayWrapper} />
            <Route exact path={'/' + ROUTES.HR_FILE_UPLOAD} component={StaffFileUpload} />
            //////////***************** Staff Attedance *************//////////////////////

            //////////***************** General Accounts *************//////////////////////
            <Route exact path={'/' + ROUTES.LEDGER_LIST} component={LedgerList} />
            <Route exact path={'/' + ROUTES.RECEIVE_TRN_TRANSACTION} component={Receive} />
            <Route exact path={'/' + ROUTES.PAYMENT_TRN_TRANSACTION} component={Payment} />
            <Route exact path={'/' + ROUTES.CONTRA_TRN_TRANSACTION} component={Contra} />
            <Route exact path={'/' + ROUTES.JOURNAL_TRN_TRANSACTION} component={Journal} />
            <Route exact path={'/' + ROUTES.DELETE_VOUCHER_GAVOUCHER} component={Voucher} />
            <Route exact path={'/' + ROUTES.TRIAL_BALANCE_GAREPORT} component={TrialBalance} />
            <Route exact path={'/' + ROUTES.BALANCE_SHEET_GAREPORT} component={BalanceSheet} />
            <Route exact path={'/' + ROUTES.INCOME_STATEMENT_GAREPORT} component={IncomeStatement} />
            <Route exact path={'/' + ROUTES.JOURNAL_GAREPORT} component={JournalR} />
            <Route exact path={'/' + ROUTES.FUNDS_FLOW_GAREPORT} component={FundsFlow} />
            <Route exact path={'/' + ROUTES.CASH_SUMMARY_GAREPORT} component={CashSummary} />
            <Route exact path={'/' + ROUTES.USER_WISE_TRAN} component={UserWiseTransaction} />
            <Route exact path={'/' + ROUTES.VOUCHER_TEMPLATE} component={VoucherTemplate} />
            <Route exact path={'/' + ROUTES.ACCOUNT_BOOK} component={AccountsBookTab} />
            //////////***************** General Accounts *************//////////////////////

            //////////***************** Student Accounts *************//////////////////////
            <Route exact path={'/' + ROUTES.FEE_HEAD} component={FeeHead} />
            <Route exact path={'/' + ROUTES.FEE_SUB_HEAD} component={FeeSubHead} />
            <Route exact path={'/' + ROUTES.FEE_HEAD_WAIVER} component={FeeWaiver} />
            <Route exact path={'/' + ROUTES.FEE_HEAD_LEDGER_CONFIGUARTION} component={FeeHeadLedger} />
            <Route exact path={'/' + ROUTES.FEE_SUB_HEAD_LEDGER_CONFIGUARTION} component={FeeSubHeadLedger} />
            <Route exact path={'/' + ROUTES.OFPS_ACCOUNT_CONFIG} component={OFPS} />
            <Route exact path={'/' + ROUTES.FEE_FINE_LEDGER_CONFIGUARTION} component={FeeFineLedger} />
            <Route exact path={'/' + ROUTES.FEE_AMOUNT_CONFIGUARTION} component={FeeAmountConfig} />
            <Route exact path={'/' + ROUTES.FEE_SUBHEAD_TIME_CONFIGUARTION} component={FeeSubHeadTimeConfig} />
            <Route exact path={'/' + ROUTES.FEE_WAIVE_CONFIGUARTION} component={FeeWaiverConfig} />
            <Route exact path={'/' + ROUTES.FEE_HEAD_DELETE_CONFIGUARTION} component={FeeHeadDelete} />
            <Route exact path={'/' + ROUTES.FEE_COLLECTION_MANUAL} component={FeesCollection} />
            <Route exact path={'/' + ROUTES.FEE_REPORT_SECTION_PAID_LIST} component={SectionPaidInfo} />
            <Route exact path={'/' + ROUTES.FEE_REPORT_CLASS_PAID_LIST} component={ClassPaidInfo} />
            <Route exact path={'/' + ROUTES.FEE_REPORT_ALL_PAID_LIST} component={AllPaidInfo} />
            <Route exact path={'/' + ROUTES.FEE_REPORT_SECTION_DUE} component={SectionDueDetails} />
            <Route exact path={'/' + ROUTES.FEE_REPORT_MONTHLY_DUE} component={MonthlyDueDetails} />
            <Route exact path={'/' + ROUTES.FEE_REPORT_FEE_COLLECTION} component={FeeHeadCollection} />
            <Route exact path={'/' + ROUTES.FEE_REPORT_FEE_COLLECTION_CLASS} component={FeeHeadCollectionClass} />
            <Route exact path={'/' + ROUTES.SPG_STATEMENT} component={SpgStatement} />
            <Route exact path={'/' + ROUTES.FEE_DELETE_INVOICE} component={Invoice} />
            <Route exact path={'/' + ROUTES.FEE_DISCARD} component={FeeDiscardWrapper} />
            <Route exact path={'/' + ROUTES.FEE_SUBHEAD_DISCARD} component={FeeSubHeadDiscardWrapper} />
            <Route exact path={'/' + ROUTES.ATTENDANCE_FINE} component={AttendanceFineWrapper} />
           //////////***************** Student Accounts *************//////////////////////

           //////////***************** Student Exam *************//////////////////////
           <Route exact path={'/' + ROUTES.EXAM_SETTING} component={ExamSettingsWrapper} />
           <Route exact path={'/' + ROUTES.EXAM_SUBJECT_CONFIGURATION} component={ExamSubject} />
           <Route exact path={'/' + ROUTES.EXAM_CONFIGURATION} component={ExamConfiguration} />
           <Route exact path={'/' + ROUTES.EXAM_MARK_CONFIGURE} component={MarkConfigPage} /> 
           <Route exact path={'/' + ROUTES.EXAM_GRANDFINAL_MARK_CONFIGURE} component={GrandFinalMark} />
           <Route exact path={'/' + ROUTES.EXAM_FOUR_SUBJECT_ASSIGN} component={ExamFortSubject} />
           <Route exact path={'/' + ROUTES.ADMIT_CARD_INSTRUCTION} component={AdmitCardInstruction} />
           <Route exact path={'/' + ROUTES.EXAM_TEMPLATE} component={ExamTemplate} />
           <Route exact path={'/' + ROUTES.ADMIT_CARD_SEAT_PLAN} component={AdmitCardSeatPlan} />
           <Route exact path={'/' + ROUTES.EXAM_MARK_INPUT} component={MarkInputForm} />
           <Route exact path={'/' + ROUTES.EXAM_MARK_UPDATE} component={MarkUpdateForm} />
           <Route exact path={'/' + ROUTES.EXAM_MARK_PROCESS_CLASS} component={MarkProcessClass} />
           <Route exaxt path={'/'+ROUTES.EXAM_MARK_DELETE} component={MarkDelete}></Route>
           <Route exaxt path={'/'+ROUTES.EXAM_GENERAL_RESULT_PROCESS} component={GeneralExam}></Route> 
           <Route exaxt path={'/'+ROUTES.EXAM_GENERAL_RESULT_PROCESS_GRAND_FINAL} component={GrandFinal}></Route>
           <Route exaxt path={'/'+ROUTES.EXAM_GENERAL_RESULT_MERIT_POSITION} component={MeritPostion}></Route>
           <Route exaxt path={'/'+ROUTES.EXAM_REMARKS_GIVE} component={ExamRemarks}></Route> 
           <Route exaxt path={'/'+ROUTES.EXAM_GENERAL_TEBULATION_SHEET} component={TabulationSheet}></Route>
           <Route exaxt path={'/'+ROUTES.EXAM_GENERAL_MARKSHEET_DOWNLOAD} component={GeneralMarkSheetDownload}></Route>
           <Route exaxt path={'/'+ROUTES.EXAM_GRAND_MARKSHEET_DOWNLOAD} component={GrandFinalMarkSheetDownload}></Route>
           <Route exaxt path={'/'+ROUTES.EXAM_REPORT_SECTION_WISE_UNINPUTTED_SUBJECTS} component={ExamReportSectionWiseUninputtedSubjects}></Route>
           <Route exaxt path={'/'+ROUTES.EXAM_REPORT_SECTION_RESULT} component={ExamReportSectionWiseResult}></Route>
           <Route exaxt path={'/'+ROUTES.EXAM_REPORT_SECTION_WISE_MERIT_LIST} component={SectionWiseMeritList}></Route> 
           <Route exaxt path={'/'+ROUTES.EXAM_REPORT_CLASS_WISE_MERIT_LIST} component={ClassWiseMeritList}></Route> 
           <Route exaxt path={'/'+ROUTES.EXAM_REPORT_CLASS_FAILED} component={ClassWiseFailedList}></Route>
           <Route exaxt path={'/'+ROUTES.EXAM_REPORT_SECTION_FAILED} component={SectionWiseFailedList}></Route>
           <Route exaxt path={'/'+ROUTES.EXAM_REPORT_SECTION_WISE_GRADING_SUMMARY} component={SectionWiseGradingSummary}></Route>
           <Route exaxt path={'/'+ROUTES.EXAM_REPORT_SECTION_WISE_PASS_FAIL_SUMMARY} component={SectionWisePassFailSummary}></Route>
           <Route exaxt path={'/'+ROUTES.EXAM_RESULT_PUBLISH} component={ResultPublicationWrapper}></Route>
           //////////***************** Student Exam *************//////////////////////
           //////////***************** ATTENDANCE *************//////////////////////
           //////////***************** Payslip *************//////////////////////
           <Route exaxt path={'/'+ROUTES.PAYSLIP_CREATE_SINGLE} component={PaySlipCreateSingle}></Route>
           <Route exaxt path={'/'+ROUTES.PAYSLIP_CREATE_BATCH} component={CreatePaySlipBatch}></Route>
           <Route exaxt path={'/'+ROUTES.PAYSLIP_COLLECT} component={CollectPaySlip}></Route>
           <Route exaxt path={'/'+ROUTES.PAYSLIP_TEMPLATE} component={PayslipTemplate}></Route>
           <Route exaxt path={'/'+ROUTES.PAYSLIP_DATE_UPDATE} component={PayslipDateUpdate}></Route>
           //////////***************** Payslip *************//////////////////////
           //////////***************** SMS *************//////////////////////
           <Route exaxt path={'/'+ROUTES.SMS_SEND_DYNAMIC} component={DynamicSms}></Route>
           <Route exaxt path={'/'+ROUTES.STAFF_SMS} component={StaffSms}></Route> 
           <Route exaxt path={'/'+ROUTES.STUDENT_CLASSWISE_SMS} component={ClassWiseSms}></Route>
           <Route exaxt path={'/'+ROUTES.STUDENT_SECTIONWISE_SMS} component={SectionWiseSms}></Route>
           <Route exaxt path={'/'+ROUTES.SEND_SMS_EXAM_RESULT} component={ExamResultSms}></Route>
           <Route exaxt path={'/'+ROUTES.STUDENT_INSTIUTE_SMS} component={InstiuteSms}></Route>
           <Route exaxt path={'/'+ROUTES.SECTION_DUE_SMS} component={SectionDueSms}></Route>
           <Route exaxt path={'/'+ROUTES.SMS_TEMPLATE} component={SmsTemplates}></Route>
           <Route exaxt path={'/'+ROUTES.SMS_PURCHASE} component={PurchaseSms}></Route>
           <Route exaxt path={'/'+ROUTES.GENERAL_SMS_LIST} component={GeneralMessageList}></Route>
           <Route exaxt path={'/'+ROUTES.STAFF_SMS_LIST} component={StaffMessageList}></Route>
           <Route exaxt path={'/'+ROUTES.STUDENT_SMS_LIST} component={StudentMessageList}></Route>
           <Route exaxt path={'/'+ROUTES.SMS_BALANCE} component={SmsBalance}></Route>
           <Route exaxt path={'/'+ROUTES.FEE_PAID_SMS} component={FeePaidSms} ></Route>
           //////////***************** SMS *************//////////////////////
           /////////*********Master Setting*******////////////
           <Route exaxt path={'/'+ROUTES.INSTITUTE_UPDATE} component={InstituteUpdate}></Route> 
           <Route exaxt path={'/'+ROUTES.DEFAULT_SIGNATURE} component={DefaultSignature}></Route>
           <Route exaxt path={'/'+ROUTES.MASTER_USER_LIST} component={UsersList}></Route> 
           <Route exaxt path={'/'+ROUTES.MASTER_PAYABLE_BILL_LIST} component={PayableBill}></Route>
           <Route exaxt path={'/'+ROUTES.MASTER_PAID_BILL_LIST} component={PaidBill}></Route>
           /////////*********TC/Testimonial Setting*******////////////
           <Route exaxt path={'/'+ROUTES.TESTIMONIALS} component={Tetimonial}></Route>
           <Route exaxt path={'/'+ROUTES.TRANSFER_CERTIFICATE} component={TransferCertificate}></Route>
           /////////*********TC/Testimonial Setting*******////////////
           /////////*********Customer Support Setting*******////////////
           <Route exaxt path={'/'+ROUTES.INSTITUTE_LIST} component={InstiuteList}></Route>
           <Route exaxt path={'/'+ROUTES.OFPS_INSTITUTE_LIST} component={OFPSInstiuteList}></Route>
           <Route exaxt path={'/'+ROUTES.INSTITUTE_STUDENT_COUNT} component={InstiuteStudentCount}></Route>
           <Route exaxt path={'/'+ROUTES.INSTITUTE_JUMP} component={InstituteJump}></Route>
           <Route exaxt path={'/'+ROUTES.EXAM_GRADE_UPDATE} component={ExamGradeUpdate}></Route>
           <Route exaxt path={'/'+ROUTES.PARTNER_INFORMATION} component={PartnerInformation}></Route>
           /////////*********Customer Support Setting*******////////////
            <Route exact path={'/' + ROUTES.USER_LIST} component={Users} />
            /////////********* /*******Miscellaneous*/*******////////////
            <Route exact path={'/' + ROUTES.CLASS_ROUTINE} component={ClassRoutine} />
            <Route exact path={'/' + ROUTES.EXAM_ROUTINE} component={ExamRoutine} />
            <Route exact path={'/' + ROUTES.OFPS_PROBLEM} component={OfpsProblemView} />
            /////////*********Online Admission*******////////////  
            <Route exact path={'/' + ROUTES.SECTION_WISE_ONLINE_ADMISSION} component={OnlineAdmissionSectionWise} />
            <Route exact path={'/' + ROUTES.GROUP_WISE_ONLINE_ADMISSION} component={OnlineAdmissionGroupWise} />
            <Route exact path={'/' + ROUTES.PAYMENT_STATUS_WISE_ONLINE_ADMISSION} component={OnlineAdmissionPaymentStatusWise} />
            <Route exact path="/change-password" component={Profile} />
            <Route exact path={'/' + ROUTES.ERROR_PAGE} component={EmptyPage} />
          </Switch>
        </Content>
        <Footer style={{ textAlign: 'center', padding:"15px" }}>
          &copy; Copyright {year}  <a href="https://techaxbd.com/" style={{color:'#00CA88'}} target="_blank" rel="noopener noreferrer">Techax Limited</a>
        </Footer>
      </Layout>
    </Layout>
  );
}
