import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Column from 'rc-table/lib/sugar/Column';
import { SearchOutlined, SwitcherOutlined } from '@ant-design/icons';
import {  useHistory } from "react-router-dom";
export default function InstituteJump() {

    const [searchInstituteForm] = Form.useForm();
    const fetchJumpInstiute = useStoreActions((state)=> state.auth.fetchJumpInstiute);
    const instituteInfo = useStoreState((state) => state.auth.instituteInfo);
    const searchInstitute = (value) => {
        fetchJumpInstiute(value.instituteId);
    };
    const history = useHistory();
  
    useEffect(()=>{
        if (instituteInfo?.roleList?.includes('ROLE_CUSTOMER_SUPPORT')) {
			console.log("ok")
		} else {
            history.push('/error-page')
        }
    },[])


    return(
        <>
           <Card title="Go To Institute">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                        <Form
                            layout="vertical"
                            id="instituteId"
                            onFinish={searchInstitute}
                            form={searchInstituteForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>

                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="instituteId"
                                        label="Institute ID"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please input institute id" },
                                        ]}
                                    >
                                       <InputNumber placeholder="Institute id" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
           </Card>
        </>
    )
}