import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';
import TableView from '../../../../contents/AntTableResponsive';
import { DeleteOutlined, DownloadOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import Column from 'rc-table/lib/sugar/Column';
import { render } from '@testing-library/react';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { v4 as uuidv4 } from "uuid";
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { FilePdfOutlined } from '@ant-design/icons';
import { SelectSection } from '../../../select/SelectSection';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
require('jspdf-autotable');

export default function ClassWiseFailedList() {
    const { Option } = Select;
    const [examReportSectionWiseResultForm] = Form.useForm();

    const fetchExamListByClassAndClassId2 = useStoreActions((state) => state.exam.fetchExamListByClassAndClassId2);
    const examListByClassAndClassId2 = useStoreState((state) => state.exam.examListByClassAndClassId2);

    const fetchclassWiseMeritexamListByClassAndClassId = useStoreActions((state) => state.exam.fetchclassWiseMeritexamListByClassAndClassId);
    const classWiseMeritexamListByClassAndClassId = useStoreState((state) => state.exam.classWiseMeritexamListByClassAndClassId);



    const unassignedSubjectMarkListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: "customStudentId", showOnResponse: true, showOnDesktop: true },
        { title: 'Sudent Name', dataIndex: 'studentName', key: "studentName", showOnResponse: true, showOnDesktop: true },
        { title: 'Student Roll', dataIndex: 'studentRoll', key: "studentRoll", showOnResponse: true, showOnDesktop: true },
        { title: 'Section', dataIndex: 'classConfigName', key: "classConfigName", showOnResponse: true, showOnDesktop: true },
        { title: 'Total Marks', dataIndex: 'totalMarks', key: "totalMarks", showOnResponse: true, showOnDesktop: true },
        { title: 'Class Position', dataIndex: 'classPosition', key: "classPosition", showOnResponse: true, showOnDesktop: true },
        { title: 'Section Position', dataIndex: 'sectionPosition', key: "sectionPosition", showOnResponse: true, showOnDesktop: true },
        { title: 'Grade', dataIndex: 'letterGrade', key: "letterGrade", showOnResponse: true, showOnDesktop: true },
        { title: 'CGPA', dataIndex: 'gradingPoint', key: "gradingPoint", showOnResponse: true, showOnDesktop: true },
    ];
    const loading = useStoreState((state) => state.exam.loading);

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Class Wise Merit List`;
        pdfDataL(doc, "");
        doc.text(details, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        var col = [
            "Student ID",
            "Sudent Name",
            "Student Roll",
            "Section",
            "Total Marks",
            "Class Position",
            "Section Position",
            "Grade",
            "CGPA",
        ].filter(Boolean);

        var rows: any = [];


        classWiseMeritexamListByClassAndClassId.forEach(element => {
            var temp: any = [
                element.customStudentId,
                element.studentName,
                element.studentRoll,
                element.classConfigName,
                element.totalMarks,
                element.classPosition,
                element.sectionPosition,
                element.letterGrade,
                element.gradingPoint,
            ];
            rows.push(temp);
        });


        let first = doc.autoTable.previous;
        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
            { title: "", dataKey: "b3" },
            { title: "", dataKey: "b4" },
            { title: "", dataKey: "b5" },
            { title: "", dataKey: "b6" },
        ], [
            {
                b1: "Academic Year",
                b2: $(".yearSelect").text(),
                b3: 'Class',
                b4: $(".classSelect").text(),
                b5: 'Exam',
                b6: $(".examSelectx").text(),
            }
        ], {
            startY: 45,
            showHeader: "never",
            theme: 'grid',

            // addPageContent: pageContent,
        });
        doc.autoTable(col, rows, {
            startY: doc.autoTable.previous.finalY + 5,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 75
                },
                debit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                },
                credit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                }
            },


        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    const failedExamReport = (value) => {
        console.log('value', value);
        fetchclassWiseMeritexamListByClassAndClassId(value);
    }

    const onClassChange = (value) => {
        fetchExamListByClassAndClassId2(value);
    }




    return (
        <>
            <Card title="Class Wise Merit List">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={failedExamReport}
                            form={examReportSectionWiseResultForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="sectionYear"
                                        label="Select Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="selectClass"
                                        label="Select Class"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select class" },
                                        ]}
                                    >
                                        <SelectClass onChange={(e) => onClassChange(e)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="selectExam"
                                        label="Select Exam"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select exam" },
                                        ]}
                                    >
                                        <Select placeholder="Exam List" style={{ width: "100%" }} className="examSelectx">
                                            {examListByClassAndClassId2 ? (
                                                examListByClassAndClassId2.map((type, idx) => (
                                                    <Option key={type.examConfigId} value={type.examConfigId}>
                                                        {type.examName}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching exam</Option>
                                            )}
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: classWiseMeritexamListByClassAndClassId.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: unassignedSubjectMarkListColumn,
                                rowKey: "subjectSerial",
                                dataSource: classWiseMeritexamListByClassAndClassId,
                                filterData: classWiseMeritexamListByClassAndClassId,
                                pagination: true,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                        <Button type="primary" htmlType="submit" icon={<DownloadOutlined />} onClick={() => exportPdf()} className="float-right">Download</Button>
                    </Col>
                </Row>
            </Card>
        </>
    )

}