import * as React from 'react';
import { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import ReactToPrint from 'react-to-print';
import image from './image.jpg'
import { PrinterOutlined } from '@ant-design/icons';

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        // extends React's HTMLAttributes
        name?: string;
    }
}

const ComponentToPrint = React.forwardRef((props: any, ref: any) => {
    return (
        <div className='print-source' ref={ref} >
            <div className="c60">
                <div>
                    <p name="goHTML" className="c18"><span style={{ overflow: 'hidden', display: 'inline-block', margin: '0.00px 0.00px', border: '0.00px solid #000000', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)', width: '239.50px', height: '73.26px' }}><img alt="" src={image} style={{ width: '239.50px', height: '73.26px', marginLeft: '0.00px', marginTop: '0.00px', transform: 'rotate(0.00rad) translateZ(0px)', WebkitTransform: 'rotate(0.00rad) translateZ(0px)' }} /></span></p><a id="t.a69e43df8d4a005973e7eb9eece8046f27bd65d8" /><a id="t.3" />
                    <table className="c17" style={{marginTop:5}}>
                        <tbody>
                            <tr className="c40">
                                <td className="c63" colSpan={1} rowSpan={1}>
                                    <p name="goHTML" className="c6"><span className="c1" /></p>
                                </td>
                                <td className="c27" colSpan={1} rowSpan={1}>
                                    <p name="goHTML" className="c5 c73"><span className="c34 c47">Sheba Digital Limited</span></p>
                                    <p name="goHTML" className="c5"><span className="c34 c28 c45">House: 55, Road: 4/A,</span></p>
                                    <p name="goHTML" className="c5"><span className="c34 c45 c28">&nbsp;Dhanmondi R/A,Dhaka-1209 </span></p>
                                    <p name="goHTML" className="c5"><span className="c28 c59"><a className="c43" href="https://www.google.com/url?q=http://www.shebadigital.com&sa=D&source=editors&ust=1642395485635801&usg=AOvVaw0JoDKrb_kCIE3PcpQfdkLZ">www.shebadigital.com</a></span>
                                    </p>
                                    <p name="goHTML" className="c5"><span className="c34 c45 c28">Helpline:09612191919</span></p>
                                </td>
                            </tr>
                            <tr className="c36">
                                <td className="c63" colSpan={1} rowSpan={1}>
                                    <p name="goHTML" className="c24"><span className="c21">Invoice#</span><span className="c21 c30">:</span><span className="c21">INV/SMS-15421</span><span className="c21 c30">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                                </td>
                                <td className="c27" colSpan={1} rowSpan={1}>
                                    <p name="goHTML" className="c5"><span className="c21">Invoice Date: 01-Dec-2021</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p name="goHTML" className="c6"><span className="c67 c34 c30" /></p>
                </div>
                <p name="goHTML" className="c18 c46"><span className="c14" /></p><a id="t.70150a2f32db8a5d7aafd6af671f2fc151ea6469" /><a id="t.0" />
                <table className="c66">
                    <tbody>
                        <tr className="c77">
                            <td className="c48 c65" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c24"><span className="c10">Client ID &nbsp; &nbsp;:20004</span></p>
                                <p name="goHTML" className="c24"><span className="c10">Name &nbsp; &nbsp; &nbsp; &nbsp; :Udayan Uchcha Madhyamik
                                    Bidyalaya</span></p>
                                <p name="goHTML" className="c24"><span className="c10">Mobile No &nbsp;:01756336093</span></p>
                            </td>
                            <td className="c48 c68" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c76"><span className="c12">Paid Amount</span></p>
                                <p name="goHTML" className="c5" id="h.gjdgxs"><span className="c11">BDT 1500</span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p name="goHTML" className="c13"><span className="c1" /></p>
                <p name="goHTML" className="c24"><span className="c58 c30">Payment Receipt</span></p> <hr style={{width:705, marginLeft:-10}} />
                <p name="goHTML" className="c24"><span className="c20 c30">Payment Receipt for </span><span className="c20" style={{fontWeight:"bold"}}>"SMS Purchase"</span></p>
                <p name="goHTML" className="c13"><span className="c1" /></p>
                <p name="goHTML" className="c19"><span className="c35 c28 c41 c30" style={{marginLeft:-10}}>Payment</span></p><a id="t.6718c0f42f7f3cb0f5ab4c6ed1d77c040c8e8e7f" /><a id="t.1" />
                <table className="c50">
                    <tbody>
                        <tr className="c90">
                            <td className="c48 c75" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c24"><span className="c12">Charge Description</span></p>
                            </td>
                            <td className="c48 c61" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c5"><span className="c12 c48">Payable Date</span></p>
                            </td>
                            <td className="c23" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c5 c31"><span className="c12 c34">Payment Date</span></p>
                            </td>
                            <td className="c48 c64" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c5"><span className="c12">Amount</span></p>
                            </td>
                        </tr>
                        <tr className="c42">
                            <td className="c33" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c24"><span className="c28">5,000 SMS Purchase</span></p>
                            </td>
                            <td className="c56" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c5 c46"><span className="c2" /></p>
                            </td>
                            <td className="c3" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c5 c46 c31"><span className="c2" /></p>
                            </td>
                            <td className="c51" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c5"><span className="c28">1500</span></p>
                            </td>
                        </tr>
                        <tr className="c4">
                            <td className="c48 c85" colSpan={3} rowSpan={1}>
                                <p name="goHTML" className="c5 c8"><span className="c12">TOTAL PAID</span></p>
                            </td>
                            <td className="c48 c80" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c5 c8"><span className="c12 c34">1500 </span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p name="goHTML" className="c13"><span className="c35 c16" /></p>
                <p name="goHTML" className="c19" id="h.30j0zll"><span className="c16 c72" style={{marginLeft:-10}}>In words</span><span className="c16">:</span><span className="c2">&nbsp;</span></p>
                <p name="goHTML" className="c6"><span className="c2" /></p>
                <p name="goHTML" className="c6"><span className="c2" /></p>
                <p name="goHTML" className="c19 c79"><span className="c28 c41 c72" style={{marginLeft:270}}>Due</span></p><a id="t.416c7d453247ee6f8e2360d2c3fa1d43a41b6aa0" /><a id="t.2" />
                <table className="c54">
                    <tbody>
                        <tr className="c4">
                            <td className="c48 c78" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c24"><span className="c12">Item / Item Description</span></p>
                            </td>
                            <td className="c48 c74" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c5"><span className="c12">Amount</span></p>
                            </td>
                        </tr>
                        <tr className="c42">
                            <td className="c44" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c6"><span className="c2" /></p>
                                <p name="goHTML" className="c24"><span className="c28">5,000 SMS Purchase</span></p>
                            </td>
                            <td className="c87" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c5"><span className="c58">00</span></p>
                            </td>
                        </tr>
                        <tr className="c82">
                            <td className="c29" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c24"><span className="c20 c30">Total </span><span className="c28">Due</span></p>
                            </td>
                            <td className="c87 c89" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c5"><span className="c55">00</span></p>
                            </td>
                        </tr>
                        <tr className="c4">
                            <td className="c44 c48" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c5"><span className="c12">TOTAL DUE</span></p>
                            </td>
                            <td className="c48 c83" colSpan={1} rowSpan={1}>
                                <p name="goHTML" className="c5 c8"><span className="c0">00</span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p name="goHTML" className="c13"><span className="c16 c35" /></p>
                <p name="goHTML" className="c19 c88"><span className="c16 c72" style={{marginLeft:318}}>In words</span><span className="c16">:</span><span className="c20 c30" style={{marginLeft:5}}>&nbsp;One
                    Thousand </span><span className="c20">Five Hundred</span><span className="c20 c30">&nbsp;Only. asf dasdfs</span></p>
                <p name="goHTML" className="c7"><span className="c2" /></p>
                <p name="goHTML" className="c37"><span className="c34 c30 c49" /></p>
                <p name="goHTML" className="c52"><span className="c84">&nbsp; &nbsp; &nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c9">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp; &nbsp; &nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c9 c70">Billing Department</span></p>
                <p name="goHTML" className="c52"><span className="c15" style={{marginLeft:120}}>&nbsp; &nbsp; &nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sheba
                    Digital Limited</span></p>
                <p name="goHTML" className="c52 c46"><span className="c15" /></p>
                <p name="goHTML" className="c6"><span className="c9 c30 c53" /></p>
                <div>
                    <p name="goHTML" className="c24"><span className="c62">This is System Generated invoice. No signature is required. For any query
                        please email:</span><span className="c9 c86">&nbsp;billing@shebadigital.com</span></p><a id="t.abc6737e762182b95b3ce59d3d02f1237246fb5c" /><a id="t.4" />
                    <table className="c17">
                        <tbody>
                            <tr className="c57">
                                <td className="c38" colSpan={1} rowSpan={1}>
                                    <p name="goHTML" className="c5"><span className="c34 c30 c71">House: 55, Road: 4/A,Dhanmondi R/A, Dhaka-1209
                                        www.shebadigital.com</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p name="goHTML" className="c6"><span className="c67 c34 c30" /></p>
                </div>
            </div>

        </div>
    );
});


export default function SmsBalance() {

    const componentRef: any = useRef();

    return (
        <>
            <Card title="SMS Balance">
                <div>
                    <ReactToPrint
                        trigger={() => <Button type='primary' icon={<PrinterOutlined />}>Print</Button>}
                        content={() => componentRef.current}
                    />
                    <ComponentToPrint ref={componentRef} value={
                        {
                            value: 1,

                        }} />
                </div>


            </Card>
        </>
    )
}