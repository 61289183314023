import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Space, Table } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf, ldatepdf, pdfGenerate } from '../../../utils/pdf';
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import TableResponsive from "../../../../contents/AntTableResponsive";
import ReactExport from "react-export-excel";
import { SelectLedgerDFPSMulti } from '../../../select/SelectLedgerDFPSMulti';
import Text from 'antd/lib/typography/Text';
require('jspdf-autotable');

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;



let fd = '';
let td = '';
let totalsum = '';
export default function AllPaidInfo(props) {
    const allPaidReport = useStoreState((state) => state.saccount.allPaidReport);
    const fetchallPaidReport = useStoreActions((state) => state.saccount.fetchallPaidReport);
    const downloadInvoice = useStoreActions((state) => state.saccount.downloadInvoice);
    const loading = useStoreState((state) =>  state.saccount.loading);

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        // console.log(value)
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
            ledgerIds: value.ledgerIds.join()
        }
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");
        fetchallPaidReport(postData);
    };

    const columns = [

        {
            title: 'Fee Invoice Id',
            dataIndex: 'feeInvoiceId',
            key: 'feeInvoiceId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Id',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },       
         {
            title: 'Mobile No',
            dataIndex: 'mobileNo',
            key: 'mobileNo',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Section',
            dataIndex: 'section',
            key: 'section',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Payment Date',
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Ledger Name',
            dataIndex: 'ledgerName',
            key: 'ledgerName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Action',
            key: 'ledgerId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">

                    <Button type='primary' icon={<DownloadOutlined />} onClick={() => {
                        downloadInvoice(record.feeInvoiceId);
                    }}> </Button>




                </Space>
            ),
        },


    ];

    function exportPdf() {
        var details = `All Paid Info from ${fd} to ${td}`;
        totalsum = allPaidReport.reduce(function (acc, obj) { return acc + obj?.paidAmount }, 0)
        var doc = new jsPDF("l", "mm", "a4");


        pdfDataL(doc, details);

        var col = ["Invoice ID", "ID", "Name", "Roll", "Mobile No","Section", "Date", "Ledger Name", "Paid Amount"];

        var rows: any = [];

        var temp: any = []


        allPaidReport.forEach(element => {
            var temp = [element.feeInvoiceId, element.customStudentId, element.studentName,
            element.studentRoll, element.mobileNo, element.section, element.paymentDate, element.ledgerName, element.paidAmount];
            rows.push(temp);
        });

        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };

        doc.autoTable(col, rows, {
            headerStyles: {
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                3: { overflow: 'linebreak' },
                0: { overflow: 'linebreak' },
                1: { overflow: 'linebreak' },
                2: { overflow: 'linebreak' },
                5: { overflow: 'linebreak' },
                6: { overflow: 'linebreak' },
                7: { overflow: 'linebreak' },

            },
            theme: "grid",
            startY: 45,
            addPageContent: pageContent
        });

        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
        ], [
            {
                b1: "Total Paid Amount:",
                b2: totalsum,
            }
        ], {
            startY: doc.autoTable.previous.finalY + 5,
            showHeader: "never",
            theme: 'grid',
            styles: { fillColor: [255, 255, 255], lineColor: 255, },
            columnStyles: {
                b1: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 250,
                    fontStyle: 'bold'
                },
                b2: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 19
                },

            },

            // addPageContent: pageContent,
        });



        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }

        doc.save(details + ".pdf");

    }

    return (
        <>
            <Card title="All Paid Info">
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>

                            <Form.Item
                                name="ledgerIds"
                                label="Ledger:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                            >
                                <SelectLedgerDFPSMulti />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>
                < Skeleton loading={loading} paragraph={{rows:10}} /> 
                {allPaidReport?.length > 0 && 
                    <>
                        <TableResponsive
                            antTableProps={{
                                showHeader: true,
                                columns: columns,
                                dataSource: allPaidReport,
                                filterData: allPaidReport,
                                pagination: true,
                                bordered: true,
                                rowKey: "masterId",
                                summary: function () {
                                    let totalBill = 0;

                                    allPaidReport?.forEach(({ paidAmount }) => {
                                        totalBill += paidAmount;
                                    });

                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}>

                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={2}>

                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={3}>

                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={4}>

                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={5}>

                                                </Table.Summary.Cell>                                               
                                                 <Table.Summary.Cell index={6}>

                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={7}>
                                                    Total
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={8}>
                                                    <Text type="danger" strong>{totalBill}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={9}>

                                                </Table.Summary.Cell>

                                            </Table.Summary.Row>

                                        </>
                                    );
                                }
                            }}
                            mobileBreakPoint={768}

                        />
                        <Space style={{ float: 'right' }} size="middle">
                            <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`All Paid Info from ${fd} to ${td}`}
                            >
                                <ExcelSheet data={allPaidReport} name="Section Paid Info">
                                    <ExcelColumn label="Invoice Id" value="feeInvoiceId" />
                                    <ExcelColumn label="Student Id" value="customStudentId" />
                                    <ExcelColumn label="Student Name" value="studentName" />
                                    <ExcelColumn label="Roll" value="studentRoll" />
                                    <ExcelColumn label="Mobile No" value="mobileNo" />
                                    <ExcelColumn label="Section" value="section" />
                                    <ExcelColumn label="Payment Date" value="paymentDate" />
                                    <ExcelColumn label="Ledger Name" value="ledgerName" />
                                    <ExcelColumn label="Paid Amount" value="paidAmount" />

                                </ExcelSheet>
                            </ExcelFile>
                            <Button type="primary" icon={<FilePdfOutlined />} onClick={exportPdf} > Download PDF </Button>
                        </Space>
                    </>
                }
            </Card>
        </>
    )
}
