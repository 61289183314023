import React from 'react'
import { Card, Tabs } from 'antd';
import TabulationSheetExcel from './TabulationSheetExcel';
import TabulationSheetPDF from './TabulationSheetPDF';


export default function TabulationSheet(props) {

    const { TabPane } = Tabs;

    return (
        <>
            <Card title="Tabulation Sheet">
                <Tabs defaultActiveKey="1">
                    <TabPane tab="PDF" key="1">
                        <TabulationSheetPDF />
                    </TabPane>
                    <TabPane tab="Excel" key="2">
                        <TabulationSheetExcel />
                    </TabPane>

                </Tabs>
            </Card>
        </>
    )
}