import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined, SettingFilled } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, notification, Popconfirm, Row, Skeleton, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../../store/hooks/easyPeasy';
import Table from "../../../../../contents/AntTableResponsive";
import { SelectFeeHead } from '../../../../select/SelectFeeHead';
import { SelectFeeWaiver } from '../../../../select/SelectFeeWaiver';
import { SelectAcademicYear } from '../../../../select/SelectAcademicYear';
import { SelectGroupbySection } from '../../../../select/SelectGroupbySection';
import { SelectCategory } from '../../../../select/SelectCategory';
import { SelectClassConfig } from '../../../../select/SelectClassConfig';


export default function WaiverConfigList(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const studentWaiverList = useStoreState((state) => state.saccount.studentWaiverList);
    const fetchstudentWaiverList = useStoreActions((state) => state.saccount.fetchstudentWaiverList);
    const updateStudentWaiver = useStoreActions((state) => state.saccount.updateStudentWaiver);
    const deleteStudentWaiver = useStoreActions((state) => state.saccount.deleteStudentWaiver);
    const loading = useStoreState((state) =>  state.saccount.loading);




    const [serachData, setsearchData] = useState<any>({});
    const formSubmit = (value) => {

        setsearchData(value);
        fetchstudentWaiverList(value)
    }

    const updateFomrSubmit = (value) => {
        var postData = {
            feeWaiverConfigId: waiverConfigId,
            feeWaiverId: waiverId,
            waiverAmount: waiverAmount
        };
        updateStudentWaiver(postData);
        setTimeout(() => {
            fetchstudentWaiverList(serachData)
        }, 1000);
        updateForm.resetFields();
        setIsModalVisible(false);
    }


    const [waiverConfigId, setwaiverConfigId] = useState<any>();
    const [waiverId, setwaiverId] = useState<any>();
    const [waiverAmount, setwaiverAmount] = useState<any>();
    const columns = [

        {
            title: 'Student Id',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Fee Name',
            dataIndex: 'feeName',
            key: 'feeName',
            showOnResponse: true,
            showOnDesktop: true,
        },        
        {
            title: 'Fee Subhead Name',
            dataIndex: 'feeSubHeadName',
            key: 'feeSubHeadName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Waiver Name',
            dataIndex: 'waiverName',
            key: 'waiverName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Waiver Amount',
            dataIndex: 'waiverAmount',
            key: 'waiverAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Action',
            key: 'studentId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setwaiverConfigId(record.waiverConfigId);
                            setwaiverId(record.waiverId);
                            setwaiverAmount(record.waiverAmount);
                            updateForm.setFieldsValue({
                                feeName: record.feeName,
                                feeSubHeadName: record.feeSubHeadName,
                                feeWaiverId: record.waiverId,
                                waiverAmount: record.waiverAmount,
                            });
                            setIsModalVisible(true);


                        }}
                        />
                    </Tooltip>

                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => {
                            deleteStudentWaiver(record.waiverConfigId); setTimeout(() => {
                                fetchstudentWaiverList(serachData)
                            }, 1000);
                        }}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </Space>
            ),
        },

    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [section, setSection] = useState<any>();




    return (
        <>

            <Form
                layout="vertical"
                onFinish={formSubmit}
                id="basic-info"
                form={form}
            >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="academicYear"
                            label="Academic Year"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select year " },
                            ]}
                        >

                            <SelectAcademicYear />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="classConfigurationId"
                            label="Section"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please class section" },
                            ]}
                        >

                            <SelectClassConfig selected={section} onChange={e => { setSection(e) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="studentCategoryId"
                            label="Student Category"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select catergory" },
                            ]}
                        >

                            <SelectCategory />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Space size="small" style={{ float: "right" }} >
                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                Search
                            </Button>
                        </Space>
                    </Col>


                </Row>
            </Form>
            < Skeleton loading={loading} paragraph={{rows:10}} />
            {studentWaiverList?.length > 0 &&
                <>
                    <div className="datatable-responsive" style={{ marginTop: 20 }}>
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                        <Table
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: studentWaiverList,
                                filterData: studentWaiverList,
                                bordered: true,
                                rowKey: "studentId",
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>

                </>
            }


            <Modal
                title="Student Waiver"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Save"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >

                    <Form.Item
                        name="feeName"
                        label="Fee Head:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select fee head" },
                        ]}
                    >
                        <Input disabled />
                    </Form.Item>
                   
                    <Form.Item
                        name="feeSubHeadName"
                        label="Fee Subhead:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select fee subhead" },
                        ]}
                    >
                        <Input disabled />
                    </Form.Item>

                    <Form.Item
                        name="feeWaiverId"
                        label="Fee Waiver:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select fee waiver" },
                        ]}
                    >
                        <SelectFeeWaiver selected={waiverId} onChange={e => setwaiverId(e)} />
                    </Form.Item>

                    <Form.Item
                        name="waiverAmount"
                        label="Amount:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please insert fee amount" },
                        ]}
                    >
                        <Input placeholder="Amount" value={waiverAmount} onChange={e => setwaiverAmount(e.target.value)} />
                    </Form.Item>



                </Form>

            </Modal>
        </>
    )
}
