import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Column from 'rc-table/lib/sugar/Column';
import { DeleteOutlined, EditOutlined, SaveFilled, SwitcherOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import { SelectDistrict2 } from '../../select/SelectDistrict2';
import { SelectThana } from '../../select/SelectThana';
import { SelectDistrict } from '../../select/SelectDistrict';
import { SelectThana2 } from '../../select/SelectThana2';
const { Option } = Select;
export default function PartnerInformationList() {
    const [updateform] = Form.useForm();
    
    const thanaListFetch2= useStoreActions((state) => state.common.thanaListFetch2);
    const fetchPartnerList = useStoreActions((state) => state.customerSupport.fetchPartnerList);
    const partnerList = useStoreState((state) => state.customerSupport.partnerList);
    const updatePartnerinformation = useStoreActions((state) =>  state.customerSupport.updatePartnerinformation);
    const deletePartnerInformation = useStoreActions((state) => state.customerSupport.deletePartnerInformation);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [partnerId, setPartnerId] = useState<any>();
    const [thanaId, setThanaId] = useState<any>();
    const [districtId, setDistrictId] = useState<any>();
    const [statusId, setStatus] = useState<any>();
    const fetchThanaByDistricId = (e) => {
        thanaListFetch2(e)
    }
    

    useEffect(() => {
        fetchPartnerList();
    },[])

    console.log('statusId', statusId);
    

    const listColumn = [
        { title: 'Partner Name', dataIndex: 'partnerName', key: 'partnerName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Mobile', dataIndex: 'mobile', key: 'mobile', showOnResponse: true, showOnDesktop: true  },
        { title: 'Thana Name', dataIndex: 'thanaName', key: 'thanaName', showOnResponse: true, showOnDesktop: true  },
        { title: 'District Name', dataIndex: 'districtName', key: 'districtName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Address', dataIndex: 'addressDetail', key: 'addressDetail', showOnResponse: true, showOnDesktop: true  },
        {
            title: 'Action',
            key: 'ledgerId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setPartnerId(record.partnerId);
                            setThanaId(record.thanaId);
                            setDistrictId(record.districtId);
                            updateform.setFieldsValue({
                                partnerName: record.partnerName,
                                mobileNumber: record.mobile,
                                address: record.addressDetail,
                                status: record.partnerStatus == 1 ? "Active" : "Deactive"
                            });
                        }}
                        />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deletePartnerInformation(record?.partnerId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        },
    ];

    const updateInfoForm = (value) => {
        console.log('value', value);
        
        let postData:any = {
            addressDetail: value.address,
            mobile: value.mobileNumber,
            partnerId: partnerId,
            partnerName: value.partnerName,
            partnerStatus: value.status == 'Active' ? 1 : 0,
            thanaId: thanaId
          }
        updatePartnerinformation(postData);    
        updateform.resetFields();
        setIsModalVisible(false);
    }

    return(
        <>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <TableView
                        antTableProps={{
                            showHeader: true,
                            columns:listColumn,
                            rowKey:"identificationId",
                            dataSource: partnerList,
                            filterData: partnerList,
                            pagination: true,
                            bordered: true,                           
                        }}
                        mobileBreakPoint={768}
                    />
                </Col>
            </Row>
            <Modal
                title="Partner Information Upsate"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={updateInfoForm}
                    id="update"
                    form={updateform}
                >
                    <Form.Item
                        name="partnerName"
                        label="Partner Name"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please write partner name" },
                        ]}
                    >
                        <Input placeholder="Enter partner name" />
                    </Form.Item>
                    <Form.Item
                        name="mobileNumber"
                        label="Mobile Number "
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please insert mobile number" },
                        ]}
                    >
                        <Input placeholder="Mobile Number" />
                    </Form.Item>
                    <Form.Item
                        name="distric"
                        label="District"
                        className="title-Text"
                    >
                        <SelectDistrict2 selected={districtId} onChange={(e) => {fetchThanaByDistricId(e); setThanaId(null)}} />
                    </Form.Item>
                    <Form.Item
                        name="thana"
                        label="Thana"
                        className="title-Text"
                    >
                        <SelectThana2 selected={thanaId} />

                    </Form.Item>
                    <Form.Item
                        name="address"
                        label="Address"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Write Address" },
                        ]}
                    >
                        <Input placeholder="Write address" />

                    </Form.Item>
                    <Form.Item
                        name="status"
                        label="Partner Status"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Select Status" },
                        ]}
                    >
                        <Select placeholder="Please select status">
                            <Option key={0} value="Active">Active</Option>
                            <Option key={1} value="Deactive">Deactive</Option>
                        </Select>

                    </Form.Item>
                </Form>

            </Modal>
        </>
    )
}