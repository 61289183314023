import React, { useEffect, useRef } from 'react'
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { DeleteOutlined, EditOutlined, FilePdfOutlined, FileTextOutlined, PayCircleOutlined, SearchOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import { v4 as uuidv4 } from "uuid";
import logo from './logo.png';
import paid from './paid.png';
import ReactToPrint from 'react-to-print';

const { Option } = Select;
var year = (new Date().getFullYear()) * 1;


const ComponentToPrint = React.forwardRef((props: any, ref: any) => {
    const { data, info } = props;
    //console.log(data);
    return (
        <div className='print-source' ref={ref} >
            <div className="cs-container" id="billinvoice">
                <div className="cs-invoice cs-style1">
                    <div className="cs-invoice_in" id="download_section">
                        <div className="cs-invoice_head cs-type1 cs-mb25">
                            <div className="cs-invoice_left">
                                <p className="cs-invoice_number cs-primary_color cs-mb0 cs-f16">
                                    <b className="cs-primary_color">Invoice No:</b> #{data.billGenerateId}
                                </p>
                            </div>
                            <div className="cs-invoice_right cs-text_right">
                                <div className="cs-logo cs-mb5">
                                    <img className="billinvoice" src={logo} alt="Logo" />
                                </div>
                            </div>
                        </div>
                        <div className="cs-invoice_head cs-mb10">
                            <div className="cs-invoice_left">
                                <b className="cs-primary_color">Invoice From:</b>
                                <p>
                                    Sheba Digital Limited <br />
                                    House: 55, Road: 4/A Dhanmondi R/A <br />
                                    Dhaka, 1209, Bangladesh <br />
                                    billing@shebadigital.com <br />
                                    09612-191919
                                </p>
                            </div>
                            <div className="cs-invoice_right cs-text_right">
                                <b className="cs-primary_color">Invoice To</b> <br />
                                <span> {data.clientName}</span> <br />
                                <span> {info.address}</span> <br />
                                {/* <span> {info.instituteEmail}</span> <br /> */}
                                <span> {info.contactNumber}</span> <br />
                                <span> Invoice Date: {data.generateDate}</span> <br />
                                <span> Payment Date: {data.paymentDate}</span> <br />
                            </div>
                        </div>
                        {/* <ul className="cs-list cs-style2">
                            <li>
                                <div className="cs-list_left">
                                    Client ID:{" "}
                                    <b className="cs-primary_color cs-semi_bold ">AS2534568</b>
                                </div>
                                <div className="cs-list_right">
                                    Outstanding Balance:{" "}
                                    <b className="cs-primary_color cs-semi_bold ">$3600</b>
                                </div>
                            </li>
                            <li>
                                <div className="cs-list_left">
                                    Invoice Date:{" "}
                                    <b className="cs-primary_color cs-semi_bold ">Johan Smith</b>
                                </div>
                                <div className="cs-list_right">
                                    Total Curent Charges:{" "}
                                    <b className="cs-primary_color cs-semi_bold ">25 Feb 2022</b>
                                </div>
                            </li>
                            <li>
                                <div className="cs-list_left">
                                    Due Date: <b className="cs-primary_color cs-semi_bold ">Winter</b>
                                </div>
                                <div className="cs-list_right">
                                    Total Balance Due:{" "}
                                    <b className="cs-primary_color cs-semi_bold ">2022 Spring</b>
                                </div>
                            </li>
                        </ul> */}
                        <div className="cs-table cs-style2">
                            <div className="billinvoice">
                                <div className="cs-table_responsive">
                                    <table>
                                        <thead>
                                            <tr className="cs-focus_bg">
                                                <th className="cs-width_5 cs-semi_bold cs-primary_color">
                                                    Description
                                                </th>
                                                <th className="cs-width_5 cs-semi_bold cs-primary_color">
                                                    Period
                                                </th>
                                                <th style={{ textAlign: "right" }} className="cs-width_2 cs-semi_bold cs-primary_color cs-text_right">
                                                    Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="cs-width_5">
                                                    {data.productPackage} Package
                                                </td>
                                                <td className="cs-width_5">
                                                    {data.billingMonth}
                                                </td>
                                                <td className="cs-width_2 cs-text_right cs-primary_color">
                                                    ৳{data.payableAmount}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cs-width_5">

                                                </td>
                                                <td className="cs-width_5">Online Charge</td>
                                                <td className="cs-width_2 cs-text_right cs-primary_color">
                                                    ৳{data.serviceCharge}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cs-width_5">

                                                </td>
                                                <td className="cs-width_5">VAT</td>
                                                <td className="cs-width_2 cs-text_right cs-primary_color">
                                                    ৳0
                                                </td>
                                            </tr>

                                            <tr className="cs-focus_bg cs-no_border">
                                                <td
                                                    className="cs-width_5 cs-text_right cs-primary_color cs-semi_bold"
                                                    colSpan={2}
                                                >
                                                    Total charges:
                                                </td>
                                                <td className="cs-width_2 cs-text_right cs-primary_color cs-semi_bold">
                                                    ৳{data.payableAmount + data.serviceCharge}
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <div style={{ marginTop: 40, display:"flex", alignItems:"center" }}>
                                        {/* <h3> <strong>Payment Information:</strong> </h3>  */}
                                        <div>
                                        <b className="cs-primary_color">Bank Information for manual payment:</b>  <p></p>
                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: 300 }}>
                                            <span>A/C Name</span>
                                            <span>: Sheba Digital Limited</span>
                                            <span>A/C Number</span>
                                            <span>: 4410702000693</span>
                                            <span>Branch Name</span>
                                            <span>: Jigatola Branch</span>
                                            <span>Routing Number</span>
                                            <span>: 200262327</span>
                                            <span>SWIFT Code</span>
                                            <span>: BSONBDDH</span>
                                            <span>Bank Name</span>
                                            <span>: Sonali Bank Limited</span>
                                        </div>
                                        </div>
                                        <div>
                                        <div className="cs-logo cs-mb5" style={{marginLeft:120}}>
                                    <img className="billinvoice" src={paid} height={100} width={150} alt="Logo" />
                                </div>
                                        </div>
                                    </div>

                                    <div style={{ position: "absolute", bottom: 10 }}>
                                        <p name="goHTML" className="c24" style={{ fontFamily: "Inter", borderBottom: "1px solid #eaeaea", paddingBottom: 5 }}><span className="c62">This is System Generated invoice. No signature is required. For any query
                                            please email:</span><span className="c9 c86">&nbsp;billing@shebadigital.com</span></p><a id="t.abc6737e762182b95b3ce59d3d02f1237246fb5c" /><a id="t.4" />

                                        <table className="c17">
                                            <tbody>
                                                <tr className="c57">
                                                    <td className="c38" colSpan={1} rowSpan={1}>
                                                        <p name="goHTML" className="c5"><span className="c34 c30 c71" style={{ fontFamily: "Inter" }}>House: 55, Road: 4/A,Dhanmondi R/A, Dhaka-1209
                                                            www.shebadigital.com</span></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p name="goHTML" className="c6"><span className="c67 c34 c30" /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
});

const ComponentToPrintWrapper = ({ data, info }) => { // 1.
    const componentRef: any = useRef(); // 2.

    return (
        <div style={{ display: "flex" }}>
            <ReactToPrint
                trigger={() => <Button type='primary' icon={<FilePdfOutlined />}>Print</Button>}
                content={() => componentRef.current}
            />
            <ComponentToPrint ref={componentRef} data={data} info={info} />
        </div>
    );
};
export default function PaidBill() {

    const fetchMasterSettingPaidList = useStoreActions<any>((state) => state.masterSettings.fetchMasterSettingPaidList);
    const masterSettingPaidList = useStoreState((state) => state.masterSettings.masterSettingPaidList);
    const loading = useStoreState((state) => state.masterSettings.loading);
    const instituteInfo = useStoreState(state => state.auth.instituteInfo)
    const instiuteInfoList = useStoreState((state) => state.masterSettings.instiuteInfoList);
    const fetchInstiuteInfoList = useStoreActions((state) => state.masterSettings.fetchInstiuteInfoList);
    useEffect(() => {
        fetchInstiuteInfoList();
    }, []);

    let columns = [
        { title: 'Year', dataIndex: 'billingYear', key: 'billingYear', showOnResponse: true, showOnDesktop: true },
        { title: 'Month', dataIndex: 'billingMonth', key: 'billingMonth', showOnResponse: true, showOnDesktop: true },
        { title: 'Billing Cycle', dataIndex: 'billingCycle', key: 'billingCycle', showOnResponse: true, showOnDesktop: true },
        { title: 'Paid Amount', dataIndex: 'paidAmount', key: 'paidAmount', showOnResponse: true, showOnDesktop: true },
        { title: 'Invoice Date', dataIndex: 'generateDate', key: 'generateDate', showOnResponse: true, showOnDesktop: true },
        { title: 'Payment Date', dataIndex: 'paymentDate', key: 'paymentDate', showOnResponse: true, showOnDesktop: true },
        
        {
            title: 'Receipt', dataIndex: 'status', key: uuidv4(), showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
                <>  <Space size="middle">

                    <ComponentToPrintWrapper key={index} data={record} info={instiuteInfoList} />
                </Space>

                </>
            )
        }
    ];


    const [onsubmitForm] = Form.useForm();

    const createSubmitForm = (value) => {
        let payload = {
            clientProvideId: instituteInfo?.instiltuteId,
            year: value.year,
        };
        fetchMasterSettingPaidList(payload);
    };


    return (
        <>

            <Card title="Paid Bill List">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 8 }} xl={{ span: 12, offset: 8 }}>
                        <Form
                            layout="vertical"
                            id="sessionInfo"
                            onFinish={createSubmitForm}
                            form={onsubmitForm}
                        >
                            <Row>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                    <Form.Item
                                        name="year"
                                        label="Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >

                                        <Select placeholder="Select Year" allowClear>
                                            <Option value={year - 1}>{year - 1}</Option>
                                            <Option value={year}>{year}</Option>
                                            <Option value={year + 1}>{year + 1}</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row className="m-t-mo-30">
                    <Col span="24">
                        <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                        {!loading &&
                            <div className="datatable-responsive-demo">
                                {masterSettingPaidList?.length > 0 &&
                                    <TableView
                                        antTableProps={{
                                            showHeader: true,
                                            columns,
                                            dataSource: masterSettingPaidList,
                                            filterData: masterSettingPaidList,
                                            pagination: true,
                                            bordered: true,
                                            rowKey: "id",
                                        }}
                                        mobileBreakPoint={768}
                                    />
                                }
                            </div>
                        }
                    </Col>
                </Row>
            </Card>
        </>
    )
}