import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, TimePicker, DatePicker, Skeleton } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectClass } from '../../../select/SelectClass';
import { SelectSection } from '../../../select/SelectSection';
import { SelectShift } from '../../../select/SelectShift';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectPeriod } from '../../../select/SelectPeriod';
import moment from 'moment';
import { format } from 'path';


export default function TakeAttendance() {
    const fetchStudentAttendanceAllPeriod = useStoreActions((state) => state.student.fetchStudentAttendanceAllPeriod);
    const fetchStudentAttendanceList = useStoreActions((state) => state.student.fetchStudentAttendanceList);
    const studentAttendanceList = useStoreState((state) => state.student.studentAttendanceList);
    const saveAttendanceInput = useStoreActions((state) => state.student.saveAttendanceInput);
    const [studentSearchForm] = Form.useForm();
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [selectedValue, setselectedValue] = useState<any>();
    const [periodSelect, setPeriodSelect] = useState<any>();
    const [selectSection, setSelectSection] = useState<any>();
    const [attendanceDate, setAttendanceDate] = useState<any>();
    const [studentSearchList, setStudentSearchList] = useState<any>();
    const dateFormat = 'YYYY/MM/DD';
    const loading = useStoreState((state) => state.student.loading);

    const searchStudent = (value) => {
        let date = value.date;
        setSelectSection(value.sectionId);
        fetchStudentAttendanceList(value.sectionId);
        setAttendanceDate(date._d);
        setPeriodSelect(value.period);
    }

    useEffect(() => {
        fetchStudentAttendanceAllPeriod();
    }, [])

    useEffect(() => {
        setStudentSearchList(studentAttendanceList);
    }, [studentAttendanceList])

    const columns = [
        {
            title: 'Student ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },
    ];

    // let checkAttendanceStatus = (value) => {
    //     let returnVal = 1 ;
    //     selectedValue.map(item => {
    //         if (item.identificationId === value) {
    //             returnVal = 2;
    //         }else{
    //             returnVal = 1;
    //         }
    //     })
    //     return returnVal;
    // }

    const attendanceProcess = () => {
        // console.log(selectedValue);
        // let detailsData:any = [];
        let detailsData = studentSearchList.map(item => {
            return {
                identificationId: item.identificationId,
                attendanceStatus: selectedValue.map(nitem => nitem.identificationId).includes(item.identificationId) ? 2 : 1
            }
        })
        let attendanceDetails: any = {
            attendanceDate: moment(attendanceDate).format("YYYY-MM-DD"),
            periodId: periodSelect,
            absentSms:sendSms,
            details: detailsData
        }
        let postData: any = {
            payload: attendanceDetails,
            payloadSecond: selectSection
        }
         saveAttendanceInput(postData);
         setselectedRowKeys([]);
         setselectedValue([]);
    }

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        columnTitle: <div style={{ width: 150 }}>Check for Absent</div>,
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const [sendSms, setsendSms] = useState<boolean>(false)
    return <>
        <Card title="Take Student Attendance">
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }}>
                    <Form
                        layout="vertical"
                        id="studentSearchForm"
                        onFinish={searchStudent}
                        form={studentSearchForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="date"
                                    label="Select Date"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select date" },
                                    ]}
                                >
                                    <DatePicker style={{ width: "100%" }} format={dateFormat} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="sectionId"
                                    label="Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectClassConfig />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="period"
                                    label="Select Period"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select period" },
                                    ]}
                                >
                                    <SelectPeriod />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{ rows: 10 }} />
            <Row className="m-t-mo-30" style={{ display: studentAttendanceList.length > 0 ? '' : 'none' }}>
                <Col span="24">
                    <div className="datatable-responsive-demo" >
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: studentAttendanceList,
                                filterData: studentAttendanceList,
                                pagination: false,
                                bordered: true,
                                rowKey: "identificationId",
                                rowSelection: rowSelection,
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                    <Space style={{ float: "right" }} size={'middle'}>
                        <Checkbox value={sendSms} onChange={e => setsendSms(!sendSms)}>Send SMS</Checkbox>
                        <Button type="primary" onClick={() => attendanceProcess()}>Process</Button>
                    </Space>

                </Col>
            </Row>

        </Card>
    </>
}