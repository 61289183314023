import React, { useEffect } from 'react'
import { useState } from 'react';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Upload } from 'antd'
import TableView from '../../../contents/AntTableResponsive';
import { DeleteOutlined, EditOutlined, EyeFilled, PlusSquareOutlined } from '@ant-design/icons';

const SMSHelper = require('smshelper');
const { TextArea } = Input;

export default function SmsTemplates() {

    const fetchSmsTemplate = useStoreActions((state) => state.sms.fetchSmsTemplate);
    const smsTemplateList = useStoreState((state) => state.sms.smsTemplateList);
    const [viewSmsText, setViewSmsText] = useState<any>('');
    const [isCreateModalVisible, setCreateIsModalVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const saveTemplateSms = useStoreActions((state) => state.sms.saveTemplateSms);
    const updateTemplateSms = useStoreActions((state) => state.sms.updateTemplateSms)
    const deleteSmsTemplate = useStoreActions((state) => state.sms.deleteSmsTemplate)
    const [updateId, setUpdateid] = useState<any>();
    const [createForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    useEffect(() => {
        fetchSmsTemplate()
    }, []);

    const [message, setMessage] = useState<any>('');
    
    const column = [
        { title: 'Template Title', dataIndex: 'templateTitle', key: "templateTitle", showOnResponse: true, showOnDesktop: true  },
        { title: 'Template Body', dataIndex: 'templateBody', key: "templateBody", showOnResponse: true, showOnDesktop: true  },
        { title: 'Action', dataIndex: 'templateBody', key: "templateBody", showOnResponse: true, showOnDesktop: true,  
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="View">
                        <Button type="primary" icon={<EyeFilled />} onClick={() => {
                                setViewSmsText(record.templateBody)
                            }
                        }
                        />
                    </Tooltip>
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} 
                            onClick={() => {
                                    setViewSmsText(record.templateBody)
                                    setUpdateid(record.templateId);
                                    setIsModalVisible(true);
                                    onChangeRemarksList(record.templateBody);
                                    updateForm.setFieldsValue({
                                        templateTitleUpdate: record.templateTitle,
                                        smsContentUpdate:record.templateBody
                                    });
                                }
                            }
                        />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteSmsTemplate(record.templateId)}

                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
          ),},
    ]

    const updateSubmitForm = (value) => {
        let updatePostData:any = {
            templateId: updateId,
            templateBody: value.smsContentUpdate,
            templateTitle: value.templateTitleUpdate
        }        
        updateTemplateSms(updatePostData);
        setIsModalVisible(false);
    }

    const onChangeRemarksList = (e) => {
        updateForm.setFieldsValue({
            "smsContent":e  
        })
    }

    const composeSms = (value) => {
        let payload:any = {
            templateBody: value.smsContentCreate,
            templateTitle: value.templateTitleCreate
        }
        saveTemplateSms(payload);
        setCreateIsModalVisible(false);
        updateForm.resetFields()
    }

    return(
        <>
            <Card title="Template List">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 18 }} lg={{ span: 18 }} xl={{ span: 18 }}>
                        <Row >
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}}>
                                <div style={{ display: smsTemplateList.length > 0 ? '' : 'none' }}>
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns:column,
                                        rowKey:"templateId",
                                        dataSource: smsTemplateList,
                                        filterData: smsTemplateList,
                                        pagination: true,
                                        bordered: true
                                    }}
                                    mobileBreakPoint={768}
                                />
                                 <br />
                                </div>
                                <Button type="primary" htmlType="submit" icon={<PlusSquareOutlined />} onClick={() => setCreateIsModalVisible(true)} className="float-right">Compose SMS</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <div className="mobile-view-wrapper">
                            <div className="outer-bodyx">
                                <div className="headerx">
                                    <div className="sensor"></div>
                                    <div className="centerx">
                                        <span className="front-camera"></span>
                                        <span className="speaker"></span>
                                    </div>
                                </div>

                                <div className="bodyx">
                                    <div className="mobileMessage">
                                        {viewSmsText == '' ? <div><span style={{ fontWeight:900 }}> ( Here, You can View Your Global Template. Like below )</span><br/> <br/><p> Dear Teacher, An Urgent Meeting will held on Today at 5:30pm. Please attend on sharp 5:30 and bring your lorem ipsum with you. Regards, Headmaster</p></div> : viewSmsText }
                                        
                                    </div>

                                    <div className="footerx">
                                        {/* <div className="home-button"></div> */}
                                    </div>

                                    <div className="right-panel-btn-1"></div>
                                    <div className="right-panel-btn-2"></div>
                                    <div className="right-panel-btn-3"></div>

                                    <div className="left-panel-btn"></div>
                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Card>
            <Modal
                title="Update"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateSubmitForm}
                    form={updateForm}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="templateTitleUpdate"
                                label="Enter Template Title"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write template title" },
                                ]}
                            >
                                <Input placeholder="Template Title" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="smsContentUpdate"
                                label="Enter SMS Content"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Write sms content" },
                                ]}
                            >
                                <TextArea style={{height:100}} placeholder="Write sms content" rows={5} value={viewSmsText} className="placeholderColorChange" onChange={e => setMessage(e.target.value)} />
                            </Form.Item>  
                        </Col>
                    </Row>
                    <div style={{ marginBottom: -15 }}>
                        {message.length > 0 &&
                            <>
                                SMS Count: {SMSHelper.parts(message)}
                            </>
                        }
                    </div>
                </Form>
            </Modal>
            <Modal
                title="Compose SMS"
                visible={isCreateModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'createForm', htmlType: 'submit' }}
                onCancel={() => setCreateIsModalVisible(false)}
                cancelText="Close"
                okText="Create"
                centered
            >
                <Form
                    layout="vertical"
                    id="createForm"
                    onFinish={composeSms}
                    form={createForm}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="templateTitleCreate"
                                label="Enter Template Title"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write template title" },
                                ]}
                            >
                                <Input placeholder="Template Title" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="smsContentCreate"
                                label="Enter SMS Content"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Write sms content" },
                                ]}
                            >
                                <TextArea style={{height:100}} placeholder="Write sms content" rows={5} value={viewSmsText} className="placeholderColorChange" onChange={e => setMessage(e.target.value)} />
                            </Form.Item>  
                        </Col>
                    </Row>
                </Form>
                <div style={{ marginBottom: -15 }}>
                    {message.length > 0 &&
                        <>
                            SMS Count: {SMSHelper.parts(message)}
                        </>
                    }
                </div>
            </Modal>
        </>
    )
}