
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import TableView from '../../../../contents/AntTableResponsive';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { FilePdfOutlined } from '@ant-design/icons';
import ReactExport from "react-export-excel";
import { SelectCategory } from '../../../select/SelectCategory';
require('jspdf-autotable');

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

export default function CategoryWiseReport() {

    const [classWiseReportForm] = Form.useForm();
    const fetchCategoryWiseReport = useStoreActions((state) => state.student.fetchCategoryWiseReport);
    const categoryWiseListReport = useStoreState((state) =>  state.student.categoryWiseListReport);
    const loading = useStoreState((state) =>  state.student.loading);


    const formSubmit = (value) => {
        let postData:any = {
            academicYear: value.yearId,
            categoryId: value.categoryId
        }
        fetchCategoryWiseReport(postData);
    }

    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true  },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true  },
        { title: 'Group', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Section', dataIndex: 'classConfigName', key: 'classConfigName', showOnResponse: true, showOnDesktop: true,  render: ((text, record: any, index) => {
            return (<>{record.classConfigName}</>);
        })  },
        { title: 'Gender', dataIndex: 'studentGender', key: 'studentGender', showOnResponse: true, showOnDesktop: true  },
        { title: 'Religion', dataIndex: 'studentReligion', key: 'studentReligion', showOnResponse: true, showOnDesktop: true  },
        { title: 'Mobile No.', dataIndex: 'guardianMobile', key: 'guardianMobile', showOnResponse: true, showOnDesktop: true  },
    ];

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Category Wise list of ${$(".classSelect").text()} and Academic Year-${classWiseReportForm.getFieldValue("yearId")}`;
        var detailsx = `Category Wise list of Class: ${$(".classSelect").text()} and Academic Year: ${classWiseReportForm.getFieldValue("yearId")}`;
        pdfDataL(doc, "");
        doc.text(detailsx, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);            
        };


        var col = [
            "Student ID",
            "Student Name",
            "Roll",
            "Group",
            "Section",
            "Gender",
            "Religion",
            "Mobile No."
        ].filter(Boolean);

        var rows:any = [];


        categoryWiseListReport.forEach(element => {
            var temp:any = [ 
                element.customStudentId, 
                element.studentName, 
                element.studentRoll,
                element.groupName,
                element.classConfigName,
                element.studentGender,
                element.studentReligion,
                element.guardianMobile,
            ];
            rows.push(temp);
        });
       

            let first = doc.autoTable.previous;
            doc.autoTable(col, rows, {
                startY: 45,
                showHeader: "firstPage",
                theme: 'grid',
                styles: {
                    overflow: 'linebreak',
                },
                headerStyles: {
                    // fillColor: [105, 105, 105],
                    // textColor: [255, 255, 255],
                    // fontSize: 10
                    lineWidth: .01,
                    lineColor: [224, 224, 224]
                },
                columnStyles: {
                    // guardianMobile: {
                    //     halign: "left",
                    //     fontSize: 8,
                    //     columnWidth: 250
                    // }
                },
                addPageContent: pageContent,
            });

  
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    return(
        <>
            <Card title="Category Wise Report">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={classWiseReportForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="yearId"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]} 
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="categoryId"
                                        label="Select Category"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select category" },
                                        ]} 
                                    >
                                        <SelectCategory />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: categoryWiseListReport.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns:studentListColumn,
                                rowKey:"identificationId",
                                dataSource: categoryWiseListReport,
                                filterData: categoryWiseListReport,
                                pagination: true,
                                bordered: true,                           
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                        {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                    </Col>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>
                        <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Category Wise Report of ${$(".classSelect").text()} and Academic Year ${classWiseReportForm.getFieldValue("yearId")}`}
                            >
                                <ExcelSheet data={categoryWiseListReport} name="Category Wise Report">
                                    <ExcelColumn label="Student ID" value="customStudentId" />
                                    <ExcelColumn label="Student Name" value="studentName" />
                                    <ExcelColumn label="Roll" value="studentRoll" />
                                    <ExcelColumn label="Group" value="groupName" />
                                    <ExcelColumn label="Section" value="classConfigName" />
                                    <ExcelColumn label="Gender" value="studentGender" />
                                    <ExcelColumn label="Religion" value="studentReligion" />
                                    <ExcelColumn label="Mobile No." value="guardianMobile" />
                                </ExcelSheet>
                            </ExcelFile>
                            <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportPdf()} >PDF Download</Button>
                        </Space>
                    </Col>
                </Row>

            </Card>
        </>
    )
}
