import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker, Skeleton } from 'antd';
import { EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectGroupbySection } from '../../../select/SelectGroupbySection';
import { SelectClassConfig2 } from '../../../select/SelectClassConfig2';
import { SelectGroupbySection2 } from '../../../select/SelectGroupbySection2';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectAcademicYear2 } from '../../../select/SelectAcademicYear2';
import { SelectClassConfig3 } from '../../../select/SelectClassConfig3';
import { SelectGroupbySection3 } from '../../../select/SelectGroupbySection3';
import { SelectAcademicYear3 } from '../../../select/SelectAcademicYear3';



export default function StudentMigrationGeneral() {

    const [searchMIgrationStudentForm] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [form4] = Form.useForm();
    const [section, setSection] = useState<any>();
    const [group, setgroup] = useState<any>();
    const [section2, setSection2] = useState<any>();
    const [group2, setgroup2] = useState<any>();
    const [section3, setSection3] = useState<any>();
    const [group3, setgroup3] = useState<any>();
    const [academicyear, setacademicyear] = useState<any>();
    const [academicyear2, setacademicyear2] = useState<any>();
    const fetchlistGroupbyClassConfig = useStoreActions((state) => state.student.fetchlistGroupbyClassConfig);
    const fetchlistGroupbyClassConfig2 = useStoreActions((state) => state.student.fetchlistGroupbyClassConfig2);
    const fetchlistGroupbyClassConfig3 = useStoreActions((state) => state.student.fetchlistGroupbyClassConfig3);
    const fetchStudentListGeneralMigration = useStoreActions((state) => state.student.fetchStudentListGeneralMigration);
    const fetchmigratedStudentList = useStoreActions((state) => state.student.fetchmigratedStudentList);
    const pushbackmigratedStudentList = useStoreActions((state) => state.student.pushbackmigratedStudentList);
    const saveStudentListGeneralMigration = useStoreActions((state) => state.student.saveStudentListGeneralMigration);
    const studentListGeneralMigration = useStoreState((state) => state.student.studentListGeneralMigration);
    const migratedStudentList = useStoreState((state) => state.student.migratedStudentList);
    const [tableRowStore, setTableRowStore] = useState<any>([]);
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [selectedValue, setselectedValue] = useState([]);
    const [selectedRowKeys2, setselectedRowKeys2] = useState([]);
    const [selectedValue2, setselectedValue2] = useState([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onSelectChange2 = (selectedRowKeys2, value2) => {
        setselectedRowKeys2(selectedRowKeys2);
        setselectedValue2(value2);
    };

    const rowSelection2 = {
        selectedRowKeys2,
        onChange: onSelectChange2,
    };
    const [val, setVal] = useState<any>([]);
    const [val2, setVal2] = useState<any>([]);
    const searchMigrationStudentList = (value) => {

        let postdata: any = {
            "classConfigurationId": value.classConfigurationId,
            "groupId": value.groupId.groupId
        }
        setVal(postdata);
        fetchStudentListGeneralMigration(postdata);

    }

    const onsubmit3 = (value) => {
        let postdata = {
            classConfigurationId: value.classconfigid,
            groupId: value.groupId.groupId,
            academicYear: value.academicYear,
        }
        setVal2(postdata);
        fetchmigratedStudentList(postdata);
    }

    useEffect(() => {
        setTableRowStore(studentListGeneralMigration);
    }, [studentListGeneralMigration]);

    const onchangeValue: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData: any = [...tableRowStore];
            newData[index][key] = e.target.value;
            setTableRowStore(newData);
        };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,

        },

        {
            title: 'Current Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'New Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Input placeholder="Roll" style={{ width: 60 }} defaultValue={record.newRoll} name="studentRoll" className="stdName" onChange={onchangeValue("newRoll", record, index)} />
            ),
        },

    ];

    const columns2 = [
        {
            title: 'ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,

        },

        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },

    ];

    const onSubmit2 = (value) => {
        let studentInfos = selectedValue.map((item: any) => {
            return {
                "identificationId": item.identificationId,
                "newRoll": item.newRoll
            }
        })

        let payload = {
            classConfigId: value.classconfigid,
            groupId: value.groupId.groupId,
            migrationAcademicYear: value.migrationAcademicYear,
            studentInfos: studentInfos
        }
        saveStudentListGeneralMigration({
            saveData: payload,
            search: val
        });
        form2.resetFields();
        setgroup2(null);
        setSection2(null);
        setselectedRowKeys([]);
        setselectedValue([]);
        setIsModalVisible(false)
    }

    const onSubmit4 = (value) => {
        let studentInfos = selectedValue2.map((item: any) => item.identificationId)

        let payload = {
            acYearToPushback: value.acYearToPushback,
            currentIndentificationIds: studentInfos
        }
        console.log(payload);
        pushbackmigratedStudentList({
            saveData: payload,
            search: val2
        });
        form4.resetFields();
        setselectedRowKeys2([]);
        setselectedValue2([]);
        setIsModalVisible2(false)
    }
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const loading = useStoreState((state) =>  state.student.loading);

    return (
        <>
            <Card title="Student Migration - General">
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11, }} xl={{ span: 11 }}>
                        <Card title="Migrate Student" className='box-shadow-none'>
                            <Form
                                layout="vertical"
                                id="migrateStudent"
                                onFinish={searchMigrationStudentList}
                                form={searchMIgrationStudentForm}
                            >
                                <Row>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12, }} xl={{ span: 12 }}>
                                        <Form.Item
                                            name="classConfigurationId"
                                            label="Section"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select section" },
                                            ]}
                                        >
                                            <SelectClassConfig onChange={e => { setSection(e); setgroup(null); fetchlistGroupbyClassConfig(e) }} style={{ width: "100%" }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10 }} xl={{ span: 12 }}>
                                        <Form.Item
                                            name="groupId"
                                            label="Group"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select group" },
                                            ]}
                                        >
                                            <SelectGroupbySection selected={group} onChange={(e) => { setgroup(e.groupName) }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                        <Button type="primary" className='mt-0' htmlType="submit" style={{ float: "right" }} icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                            <Row style={{ marginTop: "30px", display: tableRowStore.length > 0 ? 'block' : 'none' }}>
                                <Col span={24}>
                                    <div className="datatable-responsive-demo" style={{ width: "100%", overflow: "auto" }}>
                                        <Table
                                            antTableProps={{
                                                showHeader: true,
                                                rowSelection: rowSelection,
                                                columns,
                                                dataSource: tableRowStore,
                                                filterData: tableRowStore,
                                                pagination: false,
                                                bordered: true,
                                                rowKey: "studentId",
                                            }}
                                            mobileBreakPoint={768}
                                        />
                                    </div>
                                    <Space size={"middle"} style={{ float: "right" }}>

                                        <Button type="primary" className='mt-0' onClick={() => {
                                            if (selectedRowKeys?.length > 0) {
                                                setIsModalVisible(true);
                                            } else {
                                                message.error("Please select atleast one student");
                                            }
                                        }}  >
                                            Process
                                        </Button>

                                    </Space>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Modal
                        title="Migration"
                        visible={isModalVisible}
                        onCancel={() => setIsModalVisible(false)}
                        maskClosable={false}
                        footer={null}
                        cancelText="Close"
                        okText="Update"
                        centered
                        width={'40%'}
                    >
                        <Form
                            layout="vertical"
                            id="migrateStudent"
                            onFinish={onSubmit2}
                            form={form2}
                        >

                            <Form.Item
                                name="migrationAcademicYear"
                                label="Academic Year"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select year" },
                                ]}
                            >
                                <SelectAcademicYear />
                            </Form.Item>

                            <Form.Item
                                name="classconfigid"
                                label="Section"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                            >
                                <SelectClassConfig2 onChange={e => { setSection2(e); setgroup2(null); fetchlistGroupbyClassConfig2(e) }} style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item
                                name="groupId"
                                label="Group"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select group" },
                                ]}
                            >
                                <SelectGroupbySection2 selected={group2} onChange={(e) => { setgroup2(e.groupName) }} />
                            </Form.Item>
                            <div style={{ textAlign: 'right' }}>
                                <Button type="primary" className='mt-0' htmlType="submit"   >
                                    Migrate
                                </Button>
                            </div>

                        </Form>
                    </Modal>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 13 }} lg={{ span: 13, }} xl={{ span: 13 }}>
                        <Card title="Migrated List and Pushback" className='box-shadow-none'>
                            <Form
                                layout="vertical"
                                id="migrateStudent"
                                onFinish={onsubmit3}
                                form={form3}
                            >
                                <Row>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8, }} xl={{ span: 8 }}>
                                        <Form.Item
                                            name="academicYear"
                                            label="Academic Year"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select year" },
                                            ]}
                                        >
                                            <SelectAcademicYear2 />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8, }} xl={{ span: 8 }}>
                                        <Form.Item
                                            name="classconfigid"
                                            label="Section"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select section" },
                                            ]}
                                        >
                                            <SelectClassConfig3 onChange={e => { setSection3(e); setgroup3(null); fetchlistGroupbyClassConfig3(e) }} style={{ width: "100%" }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 10 }} xl={{ span: 8 }}>
                                        <Form.Item
                                            name="groupId"
                                            label="Group"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select group" },
                                            ]}
                                        >
                                            <SelectGroupbySection3 selected={group3} onChange={(e) => { setgroup3(e.groupName) }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                        <Button type="primary" className='mt-0' htmlType="submit" style={{ float: "right" }} icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                            <Row style={{ marginTop: "30px", display: migratedStudentList.length > 0 ? 'block' : 'none' }}>
                                <Col span={24}>
                                    <div className="datatable-responsive-demo" style={{ width: "100%", overflow: "auto" }}>
                                        <Table
                                            antTableProps={{
                                                showHeader: true,
                                                rowSelection: rowSelection2,
                                                columns: columns2,
                                                dataSource: migratedStudentList,
                                                filterData: tableRowStore,
                                                pagination: false,
                                                bordered: true,
                                                rowKey: "studentId",
                                            }}
                                            mobileBreakPoint={768}
                                        />
                                    </div>
                                    <Space size={"middle"} style={{ float: "right" }}>

                                        <Button type="primary" className='mt-0' onClick={() => {
                                            if (selectedRowKeys2?.length > 0) {
                                                setIsModalVisible2(true);
                                            } else {
                                                message.error("Please select atleast one student");
                                            }
                                        }}  >
                                            Process
                                        </Button>

                                    </Space>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Modal
                        title="Push Back"
                        visible={isModalVisible2}
                        onCancel={() => setIsModalVisible2(false)}
                        maskClosable={false}
                        footer={null}
                        cancelText="Close"
                        okText="Update"
                        centered
                        width={'40%'}
                    >
                        <Form
                            layout="vertical"
                            id="migrateStudent"
                            onFinish={onSubmit4}
                            form={form4}
                        >

                            <Form.Item
                                name="acYearToPushback"
                                label="Academic Year"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select year" },
                                ]}
                            >
                                <SelectAcademicYear3 />
                            </Form.Item>


                            <div style={{ textAlign: 'right' }}>
                                <Button type="primary" className='mt-0' htmlType="submit"   >
                                    Push Back
                                </Button>
                            </div>

                        </Form>
                    </Modal>
                </Row>
            </Card>
        </>
    )
}