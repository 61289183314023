import React from 'react'
import { Card, Tabs } from 'antd';
import SectionDueDetailsSummary from './SectionDueDetailsSummary';
import SectionDueDetailsDetails from './SectionDueDetailsDetails';


export default function StaffIdMappingWrapper(props) {

    const { TabPane } = Tabs;

    return (
        <>
            <Card title="Section Due">
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Summary" key="1">
                        <SectionDueDetailsSummary />
                    </TabPane>
                    <TabPane tab="Details" key="2">
                        <SectionDueDetailsDetails />
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )
}