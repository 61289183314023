import { post, get, del} from "../http";

export const fetchfeeHeadList = () => get("/initial-setup/fee-head/list");
export const createFeeHead = (payload) => post("/initial-setup/fee-head/create", payload);
export const updateFeeHead = (payload) => post("/initial-setup/fee-head/update", payload);
export const deleteFeeHead = (payload) => del("/initial-setup/fee-head/delete?id="+payload,);

export const fetchfeesubHeadList = () => get("/initial-setup/fee-sub-head/list");
export const createFeeSubHead = (payload) => post("/initial-setup/fee-sub-head/create", payload);
export const updateFeeSubHead = (payload) => post("/initial-setup/fee-sub-head/update", payload);
export const deleteFeeSubHead = (payload) => del("/initial-setup/fee-sub-head/delete?id="+payload,);

export const fetchwaiverList = () => get("/initial-setup/fee-waiver/list");
export const createWaiver = (payload) => post("/initial-setup/fee-waiver/create", payload);
export const updateWaiver = (payload) => post("/initial-setup/fee-waiver/update", payload);
export const deleteWaiver = (payload) => del("/initial-setup/fee-waiver/delete?id="+payload,);

export const fetchfeeHeadLedgerConfigurationList = () => get("/fee-head/ledger-configuration/list");
export const saveFeeHeadLedgerConfiguration = (payload) => get(`/fee-head/ledger-configuration/save?feeHeadId=${payload.feeHeadId}&ledgerId=${payload.ledgerId}`);
export const deleteFeeHeadLedgerConfiguration = (payload) => del("/fee-head/ledger-configuration/delete?configId="+payload);

export const fetchfeeSubHeadLedgerConfigurationList = () => get("/fee-subhead/configuration/list");
export const savefeeSubHeadLedgerConfiguration = (payload) => post(`/fee-subhead/configuration/save`,payload);
export const deletefeeSubHeadLedgerConfiguration = (payload) => del("/fee-subhead/configuration/delete?configId="+payload);

export const fetchfeeFineLedgerConfigurationList = () => get("/fee-fine/ledger-configuration/list");
export const savefeeFineLedgerConfiguration = (payload) => get(`/fee-fine/ledger-configuration/save?feeHeadId=${payload.feeHeadId}&ledgerId=${payload.ledgerId}`);
export const deletefeeFineLedgerConfiguration = (payload) => del("/fee-fine/ledger-configuration/delete?configId="+payload);


export const fetchfeeSubHeadConfigurationList = (payload) => get("/fee-subhead/configuration/list/by/fee-head-id?feeHeadId="+payload);
export const fetchfeeAmountConfigurationList = (payload) => post("/fee-amount/configuration/list/by/class-group-category-feehead",payload);
export const saveFeeAmountConfiguration = (payload) => post("/fee-amount/configuration/save", payload);
export const updateFeeAmountConfiguration = (payload) => post("/fee-amount/configuration/update", payload);
export const deleteFeeAmountConfigurationUrl = (payload) => del("/fee-amount/configuration/delete?feeConfigId="+payload,);

export const saveFeeSubHeadTimeConfig = (payload) => post("/fee-sub-head/time-configuration/save", payload);
export const saveFeeSubheadTimeConfiguration = (payload) => post(`/fee-sub-head/time-configuration/update/fine-active-date?fineActiveDate=${payload.fineActiveDate}&id=${payload.id}`);

export const fetchstudentWaiverList = (payload) => get(`/fee-waiver/configuration/list?classConfigurationId=${payload.classConfigurationId}&studentCategoryId=${payload.studentCategoryId}&academicYear=${payload.academicYear}`);
export const saveStudentWaiver = (payload) => post("/fee-waiver/configuration/save", payload);
export const updateStudentWaiver = (payload) => post(`/fee-waiver/configuration/update`,payload);
export const deleteStudentWaiver = (payload) => del("/fee-waiver/configuration/delete?feeWaiverConfigId="+(payload),);


export const feeheadDeleteSave = (payload) => get(`/student-accounts/fee-head-discard/save?feeHeadId=${payload.feeHeadId}&identificationIds=${payload.identificationIds}`);
export const fetchfeeheadDeletedList = (payload) => get(`/student-accounts/fee-head-discard/list?classConfigId=${payload.classConfigId}&academicYear=${payload.academicYear}`);
export const deletestudentFeeHeadLedgerConfiguration = (payload) => del("/student-accounts/fee-head-discard/delete?id="+(payload?.id));

export const fetchstudentCollecionView = (payload) => get(`/fee/collection/view?customStudentId=${payload.customStudentId}&academicYear=${payload.academicYear}`);
export const savestudentCollecionView = (payload) => post("/fee/collection/save", payload);
export const createSinglePayslip = (payload) => post("/payslip/create/single", payload);
export const moneyReceiptDownload = (payload) => get("/fee/jasper/money-receipt/download?invoiceIds="+payload);
export const downloadPayslip = (payload) => get("/payslip/download/batch?payslipIds="+payload);

export const fetchofpsconfigurationView = () => get("/ofps/configuration/view");
export const saveofpsconfigurationView = (payload) => post("/ofps/configuration/save", payload);
export const fetchyearWiseFeeHeadTime = (payload) => get(`/fee-sub-head/time-configuration/list?year=${payload.year}&feeHeadId=${payload.feeHeadId}`);
export const deleteyearWiseFeeHeadTime = (payload) => del("/fee-sub-head/time-configuration/delete?id="+payload);


export const fetchsectionPaidReport = (payload) => get(`/student-fee/report/section/paid/list?classConfigId=${payload.classConfigId}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`);
export const fetchclassPaidReport = (payload) => get(`/student-fee/report/class/paid/list?classId=${payload.classId}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`);
export const fetchallPaidReport = (payload) => get(`/student-fee/report/all/paid/list?ledgerIds=${payload.ledgerIds}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`);

export const fetchsectionDueReport = (payload) => get(`/student-fee/report/section/due-details?classConfigId=${payload.classConfigId}&academicYear=${payload.academicYear}`);
export const fetchsectionDueReportMonth = (payload) => get(`/student-fee/report/section/month/due-details?classConfigId=${payload.classConfigId}&academicYear=${payload.academicYear}&month=${payload.month}`);
export const fetchsectionDueReportMonth2 = (payload) => get(`/student-fee/report/section/due-details?classConfigId=${payload.classConfigId}&academicYear=${payload.academicYear}`);
export const fetchmonthlydueReport= (payload) => get(`/student-fee/report/monthly/due-details?month=${payload.month}&academicYear=${payload.academicYear}`);
export const fetchfeeHeadCollectionReport = (payload) => get(`/student-fee/report/date-to-date/fee-head/collection?fromDate=${payload.fromDate}&toDate=${payload.toDate}`);
export const fetchfeeHeadsubHeadollectionReport = (payload) => get(`/student-fee/report/date-to-date/fee-head/sub-head/collection?fromDate=${payload.fromDate}&toDate=${payload.toDate}`);
export const fetchspgStatement = (payload) => get(`/ofps/transaction/report/date-to-date/success/transaction?fromDate=${payload.fromDate}&toDate=${payload.toDate}`);
export const fetchfeeHeadCollectionReportclass = (payload) => get(`/student-fee/report/date-to-date/class/fee-head/collection?classId=${payload.classId}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`);
export const fetchdateToDateClassSingleFeeHeadCollection = (payload) => get(`/student-fee/report/date-to-date/class/single/fee-head/collection?classId=${payload.classId}&feeHeadId=${payload.feeHeadId}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`);
export const downloadInvoice = (payload) => get(`/fee/jasper/money-receipt/download?invoiceIds=${payload}`);

export const fetchfeeInvoiceList = (payload) => get(`/student-fee/report/invoice/view/by/invoice-id?invoiceId=${payload}`);

export const deletefeeInvoiceList = (payload) => del("/fee-invoice/delete?masterId="+payload);
export const fetchstudentListForFeeSubHeadDelete = (payload) => post("/student/fee/sub-head/delete/operation/list", payload);
export const fetchabsentFineRecordStudentListForInput = (payload) => post("/absent/fine/student/list/for/input", payload);
export const saveAbsentFineRecord = (payload) => post("/absent/fine/save", payload);
export const fetchabsentFineRecordStudentList = (payload) => post("/absent/fine/student/list", payload);
export const updateAbsentFineRecord = (payload) => post("/absent/fine/update", payload);
export const deleteAbsentFineRecord = (payload) => del("/absent/fine/delete?recordId="+payload);
export const studentFeeSubHeadDelete = (payload) => post("/student/fee/sub-head/delete", payload);
export const fetchstudentDeletedFeeSubHeadList = (payload) => post("/student/fee/sub-head/deleted/list", payload);
export const showsectionTotalPaidDetails = (payload) => post("/student-fee/report/acdemic-year/section/total-paid/details", payload);
export const fetchshowMultipleSectionDateToDateTotalPaidDetails = (payload) => post("/student-fee/report/academic-year/multiple/section/date-to-date/total-paid/details", payload);
export const reAssignStudentFeeSubHead = (payload) => post("/student/fee/sub-head/re-assign?detailsIds="+payload);
export const fetchshowStudentFeeHeadPaymentView = (payload) => get(`/student-fee/report/acdemic-year/class/total-paid/details?academicYear=${payload.academicYear}&classId=${payload.classId}`);
export const showsectionTotalPaidDetailsDateToDate = (payload) => get(`/student-fee/report/academic-year/date-to-date/total-paid/details?fromDate=${payload.fromDate}&toDate=${payload.toDate}`);