import { Button, Card, Col, Form, Row, Select } from 'antd';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectClassConfig } from '../../../select/SelectClassConfig';

import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SearchOutlined, SendOutlined } from '@ant-design/icons';
import { useState } from 'react';


export default function ExamResultSms() {
    const { Option } = Select;
    const [examReportSectionWiseResultForm] = Form.useForm();

    const fetchExamListByclassConfigIdSectionWiseExam = useStoreActions((state) => state.exam.fetchExamListByclassConfigIdSectionWiseExam);
    const examListByclassConfigIdSectionWiseExam = useStoreState((state) => state.exam.examListByclassConfigIdSectionWiseExam);

    const fetchSectionResultByAcademicYearClassConfigIdExamConfigId2 = useStoreActions((state) => state.exam.fetchSectionResultByAcademicYearClassConfigIdExamConfigId2);
    const sectionResultByAcademicYearClassConfigIdExamConfigId2 = useStoreState((state) => state.exam.sectionResultByAcademicYearClassConfigIdExamConfigId2);
    const instituteInfo = useStoreState(state => state.auth.instituteInfo)
    const sendSms = useStoreActions((state) => state.sms.sendSectionDueSmsReportList);

    const unassignedSubjectMarkListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: "customStudentId", showOnResponse: true, showOnDesktop: true },
        { title: 'Sudent Name', dataIndex: 'studentName', key: "studentName", showOnResponse: true, showOnDesktop: true },
        { title: 'Student Roll', dataIndex: 'studentRoll', key: "studentRoll", showOnResponse: true, showOnDesktop: true },
        { title: 'Total Marks', dataIndex: 'totalMarks', key: "totalMarks", showOnResponse: true, showOnDesktop: true },
        { title: 'Section Position', dataIndex: 'sectionPosition', key: "sectionPosition", showOnResponse: true, showOnDesktop: true },
        { title: 'Grade', dataIndex: 'gradingPoint', key: "gradingPoint", showOnResponse: true, showOnDesktop: true },
        { title: 'CGPA', dataIndex: 'letterGrade', key: "letterGrade", showOnResponse: true, showOnDesktop: true },
        { title: 'Status', dataIndex: 'resultStatus', key: "resultStatus", showOnResponse: true, showOnDesktop: true },
        { title: 'Total Failed Subjects', dataIndex: 'numberOfFailedSubjects', key: "numberOfFailedSubjects", showOnResponse: true, showOnDesktop: true },
    ];


    const onChangeClassList = (e) => {
        fetchExamListByclassConfigIdSectionWiseExam(e);
    }

    const examReportSearch = (value) => {
        let postData: any = {
            examConfigId: value.selectExam,
            classConfigId: value.sectionSection,
            academicYear: value.sectionYear
        }
        fetchSectionResultByAcademicYearClassConfigIdExamConfigId2(postData);
    }

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const sendSmsButton = () => {
        if (selectedRowKeys === 0) {
            alert("Please select at least one student")
            return
        };
        let postData: any = selectedValue.map(item => {
            return {
                identificationId: item.identificationId,
                message: `Exam Name: ${item.examName}, Name: ${item.studentName}, ID: ${item.customStudentId}, Total Marks: ${item.totalMarks}, GPA: ${item.gradingPoint}. Please Visit and login in school. From: ${instituteInfo?.instituteName}`
            }
        })
        sendSms(postData);
        setselectedRowKeys([]);
        setselectedValue([]);
    }

    return (
        <>
            <Card title="Result Wise SMS">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={examReportSearch}
                            form={examReportSectionWiseResultForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="sectionYear"
                                        label="Select Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="sectionSection"
                                        label="Select Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig onChange={(e) => onChangeClassList(e)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="selectExam"
                                        label="Select Exam"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select exam" },
                                        ]}
                                    >
                                        <Select placeholder="Exam List" style={{ width: "100%" }}>
                                            {examListByclassConfigIdSectionWiseExam ? (
                                                examListByclassConfigIdSectionWiseExam.map((type, idx) => (
                                                    <Option key={type.examConfigId} value={type.examConfigId}>
                                                        {type.examName}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching exam</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row style={{ display: sectionResultByAcademicYearClassConfigIdExamConfigId2.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: unassignedSubjectMarkListColumn,
                                rowKey: "identificationId",
                                dataSource: sectionResultByAcademicYearClassConfigIdExamConfigId2,
                                filterData: sectionResultByAcademicYearClassConfigIdExamConfigId2,
                                pagination: false,
                                bordered: true,
                                rowSelection: rowSelection
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                        <Button type="primary" htmlType="submit" icon={<SendOutlined />} onClick={() => sendSmsButton()} className="float-right">Send SMS</Button>
                    </Col>
                </Row>
            </Card>
        </>
    )

}