import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, TimePicker, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import moment from 'moment';
import { SelectClassConfigMultiple } from '../../../select/SelectClassConfigMultiple';
import { SelectPeriod } from '../../../select/SelectPeriod';

export default function PeriodTimeConfiguration() {

    const [form] = Form.useForm();
    const { Option } = Select;
    const format = 'HH:mm';
    const fetchStudentAttendanceAllPeriod = useStoreActions((state) => state.student.fetchStudentAttendanceAllPeriod);
    const periodConfigurationSave = useStoreActions((state) => state.student.periodConfigurationSave);
    const fetchPeriodConfigurationList = useStoreActions((state) => state.student.fetchPeriodConfigurationList);
    const periodConfigurationList = useStoreState((state) =>  state.student.periodConfigurationList);
    const periodConfigurationUpdate = useStoreActions((state) => state.student.periodConfigurationUpdate);
    const deletePeriodConfiguration = useStoreActions((state) => state.student.deletePeriodConfiguration);
    const [configurationList, setConfigurationList] = useState<any>();
    const [startTime, setStartTime] = useState<any>();
    const [endTime, setEndTime] = useState<any>();
    const loading = useStoreState((state) =>  state.student.loading);
    useEffect(() => {
        fetchStudentAttendanceAllPeriod();
        fetchPeriodConfigurationList();
    }, [])

    useEffect(() => {
        setConfigurationList(periodConfigurationList);
    },[periodConfigurationList])

    const updateStartTimeMethod = (record) => {
        let postData:any = {
            endTime: record.periodEndTime,
            periodConfigId: record.periodConfigId,
            startTime: record.periodStartTime
        }
        periodConfigurationUpdate(postData);
    }

    const deleteStartTimeMethod = (value) => {
        deletePeriodConfiguration(value)
    }

    // const startTimeChange = (value, record) => {
    //     configurationList.map(item => {
    //         if(item.periodConfigId == record.periodConfigId){
    //             var val = moment(value).format('HH:mm');
    //             item.periodStartTime = val
    //         }
    //     });
    //     setConfigurationList(configurationList);
    // }

    // const endTimeChange = (value, record) => {
    //     configurationList.map(item => {
    //         if(item.periodConfigId == record.periodConfigId){
    //             var val = moment(value).format('HH:mm');
    //             item.periodEndTime = val
    //         }
    //     });
    //     setConfigurationList(configurationList);
    // }

    const onchangeValue: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...configurationList];
            newData[index][key] = e.target.value;
            setConfigurationList(newData);
    };
    
    const columns = [
        {
            title: 'Period',
            dataIndex: 'periodName',
            key: 'periodName',
            showOnResponse: true,
            showOnDesktop: true,
        },{
            title: 'Section',
            dataIndex: 'classConfigName',
            key: 'classConfigName',
            showOnResponse: true,
            showOnDesktop: true,
        },{
            title: 'Start Time',
            dataIndex: 'periodStartTime',
            key: 'periodStartTime',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <>
                    <div>
                        {record.periodStartTime}
                    </div>
                    <div className='ant-picker' style={{ width: "100%" }}>
                        <div className="ant-picker-input">
                            <input type="time" className="form-control" onChange={onchangeValue("periodStartTime", record, index)} value={record.periodStartTime} style={{ width: "100%" }} />
                        </div>
                    </div>
                </>
            )
        },{
            title: 'End Time',
            dataIndex: 'periodEndTime',
            key: 'periodEndTime',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <>
                    <div>
                        {record.periodEndTime}
                    </div>
                    <div className='ant-picker' style={{ width: "100%" }}>
                        <div className="ant-picker-input">
                            <input type="time" className="form-control"  onChange={onchangeValue("periodEndTime", record, index)} style={{ width: "100%" }} />
                        </div>
                    </div>
                </>
            )
        },{
            title: 'Update',
            key: 'periodConfigId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Popconfirm
                    title="Are you sure to update this?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => updateStartTimeMethod(record)}
                >
                    <Tooltip title="Delete">
                        <Button type='primary' icon={<EditOutlined />} />
                    </Tooltip>
                </Popconfirm>
            ),
        },{
            title: 'Delete',
            key: 'periodConfigId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Popconfirm
                    title="Are you sure to delete this?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deleteStartTimeMethod(record?.periodConfigId)}
                >
                    <Tooltip title="Delete">
                        <Button danger icon={<DeleteOutlined />} />
                    </Tooltip>
                </Popconfirm>
            ),
        },


    ];

    const onchangeStartValue: any = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartTime(e.target.value)
    };

    const onchangeEndValue: any = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndTime(e.target.value)
    };

    

    const periodConfigurSave = (value) => {
        let postdata:any = {
            classConfigIds: value.selectSection,
            endTime: endTime,
            periodId: value.period,
            startTime: startTime
        }
        periodConfigurationSave(postdata);
    }

    return <>
        <Card title="Period Time Configuration">
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24}} xl={{ span: 24}}>
                    <Form
                        layout="vertical"
                        id="classConfigInfo"
                        form={form}
                        onFinish={periodConfigurSave}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="period"
                                    label="Period"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select period" },
                                    ]}
                                >
                                    <SelectPeriod />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="periodStartTime"
                                    label="Start Time"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please period start time" },
                                    ]}
                                >
                                <div className='ant-picker' style={{ width: "100%" }}>
                                    <div className="ant-picker-input">
                                        <input type="time" id='startTime' className="form-control" onChange={(e) => onchangeStartValue(e)} style={{ width: "100%" }} />
                                    </div>
                                </div>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="periodEndTime"
                                    label="End Time"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please period end time" },
                                    ]}
                                >
                                    <div className='ant-picker' style={{ width: "100%" }}>
                                        <div className="ant-picker-input">
                                            <input type="time" id='endTime' onChange={(e) => onchangeEndValue(e)} className="form-control" style={{ width: "100%" }} />
                                        </div>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="selectSection"
                                    label="Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectClassConfigMultiple style={{ width: '100%' }}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24}} xl={{ span: 24}}>
                                <div style={{ float:"right" }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" className='mt-0' icon={<SaveOutlined />} >
                                            Save
                                        </Button>
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Skeleton loading={loading} paragraph={{rows:10}} />
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24}} xl={{ span: 24}} className='mt-30'>
                    <Table
                            antTableProps={{
                                showHeader: true,
                                columns: columns,
                                dataSource: configurationList,
                                filterData: configurationList,
                                pagination: false,
                                bordered: true,
                                rowKey: "masterId",
                            }}
                            mobileBreakPoint={768}

                        />
                </Col>
            </Row>
        </Card>
    </>
}