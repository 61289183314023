import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, notification, Popconfirm, Row, Skeleton, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectFeeHead } from '../../../select/SelectFeeHead';



export default function ListFeeSubDiscard(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const studentDeletedFeeSubHeadList = useStoreState((state) => state.saccount.studentDeletedFeeSubHeadList);
    const fetchstudentDeletedFeeSubHeadList = useStoreActions((state) => state.saccount.fetchstudentDeletedFeeSubHeadList);
    const reAssignStudentFeeSubHead = useStoreActions((state) => state.saccount.reAssignStudentFeeSubHead);

    const loading = useStoreState((state) => state.saccount.loading);


    const [sub, setsub] = useState<any>()
    const formSubmit = (value) => {
        setsub(value)
        fetchstudentDeletedFeeSubHeadList(value)
    }


    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    const [assignId, setassignId] = useState<any>();

    const columns = [

        {
            title: 'Student Id',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Fee Head',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Fee SubHead',
            dataIndex: 'feeSubHeadName',
            key: 'feeSubHeadName',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Action',
            key: 'studentId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">

                    <Popconfirm
                        title="Are you sure?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => {
                            reAssignStudentFeeSubHead(record.detailsId);
                            setTimeout(() => {
                                fetchstudentDeletedFeeSubHeadList(sub)
                            }, 500);
                        }}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    const onProcess = () => {
        if (selectedValue.length === 0) {
            notification.error({ message: "Please select student" });
            return
        }
        let detailsId = selectedValue.map(item => item.detailsId).join(',');
        reAssignStudentFeeSubHead(detailsId);
        setTimeout(() => {
            fetchstudentDeletedFeeSubHeadList(sub)
        }, 1000)
    }


    return (
        <>

            <Form
                layout="vertical"
                onFinish={formSubmit}
                id="basic-info"
                form={form}
            >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="academicYear"
                            label="Academic Year"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select year " },
                            ]}
                        >
                            <SelectAcademicYear />

                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="classConfigId"
                            label="Section"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select section" },
                            ]}
                        >
                            <SelectClassConfig />

                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        <Form.Item
                            name="feeHeadId"
                            label="Fee Head"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select fee" },
                            ]}
                        >
                            <SelectFeeHead />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                        <Space size="small" >
                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                Search
                            </Button>
                        </Space>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                </Row>
            </Form>

            {studentDeletedFeeSubHeadList === null && < Skeleton loading={loading} paragraph={{ rows: 10 }} />}
            {studentDeletedFeeSubHeadList?.length > 0 &&
                <div className="datatable-responsive">
                    {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: studentDeletedFeeSubHeadList,
                            filterData: studentDeletedFeeSubHeadList,
                            pagination: false,
                            bordered: true,
                            rowKey: "detailsId",
                            rowSelection: rowSelection,
                        }}
                        mobileBreakPoint={768}
                    />
                    <Col span={24}>
                        <Space size="small" style={{ float: "right", marginTop: 15 }}>

                            <Button type="primary" icon={<SettingOutlined />} onClick={() => onProcess()} >Re-Assign</Button>
                        </Space>
                    </Col>
                </div>
            }


        </>
    )
}
