import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Pagination } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import TableView from '../../../contents/AntTablePagination';
import {debounce} from 'lodash';

const { Search } = Input;
export default function OFPSInstiuteList() {

    const fetchofpsinstiuteList = useStoreActions((state) => state.customerSupport.fetchofpsinstiuteList);
    const fetchofpsinstiuteList2 = useStoreActions((state) => state.customerSupport.fetchofpsinstiuteList2);
    const ofpsinstiuteList = useStoreState((state) => state.customerSupport.ofpsinstiuteList);
    const [numberOfpage, setnumberOfpage] = useState<number>(1);

    useEffect(() => {
        fetchofpsinstiuteList({
            numberOfpage: numberOfpage,
            pageSize: 10,
        });
    }, []);


    const columns = [
        { title: 'Institute ID', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true },
        { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true },
        { title: 'Type', dataIndex: 'instituteType', key: "instituteType", showOnResponse: true, showOnDesktop: true },
        { title: 'Address', dataIndex: 'instituteAddress', key: "instituteAddress", showOnResponse: true, showOnDesktop: true },
        { title: 'Live Date', dataIndex: 'liveDate', key: "liveDate", showOnResponse: true, showOnDesktop: true },
        { title: 'Status', dataIndex: 'instituteStatus', key: "instituteStatus", showOnResponse: true, showOnDesktop: true },
    ];

    const onChangePage = (page: number) => {
        console.log(page);
        setnumberOfpage(page);
        fetchofpsinstiuteList({
            numberOfpage: page,
            pageSize: 10,
        });
    };

    const onSearch = (value) => {
        setsearchv(value);
        if (value === '') {
            setnumberOfpage(1)
            fetchofpsinstiuteList({
                numberOfpage: 1,
                pageSize: 10,
            });
        } else {
            fetchofpsinstiuteList2({
                instituteName: value,
                numberOfpage: 1,
            });
        }
    }
    const debouncedOnchange = debounce(onSearch, 500);



    const [searchv, setsearchv] = React.useState<any>('');

    return (
        <>
            <Card title="OFPS Institute List">
                <Row className="m-t-mo-30">
                    <Col span={24}>

                        <div className="datatable-responsive-demo">
                            <div className="globalSearch" style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center", background: '#b8d7cd' }}>
                                <span style={{ marginLeft: 10 }}>Total Found: {ofpsinstiuteList?.numberOfItems}</span>
                                <Input
                                    style={{ margin: "10px 10px 10px 0", width: 250 }}
                                    placeholder="Institute Name"
                                    onChange={(e) => debouncedOnchange(e.target.value)}
                                    // enterButton="Search"
                                    size="large"
                                    // onSearch={onSearch}
                                />
                            </div>
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: ofpsinstiuteList?.instituteList,
                                    pagination: false,
                                    bordered: true,
                                    rowKey: "instituteId",
                                }}
                                mobileBreakPoint={768}
                            />
                            <br />
                            <div style={{ float: "right" }}>
                                <Pagination defaultCurrent={1} current={numberOfpage} disabled={searchv === '' ? false : true} onChange={onChangePage} total={ofpsinstiuteList?.numberOfItems} showSizeChanger={false} />
                            </div>
                        </div>

                    </Col>
                </Row>
            </Card>
        </>
    )
}