import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag} from 'antd';
import TableView from '../../../../contents/AntTableResponsive';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from "uuid";
import { loadavg } from 'os';


export default function ExamShortCode(props) {

    const [examShortCodeForm] = Form.useForm();
    const { Option } = Select;
    const fetchShortCodeList = useStoreActions((state) => state.exam.fetchShortCodeList);
    const fetchShortCodeClassList = useStoreActions((state) => state.exam.fetchShortCodeClassList);
    const updateShortCodeClasslist = useStoreActions((state) => state.exam.updateShortCodeClasslist)
    const shortCodeTablelist = useStoreState((state) => state.exam.shortCodeTablelist);
    const shortCodeClasslist = useStoreState((state) => state.exam.shortCodeClasslist);
    const deleteExamShortCode = useStoreActions((state) => state.exam.deleteExamShortCode);
    const [shortCodeSelections, setShortCodeSelections] = useState([]);
    const [selectedShortCodeValue, setselectedShortCodeValue] = useState<any>([]);
    const [shortCodeListTableData, setShortCodeListTableData] = useState([]);

    const [shortCodeListSelections, setShortCodeListSelections] = useState([]);
    const [classIdSelect, setClassIdSelect] = useState("");
    const [selectedShortCodeListValue, setselectedShortCodeListValue] = useState<any>([]);

    const createShortCode = useStoreActions((state) => state.exam.createShortCode);
    const loading = useStoreState((state) =>  state.exam.loading);

    const staticRablecolumns = [
      { title: 'Title', dataIndex: 'shortCodeTitle', key: 'shortCodeTitle', showOnResponse: true, showOnDesktop: true },
      { title: 'Total Marks', dataIndex: 'totalMark', key: 'totalMark', showOnResponse: true, showOnDesktop: true  },
      { title: 'Pass-mark', dataIndex: 'passMark', key: 'passMark', showOnResponse: true, showOnDesktop: true  },
      { title: 'Acceptance', dataIndex: 'acceptance', key: 'acceptance', showOnResponse: true, showOnDesktop: true  },
      // { title: 'Short Code', dataIndex: 'shortCodeNote', key: 'shortCodeNote', showOnResponse: true, showOnDesktop: true  },
      
    ];

    const staticTableData = [
      { key: "1", defaultId: '1', shortCodeTitle: 'SC-1', totalMark: 100, passMark: 0, acceptance: "1", shortCodeNote: "Short Code-1"},
      { key: "2", defaultId: '2', shortCodeTitle: 'SC-2', totalMark: 100, passMark: 0, acceptance: "1", shortCodeNote: "Short Code-2"},
      { key: "3", defaultId: '3', shortCodeTitle: 'SC-3', totalMark: 100, passMark: 0, acceptance: "1", shortCodeNote: "Short Code-3"},
      { key: "4", defaultId: '4', shortCodeTitle: 'SC-4', totalMark: 100, passMark: 0, acceptance: "1", shortCodeNote: "Short Code-4"},
      { key: "5", defaultId: '5', shortCodeTitle: 'SC-5', totalMark: 100, passMark: 0, acceptance: "1", shortCodeNote: "Short Code-5"},
      { key: "6", defaultId: '6', shortCodeTitle: 'SC-6', totalMark: 100, passMark: 0, acceptance: "1", shortCodeNote: "Short Code-6"},
    ];

    const deleteSubject = (value) => {
      deleteExamShortCode(value);

    }

    const shortCodeList = [
      { title: 'Class', dataIndex: 'className', key: 'className', showOnResponse: true, showOnDesktop: true  },
      { title: 'Default ID', dataIndex: 'defaultId', key: 'defaultId', showOnResponse: true, showOnDesktop: true  },
      { title: 'Short Code Title', dataIndex: 'shortCodeTitle', key: 'shortCodeTitle', showOnResponse: true, showOnDesktop: true,
        render: (text, record, index) => (
          <Input defaultValue={record.shortCodeTitle} name="shortCodeTitle" className="ShortCodeTitle" onChange={(e) => shortCodeTitleChange(text, record, e.target.value, e.target.name, index)}/>
        ),
      },
      { title: 'Total Mark', dataIndex: 'totalMark', key: 'totalMark', showOnResponse: true, showOnDesktop: true},
      { title: 'Pass Mark', dataIndex: 'passMark', key: 'passMark', showOnResponse: true, showOnDesktop: true},
      { title: 'Acceptance', dataIndex: 'acceptance', key: 'acceptance', showOnResponse: true, showOnDesktop: true},
      { title: 'Action', dataIndex: 'shortCodeId', key: 'shortCodeId', showOnResponse: true, showOnDesktop: true,
        render: (text, record, index) => (
          <>
            <Popconfirm
                title="Are you sure to delete this?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => deleteSubject(record?.shortCodeId)}
            >
                <Tooltip title="Delete">
                    <Button danger  icon={<DeleteOutlined />} />
                </Tooltip>
            </Popconfirm>
          </>
        ),
      }
    ];

    useEffect(() => {
      fetchShortCodeList();
      fetchShortCodeClassList();
      setShortCodeListTableData(shortCodeListTableData);      
    },[]);

    const onSelectChangeShortCode = (selectedRowKeys, value) => {
        setShortCodeSelections(selectedRowKeys);
        setselectedShortCodeValue(value);
    };

    const onSelectChangeShortCodeList = (selectedRowKeys, value) => {
      setShortCodeListSelections(selectedRowKeys);
      setselectedShortCodeListValue(value);
    };

    const shortCodeRowSelection = {
        shortCodeSelections,
        onChange: onSelectChangeShortCode,
    };

    const shortCodeListRowSelection = {
      shortCodeListSelections,
      onChange: onSelectChangeShortCodeList,
    };

    const saveShortCode = () => {
      var postData = {
        classId: classIdSelect,
        shortCodeDetails: selectedShortCodeValue
      };
      if(selectedShortCodeValue.length > 0){
        createShortCode(postData);
      }else{
        notification.error({ message: 'Select mark list' })
      }
    }

    const oncahngeClassId = (val) => {
      setClassIdSelect(val);
    }

    const shortCodeTitleChange = (text, record, val, fieldName, index) => {
      shortCodeTablelist.map((item:any) => {
          if(item.shortCodeId == record.shortCodeId){
              item[fieldName] = val
          }
      });      
    }

    const updateShortCode = () => {

      if(selectedShortCodeListValue.length > 0) {
        let details:any = [];
        selectedShortCodeListValue.map((value) => {
          let postData = {
            shortCodeId: value.shortCodeId,
            shortCodeTitle: value.shortCodeTitle
          }
          details.push(postData);
        });
        updateShortCodeClasslist(details);
      }else{
        notification.error({message:"Select row first"});
      }
      
    }

    return(
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8}}>
                  <Card className="p-0 box-shadow-none" title="Take Short Code" bordered={false}>
                    <Form
                        layout="vertical"
                        id="examInfo"
                        onFinish={saveShortCode}
                        form={examShortCodeForm}
                    >
                      <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}}>
                          <Form.Item
                              name="className"
                              label="Class Name"
                              className="title-Text"
                              rules={[
                                  { required: true, message: "Please select class" },
                              ]}
                          >
                            <Select placeholder="Select class" style={{ width: "100%" }} onChange={(val) => oncahngeClassId(val)}>
                              {shortCodeClasslist ? (
                                    shortCodeClasslist.map((type, idx) => (
                                    <Option key={type.id} value={type.id}>
                                        {type.name}
                                    </Option>
                                    ))
                                ) : (
                                    <Option value="fetching">Fetching Class</Option>
                                )}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <TableView
                          antTableProps={{
                              showHeader: true,
                              rowSelection:shortCodeRowSelection,
                              columns:staticRablecolumns,
                              rowKey:"key",
                              dataSource: staticTableData,
                              filterData: staticTableData,
                              pagination: false,
                              bordered: true
                          }}
                          mobileBreakPoint={768}
                      />
                      <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="float-right">Save</Button>
                    </Form>
                  </Card>
                </Col>
                < Skeleton loading={loading} paragraph={{rows:10}} />
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 16}}>
                  <Card className="p-0 box-shadow-none" title="Short Code List" bordered={false}>
                    <TableView
                        antTableProps={{
                            showHeader: true,
                            rowSelection:shortCodeListRowSelection,
                            columns:shortCodeList,
                            rowKey:"shortCodeId",
                            dataSource: shortCodeTablelist,
                            filterData: shortCodeTablelist,
                            pagination: false,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="float-right" onClick={()=> updateShortCode()}>Update</Button>
                  </Card>
                </Col>
            </Row>
        </>
    )
}