
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Skeleton, Table } from 'antd'
import { DeleteOutlined, DownloadOutlined, EditOutlined, FileExcelOutlined, SaveOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import TableView from '../../../../contents/AntTableResponsive';
// import { Table } from "ant-table-extensions-extended";
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectFeeHeadAttendance } from '../../../select/SelectFeeHeadAttendance';
import { SelectFeeSubHeadByFeeHeadSingle } from '../../../select/SelectFeeSubHeadByFeeHeadSingle';


export default function AttendanceFineAddList() {

    const [form2] = Form.useForm();
    const fetchfeeSubHeadConfigurationList = useStoreActions((state) => state.saccount.fetchfeeSubHeadConfigurationList);
    const absentFineRecordStudentList = useStoreState((state) => state.saccount.absentFineRecordStudentList);
    const fetchabsentFineRecordStudentList = useStoreActions((state) => state.saccount.fetchabsentFineRecordStudentList);
    const updateAbsentFineRecord = useStoreActions((state) => state.saccount.updateAbsentFineRecord);
    const deleteAbsentFineRecord = useStoreActions((state) => state.saccount.deleteAbsentFineRecord);
    const loading = useStoreState((state) => state.student.loading);

    const [data, setData] = useState<any>('');
    const [recordId, setrecordId] = useState<any>(null);

    const formSubmit = (value) => {
        setData(value);
        fetchabsentFineRecordStudentList(value);
    }




    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Fee Head', dataIndex: 'feeHeadName', key: 'feeHeadName', showOnResponse: true, showOnDesktop: true },
        { title: 'Fee Sub Head', dataIndex: 'feeSubHeadName', key: 'feeSubHeadName', showOnResponse: true, showOnDesktop: true },
        { title: 'Amount', dataIndex: 'amount', key: 'amount', showOnResponse: true, showOnDesktop: true },
        {
            title: 'Action', dataIndex: 'gradeRange', key: 'serial', showOnResponse: true, showOnDesktop: true,
            render: (text, record, index) => (
                <Space size="middle">
                    <Button type="primary" onClick={(e) => {
                        setrecordId(record.recordId);
                        setIsModalVisible(true);
                        form.setFieldsValue({
                            amount: record.amount,
                        });
                    }} icon={<EditOutlined />} />
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteAbsentFineRecord({ data: data, payload: record.recordId })}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </Space>
            ),
        },

    ];



    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onProcess = () => {
        if (selectedValue.length === 0) {
            notification.error({ message: "Please select student" });
            return
        }
        let studentList = selectedValue.map(item => {
            return {
                amount: item.amount,
                identificationId: item.identificationId,
            }
        });

        let payload = {
            "feeHeadId": data.feeHeadId,
            "feeSubHeadId": data.feeSubHeadId,
            "studentList": studentList
        };

        setselectedRowKeys([]);
        setselectedValue([])

        updateAbsentFineRecord(payload)
        // setIsModalVisible(true);
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const submitData = (value) => {
        updateAbsentFineRecord({data:data, payload:{amount:value.amount, recordId:recordId}});
        setIsModalVisible(false);
        form.resetFields()
    }

    return (
        <>

            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 2 }} xl={{ span: 20, offset: 2 }}>
                    <Form
                        layout="vertical"
                        id="classConfigInfo"
                        onFinish={formSubmit}
                        form={form2}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="academicYear"
                                    label="Academic Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select year" },
                                    ]}
                                >
                                    <SelectAcademicYear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="classConfigId"
                                    label="Select Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectClassConfig />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="feeHeadId"
                                    label="Fee Head"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select fee" },
                                    ]}
                                >
                                    <SelectFeeHeadAttendance onChange={e => { fetchfeeSubHeadConfigurationList(e); form2.setFieldsValue({ feeSubHeadId: null }) }} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="feeSubHeadId"
                                    label="Fee Sub Head"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select fee" },
                                    ]}
                                >
                                    <SelectFeeSubHeadByFeeHeadSingle />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                    </Form>
                </Col>
            </Row>
            {< Skeleton loading={loading} paragraph={{ rows: 10 }} />}
            {absentFineRecordStudentList?.length > 0 &&
                <Row >
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: studentListColumn,
                                rowKey: "identificationId",
                                dataSource: absentFineRecordStudentList,
                                filterData: absentFineRecordStudentList,
                                pagination: false,
                                bordered: true,
                            }}
                            mobileBreakPoint={768}
                        />
                        {/* <Table
                        columns={studentListColumn}
                        rowSelection={rowSelection}
                        dataSource={tableData}
                        rowKey={'identificationId'}
                    /> */}
                        {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                    </Col>
{/*                     <Col span={24}>
                        <Space size="small" style={{ float: "right", marginTop: 15 }}>

                            <Button type="primary" icon={<SaveOutlined />} onClick={() => onProcess()} >Save</Button>
                        </Space>
                    </Col> */}
                </Row>
            }
            <Modal
                title="Update"
                visible={isModalVisible}
                // onOk={handleOk}
                // okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => { setIsModalVisible(false); setrecordId(null) }}
                footer={null}
                centered
                width={"20%"}
            >
                <Form
                    layout="vertical"
                    onFinish={submitData}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24}>
                            <Form.Item
                                name="amount"
                                label="Amount"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input amount" },
                                ]}
                            >

                                <Input placeholder='Amount' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                            Update
                        </Button>
                    </div>
                </Form>

            </Modal>

        </>
    )
}
