import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Column from 'rc-table/lib/sugar/Column';
import { SwitcherOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';

export default function InstiuteList() {

    const fetchInstiuteList = useStoreActions((state)=> state.customerSupport.fetchInstiuteList);
    const instiuteList = useStoreState((state) => state.customerSupport.instiuteList);
    const submitDeactiveInstitute = useStoreActions((state) =>  state.customerSupport.submitDeactiveInstitute);
    const submitActiveInstitute = useStoreActions((state) => state.customerSupport.submitActiveInstitute);
    useEffect(() => {
        fetchInstiuteList();
    }, []);

    const deActiveInstitute = (id) => {
        submitDeactiveInstitute(id);
    }

    const activeInstitute = (id) => {
        submitActiveInstitute(id);
    }

    const columns = [
        { title: 'Institute ID', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true  },
        { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true  },
        { title: 'Type', dataIndex: 'instituteType', key: "instituteType", showOnResponse: true, showOnDesktop: true  },
        { title: 'Address', dataIndex: 'address', key: "address", showOnResponse: true, showOnDesktop: true  },
        { title: 'Status', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record:any, index) =>
        (
            <>
                <span>{record.status == true ? 'Enable' : 'Disable'}</span>
            </>
        )
        },
        { title: 'Action', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record:any, index) =>
            <>
                {record?.status == true ?
                <Space size="middle">
                    <Popconfirm
                        title="Are you sure to deactive this institute?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deActiveInstitute(record?.instituteId)}
                    >
                        <Tooltip title="Deactive">
                            <Button danger icon={<SwitcherOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </Space>
                : 
                <Space size="middle">
                    <Popconfirm
                        title="Are you sure to active this institute?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => activeInstitute(record?.instituteId)}
                    >
                        <Tooltip title="Active">
                            <Button type="primary" icon={<SwitcherOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </Space>
                }
            </>
        },
    ];

    return(
        <>
           <Card title="Institute List">
           <Row className="m-t-mo-30">
                <Col span={24}>
                    <div className="datatable-responsive-demo">
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: instiuteList,
                                filterData: instiuteList,
                                pagination: true,
                                bordered: true,
                                rowKey: "instituteId",
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                </Col>
            </Row>
           </Card>
        </>
    )
}