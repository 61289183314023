import { post, get, del, put, delBulk} from "../http";

export const examCreate = (payload) => post("/initial-setup/exam/create", payload);
export const fetchExam = () => get("/initial-setup/exam/list"); 
export const examUpdate = (payload) => post("/initial-setup/exam/update", payload);
export const examDeleteUrl = (payload) => del("/initial-setup/exam/delete?id="+payload);

////

export const subjectCreate = (payload) => post("/initial-setup/subject/create", payload);
export const fetchSubject = () => get("/initial-setup/subject/list"); 
export const subjectUpdate = (payload) => post("/initial-setup/subject/update", payload);
export const subjectDeleteUrl = (payload) => del("/initial-setup/subject/delete?id="+payload);

////

export const shortCodeCreate = (payload) => post("/exam/shortcode/create", payload);
export const fetchShortCode = () => get("/exam/shortcode/list"); 
export const fetchShortCodeClassListUrl = () => get("/initial-setup/class/list"); 
export const shortCodeUpdate = (payload) => post("/exam/shortcode/update", payload);
export const examShortCodeDeleteUrl = (payload) => post("/exam/shortcode/delete?shortCodeId="+payload);

////
export const fetchGrade = () => get("/exam/grade/list"); 
export const createExamGradeUrl = (payload) => post("/exam/grade/create", payload); 
export const updateExamGradeUrl = (payload) => put("/exam/grade/range/update?gradeId=" + payload.gradeId + "&gradeRange=" + payload.gradeRange); 

///Exam Subject
export const fetchGroupListUrl = (payload) => get("/group/configuration/list/by/class-id?classId="+payload); 
export const fetchSubjectListByGroupUrl = (payload) => get('/subject/configuration/list/by/class-id/group-id?classId='+payload.classId+'&groupId='+payload.groupId); 
export const subjectConfigurationCreate = (payload) => post("/subject/configuration/create", payload); 
export const subjectConfigurationUpdate = (payload) => post("/subject/configuration/update", payload); 
export const subjectConfigurationDelete = (payload) => del('/subject/configuration/delete?subjectConfigurationId='+payload); 

///Exam Configuration
export const fetchExamConfigurationListUrl = (payload) => get("/exam/configuration/list"); 
export const fetchExamConfiguration = (payload) => post("/exam/configuration/create", payload); 
export const examConfigurationUpdate = (payload) => post("/exam/configuration/update", payload);

//Mark Configuration
export const fetchGroupListByStudentClassUrl = (payload) => get('/group/configuration/list/by/class-id?classId='+payload); 
export const fetchExamListByStudentClassUrl = (payload) => get('/exam/configuration/list/by/class-id?classId='+payload); 
export const fetchSubjectListByGroupIdNadClassIdListUrl = (payload) => get('/subject/configuration/list/by/class-id/group-id?classId='+payload.classId+'&groupId='+payload.groupId);
export const fetchMarkConfigListUrl = (payload) => get('/mark/configuration/list/by/exam-config-id/group-id?examConfigurationId='+payload.examConfigurationId+'&groupId='+payload.groupId);
export const fetchExamByClassListUrl = (payload) => get('/exam/configuration/list/without/grandfinal/by/class-id?classId='+payload);  
export const fetchExamByClassList = (payload) => get('/exam/configuration/list/by/class-id?classId='+payload);  
export const fetchExamShortcodeByClassListUrl = (payload) => get('/exam/shortcode/list/by/class-id?classId='+payload);
export const markConfigurationSave = (payload) => post("/mark/configuration/save", payload);

//Grand Final Mark Configuration
export const grandFinalMarkConfig = (payload) => post("/exam/configuration/update/for/grand-final", payload);

//Forth Subject
export const examForthSubjectConfigurationList = (payload) => get('/student/registration/list/by/class-configuration-id/group-id?classConfigurationId='+payload.classConfigurationId+'&groupId='+payload.groupId); 
export const examForthSubjectChooseableList = (payload) => get('/subject/configuration/choosable/list/by/class-configuration-id/group-id?classConfigurationId='+payload.classConfigurationId+'&groupId='+payload.groupId); 
export const fetchelectiveSubjectConfigurationListByClassConfigurationIdAndGroupId = (payload) => get('/subject/configuration/elective/list/by/class-configuration-id/group-id?classConfigurationId='+payload.classConfigurationId+'&groupId='+payload.groupId); 
export const assignForthSubject = (payload) => post("/forth-subject/assign", payload);
export const fetchAssignForthSubjectList = (payload) => get('/forth-subject/assigned/list?classConfigurationId='+payload);
export const forthSubjectDelete = (payload) => delBulk("/forth-subject/delete", payload);

// admin card instruction
export const examListForAdmitCardInstructionUrl = (payload) => get("/exam/configuration/list/by/class-id?classId="+payload);
export const examListForAdmitCardInstructionSaveUrl = (payload) => post("/admit/card/instruction/create", payload);

//Marksheet

export const fetchMarkSheetTemplateUrl = (payload) => get("/marksheet/template/list"); 
export const fetchMarkSheetTemplateSaveUrl = (payload) => post("/marksheet/template/save", payload); 
export const remarksSaveUrl = (payload) => post("/remarks/template/save", payload);
export const remarksListUrl = (payload) => get("/remarks/template/list");
export const remarksLisUpdatetUrl = (payload) => post("/remarks/template/update", payload);
export const remarksLisDeleteUrl = (payload) => del("/remarks/template/delete?id="+payload);

//Admit Card and seat plan

export const fetchSemesterListByClassConfigIdUrl = (payload) => get("/exam/configuration/list/by/class-configuration-id?classConfigurationId="+payload); 
export const fetchAdmitCardDownloadUrl = (payload) => get("/jasper/admit/card/download?startRoll=" + payload.startRoll + "&endRoll=" + payload.endRoll + "&examConfigId=" + payload.examConfigId + "&classConfigId=" + payload.classConfigId); 
export const fetchSeatPLanDownloadUrl = (payload) => get("/jasper/seat/plan/download?startRoll=" + payload.startRoll + "&endRoll=" + payload.endRoll + "&examConfigId=" + payload.examConfigId + "&classConfigId=" + payload.classConfigId); 

//Mark Input
export const fetchMarkInputStudentList = (payload) => post("/exam/mark/input/student/list", payload);
export const fetchSubjectListByGroupID = (payload) => get('/subject/configuration/list/by/class-configuration-id/group-id?classConfigurationId='+payload.classConfigId+'&groupId='+payload.groupId); 
export const fetchMarkConfigListbySubjectIdUrl = (payload) => get('/mark/configuration/list/by/exam-config-id/subject-id?examConfigurationId='+payload.examConfigId+'&subjectId='+payload.subjectId); 
export const fetchExamMarkInputStudentListClassConfigExamConfigIdGroupIdSubjectId =(payload) => post('/exam/mark/input/student/list', payload);
export const examMarkinputDataSaveUrl = (payload) => post('/exam/mark/input', payload);

//Mark Update
export const fetchExamMarkInputStudentListClassConfigExamConfigIdGroupIdSubjectIdUpdateUrl = (payload) => post("/exam/mark/update/student/list", payload);
export const markBlankSheeturl = (payload) => get("/jasper/exam/mark/input/blank/sheet/download?classConfigId=" + payload.classConfigId + "&examconfigId=" + payload.examConfigId);
export const downloadtabulationExcel = (payload) => get("/jasper/general/tabulationsheet/download?classConfigId=" + payload.classConfigId + "&examConfigId=" + payload.examConfigId+ "&typeNo=" + payload.typeNo);

//Mark Delete
export const examMarkinputDataDeleteUrl = (payload) => post('/exam/mark/delete', payload);

//exam_result_process_general
export const processExamResultGenerateUrl = (payload) => post("/result/process/generate?classConfigId="+payload.section+"&examconfigId="+payload.examConfigId);

//exam_result_grand_final
export const processExamResultGrandFinalUrl = (payload) => post("/merit/position/generate?examconfigId="+payload);

//merit position generate
export const processExamMaritPositionUrl = (payload) => post("/merit/position/generate?examconfigId="+payload);

//remark template list
export const remarkTemplateList = (payload) => get("/remarks/template/list");
export const fetchSemesterListByClassConfigIdRemarks = (payload) => get("/exam/configuration/list/by/class-configuration-id?classConfigurationId="+payload); 
export const fetchStudentListByExamConfigIdAndClassConfigIdUrl = (payload) => get('/exam/report/section/result/for/remarks?examConfigId='+payload.examConfigId+'&classConfigId='+payload.classConfigId); 
export const saveRemarksUrl = (payload) => post("/remarks/save", payload);

//tabulation sheet
export const tabulationSheetUrl = (payload) => get("/jasper/general/tabulationsheet/download?examConfigId="+payload.examConfigId+"&classConfigId="+payload.classConfigId+"&typeNo="+payload.typeNo);
export const fetchtabulationData = (payload) => get("/jasper/general/tabulationsheet/response?examConfigId="+payload.examConfigId+"&classConfigId="+payload.classConfigId);

//general mark sheet download
export const generalMarkSheetDownloadUrl = (payload) => get("/jasper/general/marksheet/download?startRoll="+payload.startRoll+"&endRoll="+payload.endRoll+"&examConfigId="+payload.examConfigId+"&classConfigId="+payload.classConfigId+"&academicYear="+payload.academicYear);

//grand final mark sheet download
export const grandFinalMarkSheetDownloadUrl = (payload) => get("/jasper/grand/final/marksheet/download?startRoll="+payload.startRoll+"&endRoll="+payload.endRoll+"&classConfigId="+payload.classConfigId);

//Section Wise Unassigned Subject Mark
export const sectionWiseUnassignedMark = (payload) => get("/exam/report/section/wise/un-inputted/subjects?examId="+payload);
export const fetchSemesterListByClassConfigIdSectionWiseResult = (payload) => get("/exam/configuration/list/by/class-configuration-id?classConfigurationId="+payload); 
export const fetchSubjectMarkListBySectionWiseResult = (payload) => get("/exam/report/section/result?examConfigId="+payload.examConfigId+"&classConfigId="+payload.classConfigId+"&academicYear="+payload.academicYear);
export const fetchsectionMerit = (payload) => get("/exam/report/section/merit/position?examConfigId="+payload.examConfigId+"&classConfigId="+payload.classConfigId+"&academicYear="+payload.academicYear);
export const fetchClassWiseMeritByAcademicYearClassConfigIdExamConfigId = (payload) => get("/exam/report/class/merit/position?"+payload.examConfigId+"&academicYear="+payload.academicYear);

//Failed Exam
export const classWiseFailedExamUrl = (payload) => get("/exam/report/class/fail/list?examConfigId="+payload.selectExam+"&academicYear="+payload.sectionYear);
export const updateMarkByClassAndExamConfig = (payload) => post("/exam/mark/update/by/class/examconfig?examConfigId="+payload.selectExam+"&academicYear="+payload.sectionYear+"&classId="+payload.selectClass);
export const classWiseMeritExamUrl = (payload) => get("/exam/report/class/merit/position?examConfigId="+payload.selectExam+"&academicYear="+payload.sectionYear);

//sectionWiseFailedExamUrl
export const sectionWiseFailedExamUrl = (payload) => get("/exam/report/section/fail/list?examConfigId="+payload.examConfigId+"&classConfigId="+payload.classConfigId+"&academicYear="+payload.academicYear);

//Section Wise Grading Summary
export const sectionWiseGradingSummaryUrl = (payload) => get("/exam/report/section/wise/grading/summary?examId="+payload.examId+"&academicYear="+payload.academicYear);

//Section Wise Pass Fail Summary
export const sectionWisePassFailSummaryUrl = (payload) => get("/exam/report/section/wise/pass-fail/summary?examId="+payload.examId+"&academicYear="+payload.academicYear);

export const fetchresultPublishDateList = (payload) => get("/result/publish/date/list?academicYear="+payload.academicYear+"&classId="+payload.classId);
export const saveResultPublishDate = (payload) => post("/result/publish/date/save", payload);
export const updateResultPublishDate = (payload) => post("/result/publish/date/update", payload);
export const deleteResultPublishDate = (payload) => del("/result/publish/date/delete?resultPublishDateId="+payload,);

//Grand Final Process
export const fetchClassConfigurationListByclassIdForGrandFinalProcessUrl = (payload) => get(`/exam/configuration/list/grandfinal/by/class-id?classId=${payload}`);
export const processGrandFinalExamConfigIdUrl = (payload) => post(`/result/process/grand-final?examconfigId=${payload}`);
