import React from 'react'
import { Card, Tabs } from 'antd';
import EnbaledStuffMapping from './EnbaledStuffMapping';


export default function StaffIdMappingWrapper(props) {

    const { TabPane } = Tabs;

    return (
        <>
            <Card title="Settings">
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Enabled Staff" key="1">
                        <EnbaledStuffMapping />
                    </TabPane>
                    <TabPane tab="Disabled Staff" key="2">

                    </TabPane>

                </Tabs>
            </Card>
        </>
    )
}