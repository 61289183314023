import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Column from 'rc-table/lib/sugar/Column';
import { SaveFilled, SwitcherOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import { SelectDistrict2 } from '../../select/SelectDistrict2';
import { SelectThana } from '../../select/SelectThana';
import { SelectDistrict } from '../../select/SelectDistrict';
import { SelectThana2 } from '../../select/SelectThana2';

export default function PartnerInformationAdd() {
    const [form] = Form.useForm();
    const thanaListFetch2= useStoreActions((state) => state.common.thanaListFetch2);
    const savePartnerinformation = useStoreActions((state) => state.customerSupport.savePartnerinformation)
    const fetchThanaByDistricId = (e) => {
        thanaListFetch2(e)
    }

    const formSubmit = (val) => {
        let postData:any = {
            addressDetail: val.address,
            mobile: val.mobileNumber,
            partnerId: 0,
            partnerName: val.partnerName,
            partnerStatus: 0,
            thanaId: val.thana
        }    
        savePartnerinformation(postData) ;
        form.resetFields();  
    }

    return(
        <>
            <Form
                layout="vertical"
                onFinish={formSubmit}
                id="basic-info"
                form={form}
            >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}> </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="partnerName"
                            label="Partner Name"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please write partner name" },
                            ]}
                        >
                            <Input placeholder="Enter partner name" />
                        </Form.Item>
                        <Form.Item
                            name="mobileNumber"
                            label="Mobile Number "
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please insert mobile number" },
                            ]}
                        >
                            <Input placeholder="Mobile Number" />
                        </Form.Item>
                        <Form.Item
                            name="distric"
                            label="District"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Select distric " },
                            ]}
                        >
                            <SelectDistrict2 onChange={(e) => fetchThanaByDistricId(e)} />
                        </Form.Item>
                        <Form.Item
                            name="thana"
                            label="Thana"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Select thana" },
                            ]}
                        >
                            <SelectThana2 />

                        </Form.Item>
                        <Form.Item
                            name="address"
                            label="Address"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Write Address" },
                            ]}
                        >
                            <Input placeholder="Write address" />

                        </Form.Item>
                        <Space size="small" style={{ float: 'right' }}>
                            <Button type="primary" htmlType="submit" style={{ height: 40,  marginTop: 0 }} icon={<SaveFilled />}>
                                Save
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </>
    )
}