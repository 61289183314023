import React, { useCallback, useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SaveOutlined, SearchOutlined } from '@ant-design/icons';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { FilePdfOutlined } from '@ant-design/icons';
import { useTable, usePagination, useFilters } from "react-table";
import $ from 'jquery';
require('jspdf-autotable');


const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData // This is a custom function that we supplied to our table instance
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    const onChange = (e) => {
        setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return <input className="ant-input" style={{ border: "1px solid #03D665" }} value={value} onChange={onChange} onBlur={onBlur} />;
};

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            className="ant-input"
            style={{ border: "1px solid #03D665" }}
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
        />
    )
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Filter: DefaultColumnFilter,
    Cell: EditableCell
};

// Be sure to pass our updateMyData and the skipPageReset option
function Table({ columns, data, updateMyData, skipPageReset }) {
    // For this example, we're using pagination to illustrate how to stop
    // the current page from resetting when our data changes
    // Otherwise, nothing is different here.
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageSize: 50 },
            autoResetFilters: false,
            // use the skipPageReset option to disable page resetting temporarily
            autoResetPage: !skipPageReset,
            // updateMyData isn't part of the API, but
            // anything we put into these options will
            // automatically be available on the instance.
            // That way we can call this function from our
            // cell renderer!
            updateMyData
        },
        useFilters, // useFilters!
        usePagination
    );

    // Render the UI for your table
    return (
        <>
            <div className='ant-table-content' style={{ width: "100%" }}>
                <table {...getTableProps()} className='shebaTable' style={{ width: "100%" }}>
                    <thead className='ant-table-thead'>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th className='ant-table-cell' {...column.getHeaderProps()}>{column.render("Header")}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>

                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody className='ant-table-tbody' {...getTableBodyProps()}>
                        {page.length > 0 && page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr className={`ant-table-row ant-table-row-level-${i}`} {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td className='ant-table-cell' {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <br />
                <div className="pagination" style={{ float: 'right' }}>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} style={{ height: 40 }}>
                        {"<<"}
                    </button>{" "}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage} style={{ height: 40 }}>
                        {"<"}
                    </button>{" "}
                    <button onClick={() => nextPage()} disabled={!canNextPage} style={{ height: 40 }}>
                        {">"}
                    </button>{" "}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} style={{ height: 40 }}>
                        {">>"}
                    </button>{" "}
                    <span>
                        Page{" "}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{" "}
                    </span>
                    <span>
                        | Go to page:{" "}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(page);
                            }}
                            className="ant-input"
                            style={{ width: "100px", height: 40, border: "1px solid #03D665" }}
                        />
                    </span>{" "}
                    <select
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                        style={{ height: 40, border: "1px solid #03D665" }}
                    >
                        {[10, 20, 30, 40, 50, 100, 200, 500, 1000].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    );
}



export default function MarkUpdateForm() {

    const { Option } = Select;
    const [markInputUpdateForm] = Form.useForm();
    const fetchSemesterListByClassConfigId = useStoreActions((state) => state.exam.fetchSemesterListByClassConfigIdMarkUpdate);
    const semesterListByClassConfigIdArray = useStoreState((state) => state.exam.semesterListByClassConfigIdArrayMarkUpdate);
    const fetchlistGroupbyClassConfig = useStoreActions((state) => state.exam.fetchlistGroupbyClassConfigMarkUpdate);
    const listGroupbyClassConfigMarkUpdate = useStoreState((state) => state.exam.listGroupbyClassConfigMarkUpdate);
    const fetchSubjectByGroupConfigId = useStoreActions((state) => state.exam.fetchSubjectByGroupConfigIdMarkUpdate);
    const subjectByGroupConfigIdArray = useStoreState((state) => state.exam.subjectByGroupConfigIdArrayMarkUpdate);
    const fetchExamMarkCofigureListByExamConfigId = useStoreActions((state) => state.exam.fetchExamMarkCofigureListByExamConfigIdMarkUpdate);
    const examMarkCofigureListByExamConfigId = useStoreState((state) => state.exam.examMarkCofigureListByExamConfigIdMarkUpdate);
    const fetchExamMarkInputStudentList = useStoreActions((state) => state.exam.fetchExamMarkInputStudentListMarkUpdate);
    const examMarkInputStudentList = useStoreState<any>((state) => state.exam.examMarkInputStudentListMarkUpdate);
    const examMarkInputSave = useStoreActions((state) => state.exam.examMarkInputSave);
    const [classConfigId, setClassConfigId] = useState<any>("");
    const [examConfigId, setExamConfigId] = useState<any>("");
    const [markInputTableDatavisibility, setmarkInputTableDatavisibility] = useState<any>();
    const [examMarkConfigList, setExamMarkConfigList] = useState<any>();
    const [markInputTableData, setMarkInputTableData] = useState<any>([]);
    const [shortCode1Name, setShortCode1Name] = useState<any>();
    const [shortCode2Name, setShortCode2Name] = useState<any>();
    const [shortCode3Name, setShortCode3Name] = useState<any>();
    const [shortCode4Name, setShortCode4Name] = useState<any>();
    const [shortCode5Name, setShortCode5Name] = useState<any>();
    const [shortCode6Name, setShortCode6Name] = useState<any>();
    const [markInputTableHeaderVisibility, setmarkInputTableHeaderVisibility] = useState<any>();
    const loading = useStoreState((state) => state.exam.loading);

    const [markSaveObject, setMarkSaveObject] = useState<any>();



    useEffect(() => {
        if (examMarkInputStudentList?.studentList !== undefined) {
            setMarkInputTableData(examMarkInputStudentList?.studentList);
        };
        setmarkInputTableHeaderVisibility(examMarkInputStudentList.shortCodeVisibilty);
    }, [examMarkInputStudentList]);

    const onChangeClassList = (e) => {
        fetchSemesterListByClassConfigId(e);
        fetchlistGroupbyClassConfig(e);
        setClassConfigId(e)
        setMarkInputTableData([]);
        setExamMarkConfigList([]);
    }

    const searchSubjectByGroupId = (e) => {

        let payload: any = {
            classConfigId: classConfigId,
            groupId: e
        }
        fetchSubjectByGroupConfigId(payload);
    }

    const configTableColumn = [
        { title: 'Short Code Title', dataIndex: 'shortCodeName', key: 'shortCodeName', showOnResponse: true, showOnDesktop: true },
        { title: 'Total', dataIndex: 'totalMark', key: 'totalMark', showOnResponse: true, showOnDesktop: true },
        { title: 'Pass Mark', dataIndex: 'passMark', key: 'passMark', showOnResponse: true, showOnDesktop: true },
        { title: 'Acceptance', dataIndex: 'acceptance', key: 'acceptance', showOnResponse: true, showOnDesktop: true },
    ]

    const getMarkConfigListBySubject = (e) => {
        let payload: any = {
            examConfigId: examConfigId,
            subjectId: e
        }
        fetchExamMarkCofigureListByExamConfigId(payload);
    }

    useEffect(() => {
        setExamMarkConfigList(examMarkCofigureListByExamConfigId == null ? null : examMarkCofigureListByExamConfigId);
    }, [examMarkCofigureListByExamConfigId])

    const saveMarkInputValue = (e) => {
        let postdata = {
            examConfigurationId: markSaveObject.examConfigId,
            groupId: markSaveObject.groupId,
            studentMarkInfos: markInputTableData,
            subjectId: markSaveObject.subjectId,
            type: "update",
        }
        examMarkInputSave(postdata);
        setMarkInputTableData([]);
    }

    const searchMarkInputSearch = (value) => {
        let payload: any = {
            classConfigId: value.sectionList,
            examConfigId: value.semesterYear,
            subjectId: value.selectSubject,
        }
        fetchExamMarkInputStudentList(payload);
        let saveObject = {
            classConfigId: value.sectionList,
            examConfigId: value.semesterYear,
            groupId: value.classGroup,
            subjectId: value.selectSubject,
            type: "update",
        }
        setMarkSaveObject(saveObject);

    }



    useEffect(() => {
        setShortCode1Name(markInputTableHeaderVisibility?.shortCode1Name);
        setShortCode2Name(markInputTableHeaderVisibility?.shortCode2Name);
        setShortCode3Name(markInputTableHeaderVisibility?.shortCode3Name);
        setShortCode4Name(markInputTableHeaderVisibility?.shortCode4Name);
        setShortCode5Name(markInputTableHeaderVisibility?.shortCode5Name);
        setShortCode6Name(markInputTableHeaderVisibility?.shortCode6Name);
    }, [markInputTableHeaderVisibility])

    var getColumns = function () {
        return [
            { title: "Student Name", dataKey: "studentName" },
            { title: "Student Id", dataKey: "customStudentId" },
            { title: "Roll", dataKey: "studentRoll" },
            ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode1Name !== '') ? [{ title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode1Name, dataKey: "shortCode1" }] : [],
            ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode2Name !== '') ? [{ title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode2Name, dataKey: "shortCode2" }] : [],
            ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode3Name !== '') ? [{ title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode3Name, dataKey: "shortCode3" }] : [],
            ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode4Name !== '') ? [{ title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode4Name, dataKey: "shortCode4" }] : [],
            ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode5Name !== '') ? [{ title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode5Name, dataKey: "shortCode5" }] : [],
            ...(examMarkInputStudentList?.shortCodeVisibilty?.shortCode6Name !== '') ? [{ title: examMarkInputStudentList?.shortCodeVisibilty?.shortCode6Name, dataKey: "shortCode6" }] : [],
        ];
    };

    function exportPdf() {

        var doc = new jsPDF("p", "mm", "a4");

        var details = `Mark Data`;
        pdfDataL(doc, "");
        doc.text(details, 105, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };
        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
            { title: "", dataKey: "b3" },
            { title: "", dataKey: "b4" },
            // { title: "", dataKey: "b5" },
            // { title: "", dataKey: "b6" },
            { title: "", dataKey: "b7" },
            { title: "", dataKey: "b8" },
        ], [
            {
                b1: "Section:",
                b2: `${$(".sectionSelect").text()}`,
                b3: "Exam",
                b4: `${$(".examsectionSelect").text()}`,
                // b5: "Group",
                // b6: `${$(".examGroup").text()}`,
                b7: "Subject",
                b8: `${$(".examSub").text()}`,
            }
        ], {
            startY: 45,
            showHeader: "never",
            theme: 'grid',
            styles: { lineColor: 224, },
            columnStyles: {
                b1: {
                    fontStyle: 'bold'
                },
                b3: {
                    fontStyle: 'bold'
                },
                b5: {
                    fontStyle: 'bold'
                },          
                b7: {
                    fontStyle: 'bold'
                },

            },

            // addPageContent: pageContent,
        });
        let first = doc.autoTable.previous;
        doc.autoTable(getColumns(), markInputTableData, {
            startY: doc.autoTable.previous.finalY + 5,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 75
                },
                debit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                },
                credit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                }
            },


        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    const columns = React.useMemo(
        () => [
            {
                Header: "Student Name",
                accessor: "studentName",
                Cell: function Cell(cell) {
                    return <span>{cell.value}</span>;
                }
            },
            {
                Header: "Student Id",
                accessor: "customStudentId",
                Cell: function Cell(cell) {
                    return <span>{cell.value}</span>;
                }
            },
            {
                Header: "Roll",
                accessor: "studentRoll",
                Cell: function Cell(cell) {
                    return <span>{cell.value}</span>;
                }
            },
            {
                Header: "Total Marks",
                accessor: "totalScore",
                disableFilters: true,
                Cell: function Cell(cell) {
                    return <span>{cell.value}</span>;
                }
            },
            {
                Header: "Grade",
                accessor: "letterGrade",
                disableFilters: true,
                Cell: function Cell(cell) {
                    return <span>{cell.value}</span>;
                }
            },
            ...(examMarkInputStudentList !== undefined && examMarkInputStudentList?.shortCodeVisibilty !== undefined && examMarkInputStudentList?.shortCodeVisibilty?.shortCode1Name !== '') ? [{
                Header: examMarkInputStudentList?.shortCodeVisibilty?.shortCode1Name,
                disableFilters: true,
                accessor: "shortCode1",
            }] : [],
            ...(examMarkInputStudentList !== undefined && examMarkInputStudentList?.shortCodeVisibilty !== undefined && examMarkInputStudentList?.shortCodeVisibilty?.shortCode2Name !== '') ? [{
                Header: examMarkInputStudentList?.shortCodeVisibilty?.shortCode2Name,
                disableFilters: true,
                accessor: "shortCode2",
            }] : [],
            ...(examMarkInputStudentList !== undefined && examMarkInputStudentList?.shortCodeVisibilty !== undefined && examMarkInputStudentList?.shortCodeVisibilty?.shortCode3Name !== '') ? [{
                Header: examMarkInputStudentList?.shortCodeVisibilty?.shortCode3Name,
                disableFilters: true,
                accessor: "shortCode3",
            }] : [],
            ...(examMarkInputStudentList !== undefined && examMarkInputStudentList?.shortCodeVisibilty !== undefined && examMarkInputStudentList?.shortCodeVisibilty?.shortCode4Name !== '') ? [{
                Header: examMarkInputStudentList?.shortCodeVisibilty?.shortCode4Name,
                disableFilters: true,
                accessor: "shortCode4",
            }] : [],
            ...(examMarkInputStudentList !== undefined && examMarkInputStudentList?.shortCodeVisibilty !== undefined && examMarkInputStudentList?.shortCodeVisibilty?.shortCode5Name !== '') ? [{
                Header: examMarkInputStudentList?.shortCodeVisibilty?.shortCode5Name,
                disableFilters: true,
                accessor: "shortCode5",
            }] : [],
            ...(examMarkInputStudentList !== undefined && examMarkInputStudentList?.shortCodeVisibilty !== undefined && examMarkInputStudentList?.shortCodeVisibilty?.shortCode6Name !== '') ? [{
                Header: examMarkInputStudentList?.shortCodeVisibilty?.shortCode6Name,
                disableFilters: true,
                accessor: "shortCode6",
            }] : [],
        ],
        [examMarkInputStudentList]
    );


    const [skipPageReset, setSkipPageReset] = React.useState(false);

    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        setMarkInputTableData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value
                    };
                }
                return row;
            })
        );
    };

    // After data chagnes, we turn the flag back off
    // so that if data actually changes when we're not
    // editing it, the page is reset
    // React.useEffect(() => {
    //     setSkipPageReset(false);
    // }, [markInputTableData]);

    return (
        <>
            <Card title="Mark Update">
                <Form
                    layout="vertical"
                    id="sessionInfo"
                    onFinish={searchMarkInputSearch}
                    form={markInputUpdateForm}
                >
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item
                                name="sectionList"
                                label="Section List"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                            >
                                <SelectClassConfig onChange={(e) => { markInputUpdateForm.setFieldsValue({ semesterYear: null, selectSubject: null, classGroup: null }); onChangeClassList(e) }} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item
                                name="semesterYear"
                                label="Exam List"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select semester" },
                                ]}
                            >
                                <Select placeholder="Semester list" style={{ width: "100%" }} className="examsectionSelect" onChange={(e) => setExamConfigId(e)}>
                                    {semesterListByClassConfigIdArray ? (
                                        semesterListByClassConfigIdArray.map((type, idx) => (
                                            <Option key={type.examConfigId} value={type.examConfigId}>
                                                {type.examName}
                                            </Option>
                                        ))
                                    ) : (
                                        <Option value="fetching">Fetching semester</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item
                                name="classGroup"
                                className="title-Text"
                                label="Group"
                                rules={[
                                    { required: true, message: "Select group list" },
                                ]}
                            >
                                <Select className='examGroup' placeholder="Semester list" style={{ width: "100%" }} onChange={(e) => searchSubjectByGroupId(e)}>
                                    {listGroupbyClassConfigMarkUpdate ? (
                                        listGroupbyClassConfigMarkUpdate.map((type, idx) => (
                                            <Option key={type.groupId} value={type.groupId}>
                                                {type.groupName}
                                            </Option>
                                        ))
                                    ) : (
                                        <Option value="fetching">Fetching Group</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item
                                name="selectSubject"
                                label="Subject List"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select subject list" },
                                ]}
                            >
                                <Select className='examSub' placeholder="Semester list" style={{ width: "100%" }} onChange={(e) => getMarkConfigListBySubject(e)}>
                                    {subjectByGroupConfigIdArray ? (
                                        subjectByGroupConfigIdArray.map((type, idx) => (
                                            <Option key={type.subjectId} value={type.subjectId}>
                                                {type.subjectName}
                                            </Option>
                                        ))
                                    ) : (
                                        <Option value="fetching">Fetching Subject</Option>
                                    )}
                                </Select>
                            </Form.Item>
                            <div className="pull-right">
                                <Button type="primary" className=" mt-0" htmlType="submit" icon={<SearchOutlined />} >
                                    Search
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
                <br />

                <Row style={{ display: examMarkConfigList == null || examMarkConfigList == "" ? 'none' : 'block' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: configTableColumn,
                                rowKey: "defaultId",
                                dataSource: examMarkConfigList,
                                filterData: examMarkConfigList,
                                pagination: false,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                    </Col>
                </Row>
                <br />
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                {markInputTableData?.length > 0 &&
                    <Row style={{ display: examMarkConfigList !== null && markInputTableData?.length > 0 ? 'block' : 'none' }}>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                            <span style={{ fontWeight: "bold", fontSize: 16 }}>Student List:</span>
                            <Table
                                columns={columns}
                                data={markInputTableData}
                                updateMyData={updateMyData}
                                skipPageReset={skipPageReset}
                            />

                        </Col>
                        <br />
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                            <div className="pull-right">
                                <Button type="primary" onClick={exportPdf} style={{ marginRight: "15px" }} icon={<FilePdfOutlined />}  >Download PDF</Button>
                                <Button type="primary" onClick={(e) => saveMarkInputValue(e)} icon={<SaveOutlined />} >Update</Button>
                            </div>
                        </Col>
                    </Row>
                }
            </Card>
        </>
    )
}