import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectSection } from '../../../select/SelectSection';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';

export default function IdCardTemplateDownload (){


    const fetchIdFrontCardPdf = useStoreActions((state) => state.student.fetchIdFrontCardPdf);
    const fetchIdBackCardPdf = useStoreActions((state) => state.student.fetchIdBackCardPdf);
    const [selectSectionConfigId, setSelectSectionConfigId] = useState("");
    const [fromRoll, setFormRoll] = useState<any>();
    const [toRoll, setToRoll] = useState<any>();
   

    const downloadIdCardFront = () => {
            let postData:any = {
            startRoll: fromRoll,
            endRoll: toRoll,
            classConfigId: selectSectionConfigId
        }
        fetchIdFrontCardPdf(postData);
    }

    const downloadIdCardBack = () => {
        let postData:any = {
            startRoll: fromRoll,
            endRoll: toRoll,
            classConfigId: selectSectionConfigId
        }
        fetchIdBackCardPdf(postData);
    }
    
    return(
        <>
            <Card title="Student ID Card Download">
            <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            // onFinish={formSubmit}
                            // form={studentSearchForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="sectionId"
                                        label="Section List"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]} 
                                    >
                                        <SelectClassConfig onChange={(e) => setSelectSectionConfigId(e)}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="fromRoll"
                                        label="From Roll"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Write from roll" },
                                        ]} 
                                    >
                                        <InputNumber placeholder='From roll' onChange={(e) => setFormRoll(e)}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="toRoll"
                                        label="To Roll"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Write to roll" },
                                        ]} 
                                    >
                                        <InputNumber placeholder='To roll' onChange={(e) => setToRoll(e)}/>
                                    </Form.Item>
                                </Col>
                                
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                
                                    <Space size="small" >
                                        <Button type="primary" icon={<DownloadOutlined />} onClick={() => downloadIdCardFront()}>Download front side</Button>
                                        <Button type="primary" icon={<DownloadOutlined />} onClick={() => downloadIdCardBack()} >Download back side</Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
            </Card>
        </>
    )
}