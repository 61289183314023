import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';

import PartnerInformationAdd from './PartnerInformationAdd';
import PartnerInformationList from './PartnerInformationList';

export default function PartnerInformation() {
    const { TabPane } = Tabs;
    const [activeTab, setActiveTab] = React.useState<any>("1");

    return(
        <>
           <Card title="Partner Information">
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Partner Add" key="1">
                        {activeTab === "1" && <PartnerInformationAdd />}
                    </TabPane>
                    <TabPane tab="Partner List" key="2">
                        {activeTab === "1" && <PartnerInformationList />}
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )
}