import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import Table from "../../../../contents/AntTableResponsive";
import { Button, Card, Col, Divider, Form, Input, Popconfirm, Row, Select, Skeleton, Space, Tooltip } from 'antd'
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect } from 'react'

import { useState } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectLedgerCategory } from '../../../select/SelectLedgerCategory'
import { Typography } from 'antd';
const { Option } = Select;



export default function LedgerList(props) {

    const { Title } = Typography;
    const check = useStoreState((state) => state.ledger.check);
    const ledgerList = useStoreState((state) => state.ledger.ledgerList);
    const fetchledgerList = useStoreActions((state) => state.ledger.fetchledgerList);
    const createLedger = useStoreActions((state) => state.ledger.createLedger);
    const updateLedger = useStoreActions((state) => state.ledger.updateLedger);
    const deleteLedger = useStoreActions((state) => state.ledger.deleteLedger);
    const loading = useStoreState((state) =>  state.ledger.loading);

    useEffect(() => {
        fetchledgerList();
    }, [check])
    const [ledgerForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    ///modal
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedCat, setselectedCat] = useState<any>();
    const [ledgerId, setledgerId] = useState<any>();

    const oncahngeSelectedCategorId = (val) => {
        setselectedCat(val);
    };

    const [categoryId, setcategoryId] = useState<any>();
    const oncahngeCategorId = (val) => {
        setcategoryId(val);
    };

    const createLedgerForm = (value) => {
        let postData = {
            categoryId: value.categoryid,
            ledgerName: value.ledgername,
            ledgerNote: value.note === undefined ? "" : value.note,
        }
        createLedger(postData);
        setcategoryId(null);
        ledgerForm.resetFields();
    };
    const updateLedgerForm = (value) => {
        let postData = {
            accountCategoryId: value.categoryid,
            ledgerId: ledgerId,
            ledgerName: value.ledgername,
            note: value.note,
        }

        updateLedger(postData);
        setIsModalVisible(false);
        updateForm.resetFields();
    };

    const columns = [

        {
            title: 'Ledger Name',
            dataIndex: 'ledgerName',
            key: 'ledgerName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Category',
            dataIndex: 'accountCategoryName',
            key: 'accountCategoryName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Debit/Credit',
            dataIndex: 'nature',
            key: 'nature',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Action',
            key: 'ledgerId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setselectedCat(record.accountCategoryId);
                            setledgerId(record.ledgerId);
                            updateForm.setFieldsValue({
                                categoryId: record.accountCategoryId,
                                ledgername: record.ledgerName,
                                note: record.note,
                            });
                        }}
                        />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteLedger(record?.ledgerId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        },
    ];


    return (
        <>
            <Card title="Create Ledger" >

                <Form
                    layout="vertical"

                    onFinish={createLedgerForm}
                    id="basic-info"
                    form={ledgerForm}
                >

                    <Row>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="categoryid"
                                label="Category:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select category" },
                                ]}
                            >
                                <SelectLedgerCategory onChange={(val) => oncahngeCategorId(val)} selected={categoryId}

                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="ledgername"
                                label="Ledger Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input ledger name" },
                                ]}
                            >
                                <Input placeholder='Ledger Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="note"
                                label="Note:"
                                className="title-Text"
                            >
                                <Input placeholder='Note' />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ marginTop: 30, height: 40 }} icon={<SaveOutlined />} >
                                    Save
                                </Button>
                            </Space>
                        </Col>

                    </Row>
                </Form>
               {ledgerList.length>0 &&  <Skeleton loading={loading} paragraph={{rows:10}} />}
               {ledgerList.length>0 && !loading &&
                <div className="datatable-responsive-demo">
                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: ledgerList,
                            filterData: ledgerList,
                            pagination: true,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                </div>
            }
            </Card>

            <Modal
                title="General Ledger Edit"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={updateLedgerForm}
                    id="update"
                    form={updateForm}
                >

                    <Form.Item
                        name="categoryid"
                        label="Category:"
                        className="title-Text"
                        initialValue={selectedCat}
                        rules={[
                            { required: true, message: "Please select category" },
                        ]}
                    >
                        <SelectLedgerCategory onChange={(val) => oncahngeSelectedCategorId(val)} selected={selectedCat}

                        />
                    </Form.Item>



                    <Form.Item
                        name="ledgername"
                        label="Ledger Name:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please input ledger name" },
                        ]}
                    >
                        <Input placeholder='Ledger Name' />
                    </Form.Item>


                    <Form.Item
                        name="note"
                        label="Note:"
                        className="title-Text"
                    >
                        <Input placeholder='Note' />
                    </Form.Item>
                </Form>

            </Modal>
        </>
    )
}
