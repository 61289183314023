import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    notification,
    Popconfirm,
    Row,
    Space,
    Table,
    Tooltip,
} from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { SelectLedgerAll } from "../../../select/SelectLedgerAll";
import { useStoreActions, useStoreState } from "../../../../store/hooks/easyPeasy";
import * as FileSaver from "file-saver";
export default function Journal(props) {

    const doJournalVoucher = useStoreActions((state) => state.ledger.doJournalVoucher);
    const download = useStoreActions((state) => state.ledger.download);
    const trnid = useStoreState((state) => state.ledger.trnid);

    const [tableData, setTableData] = useState<any>([
        {
            key: uuidv4(),
            ledgerId: null,
            debitAmount: null,
            creditAmount: null,
            debitStatus: true,
            creditStatus: true,
        },
    ]);

    const columns = [
        {
            title: "Received From",
            render: (text: any, record: any, index) => (
                <SelectLedgerAll
                    onChange={onchangeSelect("ledgerId", record, index)}
                    selected={record.ledgerId}
                    style={{ width: 250 }}
                />
            ),
        },
        {
            title: "Debit Amount",
            render: (text: any, record: any, index) => (
                <InputNumber
                    min={1}
                    onChange={onchangeValue("debitAmount", record, index)}
                    value={record.debitAmount}
                    placeholder="Debit Amount"
                    style={{ width: 250 }}
                ></InputNumber>
            ),
        },
        {
            title: "Credit Amount",
            render: (text: any, record: any, index) => (
                <InputNumber
                    min={1}
                    onChange={onchangeValuex("creditAmount", record, index)}
                    value={record.creditAmount}
                    placeholder="Credit Amount"
                    style={{ width: 250 }}
                ></InputNumber>
            ),
        },
        {
            title: "Action",
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Add">
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => addItem(record)}
                        />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Popconfirm
                            title="Are you sure to delete this?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteItem(record?.key)}
                        >
                            <Button danger icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const onchangeSelect: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...tableData];
            newData[index][key] = e;
            setTableData(newData);
        };
    function addItem(record) {
        if (record.ledgerId == null && (record.debitAmount == null || record.creditAmount == null)) {
            notification.error({ message: "Please add ledger and desire amount" });
        } else {
            let tem = {
                key: uuidv4(),
                ledgerId: null,
                debitAmount: null,
                creditAmount: null,
                debitStatus: true,
                creditStatus: true,
            };
            const newData = [...tableData, tem];
            setTableData(newData);
        }
    }

    function deleteItem(key) {
        let temp = tableData.filter((item) => item.key !== key);
        setTableData(temp);
    }

    const onchangeValue: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...tableData];
            newData[index][key] = e;
            setTableData(newData);
        };
    const onchangeValuex: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...tableData];
            newData[index][key] = e;
            setTableData(newData);
        };



    const [form] = Form.useForm();

    const submitFom = (value) => {

        let requestDetailses = tableData.map(function (item) {
            return {
                ledgerId: parseInt(item.ledgerId),
                debitAmount: item.debitAmount==null?0:item.debitAmount,
                creditAmount: item.creditAmount==null?0:item.creditAmount,
            }
        });
        let trnDate = moment(value?.date).format("YYYY-MM-DD");
        let totalDebit = requestDetailses.reduce(function (acc, obj) {
            return acc + obj.debitAmount;
        }, 0);
        let totalCredit = requestDetailses.reduce(function (acc, obj) {
            return acc + obj.creditAmount;
        }, 0);
        if (totalDebit !== totalCredit) {
            notification.error({ message: "Total Credit and Debit Amount Should be Same" });
            return;
        } else {
            var postData = {
                detailList: requestDetailses,
                tranDate: trnDate,
                voucherNo: value.voucherNo,
                voucherNote: value.note,
                trnAmount: totalDebit,
            };
            doJournalVoucher(postData);
            setTableData([{ key: uuidv4(), ledgerId: null, debitAmount: null, debitStatus: true, creditStatus: true }]);
            form.resetFields();
        }

    };

    const [cashequi, setcashequi] = useState<any>();

    const onchangecashequi = (value) => {
        setcashequi(value)
    }


    return (
        <Card title="Journal Transaction">
            <Card title="Add Journal Transaction">
                <Table pagination={false} bordered={true} dataSource={tableData} columns={columns} />
            </Card>
            <Card title="Transaction Journal Form">
                <Form
                    layout="vertical"
                    onFinish={submitFom}
                    id="basic-info"
                    form={form}
                >
                    <Row>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="date"
                                label="Date:"
                                className="title-Text"
                                initialValue={moment((new Date()).toISOString())}
                                rules={[
                                    { required: true, message: "Please select date" },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={'DD/MM/YYYY'} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="voucherNo"
                                label="Voucher No:"
                                className="title-Text"
                                rules={[
                                    { required: false, message: "Please input ledger name" },
                                ]}
                            >
                                <Input
                                    placeholder="Voucher No:"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="note"
                                label="Note:"
                                className="title-Text"
                                rules={[
                                    { required: false, message: "Please input ledger name" },
                                ]}
                            >
                                <Input
                                    placeholder="Note:"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ marginTop: 30, height: 40 }} icon={<SaveOutlined />} >
                                    Save
                                </Button>
                            </Space>
                        </Col>
                        {trnid != null &&
                            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                                <Space size="small" >
                                    <Button type="primary" style={{ marginTop: 30, height: 40 }} icon={<SaveOutlined />} onClick={() => { download(trnid); }} >
                                        Download Voucher
                                    </Button>
                                </Space>
                            </Col>
                        }
                    </Row>
                </Form>
            </Card>
        </Card>
    );
}
