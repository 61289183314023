import { Select } from "antd";
import * as React from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
const { Option } = Select;

export interface SelectGroupbySection2 {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  style?: any
}

export const SelectGroupbySection2 = ({
  onChange,
  selected,
  defaultSelected,
  style
}: SelectGroupbySection2) => {
  const itemList = useStoreState((state) => state.student.listGroupbyClassConfig2);

  const onSelect = (id) => {
    if (itemList) {
      const value = itemList.find((item) => item.groupId === id);
      onChange(value);
    }
  };
  


  return (
    <Select
      onChange={onSelect}
      // loading={loading}
      showSearch
      // allowClear
      defaultValue={defaultSelected}
      value={selected}
      style={style}
      labelInValue={false}
      placeholder="Select Group"
      filterOption={(input, option:any) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {itemList ? (
        itemList.map((type, idx) => (
          <Option key={type.groupId} value={type.groupId}>
            {type.groupName}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching Group</Option>
      )}
    </Select>
  );
};
