import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, DatePicker, Skeleton, Descriptions, Table } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';
import { ArrowLeftOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, FileExcelOutlined, FilePdfOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf, ldatepdf, pdfGenerate } from '../../../utils/pdf';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import Text from 'antd/lib/typography/Text';

var year = (new Date().getFullYear()) * 1;

let tcurrentDueAmount = 0;
let tpaidAmount = 0;
let ttcurrentDueAmount = 0;
let ttpaidAmount = 0;

export default function MonthlyDueDetailsInstitute() {
    const { Option } = Select;
    const [form] = Form.useForm();

    const monthlydueReport = useStoreState((state) => state.saccount.monthlydueReport);
    const fetchmonthlydueReport = useStoreActions((state) => state.saccount.fetchmonthlydueReport);
    const loading = useStoreState((state) => state.saccount.loading);

    const [classDueDetails, setClassDueDetails] = useState<any>([]);
    const [step, setStep] = useState<number>(1);
    const [monthFeeHeadDueDetails, setmonthFeeHeadDueDetails] = useState<any>([]);

    useEffect(() => {
        setClassDueDetails(monthlydueReport?.classDueDetails);
    }, [monthlydueReport])

    const onsearch = (value) => {
        fetchmonthlydueReport(value);
    };

    const [className, setclassName] = useState<any>('');


    const columns = [
        {
            title: 'Class Name', dataIndex: 'className', key: 'className', showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
                <span style={{ color: "#00CA88", cursor: "pointer" }} onClick={e => onclickmonthFeeHeadDueDetails(record.classId, record.className)}>{record.className}</span>
            ),
        },
        { title: 'Current Payable', dataIndex: 'currentDueAmount', key: 'currentDueAmount', showOnResponse: true, showOnDesktop: true },
        { title: 'Paid', dataIndex: 'paidAmount', key: 'paidAmount', showOnResponse: true, showOnDesktop: true },
    ];

    const columns2 = [
        { title: 'Fee Head', dataIndex: 'feeHeadName', key: 'feeHeadName', showOnResponse: true, showOnDesktop: true },
        { title: 'Current Payable', dataIndex: 'currentDueAmount', key: 'currentDueAmount', showOnResponse: true, showOnDesktop: true },
        { title: 'Paid', dataIndex: 'paidAmount', key: 'paidAmount', showOnResponse: true, showOnDesktop: true },
    ];

    const onclickmonthFeeHeadDueDetails = (value, classNamex) => {
        setclassName(classNamex)
        let temp = monthlydueReport?.monthFeeHeadDueDetails?.filter(x => x.classId == value);
        if (temp.length > 0) {
            setmonthFeeHeadDueDetails(temp);
            setStep(2);
        } else {
            notification.error({ message: "No Data Found" });
            setStep(1);
        }
    };

    function exportPdf() {
        let acYear = $('.yearSelect .ant-select-selection-item').text();
        let monthS = $('.monthSelection .ant-select-selection-item').text();
        var details = `Due details of Academic Year - ${acYear} and Month - ${monthS}`;
        var doc = new jsPDF("p", "mm", "a4");


        pdfDataL(doc, details);

        var col = ["Class Name", "Current Payable", "Paid",];

        var rows: any = [];

        classDueDetails.forEach(element => {
            var temp = [element.className, element.currentDueAmount, element.paidAmount];
            rows.push(temp);
        });

        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };

        doc.autoTable(col, rows, {
            columnStyles: {
                0: { overflow: 'linebreak', columnWidth: 60, halign: "left" },
                1: { overflow: 'linebreak', columnWidth: 60, halign: "right" },
                2: { overflow: 'linebreak', columnWidth: 60, halign: "right" },

            },
            headerStyles: {
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },

            theme: "grid",
            startY: 45,
            addPageContent: pageContent
        });


        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
            { title: "", dataKey: "b3" },
        ], [
            {
                b1: "Total:",
                b2: tcurrentDueAmount,
                b3: tpaidAmount,
            }
        ], {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',

            columnStyles: {
                b1: {
                    halign: "right",
                    columnWidth: 60
                },
                b2: {
                    halign: "right",
                    columnWidth: 60
                },
                b3: {
                    halign: "right",
                    columnWidth: 60
                }
            },

            // addPageContent: pageContent,
        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }

        doc.save(details + ".pdf");

    }
    function exportPdf2() {
        let acYear = $('.yearSelect .ant-select-selection-item').text();
        let monthS = $('.monthSelection .ant-select-selection-item').text();
        var details = `Due details of Class- ${className},  Academic Year - ${acYear} and Month - ${monthS}`;
        var doc = new jsPDF("p", "mm", "a4");


        pdfDataL(doc, details);

        var col = ["Fee Head", "Current Payable", "Paid",];

        var rows: any = [];

        monthFeeHeadDueDetails.forEach(element => {
            var temp = [element.feeHeadName, element.currentDueAmount, element.paidAmount];
            rows.push(temp);
        });

        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };

        doc.autoTable(col, rows, {
            columnStyles: {
                0: { overflow: 'linebreak', columnWidth: 60, halign: "left" },
                1: { overflow: 'linebreak', columnWidth: 60, halign: "right" },
                2: { overflow: 'linebreak', columnWidth: 60, halign: "right" },

            },
            headerStyles: {
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },

            theme: "grid",
            startY: 45,
            addPageContent: pageContent
        });


        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
            { title: "", dataKey: "b3" },
        ], [
            {
                b1: "Total:",
                b2: ttcurrentDueAmount,
                b3: ttpaidAmount,
            }
        ], {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',

            columnStyles: {
                b1: {
                    halign: "right",
                    columnWidth: 60
                },
                b2: {
                    halign: "right",
                    columnWidth: 60
                },
                b3: {
                    halign: "right",
                    columnWidth: 60
                }
            },

            // addPageContent: pageContent,
        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }

        doc.save(details + ".pdf");

    }

    return (

        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                    <Form
                        layout="vertical"
                        id="sessionYearInfo"
                        onFinish={onsearch}
                        form={form}
                    >
                        <Row>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="academicYear"
                                    label="Academic Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select year" },
                                    ]}
                                >
                                    <SelectAcademicYear />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="month"
                                    label="Select Month"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select month" },
                                    ]}
                                >
                                    <Select placeholder="Select month" className='monthSelection'>
                                        <Option key="1" value="January">January</Option>
                                        <Option key="2" value="February">February</Option>
                                        <Option key="3" value="March">March</Option>
                                        <Option key="4" value="April">April</Option>
                                        <Option key="5" value="May">May</Option>
                                        <Option key="6" value="June">June</Option>
                                        <Option key="7" value="July">July</Option>
                                        <Option key="8" value="August">August</Option>
                                        <Option key="9" value="September">September</Option>
                                        <Option key="10" value="October">October</Option>
                                        <Option key="11" value="November">November</Option>
                                        <Option key="12" value="December">December</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                <Button type="primary" htmlType="submit" icon={<SearchOutlined />} disabled={loading} >
                                    Search
                                </Button>

                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{ rows: 10 }} />

            {classDueDetails?.length > 0 && !loading &&
                <Row >
                    {step == 1 &&
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} style={{ marginTop: 15 }}>
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns: columns,
                                    rowKey: "classId",
                                    dataSource: classDueDetails,
                                    filterData: classDueDetails,
                                    pagination: true,
                                    bordered: true,
                                    summary: function () {
                                        tcurrentDueAmount = 0;
                                        tpaidAmount = 0;

                                        classDueDetails?.forEach(({ currentDueAmount, paidAmount }) => {
                                            tcurrentDueAmount += currentDueAmount;
                                            tpaidAmount += paidAmount;
                                        });

                                        return (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0}><Text type="danger" strong>Total</Text> </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={1}>
                                                        <Text type="danger" strong>{tcurrentDueAmount}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={2}>
                                                        <Text type="danger" strong>{tpaidAmount}</Text>
                                                    </Table.Summary.Cell>


                                                </Table.Summary.Row>

                                            </>
                                        );
                                    }
                                }}
                                mobileBreakPoint={768}
                            />
                            <Space style={{ float: 'right' }} size="middle">
                                <Button type="primary" icon={<FilePdfOutlined />} onClick={exportPdf} > Download PDF </Button>
                            </Space>
                        </Col>
                    }
                    {step == 2 &&
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} style={{ marginTop: 15 }}>
                            <p style={{ color: "#00CA88", cursor: "pointer", fontSize: 18, marginBottom: 10 }} onClick={() => setStep(1)}> <ArrowLeftOutlined /> Go back </p>
                            <p><strong>Class:</strong> {className}</p>
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns: columns2,
                                    rowKey: "classId",
                                    dataSource: monthFeeHeadDueDetails,
                                    filterData: monthFeeHeadDueDetails,
                                    pagination: true,
                                    bordered: true,
                                    summary: function () {
                                        ttcurrentDueAmount = 0;
                                        ttpaidAmount = 0;

                                        monthFeeHeadDueDetails?.forEach(({ currentDueAmount, paidAmount }) => {
                                            ttcurrentDueAmount += currentDueAmount;
                                            ttpaidAmount += paidAmount;
                                        });

                                        return (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0}><Text type="danger" strong>Total</Text> </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={1}>
                                                        <Text type="danger" strong>{ttcurrentDueAmount}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={2}>
                                                        <Text type="danger" strong>{ttpaidAmount}</Text>
                                                    </Table.Summary.Cell>


                                                </Table.Summary.Row>

                                            </>
                                        );
                                    }
                                }}
                                mobileBreakPoint={768}
                            />
                            <Space style={{ float: 'right' }} size="middle">
                                <Button type="primary" icon={<FilePdfOutlined />} onClick={exportPdf2} > Download PDF </Button>
                            </Space>
                        </Col>
                    }
                </Row>
            }
        </>

    )

}