import React, { useEffect, useState } from 'react';
import {  Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, TimePicker, Skeleton, Button } from 'antd'
import { DeleteOutlined, DownloadOutlined, EditOutlined, FileExcelOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import moment from 'moment';
import { SelectPeriod } from '../../../select/SelectPeriod';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, ldatepdf, lpowerdbypdf } from '../../../utils/pdf';
import { FilePdfOutlined } from '@ant-design/icons';
import ReactExport from "react-export-excel";
require('jspdf-autotable');



const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;


export default function SectionWiseMonthlyDetailsSummary() {

    const [form] = Form.useForm();
    const { Option } = Select;
    const format = 'HH:mm';
    const fetchStudentAttendanceAllPeriod = useStoreActions((state) => state.student.fetchStudentAttendanceAllPeriod);
    const fetchStudentMonthlySectionWiseSummaryReport = useStoreActions((state) =>  state.student.fetchStudentMonthlySectionWiseSummaryReport);
    const studentMonthlySectionWiseSummaryReport = useStoreState((state) => state.student.studentMonthlySectionWiseSummaryReport)   
    const fetchmonthlyBlankAttendanceSheet = useStoreActions((state) =>  state.student.fetchmonthlyBlankAttendanceSheet);
    const monthlyBlankAttendanceSheet = useStoreState((state) => state.student.monthlyBlankAttendanceSheet);
    const loading = useStoreState((state) =>  state.student.loading);
   
    const sectionWiseMonthlyDetails = (value) => {
        let postData:any = {
            classConfigId: value.classConfig,
            monthName: value.selectMOnth,
            periodId: value.period,
            year: value.sectionYear
        }
   
        if (type===null) return;
        if (type===1) { 
            fetchmonthlyBlankAttendanceSheet(postData); 
        }      
        if (type===2) { 
            fetchStudentMonthlySectionWiseSummaryReport(postData); 
        }
        
       
    }

    useEffect(() => {
        fetchStudentAttendanceAllPeriod();
    }, []);

    const [reportData, setReporData] = useState<any>([]);

    useEffect(() => {
        let report = studentMonthlySectionWiseSummaryReport;
         report?.forEach(function(obj) {
        for(var i in obj) { 
          if(obj[i] === "-") {
            obj[i] = ' ';
          }
        }
      });
      setReporData(report)
    }, [studentMonthlySectionWiseSummaryReport]);    
    
    useEffect(() => {
        if (monthlyBlankAttendanceSheet?.length===0) return;
      setTimeout(() => {
        exportPdf2();
      }, 500);
    }, [monthlyBlankAttendanceSheet]);


    const columns= [
        { title: 'ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true  },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true  },
        { title: '1', dataIndex: 'firstDay', key: 'firstDay', showOnResponse: true, showOnDesktop: true  },
        { title: '2', dataIndex: 'secondDay', key: 'secondDay', showOnResponse: true, showOnDesktop: true  },
        { title: '3', dataIndex: 'thirdDay', key: 'thirdDay', showOnResponse: true, showOnDesktop: true  },
        { title: '4', dataIndex: 'forthDay', key: 'forthDay', showOnResponse: true, showOnDesktop: true  },
        { title: '5', dataIndex: 'fifthDay', key: 'fifthDay', showOnResponse: true, showOnDesktop: true  },
        { title: '6', dataIndex: 'sixthDay', key: 'sixthDay', showOnResponse: true, showOnDesktop: true  },
        { title: '7', dataIndex: 'seventhDay', key: 'seventhDay', showOnResponse: true, showOnDesktop: true  },
        { title: '8', dataIndex: 'eighthDay', key: 'eighthDay', showOnResponse: true, showOnDesktop: true  },
        { title: '9', dataIndex: 'ninethDay', key: 'ninethDay', showOnResponse: true, showOnDesktop: true  },
        { title: '10', dataIndex: 'tenthDay', key: 'tenthDay', showOnResponse: true, showOnDesktop: true  },
        { title: '11', dataIndex: 'eleventh', key: 'eleventh', showOnResponse: true, showOnDesktop: true  },
        { title: '12', dataIndex: 'twelvethDay', key: 'twelvethDay', showOnResponse: true, showOnDesktop: true  },
        { title: '13', dataIndex: 'thirteenthDay', key: 'thirteenthDay', showOnResponse: true, showOnDesktop: true  },
        { title: '14', dataIndex: 'fourteenthDay', key: 'fourteenthDay', showOnResponse: true, showOnDesktop: true  },
        { title: '15', dataIndex: 'fifteenthDay', key: 'fifteenthDay', showOnResponse: true, showOnDesktop: true  },
        { title: '16', dataIndex: 'sixteenthDay', key: 'sixteenthDay', showOnResponse: true, showOnDesktop: true  },
        { title: '17', dataIndex: 'seventeenthDay', key: 'seventeenthDay', showOnResponse: true, showOnDesktop: true  },
        { title: '18', dataIndex: 'eighteenthDay', key: 'eighteenthDay', showOnResponse: true, showOnDesktop: true  },
        { title: '19', dataIndex: 'nineteenthDay', key: 'nineteenthDay', showOnResponse: true, showOnDesktop: true  },
        { title: '20', dataIndex: 'twentythDay', key: 'twentythDay', showOnResponse: true, showOnDesktop: true  },
        { title: '21', dataIndex: 'twentyFirstDay', key: 'twentyFirstDay', showOnResponse: true, showOnDesktop: true  },
        { title: '22', dataIndex: 'twentySecondDay', key: 'twentySecondDay', showOnResponse: true, showOnDesktop: true  },
        { title: '23', dataIndex: 'twentyThirdDay', key: 'twentyThirdDay', showOnResponse: true, showOnDesktop: true  },
        { title: '24', dataIndex: 'twentyFourthDay', key: 'twentyFourthDay', showOnResponse: true, showOnDesktop: true  },
        { title: '25', dataIndex: 'twentyFifthDay', key: 'twentyFifthDay', showOnResponse: true, showOnDesktop: true  },
        { title: '26', dataIndex: 'twentySixthDay', key: 'twentySixthDay', showOnResponse: true, showOnDesktop: true  },
        { title: '27', dataIndex: 'twentySeventhDay', key: 'twentySeventhDay', showOnResponse: true, showOnDesktop: true  },
        { title: '28', dataIndex: 'twentyEighthDay', key: 'twentyEighthDay', showOnResponse: true, showOnDesktop: true  },
        { title: '29', dataIndex: 'twentyNinethDay', key: 'twentyNinethDay', showOnResponse: true, showOnDesktop: true  },
        { title: '30', dataIndex: 'thirtythDay', key: 'thirtythDay', showOnResponse: true, showOnDesktop: true  },
        { title: '31', dataIndex: 'thirtyFirstDay', key: 'thirtyFirstDay', showOnResponse: true, showOnDesktop: true  },
    ];

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Section Wise Daily Summary`;
        pdfDataL(doc, "");
        doc.text(`Section: ${$(".sectionSelect").text()}`, 15, 40,'left');
        doc.text(`Period: ${$(".periodSelect").text()}`, 110, 40,'left');
        doc.text(`Year: ${$(".yearSelect").text()}`, 200, 40,'center');
        doc.text(`Month: ${$(".monthSelect").text()}`, 283, 40,'right');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);            
        };


        var col = [
            "Id",
            "Name",
            "Roll",
            "1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31"
        ].filter(Boolean);

        var rows:any = [];
        reportData?.forEach(element => {
            var temp:any = [ 
                element.customStudentId, 
                element.studentName, 
                element.studentRoll, 
                element.firstDay,
                element.secondDay,
                element.thirdDay,
                element.forthDay,
                element.fifthDay,
                element.sixthDay,
                element.seventhDay,
                element.eighthDay,
                element.ninethDay,
                element.tenthDay,
                element.eleventh,
                element.twelvethDay,
                element.thirteenthDay,
                element.fourteenthDay,
                element.fifteenthDay,
                element.sixteenthDay,
                element.seventeenthDay,
                element.eighteenthDay,
                element.nineteenthDay,
                element.thirtythDay,
                element.twentyFirstDay,
                element.twentySecondDay,
                element.twentyThirdDay,
                element.twentyFourthDay,
                element.twentyFifthDay,
                element.twentySixthDay,
                element.twentySeventhDay,
                element.twentyEighthDay,
                element.twentyNinethDay,
                element.thirtythDay,
                element.thirtyFirstDay,
            ];
            rows.push(temp);
        });
       

            let first = doc.autoTable.previous;
            doc.autoTable(col, rows, {
                startY: 45,
                showHeader: "firstPage",
                theme: 'grid',
                headerStyles: {
                    // fillColor: [105, 105, 105],
                    // textColor: [255, 255, 255],
                    // fontSize: 10
                    lineWidth: .01,
                    overflow: 'linebreak',
                    lineColor: [224, 224, 224]
                },
                styles: {
                    fontSize: 8,
                },
                columnStyles: {
                    0: {
                        halign: "left",
                        fontSize: 8,
                        columnWidth: 20
                    },                   
                    1: {
                        halign: "left",
                        fontSize: 8,
                        columnWidth: 30,
                        overflow: 'linebreak',
                    },                    
                    2: {
                        halign: "left",
                        fontSize: 8,
                        columnWidth: 10
                    }
                },
                addPageContent: pageContent,
            });

  
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }    
    function exportPdf2() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Section Wise Daily Summary`;
        pdfDataL(doc, "");
        doc.text(`Section: ${$(".sectionSelect").text()}`, 15, 40,'left');
        doc.text(`Period: ${$(".periodSelect").text()}`, 110, 40,'left');
        doc.text(`Year: ${$(".yearSelect").text()}`, 200, 40,'center');
        doc.text(`Month: ${$(".monthSelect").text()}`, 283, 40,'right');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);            
        };


        var col = [
            "Id",
            "Name",
            "Roll",
            "1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31"
        ].filter(Boolean);

        var rows:any = [];
        monthlyBlankAttendanceSheet?.forEach(element => {
            var temp:any = [ 
                element.customStudentId, 
                element.studentName, 
                element.studentRoll, 
                element.firstDay,
                element.secondDay,
                element.thirdDay,
                element.forthDay,
                element.fifthDay,
                element.sixthDay,
                element.seventhDay,
                element.eighthDay,
                element.ninethDay,
                element.tenthDay,
                element.eleventh,
                element.twelvethDay,
                element.thirteenthDay,
                element.fourteenthDay,
                element.fifteenthDay,
                element.sixteenthDay,
                element.seventeenthDay,
                element.eighteenthDay,
                element.nineteenthDay,
                element.thirtythDay,
                element.twentyFirstDay,
                element.twentySecondDay,
                element.twentyThirdDay,
                element.twentyFourthDay,
                element.twentyFifthDay,
                element.twentySixthDay,
                element.twentySeventhDay,
                element.twentyEighthDay,
                element.twentyNinethDay,
                element.thirtythDay,
                element.thirtyFirstDay,
            ];
            rows.push(temp);
        });
       

            let first = doc.autoTable.previous;
            doc.autoTable(col, rows, {
                startY: 45,
                showHeader: "firstPage",
                theme: 'grid',
                headerStyles: {
                    // fillColor: [105, 105, 105],
                    // textColor: [255, 255, 255],
                    // fontSize: 10
                    lineWidth: .01,
                    overflow: 'linebreak',
                    lineColor: [224, 224, 224]
                },
                styles: {
                    fontSize: 8,
                },
                columnStyles: {
                    0: {
                        halign: "left",
                        fontSize: 8,
                        columnWidth: 20
                    },                   
                    1: {
                        halign: "left",
                        fontSize: 8,
                        columnWidth: 30,
                        overflow: 'linebreak',
                    },                    
                    2: {
                        halign: "left",
                        fontSize: 8,
                        columnWidth: 10
                    }
                },
                addPageContent: pageContent,
            });

  
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    const [type, setType] = useState<any>(null);

    return <>
        <Card title="Section Wise Monthly Details">
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24}} xl={{ span: 24}}>
                <Form
                    layout="vertical"
                    id="classConfigInfo"
                    form={form}
                    onFinish={sectionWiseMonthlyDetails}
                >
                    <Row>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item
                                name="classConfig"
                                label="Select Section"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                            >
                                <SelectClassConfig />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item
                                name="period"
                                label="Period"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select period" },
                                ]}
                            >
                                <SelectPeriod />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item
                                name="sectionYear"
                                label="Select Year"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select year" },
                                ]}
                            >
                                    <SelectAcademicYear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item
                                name="selectMOnth"
                                label="Select Month"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select month" },
                                ]}
                            >
                                <Select placeholder="Select month"  className="monthSelect">
                                    <Option key="1" value="January">January</Option>
                                    <Option key="2" value="February">February</Option>
                                    <Option key="3" value="March">March</Option>
                                    <Option key="4" value="April">April</Option>
                                    <Option key="5" value="May">May</Option>
                                    <Option key="6" value="June">June</Option>
                                    <Option key="7" value="July">July</Option>
                                    <Option key="8" value="August">August</Option>
                                    <Option key="9" value="September">September</Option>
                                    <Option key="10" value="October">October</Option>
                                    <Option key="11" value="November">November</Option>
                                    <Option key="12" value="December">December</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24}} xl={{ span: 24}}>
                            <div style={{ float:"right" }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" className='mt-0' icon={<FilePdfOutlined />} onClick={()=>setType(1)} >
                                        Download Blank Sheet
                                    </Button>                             
                                    <Button type="primary" htmlType="submit" className='mt-0' icon={<SearchOutlined />} onClick={()=>setType(2)} >
                                        Search
                                    </Button>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                </Form>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{rows:10}}/>
            <Row className='mt-30' style={{ display: studentMonthlySectionWiseSummaryReport.length > 0 ? 'block' : 'none' }}>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24}} xl={{ span: 24} }>
                    <Table
                            antTableProps={{
                                showHeader: true,
                                columns: columns,
                                dataSource: studentMonthlySectionWiseSummaryReport,
                                filterData: studentMonthlySectionWiseSummaryReport,
                                pagination: true,
                                bordered: true,
                                rowKey: "customStudentId",
                            }}
                            mobileBreakPoint={768}

                        />
                </Col>
                <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>
                        <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Section Wise Monthly Details`}
                            >
                                <ExcelSheet data={reportData} name="Section Wise Monthly Details">
                                    <ExcelColumn label="ID" value="customStudentId" />
                                    <ExcelColumn label="Student Name" value="studentName" />
                                    <ExcelColumn label="Roll" value="studentRoll" />
                                    <ExcelColumn label="1" value="firstDay" />
                                    <ExcelColumn label="2" value="secondDay" />
                                    <ExcelColumn label="3" value="thirdDay" />
                                    <ExcelColumn label="4" value="forthDay" />
                                    <ExcelColumn label="5" value="fifthDay" />
                                    <ExcelColumn label="6" value="sixthDay" />
                                    <ExcelColumn label="7" value="seventhDay" />
                                    <ExcelColumn label="8" value="eighthDay" />
                                    <ExcelColumn label="9" value="ninethDay" />
                                    <ExcelColumn label="10" value="tenthDay" />
                                    <ExcelColumn label="11" value="eleventh" />
                                    <ExcelColumn label="12" value="twelvethDay" />
                                    <ExcelColumn label="13" value="thirteenthDay" />
                                    <ExcelColumn label="14" value="fourteenthDay" />
                                    <ExcelColumn label="15" value="fifteenthDay" />
                                    <ExcelColumn label="16" value="sixteenthDay" />
                                    <ExcelColumn label="17" value="seventeenthDay" />
                                    <ExcelColumn label="18" value="eighteenthDay" />
                                    <ExcelColumn label="19" value="nineteenthDay" />
                                    <ExcelColumn label="20" value="twentythDay" />
                                    <ExcelColumn label="21" value="twentyFirstDay" />
                                    <ExcelColumn label="22" value="twentySecondDay" />
                                    <ExcelColumn label="23" value="twentyThirdDay" />
                                    <ExcelColumn label="24" value="twentyFourthDay" />
                                    <ExcelColumn label="25" value="twentyFifthDay" />
                                    <ExcelColumn label="26" value="twentySixthDay" />
                                    <ExcelColumn label="27" value="twentySeventhDay" />
                                    <ExcelColumn label="28" value="twentyEighthDay" />
                                    <ExcelColumn label="29" value="twentyNinethDay" />
                                    <ExcelColumn label="30" value="thirtythDay" />
                                    <ExcelColumn label="31" value="thirtyFirstDay" />
                                </ExcelSheet>
                            </ExcelFile>
                            <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportPdf()} >PDF Download</Button>
                        </Space>
                    </Col>
            </Row>
        </Card>
    </>
}