import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, notification, Popconfirm, Row, Skeleton, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectClass } from '../../../select/SelectClass';
import { SelectFeeHead } from '../../../select/SelectFeeHead';
import { SelectCategory } from '../../../select/SelectCategory';
import { SelectGroupByClass } from '../../../select/SelectGroupByClass';

export default function FeeAmountConfig(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const fetchGroupList = useStoreActions((state) => state.exam.fetchGroupList);
    const feeAmountConfigurationList = useStoreState((state) => state.saccount.feeAmountConfigurationList);
    const fetchfeeAmountConfigurationList = useStoreActions((state) => state.saccount.fetchfeeAmountConfigurationList);
    const feeSubHeadConfigurationList = useStoreState((state) => state.saccount.feeSubHeadConfigurationList);
    const fetchfeeSubHeadConfigurationList = useStoreActions((state) => state.saccount.fetchfeeSubHeadConfigurationList);
    const saveFeeAmountConfiguration = useStoreActions((state) => state.saccount.saveFeeAmountConfiguration);
    const updateFeeAmountConfiguration = useStoreActions((state) => state.saccount.updateFeeAmountConfiguration);
    const deleteFeeAmountConfiguration = useStoreActions((state) => state.saccount.deleteFeeAmountConfiguration);
    const [searchData, setsearchData] = useState<any>();
    const loading = useStoreState((state) =>  state.saccount.loading);

    const formSubmit = (value) => {
        setsearchData(value);
        fetchfeeSubHeadConfigurationList(value.feeHeadId)
        fetchfeeAmountConfigurationList(value);
        // setClassId(null);
    }

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);
    
    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const [tableRowStore, setTableRowStore] = useState<any>([]);




    const onchangeValue: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData: any = [...tableRowStore];
            newData[index][key] = e.target.value;
            setTableRowStore(newData);
        };

    const columns = [

        {
            title: 'Fee Subhead',
            dataIndex: 'feeSubheadName',
            key: 'feeSubheadName',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Fee Amount',
            dataIndex: 'feeAmount',
            key: 'feeAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Input placeholder="Fee Amount" name="feeAmount" onChange={onchangeValue("feeAmount", record, index)} />
            ),
        },

        {
            title: 'Fine Amount',
            dataIndex: 'fineAmount',
            key: 'fineAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Input placeholder="Fine Amount" name="fineAmount" onChange={onchangeValue("fineAmount", record, index)} />
            ),
        },

    ];
    const [tableRowStore2, setTableRowStore2] = useState<any>([]);
    const onchangeValue2: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData: any = [...tableRowStore2];
            newData[index][key] = e.target.value;
            setTableRowStore2(newData);
        };

    const deleteFeeHead = (value) => {
        console.log('searchData', searchData);
        
        deleteFeeAmountConfiguration(value);
        setTimeout(() => {
            fetchfeeAmountConfigurationList(searchData);
        }, 1000);
        // setTableRowStore2(feeAmountConfigurationList);
    }    

    const columns2 = [

        {
            title: 'Class Name',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Group Name',
            dataIndex: 'groupName',
            key: 'groupName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Student Category',
            dataIndex: 'studentCategoryName',
            key: 'studentCategoryName',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Fee Head',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Fee Subhead',
            dataIndex: 'feeSubHeadName',
            key: 'feeSubHeadName',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Fee Amount',
            dataIndex: 'feeAmount',
            key: 'feeAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Input placeholder="Fee Amount" value={record.feeAmount} name="feeAmount" onChange={onchangeValue2("feeAmount", record, index)} />
            ),
        },

        {
            title: 'Fine Amount',
            dataIndex: 'fineAmount',
            key: 'fineAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Input placeholder="Fine Amount" value={record.fineAmount} name="fineAmount" onChange={onchangeValue2("fineAmount", record, index)} />
            ),
        },
        {
            title: 'Update',
            key: 'configId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                        <Tooltip title="Update">
                            <Button type="primary" icon={<SaveOutlined />} onClick={()=>updateButton(record)} />
                        </Tooltip>
                </Space>
            ),
        },

        {
            title: 'Delete',
            key: 'configId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">

                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteFeeHead(record?.configId )}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        },

    ];

    const updateButton = (value) => {
        let data = {
            configId: value.configId,
            feeAmount: value.feeAmount === undefined ? '' : value.feeAmount,
            fineAmount: value.fineAmount === undefined ? '' : value.fineAmount,
        }
        updateFeeAmountConfiguration(data);
        // setTableRowStore2(null);
        // setTimeout(() => {
        //     fetchfeeAmountConfigurationList(searchData);
        // }, 1000);
    

    }

    useEffect(() => {
        setTableRowStore(feeSubHeadConfigurationList);
    }, [feeSubHeadConfigurationList]);
   
    useEffect(() => {
        setTableRowStore2(feeAmountConfigurationList);
    }, [ feeAmountConfigurationList]);


    const saveFeeSubHead = () => {
        console.log(selectedValue)
        if (selectedRowKeys.length === 0) {
            notification.error({ message: 'Select the table row first' });
            return;
        }
        let data = selectedValue.map((item, index) => {
            return {
                feeSubHeadId: item.feeSubHeadId,
                feeAmount: item.feeAmount === undefined ? '' : item.feeAmount,
                fineAmount: item.fineAmount === undefined ? '' : item.fineAmount,
            }
        })
        let payload = {
            classId: classId,
            feeHeadId: feeHeadid,
            groupId: groupId,
            studentCategoryId: categoryId,
            feeSubHeadDetails: data
        }
        console.log(payload)
        saveFeeAmountConfiguration(payload);
        setTableRowStore(null)
        setselectedRowKeys([]);
        setselectedValue([]);
        fetchfeeSubHeadConfigurationList(feeHeadid);

    }



    const [classId, setClassId] = useState<any>()
    const [groupId, setGroupId] = useState<any>()
    const [categoryId, setcategoryId] = useState<any>()
    const [feeHeadid, setfeeHeadid] = useState<any>()

    return (
        <>
            <Card title="Fee Amount Configure" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>

                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="classId"
                                label="Class"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Select Class" },
                                ]}
                            >

                                <SelectClass selected={classId} onChange={e => { setClassId(e); setGroupId(null); fetchGroupList(e); form.setFieldsValue({ "groupId": null }) }} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="groupId"
                                label="Group"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Select Group" },
                                ]}
                            >

                                <SelectGroupByClass selected={groupId} onChange={e => { setGroupId(e); }} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="studentCategoryId"
                                label="Category"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Select Category" },
                                ]}
                            >

                                <SelectCategory selected={categoryId} onChange={e => { setcategoryId(e); }} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="feeHeadId"
                                label="Fee Head"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select fee head" },
                                ]}
                            >
                                <SelectFeeHead selected={feeHeadid} onChange={e => { setfeeHeadid(e); }} />

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>

                    </Row>

                </Form>
                {feeAmountConfigurationList===null && < Skeleton loading={loading} paragraph={{rows:10}} />}
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        {tableRowStore !== null &&
                            <>
                                <Table
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: tableRowStore,
                                        filterData: tableRowStore,
                                        pagination: false,
                                        bordered: true,
                                        rowKey: "configId",
                                        rowSelection: rowSelection,
                                    }}
                                    mobileBreakPoint={768}
                                />
                                <br />
                                <Space size={'middle'} style={{ float: "right" }}>
                                    <Button type="primary" onClick={saveFeeSubHead} icon={<SaveOutlined />}>Save</Button>
                                </Space>
                            </>
                        }
               {feeAmountConfigurationList===null &&  < Skeleton loading={loading} paragraph={{rows:10}} />}      
                   
                    </Col>
                    {feeAmountConfigurationList!==null && 
                    <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        {tableRowStore2 !== null &&
                            <>
                                <Table
                                    antTableProps={{
                                        showHeader: true,
                                        columns: columns2,
                                        dataSource: tableRowStore2,
                                        filterData: tableRowStore2,
                                        pagination: false,
                                        bordered: true,
                                        rowKey: "configId",

                                    }}
                                    mobileBreakPoint={768}
                                />

                            </>
                        }
                    </Col>
}

                </Row>
                    
            </Card>


        </>
    )
}
