import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';
import TableView from '../../../../contents/AntTableResponsive';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { loadavg } from 'os';


export default function ExamSubject(props) {

  const { Option } = Select;
  const fetchShortCodeClassList = useStoreActions((state) => state.exam.fetchShortCodeClassList);
  const shortCodeClasslist = useStoreState((state) => state.exam.shortCodeClasslist);
  //deleteSubjectConfiguration
  const fetchGroupList = useStoreActions((state) => state.exam.fetchGroupList);
  const groupDropdownlist = useStoreState((state) => state.exam.groupDropdownlist);
  const fetchSubjectList = useStoreActions((state) => state.exam.fetchSubjectList);
  const fetchSubjectConfigurationList = useStoreActions((state) => state.exam.fetchSubjectConfigurationList);
  const setSubjectConfigurationListByClass = useStoreActions((state) => state.exam.setSubjectConfigurationListByClass);
  const subjectConfigurationList = useStoreState((state) => state.exam.subjectConfigurationList);
  const updateSubjectConfiguration = useStoreActions((state) => state.exam.updateSubjectConfiguration);
  const subjectList = useStoreState((state) => state.exam.subjectList);
  const [classListValue, setClassListValue] = useState<any>("");
  const setSubjectConfiguration = useStoreActions((state) => state.exam.setSubjectConfiguration);
  const deleteSubjectConfiguration = useStoreActions((state) => state.exam.deleteSubjectConfiguration);
  const [groupListValue, setGroupListValue] = useState<any>("");
  const [chooseSubjectValues, setChooseSubjectValues] = useState<any>([]);
  const [subjectConfigurationListData, setSubjectConfigurationListData] = useState<any>();
  const loading = useStoreState((state) => state.exam.loading);

  const chooseSubjectValue: any = [];

  useEffect(() => {
    fetchShortCodeClassList();
    fetchSubjectList();
    setSubjectConfigurationListData(subjectConfigurationList);
  }, []);

  const onChangeClassList = (val) => {
    setSubjectConfigurationListByClass();
    fetchGroupList(val);
    setClassListValue(val);
  }

  const fetchConfigurationList = (val) => {
    setSubjectConfigurationListData([]);
    var postData: any = {
      classId: classListValue,
      groupId: val
    };
    fetchSubjectConfigurationList(postData);
    setGroupListValue(val);
  }

  const removeSubjectList = (val) => {
    let temp = subjectConfigurationListData.filter((item: any) => item.subjectConfigurationId !== val);
    setSubjectConfigurationListData(temp);
    deleteSubjectConfiguration(val);
  }

  const changeSubjectType = (id, val) => {
    subjectConfigurationListData.map((item: any, index) => {
      if (item.subjectConfigurationId == id) {
        item.subjectStatus = val;
      }
    });
  }

  const changeSerialNumber = (input, val) => {
    subjectConfigurationListData.map((item: any, index) => {
      if (item.subjectConfigurationId == input) {
        item.subjectSerial = val;
      }
    });
  }

  const changeSubjectMergeID = (input, val) => {
    subjectConfigurationListData.map((item: any, index) => {
      if (item.subjectConfigurationId == input) {
        item.subjectMergeId = val;
      }
    });
  }

  useEffect(() => {
    setSubjectConfigurationListData(subjectConfigurationList);
  }, [subjectConfigurationList])


  const defaultValue = (val) => {
    switch (val) {
      case 0:
        return "Compulsory";
        break;
      case 1:
        return "Chooseable";
        break;
      case 2:
        return "Group Based";
        break;
      case 4:
        return "Uncountable";
      case 3:
        return "Elective-1";
      case 5:
        return "Elective-2";
        break;
      case 6:
        return "Elective-3";
        break;
      case 7:
        return "Elective/Optional";
        break;
      default:
        return "Compulsory";
        break;
    }
  }

  const subjectConfigurationListColumn = [
    { title: 'Subject', dataIndex: 'subjectName', key: 'id', showOnResponse: true, showOnDesktop: true },
    {
      title: 'Subject Type', dataIndex: 'subjectConfigurationId', key: 'id', showOnResponse: true, showOnDesktop: true, width: '30%',
      render: (text, record, index) => (
        <>
          <Select defaultValue={defaultValue(record.subjectStatus)} onChange={(e) => changeSubjectType(record.subjectConfigurationId, e)} style={{ width: "100%" }}>
            <Option value="0" key="0">Compulsory</Option>
            <Option value="1" key="1">Chooseable</Option>
            <Option value="2" key="2">Group Based</Option>
            <Option value="4" key="4">Uncountable</Option>
            <Option value="3" key="3">Elective-1</Option>
            <Option value="5" key="5">Elective-2</Option>
            <Option value="6" key="6">Elective-3</Option>
            <Option value="7" key="7">Elective/Optional</Option>

          </Select>
        </>
      )
    },
    {
      title: 'Subject Serial', dataIndex: 'subjectSerial', key: 'id', showOnResponse: true, showOnDesktop: true,
      render: (text, record, index) => (
        <InputNumber defaultValue={record.subjectSerial} name="subjectSerial" className="subjectSerial" onChange={(e) => changeSerialNumber(record.subjectConfigurationId, e)} />
      ),
    },
    {
      title: 'Merge ID', dataIndex: 'subjectMergeId', key: 'id', showOnResponse: true, showOnDesktop: true,
      render: (text, record, index) => (
        <InputNumber defaultValue={record.subjectMergeId} name="subjectMergeId" className="subjectMergeId" onChange={(e) => changeSubjectMergeID(record.subjectConfigurationId, e)} />
      ),
    },
    {
      title: 'Action', dataIndex: 'gradeRange', key: 'id', showOnResponse: true, showOnDesktop: true,
      render: (text, record, index) => (
        <Popconfirm
          title="Are you sure to delete this?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => removeSubjectList(record.subjectConfigurationId)}
        >
          <Tooltip title="Delete">
            <Button danger icon={<DeleteOutlined />} />
          </Tooltip>
        </Popconfirm>
      ),
    },

  ];

  const chooseSubject = (e) => {
    const chooseSubjectValue: any = [];
    if (e.target.checked) {
      let val = e.target.value;
      chooseSubjectValue.push(...chooseSubjectValues, val);
    } else {
      let val = e.target.value;
      const index = chooseSubjectValue.indexOf(val)
      chooseSubjectValue.splice(index, 1);
    }
    setChooseSubjectValues(chooseSubjectValue);
  }

  const saveSubjectConfiguretion = (val) => {
    let postData = {
      classId: classListValue,
      groupId: groupListValue,
      subjectIds: chooseSubjectValues
    }
    setSubjectConfiguration(postData);
  }

  const updateSubjectConfigurationMethod = (e) => {
    let updateArray: any = [];
    subjectConfigurationListData.map((item: any, index) => {
      let updateData = {
        subjectConfigurationId: item.subjectConfigurationId,
        subjectMergeId: item.subjectMergeId,
        subjectSerial: item.subjectSerial,
        subjectStatus: item.subjectStatus,
      }
      updateArray.push(updateData)
    });
    updateSubjectConfiguration(updateArray);
  }
  const [form] = Form.useForm();
  return (
    <>
      <Row>
        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 10 }}>
          <Form
            layout="vertical"
            id="classConfigInfo"
            onFinish={saveSubjectConfiguretion}
            form={form}
          >
            <Card className="p-0" title="Choose Subjects" bordered={false}>
              <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                  <Form.Item
                    name="classList"
                    className="title-Text"
                    label="Class Name"
                    rules={[
                      { required: true, message: "Select class name" },
                    ]}
                  >
                    <Select placeholder="Class List" style={{ width: "100%" }} onChange={(val) => { onChangeClassList(val); form.setFieldsValue({ classGroup: null }) }}>
                      {shortCodeClasslist ? (
                        shortCodeClasslist.map((type, idx) => (
                          <Option key={type.id} value={type.id}>
                            {type.name}
                          </Option>
                        ))
                      ) : (
                        <Option value="fetching">Fetching Class</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                  <Form.Item
                    name="classGroup"
                    className="title-Text"
                    label="Group Name"
                    rules={[
                      { required: true, message: "Select group name" },
                    ]}
                  >
                    <Select placeholder="Group List" style={{ width: "100%" }} onChange={(val) => fetchConfigurationList(val)}>
                      {groupDropdownlist ? (
                        groupDropdownlist.map((type, idx) => (
                          <Option key={type.groupId} value={type.groupId}>
                            {type.classGroupName}
                          </Option>
                        ))
                      ) : (
                        <Option value="fetching">Fetching Group</Option>
                      )}
                    </Select>

                  </Form.Item>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                  <Row>
                    {subjectList ? (
                      subjectList.map((type, idx) => (
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 12 }}>
                          <Checkbox value={type.id} onChange={(e) => chooseSubject(e)}>{type.name}</Checkbox>
                        </Col>
                      ))
                    ) : ''}

                  </Row>
                </Col>
                <Col md={{ span: 24 }}>
                  <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="float-right" style={{ float: "right" }}>Save</Button>
                </Col>
              </Row>
            </Card>

          </Form>
        </Col>
        <Skeleton loading={loading} paragraph={{ rows: 10 }} />
        {subjectConfigurationList.length > 0 &&
          <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 14 }}>
            <Card className="p-0" title="Subject Configuration List" bordered={false} style={{ display: subjectConfigurationList.length > 0 ? '' : 'none' }}>
              <TableView
                antTableProps={{
                  showHeader: true,
                  columns: subjectConfigurationListColumn,
                  rowKey: "subjectSerial",
                  dataSource: subjectConfigurationListData,
                  filterData: subjectConfigurationListData,
                  pagination: false,
                  bordered: true,
                  style: { maxHeight: 500 }
                }}
                mobileBreakPoint={768}
              />
              <br />
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />} onClick={(e) => updateSubjectConfigurationMethod(e)} className="float-right">Update</Button>
            </Card>
          </Col>
        }
      </Row>
    </>
  )

}
