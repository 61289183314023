import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Column from 'rc-table/lib/sugar/Column';
import { SwitcherOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';

export default function EnbaledStuffMapping() {

    const fetchenabledStuff = useStoreActions((state)=> state.staff.fetchenabledStuff);
    const saveBatchIdmapping = useStoreActions((state)=> state.staff.saveBatchIdmapping);
    const saveSingleIdmapping = useStoreActions((state)=> state.staff.saveSingleIdmapping);
    const enabledStuff = useStoreState((state) => state.staff.enabledStuff);
    const loading = useStoreState((state) =>  state.staff.loading);
   
    useEffect(() => {
        fetchenabledStuff();
    }, []);
 

    const [tableData, setTableData] = useState<any>([]);

    useEffect(() => {
        setTableData(enabledStuff);
    }, [enabledStuff]);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'customStaffId',
            key: 'customStaffId',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        },        
        
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'User ID',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => {
                return (
                    <Input value={record?.userId} placeholder='User Id' onChange={onchangeValue("userId", record, index)}></Input>

                )

            }
        },
        {
            title: 'Update',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => {
                return (
                   <Button type='primary' onClick={()=>saveSingleIdmapping({
                    mapId: record?.mapId,
                    staffId: record?.staffId,
                    userId: record?.userId
                   })}> Update</Button>

                )

            }
        },
        
    ];

    const batchSave = () => {
       
        let payload = tableData?.map((item: any) => {
            return {
                mapId: item?.mapId,
                staffId: item?.staffId,
                userId: item?.userId
            }
        })
        saveBatchIdmapping(payload);
    }

    const onchangeValue: any =
    (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newData = [...tableData];
        newData[index][key] = e.target.value;
        setTableData(newData);
    };

    const [checked, setChecked] = useState<boolean>(false);
   
    const checkItem = (e: any) => {
        setChecked(e.target.checked);
        if (e.target.checked === true) {
            let temp:any =[]
           for (let i = 0; i < tableData.length; i++) {
                tableData[i].userId = tableData[i].customStaffId;
            }
            temp.push(...tableData);
            setTableData(temp);
        } 

        if (e.target.checked === false) {
            let temp:any =[]
            for (let i = 0; i < tableData.length; i++) {
                 tableData[i].userId = null;
             }
             temp.push(...tableData);
             setTableData(temp);
        } 
    }


    return(
        <>
           <Card title="Enabled Staff Machine ID Map List" className='box-shadow-none'>
           <Row className="m-t-mo-30">
                <Col span={24}>
                    <Checkbox style={{marginBottom:10, float:"right"}} value={checked} onChange={checkItem}>Check For Staff ID As Machine ID</Checkbox>
                </Col>
                {enabledStuff === null && <Skeleton loading={loading} paragraph={{rows:10}}/>}
                <Col span={24}>
                    <div className="datatable-responsive-demo">
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: enabledStuff,
                                filterData: enabledStuff,
                                pagination: false,
                                bordered: true,
                                rowKey: "staffId",
                            }}
                            mobileBreakPoint={768}
                        />
                        <Space size={'middle'} style={{float:"right", marginTop:10}}>
                            <Button type="primary" onClick={batchSave}>Batch Update</Button>
                        </Space>
                    </div>
                </Col>
            </Row>
           </Card>
        </>
    )
}