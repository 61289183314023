import React, { useEffect } from 'react'
import { Card, Tabs } from 'antd';
import ResultPublicationEntry from './ResultPublicationEntry';
import ResultPublicationList from './ResultPublicationList';

const { TabPane } = Tabs;

export default function ResultPublicationWrapper(props) {

    return (
        <>
            <Card title="Result Publication" >
                <Tabs defaultActiveKey="1" size={"large"}>
                    <TabPane tab="Entry" key="1">
                        <ResultPublicationEntry />
                    </TabPane>
                    <TabPane tab="List" key="2">
                        <ResultPublicationList />
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )

}