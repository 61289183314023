import React from 'react'
import { Card, Tabs } from 'antd';
import MarkInputFormExcel from './MarkInputFormExcel';
import MarkInputFormBrowser from './MarkInputFormBrowser';


export default function TabulationSheet(props) {

    const { TabPane } = Tabs;

    return (
        <>
            <Card title="Mark Input">
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Manual" key="1">
                        <MarkInputFormBrowser />
                    </TabPane>
                    <TabPane tab="Excel" key="2">
                        <MarkInputFormExcel />
                    </TabPane>

                </Tabs>
            </Card>
        </>
    )
}