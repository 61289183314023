import React from 'react'
import { Card, Tabs } from 'antd';
import AcademicYear from './AcademicYear.page';
import Class from './Class.page';
import Shift from './Shift.page';
import Section from './Section.page';
import Group from './Group.page';
import StudentCategory from './StudentCategory.page';


export default function StudentWrapper(props) {

    const { TabPane } = Tabs;
    const [activeTab, setActiveTab] = React.useState<any>("1");

    return (
        <>
            <Card title="Settings">
                <Tabs defaultActiveKey="1"  onChange={(e) => { setActiveTab(e) }}>
                    <TabPane tab="Academic Year" key="1">
                        {activeTab === "1" && <AcademicYear />}
                    </TabPane>
                    <TabPane tab="Class" key="2">
                        {activeTab === "2" && <Class />}
                    </TabPane>
                    <TabPane tab="Shift" key="3">
                        {activeTab === "3" && <Shift />}
                    </TabPane>
                    <TabPane tab="Section" key="4">
                        {activeTab === "4" && <Section />}
                    </TabPane>
                    <TabPane tab="Group" key="5">
                        {activeTab === "5" && <Group />}
                    </TabPane>
                    <TabPane tab="Student Category" key="6">
                        {activeTab === "6" && <StudentCategory />}
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )
}