import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';
import TableView from '../../../../../contents/AntTableResponsive';
import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import Column from 'rc-table/lib/sugar/Column';
import { render } from '@testing-library/react';
import { SelectClass } from '../../../../select/SelectClass';
import { SelectClassConfig } from '../../../../select/SelectClassConfig';
import { SelectGroupbySection } from '../../../../select/SelectGroupbySection';

export default function AssignList() {

    const [assignSearchForm] = Form.useForm();
    const fetchForthSubjectAssignList = useStoreActions((state) => state.exam.fetchForthSubjectAssignList);
    const fetchlistGroupbyClassConfig = useStoreActions((state) => state.student.fetchlistGroupbyClassConfig);
    const forthSubjectAssignList = useStoreState((state) => state.exam.forthSubjectAssignList);
    const fetchForthSubjectChooseableList = useStoreActions((state) => state.exam.fetchForthSubjectChooseableList);
    const fetchelectiveSubjectConfigurationListByClassConfigurationIdAndGroupId = useStoreActions((state) => state.exam.fetchelectiveSubjectConfigurationListByClassConfigurationIdAndGroupId);
    const forthSubjectChooseableList = useStoreState((state) => state.exam.forthSubjectChooseableList);
    const electiveSubjectConfigurationListByClassConfigurationIdAndGroupId = useStoreState((state) => state.exam.electiveSubjectConfigurationListByClassConfigurationIdAndGroupId);
    const assignForthSubjectChooseableList = useStoreActions((state) => state.exam.assignForthSubjectChooseableList);
    const [studentSelected, setStudentSelected] = useState([]);
    const [studentSelectedValue, setstudentSelectedValue] = useState<any>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const subjectConfigurationList = useStoreState((state) => state.exam.subjectConfigurationList);
    const fetchSubjectConfigurationListbyClassConfig = useStoreActions((state) => state.exam.fetchSubjectConfigurationListbyClassConfig);
    const loading = useStoreState((state) =>  state.exam.loading);


    // let compulsoryValue: any = [];
    // let optionalValue: any = [];

    const onChangeClassList = (val) => {
        fetchlistGroupbyClassConfig(val);
    }

    const assignSearch = (value) => {
        console.log(value)
        let postData: any = {
            classConfigurationId: value?.classConfigurationId,
            groupId: value.classGroup?.groupId,
        }


        let newPosDatta: any = {
            classConfigId: value?.classConfigurationId,
            groupId: value.classGroup?.groupId,
        }
        fetchForthSubjectAssignList(postData);
        fetchSubjectConfigurationListbyClassConfig(newPosDatta);
        // fetchForthSubjectChooseableList(postData);
        // fetchelectiveSubjectConfigurationListByClassConfigurationIdAndGroupId(postData);
    }

    const assignTableColmn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Group', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true },
    ]

    const [selectedRowKeys2, setselectedRowKeys2] = useState<any>([]);
    const [selectedValue2, setselectedValue2] = useState<any>([]);

    const onSelectChange2 = (selectedRowKeys2, value2) => {
        setselectedRowKeys2(selectedRowKeys2);
        setselectedValue2(value2);
        // console.log(value)
    };

    const rowSelection2 = {
        selectedRowKeys:selectedRowKeys2,
        onChange: onSelectChange2,
    };

    const assignModalOpen = () => {
        if (selectedRowKeys2.length > 0) {
            setIsModalVisible(true);
        } else {
            notification.error({ message: 'Select student first' });
        }
    }

    const [elective1, setelective1] = useState<any>([]);
    const [elective2, setelective2] = useState<any>([]);
    const [elective3, setelective3] = useState<any>([]);

    const assignSubject = (value,compulsorySubjectIds, electiveOptionalChild) => {
 
        if (electiveOptionalChild.length > 0 || compulsorySubjectIds.length > 0) {
            let postData = {
                "compulsorySubjectIds": compulsorySubjectIds,
                "identificationIds": selectedValue2.map(item=>item.identificationId),
                "optionalSubjectIds": electiveOptionalChild
            }
         
            assignForthSubjectChooseableList(postData);
        } else {
            notification.error({ message: 'Please select subject' });
        }
        setselectedRowKeys2([]);
        setselectedValue2([]);
        setIsModalVisible(false);
        clearElectiveOptionalMain();
        clearElectiveOptionalChild();
        var elective1: any = document.getElementsByClassName('elective1');
        for (var i = 0; i < elective1.length; i++) {
            elective1[i].checked = false;
        }        
        var elective2: any = document.getElementsByClassName('elective2');
        for (var i = 0; i < elective2.length; i++) {
            elective2[i].checked = false;
        }        
        var elective3: any = document.getElementsByClassName('elective3');
        for (var i = 0; i < elective3.length; i++) {
            elective3[i].checked = false;
        }
    }




    const [electiveOptionalMain, setelectiveOptionalMain] = useState<any>([]);
    const [electiveOptionalChild, setelectiveOptionalChild] = useState<any>([]);

    const changeElective1 = (e, subjectId) => {
        setelective1([subjectId])
    };
    const changeElective2 = (e, subjectId) => {
        setelective2([subjectId])
    };
    const changeElective3 = (e, subjectId) => {
        setelective3([subjectId])
    };

    const changeElectiveOptionalMain = (e, subjectId) => {
        setelectiveOptionalMain([subjectId])
    };

    function clearElectiveOptionalMain() {
        setelectiveOptionalMain([])
        var node_list: any = document.getElementsByClassName('elective-main');
        for (var i = 0; i < node_list.length; i++) {
            node_list[i].checked = false;
        }
    }

    const changeElectiveOptionalChild = (e, subjectId) => {
        setelectiveOptionalChild([subjectId])
    };
    function clearElectiveOptionalChild() {
        setelectiveOptionalChild([])
        var node_list: any = document.getElementsByClassName('elective-child');
        for (var i = 0; i < node_list.length; i++) {
            node_list[i].checked = false;
        }

    }

    const optx = [1, 7];

    return (
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                    <Form
                        layout="vertical"
                        id="assignSearch"
                        onFinish={assignSearch}
                        form={assignSearchForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Form.Item
                                    name="classConfigurationId"
                                    label="Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Select class list" },
                                    ]}
                                >
                                    <SelectClassConfig onChange={(e) => onChangeClassList(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Form.Item
                                    name="classGroup"
                                    className="title-Text"
                                    label="Group"
                                    rules={[
                                        { required: true, message: "Select group list" },
                                    ]}
                                >
                                    <SelectGroupbySection />

                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <br />
              
                <Skeleton loading={loading} paragraph={{rows:10}}/>
              {forthSubjectAssignList!==null &&
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} style={{ display: forthSubjectAssignList.length > 0 ? "block" : "none" }}>
                    <TableView
                        antTableProps={{
                            rowSelection: rowSelection2,
                            showHeader: true,
                            columns: assignTableColmn,
                            rowKey: "studentId",
                            dataSource: forthSubjectAssignList,
                            filterData: forthSubjectAssignList,
                            pagination: false,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                </Col>
                }
                <br />
                {forthSubjectAssignList.length > 0 ?
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                        <Button type="primary" icon={<SaveOutlined />} style={{ float: "right" }} onClick={() => assignModalOpen()} >
                            Process
                        </Button>
                    </Col>
                    : ''}
                <Modal
                    title="Select Subject"
                    visible={isModalVisible}
                    onOk={(e)=>assignSubject(e,[...elective1, ...elective2, ...elective3, ...electiveOptionalMain], electiveOptionalChild)}
                    onCancel={() => setIsModalVisible(false)}
                    cancelText="Close"
                    okText="Assign"
                    centered
                >
                    <Row>
                        {subjectConfigurationList.filter(item => item?.subjectStatus === 3)?.length > 0 &&
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <h4>Elective (Group-A):</h4>
                                <hr />
                                <div className="no-margin-label">
                                    <ul style={{ paddingLeft: "0" }}>
                                        {subjectConfigurationList?.filter(item => item?.subjectStatus === 3).map((final, key) => {
                                            return (
                                                <li key={key} className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock ">

                                                    <input
                                                        type="radio"
                                                        name='elective-1'
                                                        className='elective1'
                                                        required
                                                        // disabled={optionalSubjectIds.includes(final?.subjectId)}
                                                        onChange={(e) => changeElective1(e, final?.subjectId)}
                                                    />
                                                    {final?.subjectName}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </Col>
                        }
                        {subjectConfigurationList.filter(item => item?.subjectStatus === 5)?.length > 0 &&
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <h4>Elective (Group-B):</h4>
                                <hr />
                                <div className="no-margin-label">
                                    {subjectConfigurationList?.filter(item => item?.subjectStatus === 5).map((final, key) => {
                                        return (
                                            <li key={key} className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock ">

                                                <input
                                                    type="radio"
                                                    name='elective-2'
                                                    className='elective2'
                                                    required
                                                    // disabled={optionalSubjectIds.includes(final?.subjectId)}
                                                    onChange={(e) => changeElective2(e, final?.subjectId)}
                                                />
                                                {final?.subjectName}
                                            </li>
                                        )
                                    })}
                                </div>
                            </Col>
                        }
                        {subjectConfigurationList.filter(item => item?.subjectStatus === 6)?.length > 0 &&
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <h4>Elective (Group-C):</h4>
                                <hr />
                                <div className="no-margin-label">
                                    {subjectConfigurationList?.filter(item => item?.subjectStatus === 6).map((final, key) => {
                                        return (
                                            <li key={key} className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock ">

                                                <input
                                                    type="radio"
                                                    name='elective-3'
                                                    className='elective3'
                                                    required
                                                    // disabled={optionalSubjectIds.includes(final?.subjectId)}
                                                    onChange={(e) => changeElective3(e, final?.subjectId)}
                                                />
                                                {final?.subjectName}
                                            </li>
                                        )
                                    })}
                                </div>
                            </Col>
                        }
                    </Row>
                    <Row>
                        {subjectConfigurationList.filter(item => item?.subjectStatus === 7)?.length > 0 &&
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <h4>Elective:</h4>
                                <hr />
                                <div className="no-margin-label">
                                    <ul style={{ paddingLeft: "0" }}>
                                        {subjectConfigurationList?.filter(item => item?.subjectStatus === 7).map((final, key) => {
                                            return (
                                                <li key={key} className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock ">

                                                    <input
                                                        type="radio"
                                                        name='elective-main'
                                                        className='elective-main'
                                                        required
                                                        disabled={electiveOptionalChild.includes(final?.subjectId)}
                                                        onChange={(e) => changeElectiveOptionalMain(e, final?.subjectId)}
                                                    />
                                                    {final?.subjectName}
                                                </li>
                                            )
                                        })}
                                        <span style={{ color: 'blue', marginLeft: 5, cursor: "pointer" }} onClick={clearElectiveOptionalMain}>Clear Selection</span>
                                    </ul>
                                </div>
                            </Col>
                        }
                        {subjectConfigurationList.filter((item) => { return optx.includes(item.subjectStatus) })?.length > 0 &&
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <h4>4th Subject:</h4>
                                <hr />
                                <div className="no-margin-label">
                                    <ul style={{ paddingLeft: "0" }}>
                                        {subjectConfigurationList.filter((item) => { return optx.includes(item.subjectStatus) }).map((final, key) => {
                                            return (
                                                <li key={key} className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock ">

                                                    <input
                                                        type="radio"
                                                        name='elective-child'
                                                        className='elective-child'
                                                        key={key}
                                                        required
                                                        disabled={electiveOptionalMain.includes(final?.subjectId)}
                                                        onChange={(e) => changeElectiveOptionalChild(e, final?.subjectId)}
                                                    />
                                                    {final?.subjectName}
                                                </li>
                                            )
                                        })}
                                        <span style={{ color: 'blue', marginLeft: 5, cursor: "pointer" }} onClick={clearElectiveOptionalChild}>Clear Selection</span>
                                    </ul>
                                </div>
                            </Col>
                        }
                    </Row>
                </Modal>
            </Row>

        </>
    )
}

