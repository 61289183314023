import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, DatePicker, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Column from 'rc-table/lib/sugar/Column';
import { DeleteOutlined, FilePdfOutlined, SaveOutlined, SwitcherOutlined, UploadOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import moment from 'moment';

export default function StaffFileUpload() {

    const staffTxtFile = useStoreActions((state) => state.staff.staffTxtFile);
    const staffTxtFileSave = useStoreActions((state) => state.staff.staffTxtFileSave);
    const staffTxtFileList = useStoreState((state) => state.staff.staffTxtFileList);
    const loading = useStoreState((state) =>  state.staff.loading);

    const columns = [
        { title: 'User Id', dataIndex: 'userId', key: "userId", showOnResponse: true, showOnDesktop: true },
        { title: 'Punch Date', dataIndex: 'punchDate', key: "punchDate", showOnResponse: true, showOnDesktop: true },
        { title: 'Punch Time', dataIndex: 'punchTime', key: "punchTime", showOnResponse: true, showOnDesktop: true },
        { title: 'Device Serial', dataIndex: 'deviceSerial', key: "deviceSerial", showOnResponse: true, showOnDesktop: true },

    ];


    async function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    const [isFile, setIsFile] = useState<boolean>(false);
    const [fileContent, setfileContent] = useState<any>('');

    function clearFileInput(ctrl) {
        try {
            ctrl.value = null;
        } catch (ex) { }
        if (ctrl.value) {
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    }
    const uploadFile = (file: any) => {
        if (file.target.files[0]?.size > 1000000) {
            notification.error({ message: 'Text size should be less than 1MB' })
            // file.target.value = null;
            clearFileInput(document.getElementById("upload-file"));
            setIsFile(false);
            setfileContent('');
            return;
        } else {
            setIsFile(true);
            setfileContent(file.target.files[0]);
        }


    }

    const [form] = Form.useForm();
    const formSubmit = (value) => {
        console.log(value);
        console.log(isFile);
        if (isFile === false) {
            notification.error({ message: 'Please upload a file' })
            return;
        } else {
            let payload = {
                "file": fileContent,
                "date": moment(attendanceDate).format("YYYY-MM-DD")
            }
            staffTxtFile(payload);
            clearFileInput(document.getElementById("upload-file"));
            setIsFile(false);
            form.resetFields();
        }
    }

    const [attendanceDate, setattendanceDate] = useState<any>(moment(new Date(), 'DD/MM/YYYY'));

    const saveAttendance = () => {
        let details = staffTxtFileList?.map(function (item) {
            return {
                "userId": item.userId,
                "punchDateString": item.punchDate,
                "punchTimeString": item.punchTime,
            }
        });

        staffTxtFileSave({
            attendanceDate: moment(attendanceDate).format("YYYY-MM-DD"),
            details: details
        })
    }
    return (
        <>
            <Card title="Device Data Upload">
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="classConfigId"
                                label="Attendance Date"
                                className="title-Text"

                            >

                                <DatePicker
                                    style={{ width: 300, height: 40 }}
                                    defaultValue={attendanceDate}
                                    placeholder="Select Date"
                                    format={"DD/MM/YYYY"}
                                    onChange={(e) => setattendanceDate(e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <div >
                                <div className="ant-col ant-form-item-label"><label className="ant-form-item-required" >Upload Text File</label></div>
                                <input className='ant-input' type="file" accept="text/plain" id="upload-file" onChange={uploadFile} />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<UploadOutlined />}>
                                    Upload
                                </Button>
                            </Space>
                        </Col>                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" style={{ height: 40 }} icon={<SaveOutlined />} onClick={saveAttendance}>
                                    Save
                                </Button>
                            </Space>
                        </Col>

                    </Row>
                </Form>
                <Skeleton loading={loading} paragraph={{rows:10}}/>
                {staffTxtFileList !== null && 
                    <Row className="m-t-mo-30">
                        <Col span={24}>
                            <div className="datatable-responsive-demo">
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: staffTxtFileList,
                                        filterData: staffTxtFileList,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "routineId",
                                    }}
                                    mobileBreakPoint={768}
                                />
                            </div>
                        </Col>
                    </Row>
                }
            </Card>

        </>
    )
}