import { useState } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Upload, DatePicker, Skeleton } from 'antd'
import TableView from '../../../../contents/AntTableResponsive';
import { SaveOutlined, SearchOutlined, SendOutlined, SettingOutlined, UploadOutlined } from '@ant-design/icons';
import xlsxParser from 'xlsx-parse-json';
import XLSX from "xlsx";
import $ from 'jquery';
import Table from "../../../../contents/AntTableResponsive";
import { SelectLedgerAll } from '../../../select/SelectLedgerAll';
import moment from 'moment';

export default function FeePaidSms() {

    const [form] = Form.useForm();
    const fetchFeePaidBillSmsReportData = useStoreActions((state) =>  state.sms.fetchFeePaidBillSmsReportData);
    const feePaidBillSmsReportData =useStoreState((state) => state.sms.feePaidBillSmsReportData);
    const sendFeePaidBillSms = useStoreActions((state) => state.sms.sendFeePaidBillSms)
    const [shortCodeSelections, setShortCodeSelections] = useState([]);
    const [selectedSmsValue, setselectedSmsValue] = useState<any>([]);
    const loading = useStoreState((state) =>  state.sms.loading);

    const searchFeePaidBill = (value) => {
        let postData:any = {
            leadgerId : value.selectLeadger,
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
        }
        fetchFeePaidBillSmsReportData(postData);
    }

    const onSelectChangeSectionDueDetails = (selectedRowKeys, value) => {
        setShortCodeSelections(selectedRowKeys);
        setselectedSmsValue(value);
    };


    const studentRowSelection = {
        shortCodeSelections,
        onChange: onSelectChangeSectionDueDetails,
    };

    const columns = [
        {title: 'Fee Invoice ID', dataIndex: 'feeInvoiceId', key: 'feeInvoiceId', showOnResponse: true, showOnDesktop: true},
        {title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true},
        {title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true},
        {title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true},
        {title: 'Class', dataIndex: 'className', key: 'className', showOnResponse: true, showOnDesktop: true},
        {title: 'Section', dataIndex: 'sectionName', key: 'sectionName', showOnResponse: true, showOnDesktop: true},
        {title: 'Payment Date', dataIndex: 'paymentDate', key: 'paymentDate', showOnResponse: true, showOnDesktop: true},
        {title: 'Payment Leadger', dataIndex: 'ledgerName', key: 'ledgerName', showOnResponse: true, showOnDesktop: true},
        {title: 'Paid Amount', dataIndex: 'paidAmount', key: 'paidAmount', showOnResponse: true, showOnDesktop: true}
    ];

    const sendSmsToFeePaidBillList = (e) => {
        let postDataArray:any = []
        let instiuteName:any = localStorage.getItem('insinfo');
        
        selectedSmsValue.map((item:any, index) => {
            postDataArray.push({
                "identificationId": item.identificationId,
                "message": "TAKA "+item?.paidAmount+"  Received From "+item?.studentName+" To "+JSON.parse(instiuteName)?.instituteName
            });
        });
        if (selectedSmsValue?.length > 0) {
            sendFeePaidBillSms(postDataArray);
        }else{
            notification.error({ message: "Select row first" })
        }
        
    }


    return(
        <>
            <Card title="Fee Paid SMS">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 16 , offset:4 }} lg={{ span: 16, offset: 4 }} xl={{ span: 16, offset: 4 }}>
                        <Form
                            layout="vertical"
                            onFinish={searchFeePaidBill}
                            id="basic-info"
                            form={form}
                        >
                            <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="selectLeadger"
                                    label="Ledger"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select ledger" },
                                    ]}
                                >
                                    <SelectLedgerAll />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="fromDate"
                                    label="From Date"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select from date" },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="Select from date" format={"DD/MM/YYYY"} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="toDate"
                                    label="To Date"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select to date" },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="Select to date" format={"DD/MM/YYYY"} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{rows:10}} />
                {feePaidBillSmsReportData.length > 0 ? 
                <Row>
                    <br />
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>
                        <div className="datatable-responsive-demo" style={{ width: "100%", overflow: "auto" }}>
                            <Table
                                antTableProps={{
                                    showHeader: true,
                                    rowSelection: studentRowSelection,
                                    columns: columns,
                                    dataSource: feePaidBillSmsReportData,
                                    filterData: feePaidBillSmsReportData,
                                    pagination: false,
                                    bordered: true,
                                    rowKey: "feeInvoiceId",
                                }}
                                mobileBreakPoint={768}

                            />
                        </div>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>
                        <Popconfirm
                            title="Are you want to send?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={(e) => sendSmsToFeePaidBillList(e)}
                        >
                            <Tooltip title="Send">
                                <Button type="primary" className="pull-right" icon={<SendOutlined />} >Send SMS</Button>
                            </Tooltip>
                        </Popconfirm>
                        
                    </Col>
                </Row>
                : ""}
            </Card>
        </>
    )
}