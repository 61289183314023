import { Card, Tabs } from 'antd'
import React from 'react'
import ColllectClassWise from './ColllectClassWise.page'
import ColllectSingle from './ColllectSingle.page'
const { TabPane } = Tabs;

export default function CollectPaySlip(props) {

    const [activeTab, setActiveTab] = React.useState<any>("1");

    return (
        <>
            <Card title="Collect Payslip" >
                <ColllectSingle />
                {/* <Tabs defaultActiveKey="1" onChange={(e) => { setActiveTab(e) }} >
                    <TabPane tab="Single" key="1">
                    {activeTab === "1" && <ColllectSingle /> }
                    </TabPane>
                    <TabPane tab="Class-wise" key="2">
                    {activeTab === "2" && <ColllectClassWise /> }
                    </TabPane>
                </Tabs> */}
            </Card>
        </>
    )
}
