import { post, get, del} from "../http";


export const createClassWisePayslip = (payload) => post("/payslip/create/class-wise", payload);
export const collectPayslip = (payload) => post("/payslip/collection/batch", payload);

export const fetchSinglePaySlipOld = (payload) => get(`/payslip/report/list/single?customStudentId=${payload?.customStudentId}&semesterYearId=${payload?.semesterYearId}`);
export const fetchSinglePaySlip = (payload) => get(`/payslip/view/single/student?customStudentId=${payload?.customStudentId}&academicYear=${payload?.academicYear}&payslipStatus=${payload?.payslipStatus}`);
export const fetchMultiPlePaySlip = (payload) => get(`/payslip/report/list/class-department?classDepartmentConfigId=${payload.classDepartmentConfigId}&semesterYearId=${payload.semesterYearId}&sessionId=${payload.sessionId}`);
export const deletePayslip = (payload) => del("/payslip/delete/unpaid?payslipId="+payload);
export const deletePayslipPaid = (payload) => del("/payslip/delete/paid/unpaid?payslipId="+payload);

export const fetchdateToDateFeeHeadCollection = (payload) => get(`/student/accounts-report/date-to-date/fee-head/collection?fromDate=${payload.fromDate}&toDate=${payload.toDate}`);
export const fetchclassDepartmentDateToDateInvoiceList = (payload) => get(`/student/accounts-report/invoice/list/class-department?classDepartmentConfigId=${payload.classDepartmentConfigId}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`);
export const fetchledgerByDateToDateInvoiceList = (payload) => get(`/student/accounts-report/invoice/list/ledger-by?fromDate=${payload.fromDate}&toDate=${payload.toDate}&ledgerIds=${payload.ledgerIds}`);
export const downloadPayslip = (payload) => get(`/payslip/download/batch?payslipIds=${payload}`);


export const fetchdateToDateStudentFeeHeadCollection = (payload) => post("/student/accounts-report/date-to-date/student/fee-head/collection", payload);

export const fetchdefaultPayslipTemplateList = () => get(`/payslip/template/default/list`);
export const fetchviewPayslipTemplate = () => get(`/payslip/template/view`);
export const savePayslipTemplate = (payload) => post(`/payslip/template/save?payslipDefaultId=${payload}`);

export const createBatchPayslip = (payload) => post("/payslip/create/batch", payload);

export const fetchviewByPayslipId = (payload) => get(`/payslip/view/by/pay-slip/id?payslipId=${payload}`);
export const savepayslipUpdateDate = (payload) => post(`/payslip/update/date?payslipDate=${payload?.payslipDate}&payslipId=${payload?.payslipId}`);