import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Column from 'rc-table/lib/sugar/Column';
import { DeleteOutlined, FilePdfOutlined, SaveOutlined, SwitcherOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import { SelectClassConfig } from '../../select/SelectClassConfig';

export default function ClassRoutine() {

    const fetchclassRoutineList = useStoreActions((state) => state.common.fetchclassRoutineList);
    const fetchclassRoutineView = useStoreActions((state) => state.common.fetchclassRoutineView);
    const classRoutineSave = useStoreActions((state) => state.common.classRoutineSave);
    const classRoutineDelete = useStoreActions((state) => state.common.classRoutineDelete);
    const classRoutineList = useStoreState((state) => state.common.classRoutineList);
    const classRoutineView = useStoreState((state) => state.common.classRoutineView);
    useEffect(() => {
        fetchclassRoutineList();
    }, []);
    const loading = useStoreState((state) =>  state.common.loading);



    const columns = [
        { title: 'Class Name', dataIndex: 'className', key: "className", showOnResponse: true, showOnDesktop: true },
        { title: 'Shift Name', dataIndex: 'shiftName', key: "shiftName", showOnResponse: true, showOnDesktop: true },
        { title: 'Section', dataIndex: 'sectionName', key: "sectionName", showOnResponse: true, showOnDesktop: true },
        {
            title: 'Action', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record: any, index) =>
                <>

                    <Space size="middle">

                        <Tooltip title="View Pdf">
                            <Button type='primary' icon={<FilePdfOutlined />} onClick={() => {fetchclassRoutineView(record?.routineId); setIsModalVisible(true)}} />
                        </Tooltip>
                        <Popconfirm
                            title="Are you sure you want to delete this item?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => classRoutineDelete(record?.routineId)}
                        >
                            <Tooltip title="Active">
                                <Button danger icon={<DeleteOutlined />} />
                            </Tooltip>
                        </Popconfirm>
                    </Space>

                </>
        },
    ];

    console.log(classRoutineView)
    async function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    const [isFile, setIsFile] = useState<boolean>(false);
    const [fileContent, setfileContent] = useState<any>('');

    function clearFileInput(ctrl) {
        try {
            ctrl.value = null;
        } catch (ex) { }
        if (ctrl.value) {
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    }
    const uploadPdf = (file: any) => {
        if (file.target.files[0]?.size > 1000000) {
            notification.error({ message: 'PDF size should be less than 2MB' })
            // file.target.value = null;
            clearFileInput(document.getElementById("upload-file"));
            setIsFile(false);
            setfileContent('');
            return;
        };

        getBase64(file.target.files[0], (imageUrl) => {
            setfileContent(imageUrl.split(',')[1]);
            setIsFile(true);
        })

    }

    const [form] = Form.useForm();
    const formSubmit = (value) => {
        console.log(value);
        console.log(isFile);
        if (isFile === false) {
            notification.error({ message: 'Please upload a file' })
            return;
        } else {
            let payload = {
                "classConfigId": value?.classConfigId,
                "fileContent": fileContent
            }
            classRoutineSave(payload);
            clearFileInput(document.getElementById("upload-file"));
            setIsFile(false);
            form.resetFields();
        }
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    return (
        <>
            <Card title="Class Routine">
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={1} xl={1}> </Col>

                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item
                                name="classConfigId"
                                label="Select Section"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                            >

                                <SelectClassConfig />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <div >
                                <div className="ant-col ant-form-item-label"><label className="ant-form-item-required" >Upload Pdf</label></div>
                                <input className='ant-input' type="file" accept="application/pdf" id="upload-file" onChange={uploadPdf} />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                                    Save
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}>
                            <Button type="primary" onClick={() => window.open('https://res.cloudinary.com/dnyoqhb7g/raw/upload/v1624253035/Routine/Student_Daily_routine_vertical.docx', '_blank')}> Sample Download 1</Button>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={1} xl={1}> </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}>
                            <Button type="primary" onClick={() => window.open('https://res.cloudinary.com/dnyoqhb7g/raw/upload/v1624253034/Routine/Student_Daily_Routine.docx', '_blank')}>Sample Download 2</Button>
                        </Col>
                    </Row>
                </Form>
                { classRoutineList=== null && < Skeleton loading={loading} paragraph={{rows:10}} />}
              { classRoutineList!== null &&
                <Row className="m-t-mo-30">
                    <Col span={24}>
                        <div className="datatable-responsive-demo">
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: classRoutineList,
                                    filterData: classRoutineList,
                                    pagination: true,
                                    bordered: true,
                                    rowKey: "routineId",
                                }}
                                mobileBreakPoint={768}
                            />
                        </div>
                    </Col>
                </Row>
}
            </Card>
            <Modal
                title="PDF Viewer"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                footer={null}
                width={'80%'}
                centered
            >
                <object id="pdf" data={"data:application/pdf;base64,"+classRoutineView} type="application/pdf" width="100%" height="600px"></object>
            </Modal>
        </>
    )
}