import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker, Radio } from 'antd';
import idCardTemplateOne from "../../../../../assets/images/ID_1.png";
import idCardTemplateTwo from "../../../../../assets/images/ID_2.png";
import idCardTemplateThree from "../../../../../assets/images/ID_3.png";
import idCardTemplateFour from "../../../../../assets/images/ID_4.png";
import idCardTemplateFive from "../../../../../assets/images/ID_5.png";
import idCardTemplateSix from "../../../../../assets/images/ID_6.png"; 
import idCardTemplateSeven from "../../../../../assets/images/ID_7.png"; 
import idCardTemplateEight from "../../../../../assets/images/ID_8.png"; 
import idCardTemplatNIu from "../../../../../assets/images/ID_9.jpg"; 

import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';


export default function IdCardTemplate() {
    const fetchIdCardTemplate = useStoreActions((state) => state.student.fetchIdCardTemplate);
    const idCardTemplateList = useStoreState((state) => state.student.idCardTemplateList);
    const idCardSaveTemplate = useStoreActions((state) => state.student.idCardSaveTemplate);
    const [templateDefaultValue, setTemplateDefaultValue] = useState<any>();
    useEffect( () => {
            fetchIdCardTemplate();
    }, []);

    useEffect(() => {
        setTemplateDefaultValue(idCardTemplateList?.templateId);
    }, [idCardTemplateList])

    const templateSaveMethod = (val) => {
        setTemplateDefaultValue(val);
        var postData:any = {
            templateId: val,
            barcodeValue: "Barcode value not found",
        }
        idCardSaveTemplate(postData);
    }
   
    return(
        <>

            {templateDefaultValue !== '' ? 
                <Card title="Student ID Card Template Configuration">
                    <Row>
                        <Col span={24}>
                            <Radio.Group size="large" style={{ width: "100%" }} className='cardTemplateWrapper' onChange={(e) =>  templateSaveMethod(e.target.value)}> 
                                <Row>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value={101} className={templateDefaultValue == 101 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateOne} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 1</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="102" className={templateDefaultValue == 102 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateTwo} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 2</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="103" className={templateDefaultValue == 103 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateThree} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 3</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="104" className={templateDefaultValue == 104 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateFour} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 4</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="105" className={templateDefaultValue == 105 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateFive} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 5</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="106" className={templateDefaultValue == 106 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateSix} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 6</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>                                   
                                     <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="107" className={templateDefaultValue == 107 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateSeven} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 7</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>                                     
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="108" className={templateDefaultValue == 108 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplateEight} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 8</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 4}}>
                                        <Radio.Button value="109" className={templateDefaultValue == 108 ? 'ant-radio-button-wrapper-checked' : ''}><img src={idCardTemplatNIu} className='img-responsive' alt="" />
                                            <div className='d-flex justify-content-center'>
                                                <strong>Template 9</strong>
                                            </div>
                                        </Radio.Button>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Card>
            : ''}
        </>
    )
}