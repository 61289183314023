import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, DatePicker } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { SelectAcademicYear4 } from '../../../select/SelectAcademicYear4';
import { SelectClass } from '../../../select/SelectClass';
import moment from 'moment';

const { Option } = Select;

export default function ResultPublicationEntry() {

    const [form] = Form.useForm();
    const saveResultPublishDate = useStoreActions((state) => state.exam.saveResultPublishDate);

    const fetchExamListByClassAndClassId2 = useStoreActions((state) => state.exam.fetchExamListByClassAndClassId2);
    const examListByClassAndClassId2 = useStoreState((state) => state.exam.examListByClassAndClassId2);




    const submitForm = (value) => {
        let postData = {
            "academicYear": value.academicYear,
            "examConfigurationId": value.examConfigurationId,
            "markEditStatus": value.markEditStatus,
            "markViewStatus": value.markViewStatus,
            "resultPublishDate": moment(value?.resultPublishDate).format("YYYY-MM-DD"),
            "resultPublishMessage": value.resultPublishMessage,
            "resultPublishStatus": value.resultPublishStatus,
            "workingDayEnd": moment(value?.workingDayEnd).format("YYYY-MM-DD"),
            "workingDayStart": moment(value?.workingDayStart).format("YYYY-MM-DD"),
        };
        saveResultPublishDate(postData);
        form.resetFields();
    };

    const onClassChange = (value) => {
        fetchExamListByClassAndClassId2(value);
    }


    return (
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 14, offset: 5 }} xl={{ span: 14, offset: 5 }}>
                    <Form
                        layout="vertical"
                        id="instructionForm"
                        onFinish={submitForm}
                        form={form}
                    >
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="academicYear"
                                    label="Academic Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please enter academic year" },
                                    ]}
                                >
                                    <SelectAcademicYear4 />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="selectClass"
                                    label="Select Class"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select class" },
                                    ]}
                                >
                                    <SelectClass onChange={(e) => onClassChange(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="examConfigurationId"
                                    label="Select Exam"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select exam" },
                                    ]}
                                >
                                    <Select placeholder="Exam List" style={{ width: "100%" }}>
                                        {examListByClassAndClassId2 ? (
                                            examListByClassAndClassId2.map((type, idx) => (
                                                <Option key={type.examConfigId} value={type.examConfigId}>
                                                    {type.examName}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching exam</Option>
                                        )}
                                    </Select>

                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="markEditStatus"
                                    label="Mark Edit Status"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select edit status" },
                                    ]}
                                >
                                    <Select placeholder="Mark Edit Status" style={{ width: "100%" }}>
                                        <Option value={1}>Yes</Option>
                                        <Option value="0">No</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="markViewStatus"
                                    label="Mark View Status"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select view status" },
                                    ]}
                                >
                                    <Select placeholder="Mark View Status" style={{ width: "100%" }}>
                                        <Option value={1}>Yes</Option>
                                        <Option value="0">No</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="resultPublishDate"
                                    label="Result Publish Date"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select date" },
                                    ]}
                                >

                                    <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="resultPublishMessage"
                                    label="Result Publish Message"
                                    className="title-Text"
                                    // rules={[
                                    //     { required: true, message: "Please input result publish message" },
                                    // ]}
                                >

                                    <Input placeholder="Result Publish Message" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="resultPublishStatus"
                                    label="Result Publish Status"
                                    className="title-Text"
                                    // rules={[
                                    //     { required: true, message: "Please select result publish status" },
                                    // ]}
                                >
                                    <Select placeholder="Result Publish Status" style={{ width: "100%" }}>
                                        <Option value={1}>Yes</Option>
                                        <Option value="0">No</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="workingDayStart"
                                    label="Working Day Start"
                                    className="title-Text"
                                    // rules={[
                                    //     { required: true, message: "Please select date" },
                                    // ]}
                                >

                                    <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="workingDayEnd"
                                    label="Working Day End"
                                    className="title-Text"
                                    // rules={[
                                    //     { required: true, message: "Please select date" },
                                    // ]}
                                >

                                    <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                    Save
                                </Button>
                            </Col>
                        </Row>



                    </Form>
                </Col>
            </Row>
        </>
    )
}