import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, TimePicker, DatePicker, Skeleton } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectClass } from '../../../select/SelectClass';
import { SelectSection } from '../../../select/SelectSection';
import { SelectShift } from '../../../select/SelectShift';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectPeriod } from '../../../select/SelectPeriod';
import moment from 'moment';
import { format } from 'path';


export default function TakeAttendanceUpdate() {
    const fetchStudentAttendanceAllPeriod = useStoreActions((state) => state.student.fetchStudentAttendanceAllPeriod);
    const fetchStudentAttendanceUpdateList = useStoreActions((state) => state.student.fetchStudentAttendanceUpdateList);
    const studentAttendanceUpdateList = useStoreState((state) => state.student.studentAttendanceUpdateList);
    const updateAttendanceInput = useStoreActions((state) => state.student.updateAttendanceInput);
    const [studentSearchForm] = Form.useForm();
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [selectedValue, setselectedValue] = useState<any>();
    const [periodSelect, setPeriodSelect] = useState<any>();
    const [selectSection, setSelectSection] = useState<any>();
    const [attendanceDate, setAttendanceDate] = useState<any>();
    const dateFormat = 'YYYY-MM-DD';
    const loading = useStoreState((state) =>  state.student.loading);

    const searchStudent = (value) => {
        let date = value.date;
        setSelectSection(value.sectionId);
        let postData:any = {
            attendanceDate : moment(date).format(dateFormat),
            classConfigId: value.sectionId,
            periodId: value.period
        }
        fetchStudentAttendanceUpdateList(postData);
        setAttendanceDate(moment(date._d).format(dateFormat));
        
        setPeriodSelect(value.period);        
    }

    useEffect(() => {
        fetchStudentAttendanceAllPeriod();
    }, [])

    const columns = [
        {
            title: 'Student ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Status',
            dataIndex: 'attendanceStatus',
            key: 'attendanceStatus',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <>
                    <span>{record.attendanceStatus == 1 ? 'Present' : 'Absent'}</span>
                </>
            ),
        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },
        Table.SELECTION_COLUMN,
    ];

    const attendanceProcess = () => {
 
        let detailsData = studentAttendanceUpdateList.map(item => {
            return {
                attendanceId: item.attendanceId,
                attendanceStatus: selectedValue.map(nitem => nitem.attendanceId).includes(item.attendanceId) ? 2 : 1
            }
        })
        let postdata:any ={
            payload: detailsData,
            payloadSecond: {
                attendanceDate :  moment(attendanceDate).format("YYYY-MM-DD"),
                classConfigId: selectSection,
                periodId: periodSelect
            }
        }
        updateAttendanceInput(postdata);
        setselectedRowKeys([]);
        setselectedValue([]);
    }

    const onSelectChange = (selectedRowKeys, value) => {
        
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        columnTitle: <div style={{ width: 150 }}>Check for Absent</div>,
        selectedRowKeys,
        onChange: onSelectChange,
    };

    useEffect(() => {
        let selected= studentAttendanceUpdateList.filter(item => item.attendanceStatus === 2).map(item => item.customStudentId);
        let selectedvaluex= studentAttendanceUpdateList.filter(item => item.attendanceStatus === 2);
        setselectedRowKeys(selected);
        setselectedValue(selectedvaluex);
    }, [studentAttendanceUpdateList])


    return <>
        <Card title="Take Student Attendance Update">
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }}>
                    <Form
                        layout="vertical"
                        id="studentSearchForm"
                        onFinish={searchStudent}
                        form={studentSearchForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="date"
                                    label="Select Date"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select date" },
                                    ]}
                                >
                                    <DatePicker style={{ width: "100%" }}  format={dateFormat}/>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="sectionId"
                                    label="Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectClassConfig />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="period"
                                    label="Select Period"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select period" },
                                    ]}
                                >
                                    <SelectPeriod />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{rows:10}}/>
            <Row className="m-t-mo-30" style={{ display: studentAttendanceUpdateList.length > 0 ? '' : 'none' }}>
                <Col span="24">
                    <div className="datatable-responsive-demo" >
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: studentAttendanceUpdateList,
                                filterData: studentAttendanceUpdateList,
                                pagination: false,
                                bordered: true,
                                rowKey: "customStudentId",
                                rowSelection: rowSelection,
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                    <Button style={{ float: "right" }} type="primary" onClick={() => attendanceProcess()}>Update</Button>
                </Col>
            </Row>
            
        </Card>
    </>
}