import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectSection } from '../../../select/SelectSection';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag} from 'antd';
import TableView from '../../../../contents/AntTableResponsive';
import { v4 as uuidv4 } from "uuid";

export default function StudentDelete() {

    const [studentSearchForm] = Form.useForm();
    const fetchRegistrationstudentForDelete = useStoreActions((state) => state.student.fetchRegistrationstudentForDelete);
    const registrationstudentForDeleteList = useStoreState((state) => state.student.registrationstudentForDeleteList);
    const deleteRegistrationStudent = useStoreActions((state)=> state.student.deleteRegistrationStudent);
    const [studentList, setStudentList] = useState([]);
    const [searchData, setSearchData] = useState<any>();

    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [selectedValue, setselectedValue] = useState([]);
    const loading = useStoreState((state) =>  state.student.loading);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const formSubmit = (value) => {
        let postData:any = {
            classconfigid : value.sectionId,
            academicYear: value.yearId
        }
        fetchRegistrationstudentForDelete(postData);
        setStudentList(registrationstudentForDeleteList);
        setSearchData(postData);
    }


    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true  },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true  },
        { title: 'Group', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Father', dataIndex: 'fatherName', key: 'fatherName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Mother', dataIndex: 'motherName', key: 'motherName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Gender', dataIndex: 'studentGender', key: 'studentGender', showOnResponse: true, showOnDesktop: true  },
        { title: 'Religion', dataIndex: 'studentReligion', key: 'studentReligion', showOnResponse: true, showOnDesktop: true  },
        { title: 'Mobile No.', dataIndex: 'guardianMobile', key: 'guardianMobile', showOnResponse: true, showOnDesktop: true  },
    ];

    const deleteStudent = () => {
        let payload = {
            searchData : searchData, 
            postData :{
                "identificationIds": selectedRowKeys
            }
        }
        deleteRegistrationStudent(payload);
        
    }

    return (
        <>
            <Card title="Student Registration Delete">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={studentSearchForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="yearId"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]} 
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="sectionId"
                                        label="Select Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]} 
                                    >
                                        <SelectClassConfig />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: registrationstudentForDeleteList.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns:studentListColumn,
                                rowKey:"identificationId",
                                dataSource: registrationstudentForDeleteList,
                                filterData: registrationstudentForDeleteList,
                                pagination: false,
                                bordered: true,
                                rowSelection: rowSelection,                                
                            }}
                            mobileBreakPoint={768}
                        />
                        <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button>
                    </Col>
                </Row>
            </Card>
        </>
    )
}