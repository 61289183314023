import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../../../store/hooks/easyPeasy';
import { Table, Tag} from 'antd';
import TableView from '../../../../../contents/AntTableResponsive';
import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import Column from 'rc-table/lib/sugar/Column';
import { render } from '@testing-library/react';
import { SelectClass } from '../../../../select/SelectClass';
import MarkSheet from './MarkSheet';
import RemarksTemplate from './RemarksTemplate';



export default function ExamTemplate(){
    
    const { TabPane } = Tabs;
    

    return(
        <>
            <Card title="Marksheet Template Configurations" bordered={false}>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Marksheet" key="1">
                        <MarkSheet />
                    </TabPane>
                    <TabPane tab="Remark" key="2">
                        <RemarksTemplate />
                    </TabPane>
                </Tabs>
                
            </Card>
            
        </>
    )
}