import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../../store/hooks/easyPeasy';
import Table from "../../../../../contents/AntTableResponsive";
import { SelectSession } from '../../../../select/SelectSession';
import { SelectClassDepartment } from '../../../../select/SelectClassDepartment';
import { SelectSemesterYearByClassDepartment } from '../../../../select/SelectSemesterYearByClassDepartment';
import { SelectFeeHead } from '../../../../select/SelectFeeHead';
import { SelectFeeWaiver } from '../../../../select/SelectFeeWaiver';


export default function WaiverConfigList(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const feeheadDeletedList = useStoreState((state) => state.saccount.feeheadDeletedList);
    const fetchfeeheadDeletedList = useStoreActions((state) => state.saccount.fetchfeeheadDeletedList);
    const deletestudentFeeHeadLedgerConfiguration = useStoreActions((state) => state.saccount.deletestudentFeeHeadLedgerConfiguration);
    const updateStudentWaiver = useStoreActions((state) => state.saccount.updateStudentWaiver);


    // useEffect(() => {
    //     fetchfeeHeadList();
    // }, [])

    const formSubmit = (value) => {
        fetchfeeheadDeletedList(value)
    }


    const updateFomrSubmit = (value) => {
        value.assignId = assignId;
        value.classDeptConfId = dep;
        value.semesterYear = semy;
        value.sessionId = session;
        updateStudentWaiver(value);
        setIsModalVisible(false);
    }

    const [assignId, setassignId] = useState<any>();

    const columns = [


        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Class',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Fee Head',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Semester Year',
            dataIndex: 'semesterYear',
            key: 'semesterYear',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Action',
            key: 'studentId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">

                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deletestudentFeeHeadLedgerConfiguration({
                            id: record?.id,
                            classDeptConfId: dep,
                            semesterYear: semy,
                            sessionId: session,
                        })}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [session, setsession] = useState<any>();
    const [dep, setDep] = useState<any>();
    const [semy, setsemy] = useState<any>();
    const [waiver, setWaiver] = useState<any>();

    return (
        <>

            <Form
                layout="vertical"
                onFinish={formSubmit}
                id="basic-info"
                form={form}
            >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="sessionId"
                            label="Session"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select session " },
                            ]}
                        >

                            <SelectSession selected={session} onChange={e => setsession(e)} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="classDeptConfId"
                            label="Class Department"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please class department" },
                            ]}
                        >

                            <SelectClassDepartment selected={dep} onChange={e => { setDep(e); setsemy(null) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="semesterYear"
                            label="Semester Year"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select year" },
                            ]}
                        >

                            <SelectSemesterYearByClassDepartment selected={semy} onChange={e => { setsemy(e) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                        <Space size="small" >
                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                Search
                            </Button>
                        </Space>
                    </Col>

                </Row>
            </Form>

            {feeheadDeletedList?.length > 0 &&
                <div className="datatable-responsive">
                    {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: feeheadDeletedList,
                            filterData: feeheadDeletedList,
                            pagination: false,
                            bordered: true,
                            rowKey: "assignId"
                        }}
                        mobileBreakPoint={768}
                    />
                </div>
            }


            <Modal
                title="Student Waiver"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Save"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >


                    <Form.Item
                        name="waiverId"
                        label="Fee Waiver:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select fee waiver" },
                        ]}
                    >
                        <SelectFeeWaiver selected={waiver} onChange={e => setWaiver(e)} />
                    </Form.Item>

                    <Form.Item
                        name="amount"
                        label="Amount:"
                        className="title-Text"
                    // rules={[
                    //     { required: true, message: "Please insert fee amount" },
                    // ]}
                    >
                        <Input placeholder="Amount" />
                    </Form.Item>



                </Form>

            </Modal>
        </>
    )
}
