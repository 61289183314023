import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { SelectClass } from '../../../select/SelectClass';
import { SelectGroupMultiple } from '../../../select/SelectGroupMultiple';

import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';
import TableView from '../../../../contents/AntTableResponsive';


export default function GroupConfiguration() {
    const fetchGroupConfigList = useStoreActions((state) => state.student.fetchGroupConfigList);
    const createGroupConfiguration = useStoreActions((state) => state.student.createGroupConfiguration);
    const updateGroupConfigurationSerial = useStoreActions((state) => state.student.updateGroupConfigurationSerial);
    const groupConfigList = useStoreState((state) => state.student.groupConfigList);
    const deleteGroupConfig = useStoreActions((state) => state.student.deleteGroupConfig);
    const [formS] = Form.useForm();
    const [updateForm] = Form.useForm();
    const loading = useStoreState((state) =>  state.student.loading);



    const formSubmit = (value) => {
        createGroupConfiguration(value)
        formS.resetFields();
    }
    const [tableData, setTableData] = useState<any>([])

    useEffect(() => {
        fetchGroupConfigList();
    }, []);

    useEffect(() => {
        setTableData(groupConfigList)
    }, [groupConfigList])

    const onchangeValue: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...tableData];
            newData[index][key] = e;
            setTableData(newData);
        };

    const columns = [
        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <InputNumber
                    min={0}
                    onChange={onchangeValue("serial", record, index)}
                    value={record.serial}
                    placeholder="Serial"
                // style={{ width: 250 }}
                ></InputNumber>
            ),
        },
        {
            title: 'Class Name',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Group Name',
            dataIndex: 'groupName',
            key: 'groupName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Application Fee',
            dataIndex: 'applicationFee',
            key: 'applicationFee',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <InputNumber
                    min={0}
                    onChange={onchangeValue("applicationFee", record, index)}
                    value={record.applicationFee}
                    placeholder="Application Fee"
                // style={{ width: 250 }}
                ></InputNumber>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <>
                    <span>{record.status == 1 ? 'Enable' : 'Disable'}</span>
                </>
            )
        },
        {
            title: 'Action',
            key: 'id',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteGroupConfig(record?.id)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </Space>
            ),
        }
    ];

    const updateSerial = () => {
        let postdata = tableData.map(i => ({ serial: i?.serial, id: i?.id , applicationFee: i?.applicationFee}));
        updateGroupConfigurationSerial(postdata)
    }

    return <>
        <Card title="Group Configuration">
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }}>
                    <Form
                        layout="vertical"
                        id="classConfigInfo"
                        onFinish={formSubmit}
                        form={formS}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="classId"
                                    label="Class"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select class" },
                                    ]}
                                >
                                    <SelectClass />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="groupIds"
                                    label="Group"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select group" },
                                    ]}
                                >
                                    <SelectGroupMultiple />
                                </Form.Item>
                            </Col>
  
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                        Save
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{rows:10}} />
            <Row className="m-t-mo-30">
                <Col span="24">
                    <div className="datatable-responsive-demo">
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: tableData,
                                filterData:tableData,
                                pagination: false,
                                bordered: true,
                                rowKey: "id",
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                </Col>
            </Row>
            <Button style={{ float: "right" }} type="primary" onClick={() => updateSerial()}>Update Serial</Button>
        </Card>
    </>
}