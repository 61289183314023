
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton, Table } from 'antd'
import { DeleteOutlined, DownloadOutlined, EyeInvisibleOutlined, EyeOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import TableView from '../../../../contents/AntTableResponsive';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { FilePdfOutlined } from '@ant-design/icons';
import ReactExport from "react-export-excel";
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { Tabs } from 'antd';
import profileImage from '../../../../../assets/images/no-photo-available-icon-8.jpg';
import Text from 'antd/lib/typography/Text';
require('jspdf-autotable');

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

export default function StudentOverViewReportReport() {

    const { TabPane } = Tabs;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [classWiseReportForm] = Form.useForm();
    const fetchStudentOverviewReport = useStoreActions((state) => state.student.fetchStudentOverviewReport);
    const studentOverviewListReport = useStoreState((state) => state.student.studentOverviewListReport);
    const fetchStudentOverFeesReport = useStoreActions((state) => state.student.fetchStudentOverFeesReport);
    const fetchStudentOverFeesDueReport = useStoreActions((state) => state.student.fetchStudentOverFeesDueReport);
    const studentOverFeesListReport = useStoreState((state) => state.student.studentOverFeesListReport);
    const studentOverFeesListDueReport = useStoreState((state) => state.student.studentOverFeesListDueReport);
    const [modalHeader, setModalHeader] = useState<any>();
    const [basicInfo, setBasicInfo] = useState();
    const [className, setClassName] = useState<any>();
    const [shiftName, setShiftName] = useState<any>();
    const [sectionName, setSectionName] = useState<any>();
    const [studentRoll, setStudentRoll] = useState<any>();
    const [groupName, setGroupName] = useState<any>();
    const [currentYear, setCurrentYear] = useState<any>();
    const [studentId, setStudentId] = useState<any>();
    const [studentName, setStudentName] = useState<any>();
    const [studentGender, setStudentGender] = useState<any>();
    const [studentReligion, setStudentReligion] = useState<any>();
    const [studentDob, setStudentDOB] = useState<any>();
    const [fatherName, setFatherName] = useState<any>();
    const [motherName, setMotherName] = useState<any>();
    const [guardianMobile, setGuardianMobile] = useState<any>();
    const [mailingAddress, setMailingAddress] = useState<any>();
    const [postOffice, setPostOffice] = useState<any>();
    const [thanaName, setThanaName] = useState<any>();
    const [districtName, setDistrictName] = useState<any>();
    const loading = useStoreState((state) => state.student.loading);

    const formSubmit = (value) => {
        let postData: any = {
            academicYear: value.yearId,
            classconfigid: value.classId
        }
        fetchStudentOverviewReport(postData);
    }

    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Group', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true },
        {
            title: 'Action', dataIndex: 'fatherName', key: 'fatherName', showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Button type='primary' icon={<EyeOutlined />} onClick={() => {
                        setIsModalVisible(true);
                        setModalHeader(record.studentName);
                        setBasicInfo(record);
                        setClassName(record.className);
                        setShiftName(record.shiftName);
                        setSectionName(record.sectionName);
                        setStudentRoll(record.studentRoll);
                        setGroupName(record.groupName);
                        setStudentId(record.customStudentId);
                        setStudentName(record.studentName);
                        setStudentGender(record.studentGender);
                        setStudentReligion(record.studentReligion);
                        setStudentDOB(record.studentDOB);
                        setFatherName(record.fatherName);
                        setMotherName(record.motherName);
                        setGuardianMobile(record.guardianMobile);
                        setMailingAddress(record.mailingAddress);
                        setPostOffice(record.postOffice);
                        setThanaName(record.thanaName);
                        setDistrictName(record.districtName);
                        studentAccountInformationDetails(record.identificationId);
                    }} />
                </Space>
            ),
        },
    ];

    const basicFeesInfoColumn = [
        { title: 'Invoice ID', dataIndex: 'feeInvoiceId', key: 'feeInvoiceId', showOnResponse: true, showOnDesktop: true },
        { title: 'Fee Head', dataIndex: 'feeHeads', key: 'feeHeads', showOnResponse: true, showOnDesktop: true },
        { title: 'Fee Sub Head', dataIndex: 'feeSubHeads', key: 'feeSubHeads', showOnResponse: true, showOnDesktop: true },
        { title: 'Paid Amount', dataIndex: 'paidAmount', key: 'paidAmount', showOnResponse: true, showOnDesktop: true },
        { title: 'Status', dataIndex: 'paymentStatus', key: 'paymentStatus', showOnResponse: true, showOnDesktop: true },
        { title: 'Date', dataIndex: 'paymentDate', key: 'paymentDate', showOnResponse: true, showOnDesktop: true },
    ];

    const dueInfoColumn = [
        { title: 'Fee Head', dataIndex: 'feeHeadName', key: 'feeHeadName', showOnResponse: true, showOnDesktop: true },
        { title: 'Fee Sub Head', dataIndex: 'feeSubHeadName', key: 'feeSubHeadName', showOnResponse: true, showOnDesktop: true },
        { title: 'Current Payable', dataIndex: 'payableAmount', key: 'payableAmount', showOnResponse: true, showOnDesktop: true },
    ];

    const studentAccountInformationDetails = (id) => {
        fetchStudentOverFeesReport(id);
        fetchStudentOverFeesDueReport(id);
    }

    return (
        <>
            <Card title="Student Overview">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={classWiseReportForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="yearId"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear onChange={(e) => setCurrentYear(e)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="classId"
                                        label="Select Class"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select class" },
                                        ]}
                                    >
                                        <SelectClassConfig />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: studentOverviewListReport.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: studentListColumn,
                                rowKey: "identificationId",
                                dataSource: studentOverviewListReport,
                                filterData: studentOverviewListReport,
                                pagination: true,
                                bordered: true,
                            }}
                            mobileBreakPoint={768}
                        />
                    </Col>
                </Row>
            </Card>
            <Modal
                title={modalHeader}
                visible={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                okText="Ok"
                centered
                maskClosable={false}
                width="100%"
                destroyOnClose={true}
            >

                <Row>
                    <Col span={24}>
                        <Tabs defaultActiveKey="1" type="card" size="large">
                            <TabPane tab="Profile" key="1">
                                <Tabs tabPosition="left">
                                    <TabPane tab="Basic Information" key="1">
                                        <Row>
                                            <Col span={24}>
                                                <table style={{ width: "100%" }}>
                                                    <thead className="ant-table-thead">
                                                        <tr className="ant-table-row">
                                                            <th className="ant-table-cell" style={{ width: "150px", borderRight: "1px solid #f0f0f0" }}></th>
                                                            <th className="ant-table-cell" style={{ textAlign: "center", borderRight: "1px solid #f0f0f0" }}>Class</th>
                                                            <th className="ant-table-cell" style={{ textAlign: "center", borderRight: "1px solid #f0f0f0" }}>Shift</th>
                                                            <th className="ant-table-cell" style={{ textAlign: "center", borderRight: "1px solid #f0f0f0" }}>Section</th>
                                                            <th className="ant-table-cell" style={{ textAlign: "center", borderRight: "1px solid #f0f0f0" }}>Roll No.</th>
                                                            <th className="ant-table-cell" style={{ textAlign: "center", borderRight: "1px solid #f0f0f0" }}>Group</th>
                                                            <th className="ant-table-cell" style={{ textAlign: "center" }}>Academic Year</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="ant-table-tbody">
                                                        <tr className="ant-table-row">
                                                            <td className="ant-table-cell" style={{ textAlign: "center", borderRight: "1px solid #f0f0f0" }}><img src={profileImage} style={{ width: "150px" }} /></td>
                                                            <td className="ant-table-cell" style={{ textAlign: "center", borderRight: "1px solid #f0f0f0" }}>{className ? className : 'null'}</td>
                                                            <td className="ant-table-cell" style={{ textAlign: "center", borderRight: "1px solid #f0f0f0" }}>{shiftName ? shiftName : 'null'}</td>
                                                            <td className="ant-table-cell" style={{ textAlign: "center", borderRight: "1px solid #f0f0f0" }}>{sectionName ? sectionName : 'null'}</td>
                                                            <td className="ant-table-cell" style={{ textAlign: "center", borderRight: "1px solid #f0f0f0" }}>{studentRoll ? studentRoll : 'null'}</td>
                                                            <td className="ant-table-cell" style={{ textAlign: "center", borderRight: "1px solid #f0f0f0" }}>{groupName ? groupName : 'null'}</td>
                                                            <td className="ant-table-cell" style={{ textAlign: "center" }}>{currentYear ? currentYear : 'null'}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, }} xl={{ span: 12, }}>
                                                <table className='mt-30' style={{ width: "100%", border: "1px solid #f0f0f0" }}>
                                                    <tbody className="ant-table-tbody">
                                                        <tr className="ant-table-row">
                                                            <td className="ant-table-cell">Student ID</td>
                                                            <td className="ant-table-cell">:</td>
                                                            <td className="ant-table-cell">{studentId ? studentId : 'null'}</td>
                                                        </tr>
                                                        <tr className="ant-table-row">
                                                            <td className="ant-table-cell">Name</td>
                                                            <td className="ant-table-cell">:</td>
                                                            <td className="ant-table-cell">{studentName ? studentName : 'null'}</td>
                                                        </tr>
                                                        <tr className="ant-table-row">
                                                            <td className="ant-table-cell">Gender</td>
                                                            <td className="ant-table-cell">:</td>
                                                            <td className="ant-table-cell">{studentGender ? studentGender : 'null'}</td>
                                                        </tr>
                                                        <tr className="ant-table-row">
                                                            <td className="ant-table-cell">Religion</td>
                                                            <td className="ant-table-cell">:</td>
                                                            <td className="ant-table-cell">{studentReligion ? studentReligion : 'null'}</td>
                                                        </tr>
                                                        <tr className="ant-table-row">
                                                            <td className="ant-table-cell">Date of Birth</td>
                                                            <td className="ant-table-cell">:</td>
                                                            <td className="ant-table-cell">{studentDob ? studentDob : 'null'}</td>
                                                        </tr>
                                                        <tr className="ant-table-row">
                                                            <td className="ant-table-cell">Father's Name</td>
                                                            <td className="ant-table-cell">:</td>
                                                            <td className="ant-table-cell">{fatherName ? fatherName : 'null'}</td>
                                                        </tr>
                                                        <tr className="ant-table-row">
                                                            <td className="ant-table-cell">Mother's Name</td>
                                                            <td className="ant-table-cell">:</td>
                                                            <td className="ant-table-cell">{motherName ? motherName : 'null'}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tab="Address" key="2">
                                        <Row>
                                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, }} xl={{ span: 12, }}>
                                                <table className='mt-30' style={{ width: "100%", border: "1px solid #f0f0f0" }}>
                                                    <tbody className="ant-table-tbody">
                                                        <tr className="ant-table-row">
                                                            <td className="ant-table-cell">Mailing Address	</td>
                                                            <td className="ant-table-cell">:</td>
                                                            <td className="ant-table-cell">{mailingAddress ? mailingAddress : 'null'}</td>
                                                        </tr>
                                                        <tr className="ant-table-row">
                                                            <td className="ant-table-cell">Post Office</td>
                                                            <td className="ant-table-cell">:</td>
                                                            <td className="ant-table-cell">{postOffice ? postOffice : 'null'}</td>
                                                        </tr>
                                                        <tr className="ant-table-row">
                                                            <td className="ant-table-cell">Thana</td>
                                                            <td className="ant-table-cell">:</td>
                                                            <td className="ant-table-cell">{thanaName ? thanaName : 'null'}</td>
                                                        </tr>
                                                        <tr className="ant-table-row">
                                                            <td className="ant-table-cell">District</td>
                                                            <td className="ant-table-cell">:</td>
                                                            <td className="ant-table-cell">{districtName ? districtName : 'null'}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </Tabs>
                            </TabPane>
                            <TabPane tab="Accounts" key="2">
                                <Tabs tabPosition="left">
                                    <TabPane tab="Paid" key="1">
                                        <Row>
                                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                                                <TableView
                                                    antTableProps={{
                                                        showHeader: true,
                                                        columns: basicFeesInfoColumn,
                                                        rowKey: "feeInvoiceId",
                                                        dataSource: studentOverFeesListReport,
                                                        filterData: studentOverFeesListReport,
                                                        pagination: false,
                                                        style: { maxHeight: 500 },
                                                        bordered: true,
                                                        // scroll:{ y: 350 },
                                                       
                                                        summary: function () {
                                                            let topaidAmount = 0;

                                                            studentOverFeesListReport?.forEach(({ paidAmount }) => {
                                                                topaidAmount += paidAmount;
                                                            });

                                                            return (
                                                                <>
                                                                    <Table.Summary.Row>
                                                                        <Table.Summary.Cell index={0}><Text type="danger" strong>Total</Text> </Table.Summary.Cell>
                                                                        <Table.Summary.Cell index={1}>

                                                                        </Table.Summary.Cell>
                                                                        <Table.Summary.Cell index={2}>

                                                                        </Table.Summary.Cell>
                                                                        <Table.Summary.Cell index={3}>
                                                                        <Text type="danger" strong>{topaidAmount}</Text>
                                                                        </Table.Summary.Cell>
                                                                        <Table.Summary.Cell index={4}>
                                                                            
                                                                        </Table.Summary.Cell>
                                                                        <Table.Summary.Cell index={5}>

                                                                        </Table.Summary.Cell>
                                                                        <Table.Summary.Cell index={6}>

                                                                        </Table.Summary.Cell>
                                                                    </Table.Summary.Row>

                                                                </>
                                                            );
                                                        }
                                                    }}
                                                    mobileBreakPoint={768}
                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tab="Unpaid" key="2">
                                        <Row>
                                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                                                <TableView
                                                    antTableProps={{
                                                        showHeader: true,
                                                        columns: dueInfoColumn,
                                                        rowKey: "feeHeadId",
                                                        dataSource: studentOverFeesListDueReport,
                                                        filterData: studentOverFeesListDueReport,
                                                        pagination: false,
                                                        style: { maxHeight: 500 },
                                                        bordered: true,
                                                        scroll:{ y: 350 },
                                                        sticky:true,
                                                        summary: function () {
                                                            let tpayableAmount = 0;

                                                            studentOverFeesListDueReport?.forEach(({ payableAmount }) => {
                                                                tpayableAmount += payableAmount;
                                                            });

                                                            return (
                                                                <>
                                                                    <Table.Summary.Row>
                                                                        <Table.Summary.Cell index={0}><Text type="danger" strong>Total</Text> </Table.Summary.Cell>
                                                                        <Table.Summary.Cell index={1}>

                                                                        </Table.Summary.Cell>
                                                                        <Table.Summary.Cell index={2}>
                                                                        <Text type="danger" strong>{tpayableAmount}</Text>
                                                                        </Table.Summary.Cell>

                                   
                                                                    </Table.Summary.Row>

                                                                </>
                                                            );
                                                        }
                                                    }}
                                                    mobileBreakPoint={768}
                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </Tabs>

                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>

            </Modal>
        </>
    )
}
