import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import SessionYear from '../Students/settings/SessionYear.page';
import { SelectAcademicYear } from '../../select/SelectAcademicYear';
import { PlusOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';

export default function TetimonialAdd() {
    const [searchForm] = Form.useForm();
    const [addForm] = Form.useForm();
    const { Option } = Select;
    const fetchStudentInfo = useStoreActions((state) => state.testimonials.fetchStudentInfo );
    const studentInfo = useStoreState((state) => state.testimonials.studentInfo);
    const saveTestimonials = useStoreActions((state) => state.testimonials.saveTestimonials);
    const [studentInfoData, setStudentInfoData] = useState<any>();
    const [studentidentIficationId, setStudentIdentificationId] = useState<any>();
    const searchStudentForm = (value) => {
        let postData:any = {
            customStudentId: value.student_id,
            academicYear: value.academic_year,
        }
        fetchStudentInfo(postData);
        
    }

    useEffect(() => {
        setStudentInfoData(studentInfo);
        setStudentIdentificationId(studentInfo.identificationId)        
    }, [studentInfo])

    const addStudentForm = (value) => {
        
        let postData:any = {
            "boardRegNo": value.board_reg,
            "boardRoll": value.board_roll,
            "examBoard": value.board,
            "examGpa": value.gpa,
            "examGrade": value.grade,
            "examName": value.exam_name,
            "examSession": value.session,
            "identificationId": studentidentIficationId,
            "passingYear": value.passing_year
        }
        saveTestimonials(postData);
    }

    return (
        <>
            <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                    <Card title="Student Information">
                        <Form
                            layout="vertical"
                            id="sessionInfo"
                            onFinish={searchStudentForm}
                            form={searchForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                    <Form.Item
                                        name="student_id"
                                        label="Student Id"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write student id" },
                                        ]}
                                    >
                                        <InputNumber placeholder="student id" />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                    <Form.Item
                                        name="academic_year"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select academic year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                    <Button type="primary" htmlType="submit" className="mt-0" icon={<SearchOutlined />} style={{ float: "right" }}>
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <br />
                        <Row>
                            <Col span={24}>
                                <div className={studentInfoData == "" || studentInfoData == undefined ? 'ant-table-wrapper d-none' : 'ant-table-wrapper'}>
                                    <div className="ant-spin-nested-loading">
                                        <div className="ant-spin-container">
                                            <div className="ant-table ant-table-bordered">
                                                <div className="ant-table-container">
                                                <div className="ant-table-content">
                                                    <table style={{ tableLayout: "auto" }}>
                                                        <tbody className="ant-table-tbody">
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">Name</td>
                                                                <td className="ant-table-cell">{studentInfoData?.studentName}</td>
                                                            </tr>
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">Father's Name</td>
                                                                <td className="ant-table-cell">{studentInfoData?.fatherName}</td>
                                                            </tr>
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">Mother's Name</td>
                                                                <td className="ant-table-cell">{studentInfoData?.motherName}</td>
                                                            </tr>
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">Date of Birth</td>
                                                                <td className="ant-table-cell">{studentInfoData?.studentDOB}</td>
                                                            </tr>
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">Present Village</td>
                                                                <td className="ant-table-cell">{studentInfoData?.presentVillege}</td>
                                                            </tr>                                                            
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">Present Post Office</td>
                                                                <td className="ant-table-cell">{studentInfoData?.presentPostOffice}</td>
                                                            </tr>
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">Present Upazila/ P.S</td>
                                                                <td className="ant-table-cell">{studentInfoData?.presentThanaName}</td>
                                                            </tr>
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell">Present District</td>
                                                                <td className="ant-table-cell">{studentInfoData?.presentDistrictName}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 16 }} className={studentInfoData == "" || studentInfoData == undefined ? 'd-none' : ''}>
                    <Card title="Add Testimonial">
                        <Form
                            layout="vertical"
                            id="sessionInfo"
                            onFinish={addStudentForm}
                            form={addForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                    <Form.Item
                                            name="exam_name"
                                            label="Exam Name"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select exam name" },
                                            ]}
                                        >
                                            <Select placeholder="exam name">
                                                <Option value="fetching">Fetching Semester</Option>
                                                <Option value="PEC">PEC</Option>
                                                <Option value="JSC">JSC</Option>
                                                <Option value="JDC">JDC</Option>
                                                <Option value="SSC">SSC</Option>
                                                <Option value="SSC (Dakhil)">SSC (Dakhil)</Option>
                                                <Option value="HSC">HSC</Option>
                                                <Option value="HSC (Alim)">HSC (Alim)</Option>
                                                <Option value="BA">BA</Option>
                                                <Option value="BBA">BBA</Option>
                                                <Option value="BBS">BBS</Option>
                                                <Option value="B.Com">B.Com</Option>
                                                <Option value="B.Sc">B.Sc</Option>
                                                <Option value="Fazil">Fazil</Option>
                                            </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="board_reg"
                                        label="Board Reg"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please enter board reg" },
                                        ]}
                                    >
                                        <InputNumber placeholder="board reg" />    
                                    </Form.Item>
                                    <Form.Item
                                        name="gpa"
                                        label="GPA"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please enter gpa" },
                                        ]}
                                    >
                                        <InputNumber placeholder="enter gpa" />    
                                    </Form.Item>
                                    <Form.Item
                                        name="board"
                                        label="Board"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select board" },
                                        ]}
                                    >
                                        <Select placeholder="select board">
                                            <Option value=""> Select Board</Option>
                                            <Option value="Dhaka">Dhaka</Option>
                                            <Option value="Rajshahi">Rajshahi</Option>
                                            <Option value="Jessore">Jessore</Option>
                                            <Option value="Barisal">Barisal</Option>
                                            <Option value="Dinajpur">Dinajpur</Option>
                                            <Option value="Chattogram">Chattogram</Option>
                                            <Option value="Cumilla">Cumilla</Option>
                                            <Option value="Mymensingh">Mymensingh</Option>
                                            <Option value="Sylhet">Sylhet</Option>
                                            <Option value="Madrasah">Madrasah</Option>
                                            <Option value="BTEB">BTEB</Option>
                                            <Option value="DPE">DPE</Option>
                                            <Option value="National University">National University</Option>
                                        </Select>    
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                    <Form.Item
                                        name="board_roll"
                                        label="Board Roll"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please enter board roll" },
                                        ]}
                                    >
                                        <InputNumber placeholder="enter board roll" />    
                                    </Form.Item>
                                    <Form.Item
                                        name="passing_year"
                                        label="Passing Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select passing Year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                    <Form.Item
                                        name="grade"
                                        label="Grade"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select grade" },
                                        ]}
                                    >
                                        <Select placeholder="select grade">
                                            <Option value="A+">A+</Option>
                                            <Option value="A">A</Option>
                                            <Option value="A-">A-</Option>
                                            <Option value="B">B</Option>
                                            <Option value="C">C</Option>
                                            <Option value="D">D</Option>
                                            <Option value="F">F</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="session"
                                        label="Session"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please enter session" },
                                        ]}
                                    >
                                        <Input placeholder="enter session" />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                    <Button type="primary" htmlType="submit" className="mt-0" icon={<PlusOutlined />} style={{ float: "right" }}>
                                        Add
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </>
    )
}