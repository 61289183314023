import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Column from 'rc-table/lib/sugar/Column';
import { DeleteOutlined, FilePdfOutlined, SaveOutlined, SwitcherOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import { SelectClass } from '../../select/SelectClass';
import { SelectExamByClassRoutine } from '../../select/SelectExamByClassRoutine';

export default function ExamRoutine() {

    const fetchexamRoutineList = useStoreActions((state) => state.common.fetchexamRoutineList);
    const fetchexamRoutine = useStoreActions((state) => state.common.fetchexamRoutine);
    const examRoutineSave = useStoreActions((state) => state.common.examRoutineSave);
    const examRoutineDelete = useStoreActions((state) => state.common.examRoutineDelete);
    const examRoutineList = useStoreState((state) => state.common.examRoutineList);
    const examRoutineView = useStoreState((state) => state.common.examRoutineView);
    const fetchExamByClassListUrl = useStoreActions((state) => state.exam.fetchExamListByClassAndClassIdroutine);
    useEffect(() => {
        fetchexamRoutineList();
    }, []);
    const loading = useStoreState((state) =>  state.common.loading);



    const columns = [
        { title: 'Class Name', dataIndex: 'className', key: "className", showOnResponse: true, showOnDesktop: true },
        { title: 'Exam Name', dataIndex: 'examName', key: "examName", showOnResponse: true, showOnDesktop: true },
        {
            title: 'Action', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record: any, index) =>
                <>

                    <Space size="middle">

                        <Tooltip title="View Pdf">
                            <Button type='primary' icon={<FilePdfOutlined />} onClick={() => { fetchexamRoutine(record?.routineId); setIsModalVisible(true) }} />
                        </Tooltip>
                        <Popconfirm
                            title="Are you sure you want to delete this item?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => examRoutineDelete(record?.routineId)}
                        >
                            <Tooltip title="Active">
                                <Button danger icon={<DeleteOutlined />} />
                            </Tooltip>
                        </Popconfirm>
                    </Space>

                </>
        },
    ];

    console.log(examRoutineView)
    async function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    const [isFile, setIsFile] = useState<boolean>(false);
    const [fileContent, setfileContent] = useState<any>('');

    function clearFileInput(ctrl) {
        try {
            ctrl.value = null;
        } catch (ex) { }
        if (ctrl.value) {
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    }
    const uploadPdf = (file: any) => {
        if (file.target.files[0]?.size > 1000000) {
            notification.error({ message: 'PDF size should be less than 1MB' })
            // file.target.value = null;
            clearFileInput(document.getElementById("upload-file"));
            setIsFile(false);
            setfileContent('');
            return;
        };

        getBase64(file.target.files[0], (imageUrl) => {
            setfileContent(imageUrl.split(',')[1]);
            setIsFile(true);
        })

    }

    const [form] = Form.useForm();
    const formSubmit = (value) => {
        console.log(value);
        console.log(isFile);
        if (isFile === false) {
            notification.error({ message: 'Please upload a file' })
            return;
        } else {
            let payload = {
                "classId": value?.classId,
                "examId": value?.examId,
                "fileContent": fileContent
            }
            examRoutineSave(payload);
            clearFileInput(document.getElementById("upload-file"));
            setIsFile(false);
            form.resetFields();
        }
    }

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [exId, setExid] = useState<any>(null);


    return (
        <>
            <Card title="Exam Routine">
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={1} xl={1}> </Col>

                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item
                                name="classId"
                                label="Select Class"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                            >

                                <SelectClass  onChange={(e)=> {fetchExamByClassListUrl(e); form.setFieldsValue({examId:null}); setExid(null)}}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item
                                name="examId"
                                label="Select Exam"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select exam" },
                                ]}
                            >
                                <SelectExamByClassRoutine selected={exId} onChange={(e)=>setExid(e)}  />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <div >
                                <div className="ant-col ant-form-item-label"><label className="ant-form-item-required" >Upload Pdf</label></div>
                                <input className='ant-input' type="file" accept="application/pdf" id="upload-file" onChange={uploadPdf} />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                                    Save
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}>
                            <Button type="primary" onClick={() => window.open('https://res.cloudinary.com/dnyoqhb7g/raw/upload/v1624253034/Routine/Student_Exam_Routine.docx', '_blank')}> Sample Download 1</Button>
                        </Col>

                    </Row>
                </Form>
               { examRoutineList=== null && < Skeleton loading={loading} paragraph={{rows:10}} />}
              { examRoutineList!== null &&
                <Row className="m-t-mo-30">
                    <Col span={24}>
                        <div className="datatable-responsive-demo">
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: examRoutineList,
                                    filterData: examRoutineList,
                                    pagination: true,
                                    bordered: true,
                                    rowKey: "routineId",
                                }}
                                mobileBreakPoint={768}
                            />
                        </div>
                    </Col>
                </Row>
}
            </Card>
            <Modal
                title="PDF Viewer"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                footer={null}
                width={'80%'}
                centered
            >
                <object id="pdf" data={"data:application/pdf;base64," + examRoutineView} type="application/pdf" width="100%" height="600px"></object>
            </Modal>
        </>
    )
}