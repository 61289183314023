import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";


export default function TakePeriod() {

    const fetchStudentAttendancePeriod = useStoreActions((state) => state.student.fetchStudentAttendancePeriod);
    const studentAttendancePeriodList = useStoreState((state) => state.student.studentAttendancePeriodList);
    const fetchStudentAttendanceAllPeriod = useStoreActions((state) => state.student.fetchStudentAttendanceAllPeriod);
    const studentAttendanceAllPeriodList = useStoreState((state) => state.student.studentAttendanceAllPeriodList);
    const createAttendancePeriod = useStoreActions((state) => state.student.createAttendancePeriod);
    const deleteAttendancePeriod = useStoreActions((state) => state.student.deleteAttendancePeriod);
    const loading = useStoreState((state) =>  state.student.loading);
    useEffect(() => {
        fetchStudentAttendancePeriod();
        fetchStudentAttendanceAllPeriod();
    }, []);

    const createPeriodMethod = (value) => {
        let postdata:any ={
            defaultId: value.defaultId,
            name: value.name,
            serial: value.serial,
        }
        createAttendancePeriod(postdata);
    }

    const deletePeriodMethod = (id) => {
        deleteAttendancePeriod(id);
    }

    const columns = [
        {
            title: 'Serial',
            dataIndex: 'defaultId',
            key: 'defaultId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Action',
            key: 'defaultId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Button type='primary' onClick={() => createPeriodMethod(record)}>Take</Button>
                </Space>
            ),
        },


    ];

    const periodListColumn = [
        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <span>
                    {record.serial + 1}
                </span>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <span>
                    {record.status == 1 ? 'Enabled' : 'Disabled'}
                </span>
            ),
        },
        {
            title: 'Action',
            key: 'defaultId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Popconfirm
                    title="Are you sure to delete this?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deletePeriodMethod(record?.id)}
                >
                    <Tooltip title="Delete">
                        <Button danger icon={<DeleteOutlined />} />
                    </Tooltip>
                </Popconfirm>
            ),
        },


    ];

    return <>
    
        <Card title="Take Period">
        <Skeleton loading={loading} paragraph={{rows:10}} />
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 10 }} lg={{ span: 10}} xl={{ span: 10}}>
                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns: columns,
                            dataSource: studentAttendancePeriodList,
                            filterData: studentAttendancePeriodList,
                            pagination: true,
                            bordered: true,
                            rowKey: "masterId",
                        }}
                        mobileBreakPoint={768}

                    />
                </Col>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 14 }} lg={{ span: 14}} xl={{ span: 14}}>
                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns: periodListColumn,
                            dataSource: studentAttendanceAllPeriodList,
                            filterData: studentAttendanceAllPeriodList,
                            pagination: true,
                            bordered: true,
                            rowKey: "masterId",
                        }}
                        mobileBreakPoint={768}

                    />
                </Col>
            </Row>
        </Card>
    </>
}