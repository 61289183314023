import { Card, Tabs } from 'antd'
import React from 'react'
import AccountsBookCash from './AccountsBookCash';
import AccountsBookLedger from './AccountsBookLedger';
const { TabPane } = Tabs;

export default function AccountsBookTab(props) {

    return (
        <>
            <Card title="Accounts Book Review" >
                <Tabs defaultActiveKey="1" >
                    <TabPane tab="Cash Book" key="1">
                        <AccountsBookCash />
                    </TabPane>
                    <TabPane tab="Ledger Book" key="2">
                        <AccountsBookLedger />
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )
}
