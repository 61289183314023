import React, { useEffect } from 'react'
import { useState } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Upload, Skeleton } from 'antd'
import TableView from '../../../../contents/AntTableResponsive';
import { SaveOutlined, SearchOutlined, SendOutlined, SettingOutlined, UploadOutlined } from '@ant-design/icons';
import xlsxParser from 'xlsx-parse-json';
import XLSX from "xlsx";
import $ from 'jquery';
import Table from "../../../../contents/AntTableResponsive";
import { SelectClass } from '../../../select/SelectClass';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClassConfigMultiple } from '../../../select/SelectClassConfigMultiple';

export default function SectionWiseSms() {

    const [smsTemplateForm] = Form.useForm();
    const { Option } = Select;
    const { TextArea } = Input;
    const [searchForm] = Form.useForm();
    const fetchSMSCount = useStoreActions((state) => state.sms.fetchSMSCount);
    const smsCount = useStoreState((state) => state.sms.staffCount);
    const fetchSmsTemplate = useStoreActions((state) => state.sms.fetchSmsTemplate);
    const smsTemplateList = useStoreState((state) => state.sms.smsTemplateList);
    const [className, seTClassName] = useState("");
    const sendStudentSms = useStoreActions((state) => state.sms.sendStudentSms);
    const [templateContent, setTemplateContent] = useState<any>();    
    /**********/
    const fetchstudentRegistrationListByClassConfigurations = useStoreActions((state) => state.sms.fetchstudentRegistrationListByClassConfigurations);
    const studentRegistrationListByClassConfigurations = useStoreState((state) => state.sms.studentRegistrationListByClassConfigurations);

    useEffect(() => {
        fetchSMSCount();
        fetchSmsTemplate();
    }, []);

    const columns = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Group', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true },
        { title: 'Father Name', dataIndex: 'fatherName', key: 'fatherName', showOnResponse: true, showOnDesktop: true },
        { title: 'Mother Name', dataIndex: 'motherName', key: 'motherName', showOnResponse: true, showOnDesktop: true },
        { title: 'Gender', dataIndex: 'studentGender', key: 'studentGender', showOnResponse: true, showOnDesktop: true },
        { title: 'Religion', dataIndex: 'studentReligion', key: 'studentReligion', showOnResponse: true, showOnDesktop: true },
        { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup', showOnResponse: true, showOnDesktop: true },
        { title: 'Birth Day', dataIndex: 'studentDOB', key: 'studentDOB', showOnResponse: true, showOnDesktop: true },
        { title: 'Mobile No.', dataIndex: 'guardianMobile', key: 'guardianMobile', showOnResponse: true, showOnDesktop: true },
    ]


    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);
    const loading = useStoreState((state) =>  state.sms.loading);
    

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const smsSendForm = (value) => {

        if (selectedValue.length === 0) {
            message.error("Please select atleast one student");
            return
        } 
        let postData = {
            "message": value.smsContent,
            "identificationIds": selectedValue.map((item) => item.identificationId),
        }
        sendStudentSms(postData);
        smsTemplateForm.resetFields();
        setselectedRowKeys([]); setselectedValue([]);
    }

    const onChangeRemarksList = (e) => {

 
        smsTemplateForm.setFieldsValue({
            "smsContent": e
        })
        setTemplateContent(e);
    }

    const fetchStudentList = (value) => {
        let payload = {
            academicYear: smsTemplateForm.getFieldValue('academicYear'),
            classConfigurationIds: smsTemplateForm.getFieldValue('classConfigurationIds').join(','),
        }
        fetchstudentRegistrationListByClassConfigurations(payload);
    }

    const onChangeSmsText = (e) => {
        setTemplateContent(e.traget.value)
    }

    return (
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>

                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>
                            <Card title="Send SMS Section Wise">
                                <Form
                                    layout="vertical"
                                    id="classConfigInfo"
                                    form={smsTemplateForm}
                                    onFinish={smsSendForm}

                                >
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8, offset: 8 }} lg={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
                                            <Form.Item
                                                name="academicYear"
                                                label="Academic Year"
                                                className="title-Text"
                                                rules={[
                                                    { required: true, message: "Please select year" },
                                                ]}
                                            >
                                                <SelectAcademicYear />
                                            </Form.Item>
                                            <Form.Item
                                                name="classConfigurationIds"
                                                label="Select Sectiom"
                                                className="title-Text"
                                                rules={[
                                                    { required: true, message: "Please select section" },
                                                ]}
                                            >
                                                <SelectClassConfigMultiple />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8, offset: 8 }} lg={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
                                            <Button type="primary" onClick={(e) => fetchStudentList(e)} className="mt-0 pull-right" icon={<SearchOutlined />} >
                                                Search
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8, offset: 8 }} lg={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
                                            <Form.Item
                                                name="templateSelect"
                                                label="Select Template"
                                                className="title-Text"
                                                rules={[
                                                    { required: true, message: "Please select template" },
                                                ]}
                                            >
                                                <Select placeholder="Select SMS Template" onChange={(e) => onChangeRemarksList(e)}>
                                                    {smsTemplateList ? (
                                                        smsTemplateList.map((type, idx) => (
                                                            <Option key={type.templateId} value={type.templateBody}>
                                                                {type.templateTitle}
                                                            </Option>
                                                        ))
                                                    ) : (
                                                        <Option value="fetching">Fetching sms template</Option>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8, offset: 8 }} lg={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
                                            {smsCount}
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8, offset: 8 }} lg={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
                                            <Form.Item
                                                name="smsContent"
                                                label="Enter SMS Content"
                                                className="title-Text"
                                                rules={[
                                                    { required: true, message: "Write sms content" },
                                                ]}
                                            >
                                                <TextArea placeholder="Write sms content" rows={5} value={templateContent} onChange={(e) => onChangeSmsText(e)} className="placeholderColorChange" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8, offset: 8 }} lg={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
                                            <Button type="primary" htmlType="submit" onClick={(e) => {  }} className="mt-0 pull-right" icon={<SendOutlined />} >
                                                Send
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                                <br />
                                <Skeleton loading={loading} paragraph={{rows:10}} />
                                {studentRegistrationListByClassConfigurations.length > 0 ?
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>
                                            < div className="datatable-responsive-demo" style={{ width: "100%", overflow: "auto" }}>
                                                <Table
                                                    antTableProps={{
                                                        showHeader: true,
                                                        rowSelection: rowSelection,
                                                        columns: columns,
                                                        dataSource: studentRegistrationListByClassConfigurations,
                                                        filterData: studentRegistrationListByClassConfigurations,
                                                        pagination: false,
                                                        bordered: true,
                                                        rowKey: "identificationId",
                                                    }}
                                                    mobileBreakPoint={768}

                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    : ''}
                            </Card>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    )
}