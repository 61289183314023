import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import SessionYear from '../Students/settings/SessionYear.page';
import { SelectAcademicYear } from '../../select/SelectAcademicYear';
import TetimonialAdd from './TetimonialAdd';
import TetimonialAddDownload from './TetimonialAddDownload';

export default function Tetimonial() {

    const { TabPane } = Tabs;

    return(
        <>
            <Card title="Testimonial">
                <Row>
                    <Col span={24}>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Add" key="1">
                                <TetimonialAdd />
                            </TabPane>
                            <TabPane tab="Download" key="2">
                                <TetimonialAddDownload />
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </Card>
        </>
    )
}