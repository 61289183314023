
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, DatePicker, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import TableView from '../../../../contents/AntTableResponsive';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { SelectClassConfigMultiple } from '../../../select/SelectClassConfigMultiple';
import { SelectFeeHeadMultiple } from '../../../select/SelectFeeHeadMultiple';
import moment from 'moment';
require('jspdf-autotable');



export default function FeeHeadCollectionSectionDatetoDate() {

    const [classWiseReportForm] = Form.useForm();
    //const fetchshowStudentFeeHeadPaymentView = useStoreActions((state) => state.saccount.fetchshowStudentFeeHeadPaymentView);
    const fetchshowMultipleSectionDateToDateTotalPaidDetails = useStoreActions((state) => state.saccount.fetchshowMultipleSectionDateToDateTotalPaidDetails);
    const showMultipleSectionDateToDateTotalPaidDetails = useStoreState((state) => state.saccount.showMultipleSectionDateToDateTotalPaidDetails);
    const loading = useStoreState((state) => state.student.loading);

    const [tableData, setTableData] = useState<any>([])
    const formSubmit = (value) => {

        let postData: any = {
            academicYear: value.yearId,
            classConfigIds: value.classConfigId,
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
            feeHeadIds: value.feeHeadIds === null ? [] : value.feeHeadIds,
        }
        fetchshowMultipleSectionDateToDateTotalPaidDetails(postData);
    }
    useEffect(() => {
        setTableData(showMultipleSectionDateToDateTotalPaidDetails?.studentList)
    }, [showMultipleSectionDateToDateTotalPaidDetails])


    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName1 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName1, dataIndex: 'feeHead1', key: 'feeHead1', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName2 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName2, dataIndex: 'feeHead2', key: 'feeHead2', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName3 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName3, dataIndex: 'feeHead3', key: 'feeHead3', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName4 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName4, dataIndex: 'feeHead4', key: 'feeHead4', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName5 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName5, dataIndex: 'feeHead5', key: 'feeHead5', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName6 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName6, dataIndex: 'feeHead6', key: 'feeHead6', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName7 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName7, dataIndex: 'feeHead7', key: 'feeHead7', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName8 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName8, dataIndex: 'feeHead8', key: 'feeHead8', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName9 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName9, dataIndex: 'feeHead9', key: 'feeHead9', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName10 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName10, dataIndex: 'feeHead10', key: 'feeHead10', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName11 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName11, dataIndex: 'feeHead11', key: 'feeHead11', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName12 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName12, dataIndex: 'feeHead12', key: 'feeHead12', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName13 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName13, dataIndex: 'feeHead13', key: 'feeHead13', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName14 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName14, dataIndex: 'feeHead14', key: 'feeHead14', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName15 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName15, dataIndex: 'feeHead15', key: 'feeHead15', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName16 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName16, dataIndex: 'feeHead16', key: 'feeHead16', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName17 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName17, dataIndex: 'feeHead17', key: 'feeHead17', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName18 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName18, dataIndex: 'feeHead18', key: 'feeHead18', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName19 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName19, dataIndex: 'feeHead19', key: 'feeHead19', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName20 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName20, dataIndex: 'feeHead20', key: 'feeHead20', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName21 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName21, dataIndex: 'feeHead21', key: 'feeHead21', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName22 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName22, dataIndex: 'feeHead22', key: 'feeHead22', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName23 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName23, dataIndex: 'feeHead23', key: 'feeHead23', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName24 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName24, dataIndex: 'feeHead24', key: 'feeHead24', showOnResponse: true, showOnDesktop: true },
        showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName25 !== '' && { title: showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName25, dataIndex: 'feeHead25', key: 'feeHead25', showOnResponse: true, showOnDesktop: true },
        { title: 'Total', dataIndex: 'total', key: 'total', showOnResponse: true, showOnDesktop: true },
    ];

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Class Wise list of ${$(".classSelect").text()} and Academic Year-${classWiseReportForm.getFieldValue("yearId")}`;
        var detailsx = `Class Wise list of Class: ${$(".classSelect").text()} and Academic Year: ${classWiseReportForm.getFieldValue("yearId")}`;
        pdfDataL(doc, "");
        doc.text(detailsx, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        var col = [
            "Student ID",
            "Student Name",
            "Roll",
            "Group",
            "Father",
            "Mother",
            "Gender",
            "Religion",
            "Mobile No."
        ].filter(Boolean);

        var rows: any = [];


        showMultipleSectionDateToDateTotalPaidDetails.forEach(element => {
            var temp: any = [
                element.customStudentId,
                element.studentName,
                element.studentRoll,
                element.groupName,
                element.fatherName,
                element.motherName,
                element.studentGender,
                element.studentReligion,
                element.guardianMobile,
            ];
            rows.push(temp);
        });


        let first = doc.autoTable.previous;
        doc.autoTable(col, rows, {
            startY: 45,
            showHeader: "firstPage",
            theme: 'grid',
            styles: {
                overflow: 'linebreak',
            },
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                // guardianMobile: {
                //     halign: "left",
                //     fontSize: 8,
                //     columnWidth: 250
                // }
            },
            addPageContent: pageContent,
        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    // const dx= new Array(4).fill(0);
    // console.log(dx)
    return (
        <>
            <>
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24, offset: 1 }} xl={{ span: 24, offset: 1 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={classWiseReportForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Form.Item
                                        name="yearId"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Form.Item
                                        name="classConfigId"
                                        label="Select Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select Section" },
                                        ]}
                                    >
                                        <SelectClassConfigMultiple />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Form.Item
                                        name="feeHeadIds"
                                        label="Select Feehead"
                                        className="title-Text"
                                        initialValue={null}
                                    // rules={[
                                    //     { required: true, message: "Please select feehead" },
                                    // ]} 
                                    >
                                        <SelectFeeHeadMultiple />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Form.Item
                                        name="fromDate"
                                        label="From Date"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please enter from date" },
                                        ]}
                                    >

                                        <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Form.Item
                                        name="toDate"
                                        label="To Date"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please enter to date" },
                                        ]}
                                    >

                                        <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: showMultipleSectionDateToDateTotalPaidDetails !== null ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: studentListColumn,
                                rowKey: "identificationId",
                                dataSource: tableData,
                                filterData: tableData,
                                pagination: true,
                                bordered: true,
                                exportable: true,
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                        {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                    </Col>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>
                            {/* <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Student Fee Head Collection of Class ${$(".classSelect").text()} and Academic Year ${classWiseReportForm.getFieldValue("yearId")}`}
                            >
                                <ExcelSheet data={tableData} name="Student Fee Head Collection">
                                    <ExcelColumn label="Student ID" value="customStudentId" />
                                    <ExcelColumn label="Student Name" value="studentName" />
                                    <ExcelColumn label="Roll" value="studentRoll" />
                                    {showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName1!==''&&<ExcelColumn label={showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName1} value="feeHead1" />}
                                    {showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName2!==''&&<ExcelColumn label={showMultipleSectionDateToDateTotalPaidDetails?.feeHeadNames?.feeHeadName2} value="feeHead2" />}

                                    <ExcelColumn label="Total" value="studentRoll" />
                                </ExcelSheet>
                            </ExcelFile> */}
                            {/* <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportPdf()} >PDF Download</Button> */}
                        </Space>
                    </Col>
                </Row>

            </>
        </>
    )
}
