import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { createDesignation, creategovtHolidayList, createHolidayList, deleteclassTeacherAssignList, deleteDesignation, deletegovtHolidayList, deleteHolidayList, deleteStaffLeaveInfo, doMultipleStaffRegistration, fetchattendanceDetailsAllStaff, fetchclassTeacherAssignList, fetchdesignationList, fetchenabledStuff, fetchgovtHolidayList, fetchIdCardTemplate, fetchsingleStuffView, fetchstaffAtttendanceList, fetchstaffBasicInfoList, fetchstaffBasicInfoListReport, fetchstaffDateWiseAttReport, fetchstaffLeaveList, fetchstaffMonthWiseAttReport, fetchstaffTimeConfigReport, fetchtimeConfigurationList, fetchweeklyHolidayList, idCardSaveTemplate, saveBatchIdmapping, saveclassTeacherAssignList, saveSingleIdmapping, saveSingleStaffRegistration, savestaffLeave, saveTimeConfiguration, staffTxtFile, staffTxtFileSave, takeAttendance, updateAttendance, updateDesignation, updateSingleStaffBasicInfo, updateSingleStaffPhoto, updateStaffAttendanceComments } from '../../../http/staff/staff';

export interface Staff {

	designationList: any;
	setdesignationList: Action<Staff, any>;
	fetchdesignationList: Thunk<Staff>
	createDesignation: Thunk<Staff, any>
	updateDesignation: Thunk<Staff, any>
	deleteDesignation: Thunk<Staff, any>

	saveSingleStaffRegistration: Thunk<Staff, any>
	doMultipleStaffRegistration: Thunk<Staff, any>

	staffBasicInfoList: any;
	setstaffBasicInfoList: Action<Staff, any>;
	fetchstaffBasicInfoList: Thunk<Staff>
	updateSingleStaffPhoto: Thunk<Staff, any>
	updateSingleStaffBasicInfo: Thunk<Staff, any>

	classTeacherAssignList: any;
	setclassTeacherAssignList: Action<Staff, any>;
	fetchclassTeacherAssignList: Thunk<Staff>
	saveclassTeacherAssignList: Thunk<Staff, any>
	deleteclassTeacherAssignList: Thunk<Staff, any>

	staffBasicInfoListReport: any;
	setstaffBasicInfoListReport: Action<Staff, any>;
	fetchstaffBasicInfoListReport: Thunk<Staff>

	fetchIdCardTemplate: Thunk<Staff>;
	setIdCardTemplateList: Action<Staff, any>;
	idCardTemplateList: any;
	idCardSaveTemplate: Thunk<Staff, any>;
	takeAttendance: Thunk<Staff, any>;

	staffAtttendanceList: any;
	setstaffAtttendanceList: Action<Staff, any>;
	fetchstaffAtttendanceList: Thunk<Staff, any>

	updateAttendance: Thunk<Staff, any>;

	singleStuffView: any;
	setsingleStuffView: Action<Staff, any>;
	fetchsingleStuffView: Thunk<Staff, any>

	savestaffLeave: Thunk<Staff, any>;

	staffLeaveList: any;
	setstaffLeaveList: Action<Staff, any>;
	fetchstaffLeaveList: Thunk<Staff, any>

	deleteStaffLeaveInfo: Thunk<Staff, any>;

	timeConfigurationList: any;
	settimeConfigurationList: Action<Staff, any>;
	fetchtimeConfigurationList: Thunk<Staff>

	saveTimeConfiguration: Thunk<Staff, any>;

	enabledStuff: any;
	setenabledStuff: Action<Staff, any>;
	fetchenabledStuff: Thunk<Staff>

	saveSingleIdmapping: Thunk<Staff, any>;
	saveBatchIdmapping: Thunk<Staff, any>;

	staffTimeConfigReport: any;
	setstaffTimeConfigReport: Action<Staff, any>;
	fetchstaffTimeConfigReport: Thunk<Staff>

	staffDateWiseAttReport: any;
	setstaffDateWiseAttReport: Action<Staff, any>;
	fetchstaffDateWiseAttReport: Thunk<Staff, any>

	staffMonthWiseAttReport: any;
	setstaffMonthWiseAttReport: Action<Staff, any>;
	fetchstaffMonthWiseAttReport: Thunk<Staff, any>

	attendanceDetailsAllStaff: any;
	setattendanceDetailsAllStaff: Action<Staff, any>;
	fetchattendanceDetailsAllStaff: Thunk<Staff, any>

	staffDateWiseAttReport2: any;
	setstaffDateWiseAttReport2: Action<Staff, any>;
	fetchstaffDateWiseAttReport2: Thunk<Staff, any>
	updateStaffAttendanceComments: Thunk<Staff, any>


	staffTxtFileList: any;
	setstaffTxtFileList: Action<Staff, any>;

	staffTxtFile: Thunk<Staff, any>
	staffTxtFileSave: Thunk<Staff, any>;

	loading: boolean;
	setLoading: Action<Staff, boolean>;

	fetchweeklyHolidayList: Thunk<Staff>;
	createHolidayList: Thunk<Staff, any>;
	deleteHolidayList: Thunk<Staff, any>;
	weeklyHolidayList: any;
	setweeklyHolidayList: Action<Staff, any>;

	fetchgovtHolidayList: Thunk<Staff>;
	creategovtHolidayList: Thunk<Staff, any>;
	deletegovtHolidayList: Thunk<Staff, any>;
	govtHolidayList: any;
	setgovtHolidayList: Action<Staff, any>;
}

export const staffStore: Staff = {
	loading: false,
	setLoading: action((state, payload) => {
		state.loading = payload;
	}),

	designationList: null,
	fetchdesignationList: thunk(async (actions) => {
		actions.setLoading(true);
		const response = await fetchdesignationList();
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			actions.setdesignationList(body?.item);
		} else {
			actions.setLoading(false);
		}

	}),

	setdesignationList: action((state, payload) => {
		state.designationList = payload;
	}),

	createDesignation: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await createDesignation(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchdesignationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	updateDesignation: thunk(async (actions, payload) => {
		const response = await updateDesignation(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchdesignationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	deleteDesignation: thunk(async (actions, payload) => {
		const response = await deleteDesignation(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchdesignationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	saveSingleStaffRegistration: thunk(async (actions, payload) => {
		const response = await saveSingleStaffRegistration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchdesignationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),
	doMultipleStaffRegistration: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await doMultipleStaffRegistration(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchdesignationList();
		} else {
			notification.error({ message: "Something went wrong" })
			actions.setLoading(false);
		}
	}),
	updateSingleStaffBasicInfo: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await updateSingleStaffBasicInfo(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchstaffBasicInfoList();
		} else {
			notification.error({ message: "Something went wrong" })
			actions.setLoading(false);
		}
	}),

	updateSingleStaffPhoto: thunk(async (actions, payload) => {

		// console.log(payload)

		var data = new FormData()
		data.append('file', payload.file)


		const response = await updateSingleStaffPhoto(data, payload.staffId);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchstaffBasicInfoList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}

	),

	staffBasicInfoList: null,
	fetchstaffBasicInfoList: thunk(async (actions) => {
		actions.setLoading(true);
		const response = await fetchstaffBasicInfoList();
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			actions.setstaffBasicInfoList(body?.item);
		} else {
			actions.setLoading(false);
		}
	}),

	setstaffBasicInfoList: action((state, payload) => {
		state.staffBasicInfoList = payload;
	}),

	classTeacherAssignList: null,
	fetchclassTeacherAssignList: thunk(async (actions) => {
		actions.setLoading(true);
		const response = await fetchclassTeacherAssignList();
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			actions.setclassTeacherAssignList(body?.item);
		} else {
			actions.setLoading(false);

		}
	}),

	setclassTeacherAssignList: action((state, payload) => {
		state.classTeacherAssignList = payload;
	}),

	saveclassTeacherAssignList: thunk(async (actions, payload) => {
		const response = await saveclassTeacherAssignList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchclassTeacherAssignList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),
	deleteclassTeacherAssignList: thunk(async (actions, payload) => {
		const response = await deleteclassTeacherAssignList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchclassTeacherAssignList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	staffBasicInfoListReport: null,
	fetchstaffBasicInfoListReport: thunk(async (actions) => {
		actions.setLoading(true);
		const response = await fetchstaffBasicInfoListReport();
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			actions.setstaffBasicInfoListReport(body?.item);
		} else {
			actions.setstaffBasicInfoListReport(null);
			actions.setLoading(false);
		}
	}),

	setstaffBasicInfoListReport: action((state, payload) => {
		state.staffBasicInfoListReport = payload;
	}),

	idCardTemplateList: [],
	fetchIdCardTemplate: thunk(async (actions) => {
		const response = await fetchIdCardTemplate();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.item) {
				actions.setIdCardTemplateList(body?.item);
			} else {
				notification.error({ message: 'No data found' });
			}
		}
	}),

	setIdCardTemplateList: action((state, payload) => {
		state.idCardTemplateList = payload;
	}),

	idCardSaveTemplate: thunk(async (actions, payload) => {
		const response = await idCardSaveTemplate(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				notification.success({ message: body.message })
				actions.fetchIdCardTemplate();
			} else {
				notification.error({ message: body.message })
			}

		} else {
			const body = await response.json();
			notification.error({ message: body.statusText })
		}
	}),

	takeAttendance: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await takeAttendance(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body.messageType == 1) {
				actions.fetchstaffBasicInfoListReport();
				notification.success({ message: body.message })
			} else {
				notification.error({ message: 'Staff attendance has already taken on the specified date' })
			}

		} else {
			notification.error({ message: "Something went wrong" })
			actions.setLoading(false);
		}
	}),

	staffAtttendanceList: [],
	fetchstaffAtttendanceList: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchstaffAtttendanceList(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body.item?.length > 0) {
				actions.setstaffAtttendanceList(body?.item);
			} else {
				notification.error({ message: "Please take attendance first on the specified date" })
				actions.setstaffAtttendanceList([]);
			}
		} else {
			notification.error({ message: "Something went wrong" })
			actions.setLoading(false);
		}
	}),

	setstaffAtttendanceList: action((state, payload) => {
		state.staffAtttendanceList = payload;
	}),

	updateAttendance: thunk(async (actions, payload) => {
		const response = await updateAttendance(payload?.data);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				actions.fetchstaffAtttendanceList(payload?.attendanceDate);
				notification.success({ message: body.message })
			} else {
				notification.error({ message: body.message })
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	singleStuffView: null,
	fetchsingleStuffView: thunk(async (actions, payload) => {
		const response = await fetchsingleStuffView(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				actions.setsingleStuffView(body?.item);
			} else {
				notification.error({ message: "No data found" })
				actions.setsingleStuffView(null);
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	setsingleStuffView: action((state, payload) => {
		state.singleStuffView = payload;
	}),

	staffLeaveList: [],
	fetchstaffLeaveList: thunk(async (actions, payload) => {
		const response = await fetchstaffLeaveList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.item?.length > 0) {
				actions.setstaffLeaveList(body?.item);
			} else {
				// notification.error({ message: "No data found" })
				actions.setstaffLeaveList([]);
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	setstaffLeaveList: action((state, payload) => {
		state.staffLeaveList = payload;
	}),

	savestaffLeave: thunk(async (actions, payload) => {
		const response = await savestaffLeave(payload?.data);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				actions.fetchstaffLeaveList(payload?.id);
				notification.success({ message: body.message })
			} else {
				notification.error({ message: body.message })
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	deleteStaffLeaveInfo: thunk(async (actions, payload) => {
		const response = await deleteStaffLeaveInfo(payload?.data);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				actions.fetchstaffLeaveList(payload?.id);
				notification.success({ message: body.message })
			} else {
				notification.error({ message: body.message })
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	timeConfigurationList: [],
	fetchtimeConfigurationList: thunk(async (actions) => {
		actions.setLoading(true);
		const response = await fetchtimeConfigurationList();
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body.item?.length > 0) {
				actions.settimeConfigurationList(body?.item);
			} else {
				// notification.error({ message: "No data found" })
				actions.settimeConfigurationList([]);
			}
		} else {
			notification.error({ message: "Something went wrong" })
			actions.setLoading(false);
		}
	}),

	settimeConfigurationList: action((state, payload) => {
		state.timeConfigurationList = payload;
	}),

	saveTimeConfiguration: thunk(async (actions, payload) => {
		const response = await saveTimeConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				actions.fetchtimeConfigurationList();
				notification.success({ message: body.message })
			} else {
				notification.error({ message: body.message })
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	enabledStuff: null,
	fetchenabledStuff: thunk(async (actions) => {
		actions.setLoading(true);
		const response = await fetchenabledStuff();
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			actions.setenabledStuff(body?.item);
		} else {
			actions.setenabledStuff(null);
			actions.setLoading(false);
		}
	}),

	setenabledStuff: action((state, payload) => {
		state.enabledStuff = payload;
	}),

	saveSingleIdmapping: thunk(async (actions, payload) => {
		const response = await saveSingleIdmapping(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				actions.fetchenabledStuff();
				notification.success({ message: body.message })
			} else {
				notification.error({ message: body.message })
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	saveBatchIdmapping: thunk(async (actions, payload) => {
		const response = await saveBatchIdmapping(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				actions.fetchenabledStuff();
				notification.success({ message: body.message })
			} else {
				notification.error({ message: body.message })
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	staffTimeConfigReport: [],
	fetchstaffTimeConfigReport: thunk(async (actions) => {
		actions.setLoading(true);
		const response = await fetchstaffTimeConfigReport();
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body.item?.length > 0) {
				actions.setstaffTimeConfigReport(body?.item);
			} else {
				notification.error({ message: "No data found" })
				actions.setstaffTimeConfigReport([]);
			}
		} else {
			notification.error({ message: "Something went wrong" })
			actions.setLoading(false);
		}
	}),

	setstaffTimeConfigReport: action((state, payload) => {
		state.staffTimeConfigReport = payload;
	}),

	staffDateWiseAttReport: [],
	fetchstaffDateWiseAttReport: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchstaffDateWiseAttReport(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body.item?.length > 0) {
				actions.setstaffDateWiseAttReport(body?.item);
			} else {
				notification.error({ message: "No data found" })
				actions.setstaffDateWiseAttReport([]);
			}
		} else {
			notification.error({ message: "Something went wrong" })
			actions.setLoading(false);
		}
	}),

	setstaffDateWiseAttReport: action((state, payload) => {
		state.staffDateWiseAttReport = payload;
	}),

	staffMonthWiseAttReport: [],
	fetchstaffMonthWiseAttReport: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchstaffMonthWiseAttReport(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body.item?.details?.length > 0) {
				actions.setstaffMonthWiseAttReport(body?.item);
			} else {
				notification.error({ message: "No data found" })
				actions.setstaffMonthWiseAttReport([]);
			}
		} else {
			notification.error({ message: "Something went wrong" })
			actions.setLoading(false);
		}
	}),

	setstaffMonthWiseAttReport: action((state, payload) => {
		state.staffMonthWiseAttReport = payload;
	}),

	attendanceDetailsAllStaff: [],
	fetchattendanceDetailsAllStaff: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchattendanceDetailsAllStaff(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body.item?.staffList?.length > 0) {
				actions.setattendanceDetailsAllStaff(body?.item);
			} else {
				notification.error({ message: "No data found" })
				actions.setattendanceDetailsAllStaff([]);
			}
		} else {
			notification.error({ message: "Something went wrong" })
			actions.setLoading(false);
		}
	}),

	setattendanceDetailsAllStaff: action((state, payload) => {
		state.attendanceDetailsAllStaff = payload;
	}),

	staffDateWiseAttReport2: [],
	fetchstaffDateWiseAttReport2: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchstaffDateWiseAttReport(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body.item?.length > 0) {
				actions.setstaffDateWiseAttReport2(body?.item);
			} else {
				notification.error({ message: "No data found" })
				actions.setstaffDateWiseAttReport2([]);
			}
		} else {
			notification.error({ message: "Something went wrong" })
			actions.setLoading(false);
		}
	}),

	setstaffDateWiseAttReport2: action((state, payload) => {
		state.staffDateWiseAttReport2 = payload;
	}),

	updateStaffAttendanceComments: thunk(async (actions, payload) => {
		const response = await updateStaffAttendanceComments(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	staffTxtFile: thunk(async (actions, payload) => {
		var data = new FormData()
		data.append('file', payload.file)


		const response = await staffTxtFile(data, payload.date);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: 'Success, please save now' })
				actions.setstaffTxtFileList(body?.item);
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchstaffBasicInfoList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	staffTxtFileSave: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await staffTxtFileSave(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body.messageType == 1) {
				actions.setstaffTxtFileList(null)
				notification.success({ message: body.message })
			} else {
				notification.error({ message: body.message })
				actions.setLoading(false);
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),
	staffTxtFileList: null,
	setstaffTxtFileList: action((state, payload) => {
		state.staffTxtFileList = payload;
	}),

	///////////
	weeklyHolidayList: null,

	fetchweeklyHolidayList: thunk(async (actions) => {
		actions.setLoading(true);
		const response = await fetchweeklyHolidayList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setLoading(false);


			actions.setweeklyHolidayList(body.item);
		} else {
			const body = await response.json();
			actions.setLoading(false);
		}
	}),
	setweeklyHolidayList: action((state, payload) => {
		state.weeklyHolidayList = payload;
	}),

	createHolidayList: thunk(async (actions, payload) => {
		const response = await createHolidayList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				notification.success({ message: body.message })
				actions.fetchweeklyHolidayList();
			} else {
				notification.error({ message: body.message })
			}
		} else {
			const body = await response.json();
			notification.error({ message: body.message })
		}
	}),


	deleteHolidayList: thunk(async (actions, payload) => {
		const response = await deleteHolidayList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				notification.success({ message: body.message })
				actions.fetchweeklyHolidayList();
			} else {
				notification.error({ message: body.message })
			}
		} else {
			const body = await response.json();
			notification.error({ message: body.statusText })
		}
	}),
	//////////////

	///////////
	govtHolidayList: null,

	fetchgovtHolidayList: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchgovtHolidayList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item?.length>0){
				actions.setgovtHolidayList(body.item);
			} else {
				actions.setgovtHolidayList([]);
				notification.warning({ message: "No data found" })
			}
			actions.setLoading(false);
		} else {
			const body = await response.json();
			actions.setLoading(false);
		}
	}),
	setgovtHolidayList: action((state, payload) => {
		state.govtHolidayList = payload;
	}),

	creategovtHolidayList: thunk(async (actions, payload) => {
		const response = await creategovtHolidayList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				notification.success({ message: body.message })
				actions.fetchgovtHolidayList();
			} else {
				notification.error({ message: body.message })
			}
		} else {
			const body = await response.json();
			notification.error({ message: body.message })
		}
	}),


	deletegovtHolidayList: thunk(async (actions, payload) => {
		const response = await deletegovtHolidayList(payload?.id);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body.messageType == 1) {
				notification.success({ message: body.message })
				actions.fetchgovtHolidayList({year:payload?.year});
			} else {
				notification.error({ message: body.message })
			}
		} else {
			const body = await response.json();
			notification.error({ message: body.statusText })
		}
	}),
	//////////////

}
