import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import {  SettingOutlined } from '@ant-design/icons';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';

export default function MarkProcessClass() {
    const { Option } = Select;
    const [proForm] = Form.useForm();

    const fetchExamListByClassAndClassId3 = useStoreActions((state) => state.exam.fetchExamListByClassAndClassId3);
    const examListByClassAndClassId3 = useStoreState((state) => state.exam.examListByClassAndClassId3);

    const updateMarkByClassAndExamConfig = useStoreActions((state) => state.exam.updateMarkByClassAndExamConfig);

    const loading = useStoreState((state) => state.exam.loading);



    const onSubmit = (value) => {
        updateMarkByClassAndExamConfig(value);
        proForm.resetFields();
    }

    const onClassChange = (value) => {
        fetchExamListByClassAndClassId3(value);
    }




    return (
        <>
            <Card title="Mark Process Class Wise">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={onSubmit}
                            form={proForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="sectionYear"
                                        label="Select Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="selectClass"
                                        label="Select Class"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select class" },
                                        ]}
                                    >
                                        <SelectClass onChange={(e) => onClassChange(e)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="selectExam"
                                        label="Select Exam"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select exam" },
                                        ]}
                                    >
                                        <Select placeholder="Exam List" style={{ width: "100%" }} className="examSelectx">
                                            {examListByClassAndClassId3 ? (
                                                examListByClassAndClassId3.map((type, idx) => (
                                                    <Option key={type.examConfigId} value={type.examConfigId}>
                                                        {type.examName}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching exam</Option>
                                            )}
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Button type="primary" htmlType="submit" icon={<SettingOutlined />} >
                                        Process
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />

            </Card>
        </>
    )

}