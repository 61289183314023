
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SaveOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectFeeHead } from '../../../select/SelectFeeHead';
import { SelectFeeSubHeadByFeeHead } from '../../../select/SelectFeeSubHeadByFeeHead';


export default function AttendanceFineAdd() {

    const [form2] = Form.useForm();
    const fetchstudentListForFeeSubHeadDelete = useStoreActions((state) => state.saccount.fetchstudentListForFeeSubHeadDelete);
    const fetchfeeSubHeadConfigurationList = useStoreActions((state) => state.saccount.fetchfeeSubHeadConfigurationList);
    const studentListForFeeSubHeadDelete = useStoreState((state) => state.saccount.studentListForFeeSubHeadDelete);
    const studentFeeSubHeadDelete = useStoreActions((state) => state.saccount.studentFeeSubHeadDelete);
    const loading = useStoreState((state) =>  state.student.loading);

    const [feeHeadId, setfeeHeadId] = useState<any>('');

    const formSubmit = (value) => {
        let postData: any = {
            academicYear: value.yearId,
            classConfigId: value.sectionId,
            feeHeadId: value.feeHeadId
        };
        setfeeHeadId(value.feeHeadId);
        fetchfeeSubHeadConfigurationList(value.feeHeadId)
        fetchstudentListForFeeSubHeadDelete(postData);
    }

    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Section', dataIndex: 'sectionName', key: 'sectionName', showOnResponse: true, showOnDesktop: true },
    ];

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onProcess = () => {
        if (selectedValue.length === 0) {
            notification.error({ message: "Please select student" });
            return
        }
        setIsModalVisible(true);
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const submitData = (value) => {
        value.identificationIds = selectedValue.map(item => item.identificationId);
        value.feeHeadId=feeHeadId;
        studentFeeSubHeadDelete(value);
        setIsModalVisible(false);
        form.resetFields()
        setselectedRowKeys([]);
        setselectedValue([])
    }

    return (
        <>

            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 3 }} xl={{ span: 20, offset: 3 }}>
                    <Form
                        layout="vertical"
                        id="classConfigInfo"
                        onFinish={formSubmit}
                        form={form2}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="yearId"
                                    label="Academic Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select year" },
                                    ]}
                                >
                                    <SelectAcademicYear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="sectionId"
                                    label="Select Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectClassConfig />
                                </Form.Item>
                            </Col>                            
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="feeHeadId"
                                    label="Fee Head"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select fee" },
                                    ]}
                                >
                                   <SelectFeeHead  />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                    </Form>
                </Col>
            </Row>
            {< Skeleton loading={loading} paragraph={{rows:10}} />}
            <Row style={{ display: studentListForFeeSubHeadDelete.length > 0 ? '' : 'none' }}>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                    <TableView
                        antTableProps={{
                            showHeader: true,
                            columns: studentListColumn,
                            rowKey: "identificationId",
                            dataSource: studentListForFeeSubHeadDelete,
                            filterData: studentListForFeeSubHeadDelete,
                            pagination: false,
                            bordered: true,
                            rowSelection: rowSelection,
                        }}
                        mobileBreakPoint={768}
                    />
                    {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                </Col>
                <Col span={24}>
                    <Space size="small" style={{ float: "right", marginTop:15 }}>

                        <Button type="primary" icon={<SettingOutlined />} onClick={() => onProcess()} >Process</Button>
                    </Space>
                </Col>
            </Row>
            <Modal
                title="Fee Discard"
                visible={isModalVisible}
                // onOk={handleOk}
                // okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                centered
            >
                <Form
                    layout="vertical"
                    onFinish={submitData}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24}>
                            <Form.Item
                                name="feeSubHeadIds"
                                label="Fee Sub Head"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select fee sub head" },
                                ]}
                            >

                                <SelectFeeSubHeadByFeeHead />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                            Save
                        </Button>
                    </div>
                </Form>

            </Modal>

        </>
    )
}
