import React, { useEffect } from 'react'
import { useState } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Upload, DatePicker, Skeleton } from 'antd'
import { RestOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import TableView from '../../../../contents/AntTableResponsive';
import moment from 'moment';

export default function StudentMessageList() {

    const [searchForm] = Form.useForm();
    const fetchSmsReportData = useStoreActions((state) => state.sms.fetchSmsReportData);
    const studentSmsData = useStoreState((state) => state.sms.studentSmsData);
    const loading = useStoreState((state) =>  state.sms.loading);

    const searchSubmitForm = (value) => {
        let postdata:any = {
            msgType: "student",
            fromDate : moment(value.fromDate._d).format("YYYY-MM-DD"),
            toDate : moment(value.toDate._d).format("YYYY-MM-DD"),
        }
        
        fetchSmsReportData(postdata);
    }

    let columns = [
        {title: 'Date', dataIndex: 'date', key: 'date', showOnResponse: true, showOnDesktop: true},
        {title: 'Name/Mobile Number', dataIndex: 'name', key: 'name', showOnResponse: true, showOnDesktop: true},
        {title: 'Message', dataIndex: 'smsBody', key: 'smsBody', showOnResponse: true, showOnDesktop: true},
        {title: 'Message Count', dataIndex: 'smsCount', key: 'smsCount', showOnResponse: true, showOnDesktop: true},
    ]

    return(
        <>
            <Card title="Student Message List">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                        <Form
                            layout="vertical"
                            id="sessionInfo"
                            onFinish={searchSubmitForm}
                            form={searchForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                    <Form.Item
                                        name="fromDate"
                                        label="From Date"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select from date" },
                                        ]}
                                    >
                                        <DatePicker placeholder="from date" style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                    <Form.Item
                                        name="toDate"
                                        label="To Date"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select to date" },
                                        ]}
                                    >
                                        <DatePicker placeholder="to date" style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                < Skeleton loading={loading} paragraph={{rows:10}} />
                <Row className="m-t-mo-30">
                    <Col span="24">
                        <div className="datatable-responsive-demo">
                            {studentSmsData?.length > 0 &&
                                <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: studentSmsData,
                                    filterData: studentSmsData,
                                    pagination: true,
                                    bordered: true,
                                    rowKey:"id",
                                }}
                                mobileBreakPoint={768}
                            />
                            }
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    )
}