import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Table, Tag } from 'antd';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';


export default function Shift() {
    const createshift = useStoreActions((state) => state.student.createShift);
    const updateshift = useStoreActions((state) => state.student.updateShift);
    const deleteshift = useStoreActions((state) => state.student.deleteShift);
    const fetchshiftList = useStoreActions((state) => state.student.fetchShiftList);
    const shiftList = useStoreState((state) => state.student.shiftList);
    const [shiftForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const loading = useStoreState((state) =>  state.student.loading);

    ///modal
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedStatus, setselectedStatus] = useState<any>();
    const [updateshiftId, setupdateshiftId] = useState<any>();

    useEffect(() => {
        fetchshiftList();

    }, [])

    const createshiftForm = (value) => {

        let postData = {
            name: value.shiftName,
            serial: value.serialNumber,
            status: 1
        }
        createshift(postData);
        shiftForm.resetFields();
    }

    /////////////

    const updateshiftForm = (value) => {
        let updatePostData = {
            id: updateshiftId,
            name: value.nameUpdate,
            serial: value.serialUpdate,
            status: value.status == true ? 1 : 0
        }
        updateshift(updatePostData);
        setIsModalVisible(false);
        updateForm.resetFields();
    }



    const success = () => {
        message.success('This is a success message');
    };

    const columns = [
        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Shift',
            dataIndex: 'name',
            key: 'name',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <>
                    <span>{record.status == 1 ? 'Enable' : 'Disable'}</span>
                </>
            )
        },
        {
            title: 'Action',
            key: 'ledgerId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setselectedStatus(record.status);
                            setupdateshiftId(record.id);
                            updateForm.setFieldsValue({
                                serialUpdate: record.serial,
                                nameUpdate: record.name,
                                status: record.status,
                            });
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteshift(record?.id)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        }
    ];
    return (
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                    <Form
                        layout="vertical"
                        id="shiftInfo"
                        onFinish={createshiftForm}
                        form={shiftForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Form.Item
                                    name="shiftName"
                                    label="Shift Name"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write shift name" },
                                    ]}
                                >
                                    <Input placeholder="Enter shift name" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Form.Item
                                    name="serialNumber"
                                    label="Serial Number"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please write serial number" },
                                    ]}
                                >
                                    <InputNumber placeholder="Enter serial number" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                        Save
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{ rows: 10 }} />
            <Row className="m-t-mo-30">
                <Col span="24">
                    <div className="datatable-responsive-demo">
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} shiftName="p-datatable-responsive-demo" /> */}
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: shiftList,
                                filterData: shiftList,
                                pagination: true,
                                bordered: true,
                                rowKey: "id",
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                </Col>
            </Row>
            <Modal
                title="shift Edit"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
                maskClosable={false}
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateshiftForm}
                    form={updateForm}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="nameUpdate"
                                label="shift Year"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write shift Year name" },
                                ]}
                            >
                                <Input placeholder="Enter shift Year name" value={updateshiftId} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="serialUpdate"
                                label="Serial Number"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write serial number" },
                                ]}
                            >
                                <InputNumber placeholder="Enter serial number" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="status"
                                label="Status:"
                                valuePropName="checked"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input serial" },
                                ]}
                            >
                                <Checkbox>{selectedStatus == 0 ? 'Disable' : 'Enable'}</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )

}