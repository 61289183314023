import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag} from 'antd';
import TableView from '../../../../contents/AntTableResponsive';
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import Column from 'rc-table/lib/sugar/Column';

export default function GrandFinalMark() {
    const [updateFinalMarkUpdateForm] = Form.useForm();
    const { Option } = Select;
    const fetchShortCodeClassList = useStoreActions((state) => state.exam.fetchShortCodeClassList);
    const updateGrandFinalMarkConfig = useStoreActions((state) => state.exam.updateGrandFinalMarkConfig);
    const fetchExamByClassListUrl = useStoreActions((state) => state.exam.fetchExamListByClassAndClassId);
    const examListByClassAndClassId =  useStoreState((state) => state.exam.examListByClassAndClassId)
    const shortCodeClasslist = useStoreState((state) => state.exam.shortCodeClasslist);
    const [shortCodeClassListTable, setShortCodeClassListTable] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [classConfigId, setClassConfigId] = useState(false);
    const [examList, setExamList] = useState<any>([]);

    const classListColumn = [
        { title: 'Class', dataIndex: 'name', key: 'serial', showOnResponse: true, showOnDesktop: true  },
        { title: 'Action', dataIndex: 'gradeRange', key: 'serial', showOnResponse: true, showOnDesktop: true,
          render: (text, record, index) => (
            <Button type="primary" onClick={(e) => {
                setIsModalVisible(true);
                setClassConfigId(record.id)
                fetchExamByClassListUrl(record.id)
            }} icon={<EditOutlined />} />
          ),
        },        
    ];

    const changeParcentage = (e, id) => {
        examList.map(item => {
            if(item.examConfigId == id){
                item.percentage = e
            }
        });
        setExamList(examList);
    }

    const changeExamSerial = (e, id) => {
        examList.map(item => {
            if(item.examConfigId == id){
                item.examSerial = e
            }
        });
        setExamList(examList);
    } 
 
    const classListColumnUpdate = [
        { title: 'Exam Name', dataIndex: 'examName', key: 'examConfigId', showOnResponse: true, showOnDesktop: true},  
        { title: 'Percentage', dataIndex: 'percentage', key: 'examConfigId', showOnResponse: true, showOnDesktop: true, 
        render: (text, record, index) => (
            <InputNumber defaultValue={record.percentage}  name="subjectSerial" className="subjectSerial" onChange={(e) => changeParcentage(e, record.examConfigId)}/>
        )},  
        { title: 'examSerial', dataIndex: 'examSerial', key: 'examConfigId', showOnResponse: true, showOnDesktop: true,  
            render: (text, record, index) => (
                <>
                    <Select defaultValue={record.examSerial} onChange={(e) => changeExamSerial(e, record.examConfigId)} style={{ width: "100%" }}><Option value="" key="" >Select Serial</Option><Option value="0" key="0" >0</Option><Option value="1" key="1">1</Option><Option value="2" key="2">2</Option><Option value="3" key="3">3</Option><Option value="4" key="4">4</Option><Option value="5" key="5">5</Option><Option value="6" key="6">6</Option><Option value="7" key="7">7</Option><Option value="8" key="8">8</Option><Option value="9" key="9">9</Option></Select>
                </>
            )  
        }    
    ];

    useEffect(() => {
        fetchShortCodeClassList();
        setShortCodeClassListTable(shortCodeClasslist)
        
    }, [])

    useEffect(() => {
        setShortCodeClassListTable(shortCodeClasslist)
    }, [shortCodeClasslist])

    useEffect(() => {
        setExamList(examListByClassAndClassId)
    }, [examListByClassAndClassId])

    const finalMarkConfiguration = () => {
        setIsModalVisible(true);
    }

    const grandFinalMarkConfigureation = () => {
        let dataList:any = [];
        examList.map((item, index) => {
            let postData = {
                examConfigurationId : item.examConfigId,
                examSerial: item.examSerial,
                percentage: item.percentage
            }
            dataList.push(postData);
        });
        let check = true;
        let percentageTotal = dataList.reduce((a, b) => +a + +b.percentage, 0);
        if (percentageTotal < 100 || percentageTotal > 100) {
            check = false
            notification.error({ message: 'Total percentage should be equal to 100' })
        }else{
            setIsModalVisible(false)
        }
        if (check) {
            for (let k in dataList) {
                if (dataList[k].examSerial == 0) {
                    check = false
                    notification.error({ message: 'Please Select Exam Serial' })
                }
            }
        }
        if (check) {
            updateGrandFinalMarkConfig(dataList);
            //examListByClassAndClassId
        }
    }

    return(
        <Row>
            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}} lg={{ span: 24}} xl={{ span: 24}}>
                <Card title="Grand Final Mark Configuration">
                    <TableView
                        antTableProps={{
                            showHeader: true,
                            columns:classListColumn,  
                            rowKey:"serial",
                            dataSource: shortCodeClassListTable,
                            pagination: true,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                </Card>
            </Col>
            <Modal
                title="Mark Configuration"
                visible={isModalVisible}
                onOk={grandFinalMarkConfigureation}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}} lg={{ span: 24}} xl={{ span: 24}}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns:classListColumnUpdate,
                                rowKey:"subjectSerial",
                                dataSource: examList,
                                filterData: examList,
                                pagination: true,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                    </Col>
                </Row>
            </Modal>
        </Row>
    )

}