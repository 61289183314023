import React, { useEffect } from 'react'
const interval = 1000;

export default function EmptyPage(props) {

    function generateLocks() {
        const lock: any = document.createElement('div'),
            position = generatePosition();
        lock.innerHTML = '<div class="top"></div><div class="bottom"></div>';
        lock.style.top = position[0];
        lock.style.left = position[1];
        lock.classList = 'lock'// generated';
        document.body.appendChild(lock);
        setTimeout(() => {
            lock.style.opacity = '1';
            lock.classList.add('generated');
        }, 100);
        setTimeout(() => {
            lock.parentElement.removeChild(lock);
        }, 2000);
    }
    function generatePosition() {
        const x = Math.round((Math.random() * 100) - 10) + '%';
        const y = Math.round(Math.random() * 100) + '%';
        return [x, y];
    }

    useEffect(() => {
        const myInterval =  setInterval(generateLocks, interval);
        generateLocks();
        setTimeout(() => {
          clearInterval(myInterval);
        }, 50000);

    }, [])

    return (
        <>
            <style>
                {`
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
* {
  box-sizing: border-box;
  overflow:hidden;
}
.ant-layout-content{
    padding:0;
}
.ant-layout-footer{
    display:none;
}

.container {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 10px;
  min-width: 300px;
}
.container div {
  display: inline-block;
}
.container .lock {
  opacity: 1;
}
.container h1 {
  font-family: "Comfortaa", cursive;
  font-size: 100px;
  text-align: center;
  color: #eee;
  font-weight: 100;
  margin: 0;
}
.container p {
  color: #fff;
}

.lock {
  transition: 0.5s ease;
  position: relative;
  overflow: hidden;
  opacity: 0;
}
.lock.generated {
  transform: scale(0.5);
  position: absolute;
  -webkit-animation: 2s move linear;
          animation: 2s move linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.lock ::after {
  content: "";
  background: #a74006;
  opacity: 0.3;
  display: block;
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  left: 0;
}
.lock .bottom {
  background: #d68910;
  height: 40px;
  width: 60px;
  display: block;
  position: relative;
  margin: 0 auto;
}
.lock .top {
  height: 60px;
  width: 50px;
  border-radius: 50%;
  border: 10px solid #fff;
  display: block;
  position: relative;
  top: 30px;
  margin: 0 auto;
}
.lock .top::after {
  padding: 10px;
  border-radius: 50%;
}

@-webkit-keyframes move {
  to {
    top: 100%;
  }
}

@keyframes move {
  to {
    top: 100%;
  }
}
@media (max-width: 420px) {
  .container {
    transform: translate(-50%, -50%) scale(0.8);
  }

  .lock.generated {
    transform: scale(0.3);
  }
}
        }
       `}
            </style>

            <div style={{ backgroundColor: "rgb(32 58 50)", height: "100%", }}>
                <div className="container" >
                    <h1>
                        4
                        <div className="lock">
                            <div className="top" />
                            <div className="bottom" />
                        </div>
                        3
                    </h1>
                    <h2 style={{color:"#fff"}}>Access denied</h2>
                    <h3 style={{color:"#fff"}}>This Page Isn't Available</h3>
                    <h3 style={{color:"#fff"}}>Your current package doesn't have permission to view this page.</h3>
                    <h3 style={{color:"#fff"}}>To upgrade your current package, contact us. </h3>
                    <h2 style={{color:"#fff"}}>Helpline- <a href="tel:+8809612-191919" rel="nofollow" ><b>+8809612-191919</b></a>  </h2>
                </div>
            </div>

        </>

    )
}
