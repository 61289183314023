import React, { useEffect } from 'react'
import { useState } from 'react';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Upload, Skeleton } from 'antd'
import TableView from '../../../contents/AntTableResponsive';
import { SaveOutlined, SearchOutlined, SendOutlined, SettingOutlined, UploadOutlined } from '@ant-design/icons';
import xlsxParser from 'xlsx-parse-json';
import XLSX from "xlsx";
import $ from 'jquery';
import Table from "../../../contents/AntTableResponsive";

const SMSHelper = require('smshelper');
const { TextArea } = Input;

export default function StaffSms() {
    const [form] = Form.useForm();
    const fetchStaffList = useStoreActions((state) => state.sms.fetchStaffList);
    const staffList = useStoreState((state) => state.sms.staffList);
    const fetchSMSCount = useStoreActions((state) => state.sms.fetchSMSCount);
    const staffCount = useStoreState((state) => state.sms.staffCount);

    const sendStaffSms = useStoreActions((state) => state.sms.sendStaffSms);
    const [message, setMessage] = useState<any>('');
    const fetchSmsTemplate = useStoreActions((state) => state.sms.fetchSmsTemplate);
    const smsTemplateList = useStoreState((state) => state.sms.smsTemplateList);
    const loading = useStoreState((state) =>  state.sms.loading);

    useEffect(() => {
        fetchStaffList();
        fetchSMSCount();
        fetchSmsTemplate();
    }, []);

    const columns = [
        { title: 'Name', dataIndex: 'staffName', key: 'staffId', showOnResponse: true, showOnDesktop: true },
        { title: 'ID', dataIndex: 'customStaffId', key: 'customStaffId', showOnResponse: true, showOnDesktop: true },
        { title: 'Designation', dataIndex: 'designationName', key: 'customStaffId', showOnResponse: true, showOnDesktop: true },
        { title: 'Mobile No.', dataIndex: 'mobileNumber', key: 'mobileNumber', showOnResponse: true, showOnDesktop: true },
        { title: 'Gender', dataIndex: 'gender', key: 'gender', showOnResponse: true, showOnDesktop: true },
        { title: 'Religion', dataIndex: 'religion', key: 'religion', showOnResponse: true, showOnDesktop: true },
        { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup', showOnResponse: true, showOnDesktop: true },
    ]

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const smsSendForm = (value) => {
        let postData = {
            "message": value.smsContent,
            "staffIds": selectedValue.map(item => item.staffId)
        };
        sendStaffSms(postData);
        setselectedRowKeys([]);
        setselectedValue([])

    }

    return (
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>

                    <Form
                        layout="vertical"
                        id="classConfigInfo"
                        form={form}
                        onFinish={smsSendForm}
                    >
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>
                                <Card title="Send SMS To Staff">
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8, offset: 8 }} lg={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
                                            <div className="ant-col ant-form-item-label"><label className="ant-form-item" title="Select Template">Select Template</label></div>
                                            <Select placeholder="Select Template" style={{ width: "100%" }} onChange={(val) => { form.setFieldsValue({ smsContent: val }); setMessage(val) }}>
                                                {smsTemplateList ? (
                                                    smsTemplateList.map((type, idx) => (
                                                        <Select.Option key={type.templateId} value={type.templateBody}>
                                                            {type.templateTitle}
                                                        </Select.Option>
                                                    ))
                                                ) : (
                                                    <Select.Option value="fetching">Fetching Class</Select.Option>
                                                )}
                                            </Select> <br /><br />
                                            <Form.Item
                                                name="smsContent"
                                                label="Enter SMS Content"
                                                className="title-Text"
                                                rules={[
                                                    { required: true, message: "Write sms content" },
                                                ]}
                                            >
                                                <TextArea rows={8} placeholder="Write sms content" onChange={e => setMessage(e.target.value)} ></TextArea>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8, offset: 8 }} lg={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <div>
                                                    {message.length > 0 &&
                                                        <>
                                                            SMS Count: {SMSHelper.parts(message)}
                                                        </>
                                                    }
                                                </div>
                                                <div>{staffCount}</div>
                                            </div>
                                        </Col>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8, offset: 8 }} lg={{ span: 8, offset: 8 }} xl={{ span: 8, offset: 8 }}>
                                            <Button type="primary" htmlType="submit" className="pull-right" icon={<SendOutlined />} >
                                                Send
                                            </Button>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Skeleton loading={loading} paragraph={{rows:10}} />
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>
                                            <   div className="datatable-responsive-demo" style={{ width: "100%", overflow: "auto" }}>
                                                <Table
                                                    antTableProps={{
                                                        showHeader: true,
                                                        rowSelection: rowSelection,
                                                        columns: columns,
                                                        dataSource: staffList,
                                                        filterData: staffList,
                                                        pagination: false,
                                                        bordered: true,
                                                        rowKey: "staffId",
                                                    }}
                                                    mobileBreakPoint={768}

                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    )
}