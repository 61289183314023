import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Upload, Badge } from 'antd'
import TableView from '../../../contents/AntTableResponsive';
import { SaveOutlined, SendOutlined, SettingOutlined, UploadOutlined } from '@ant-design/icons';
import xlsxParser from 'xlsx-parse-json';
import { useStoreActions } from '../../../store/hooks/easyPeasy';

const { Option } = Select;
let tempMessage: any = [];
export default function DynamicSms() {
    const sendManyToManySms = useStoreActions((state) => state.sms.sendManyToManySms);
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState<any>([]);
    const [sheetData, setSheetData] = useState<any>([]);
    const [sheetList, setSheetList] = useState<any>([]);
    const [column, setColumn] = useState<any>([]);
    const [columnx, setColumnx] = useState<any>([]);
    const [columnData, setcolumnData] = useState<any>([]);
    const [tableData, settableData] = useState<any>([]);
    const [mobile, setMobile] = useState<any>('')
    const [message, setMessage] = useState<any>('')

    const onChangeFile = ({ fileList: newFileList }) => {
        setSheetList([]);
        setSheetData([]);
        tempMessage = [];
        settableData([]);
        setselectedRowKeys([]);
        setselectedValue([]);
        setMessage('');
        newFileList[0].status = "done"
        xlsxParser
            .onFileSelection(newFileList[0]?.originFileObj)
            .then(data => {
                console.log(data);
                setSheetData(data);
                let temp: any = [];
                for (var i in data) {
                    temp.push({ value: i, label: i })
                };
                setSheetList(temp);
                return;
            }).catch(err => {
                console.log(err);
            });
    };

    const onChangeSheet = (value: any) => {
        //console.log(sheetData[value]);
        tempMessage = [];
        settableData([]);
        setselectedRowKeys([]);
        setselectedValue([]);
        setMessage('');
        let temp: any = sheetData[value]
        setcolumnData(sheetData[value]);
        if (temp?.length === 0) {
            notification.error({ message: "No data found in sheet" });
            return;
        }
        let tempColumn: any = [];
        let tempColumnx: any = [];
        for (var i in temp[0]) {
            tempColumn.push({ value: i, label: i })
            tempColumnx.push(i)
        };
        setColumn(tempColumn);
        setColumnx(tempColumnx);
    };


    const selectMobile = (value: any) => {
        //console.log(value)
        setMobile(value);
    };

    const appendColumn = (value: any) => {
        setMessage(message + '#' + value + '#');
    };


    function processExcel() {
        // let allMatch = message.match(/#(.*?)#/g);
        tempMessage = [];
        settableData([]);
        for (let i = 0; i < columnData.length; i++) {
            let mes = message.replace(/#(.*?)#/g, function (match, p1) {
                return columnData[i][p1]
            });
            let mobileval = columnData[i][mobile];
            tempMessage.push({
                key: i,
                to: mobileval,
                message: mes
            })
        }
        //console.log(columnData[i][allMatch[1].replace(/#(.*?)#/g, '$1')])
        settableData(tempMessage);
        // console.log(individualMessage)
    }

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: 'Mobile',
            dataIndex: 'to',
            key: 'to',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'SMS Body',
            dataIndex: 'message',
            key: 'message',
            showOnResponse: true,
            showOnDesktop: true,
        },
    ];

    const sendSms = () => {
        if (selectedRowKeys.length === 0) {
            notification.error({ message: "Select atleast one row" });
            return;
        } 
        sendManyToManySms(selectedValue.map(item => {
            return {
                to: item.to,
                message: item.message
            }
        }));
        tempMessage = [];
        settableData([]);
        setselectedRowKeys([]);
        setselectedValue([]);
        setMessage('');

    }
    return (
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 10, offset: 0 }} xl={{ span: 10, offset: 0 }}>
                    <Form
                        layout="vertical"
                        id="classConfigInfo"
                        form={form}
                    // onFinish={createSessionYearForm}
                    >
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>
                                <Card title="Process SMS">
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                            <div className="ant-col ant-form-item-label"><label className="ant-form-item-required" title="Excel File">Excel File</label></div> <br />
                                            <Upload
                                                listType="text"
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                fileList={fileList}
                                                onChange={(e) => onChangeFile(e)}
                                            // style={{width:"100%" }}

                                            >
                                                {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload Excel File</Button></>}
                                            </Upload>
                                        </Col>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 14 }} xl={{ span: 14 }}>
                                            <div className="ant-col ant-form-item-label"><label className="ant-form-item-required" title="Select Sheet">Select Sheet</label></div>
                                            <Select placeholder="Select Sheet" style={{ width: "100%" }} notFoundContent="Upload Excel File First" onChange={e => onChangeSheet(e)}>
                                                {sheetList ? (
                                                    sheetList.map((type: any) => (
                                                        <Option key={type.value} value={type.value}>
                                                            {type.label}
                                                        </Option>
                                                    ))
                                                ) : (
                                                    <Option value="fetching">Upload Valid Excel File</Option>
                                                )}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>
                                            <div className="ant-col ant-form-item-label"><label className="ant-form-item-required" title="Select Mobile No. Column">Select Mobile No. Column</label></div>
                                            <Select placeholder="Select Mobile" style={{ width: "100%", marginTop: 5 }} onChange={e => selectMobile(e)} notFoundContent="Select Sheet First">
                                                {column ? (
                                                    column.map((type: any) => (
                                                        <Option key={type.value} value={type.value}>
                                                            {type.label}
                                                        </Option>
                                                    ))
                                                ) : (
                                                    <Option value="fetching">Select Mobile</Option>
                                                )}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>
                                            {column?.length > 0 ?
                                                <div className='dynamicSMS' style={{ display: "flex", overflow: "auto", }}>
                                                    {column.map((data: any, index: number) => <Button onClick={() => appendColumn(data.value)} style={{ height: 30, borderRadius: 20, backgroundColor: '#13C2C2', color: '#fff', marginBottom: 5 }}>{data.value}</Button>)}
                                                </div>
                                                :
                                                <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 20 }}> Please select sheet and dynamic value will appear here</div>}

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}>
                                            {/* <Form.Item
                                                name="messagecontent"
                                                label="Enter SMS Content"
                                                className="title-Text"
                                                rules={[
                                                    { required: true, message: "write sms content" },
                                                ]}
                                            >
                                                <TextArea showCount maxLength={100} />
                                            </Form.Item> */}

                                            <Input.TextArea value={message} onChange={e => setMessage(e.target.value)} style={{ minHeight: 200, border: '1px solid #00CA88', marginBottom: 10, marginTop: 10 }} ></Input.TextArea>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 0 }} xl={{ span: 12, offset: 0 }}>
                                            <span>SMS BALANCE : 566</span>
                                        </Col>
                                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 0 }} xl={{ span: 12, offset: 0 }}>
                                            <Button type="primary" icon={<SettingOutlined />} onClick={processExcel} className="mt-0 pull-right">
                                                Process
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 14, offset: 0 }} xl={{ span: 14, offset: 0 }}>
                    <Card title="Send SMS">
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: tableData,
                                filterData: tableData,
                                pagination: false,
                                bordered: true,
                                rowKey: "key",
                                rowSelection: rowSelection,
                                locale: { emptyText: 'Processed Data will appear here' }
                            }}
                            mobileBreakPoint={768}
                        />
                        <Space size={'large'} style={{ float: "right", marginTop: 10 }}>
                            <Button onClick={sendSms} type='primary' icon={<SendOutlined />}>Send Sms</Button>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </>
    )
}