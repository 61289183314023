import React, { useEffect } from 'react'
import error from './error.png'

export default function DisabledPage(props) {



  return (
    <>
      <style>
        {`
      @import url('https://fonts.googleapis.com/css?family=Barlow|Barlow+Condensed:700');
      body{
        font-size: 20px;
        height: 100vh;
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"%3E%3Cg fill-rule="evenodd"%3E%3Cg fill="%239C92AC" fill-opacity="0.4"%3E%3Cpath opacity=".5" d="M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z"/%3E%3Cpath d="M6 5V0H5v5H0v1h5v94h1V6h94V5H6z"/%3E%3C/g%3E%3C/g%3E%3C/svg%3E'),linear-gradient(to top, #0ba360 0%, #3cba92 100%);
      }
      .box {
        background: #0d0e0e;
        margin: 5% auto;
        padding: 3%;
        display: flex;
        flex-direction: column;
        max-width: 350px;
        border-radius: 5px ;
        position: relative;
        box-shadow: 0 15px 15px -15px #2C3E50;
      }
      
      img {
        height: 48px;
        width: 48px;
        margin: 0 auto -10px auto;
      }
      h2 {
        font-family: 'Barlow Condensed';
        text-align: center;
        color: gold;
      }
      
      p {
        font-family: 'Barlow';
        color: grey;
        font-weight: 500;
        margin: -8px auto 50px auto;
      }
      
      button {
        font-family: 'Barlow';
        border: none;
        outline:none;
        cursor:pointer;
        width :230px;
           height: 50px;
           font-size: 20px;
           text-align: center;
           
           color: rgba(255,255,255,0.9);
           border-radius: 10px;
        background-image: linear-gradient(325deg, rgba(34,67,195,1) 72%, rgba(253,187,45,1) 100%);
      }
      
      span{
        
        height: 24px;
        width: 24px;
        padding:5px;
        border-radius:10px;
        position: absolute;
        right:20px;
        top:20px;
      }
       `}
      </style>

      <div className="box">
        <span />
        <img src={error} alt="" />
        <h2>Institute Disabled</h2>
        <p>Your bills are due.  Please pay your bill to continue.</p>
        <div style={{textAlign:'center'}}>
        <button>RETRY CONNECTION</button>
        </div>
       
      </div>


    </>

  )
}
