import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined, SettingFilled } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, notification, Popconfirm, Row, Skeleton, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../../store/hooks/easyPeasy';
import Table from "../../../../../contents/AntTableResponsive";
import { SelectFeeHead } from '../../../../select/SelectFeeHead';
import { SelectFeeWaiver } from '../../../../select/SelectFeeWaiver';
import { SelectAcademicYear } from '../../../../select/SelectAcademicYear';
import { SelectGroupbySection } from '../../../../select/SelectGroupbySection';
import { SelectCategory } from '../../../../select/SelectCategory';
import { SelectClassConfig } from '../../../../select/SelectClassConfig';
import { SelectFeeSubHeadByFeeHead } from '../../../../select/SelectFeeSubHeadByFeeHead';


export default function WaiverConfig(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const studentBasicDetails = useStoreState((state) => state.common.studentBasicDetails);
    const fetchstudentBasicDetails = useStoreActions((state) => state.common.fetchstudentBasicDetails);
    const fetchfeeSubHeadConfigurationList = useStoreActions((state) => state.saccount.fetchfeeSubHeadConfigurationList);
    const saveStudentWaiver = useStoreActions((state) => state.saccount.saveStudentWaiver);
    const fetchlistGroupbyClassConfig = useStoreActions((state) => state.student.fetchlistGroupbyClassConfig);//
    const loading = useStoreState((state) => state.common.loading);

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);
    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);

    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const formSubmit = (value) => {
        value.groupId = value.groupId.groupId;
        fetchstudentBasicDetails(value)
    }

    const updateFomrSubmit = (value) => {
        value.identificationIds = selectedValue?.map((item) => item.identificationId);
        saveStudentWaiver(value);
        setIsModalVisible(false);
        setselectedRowKeys([]);
        setselectedValue([]);
        updateForm.resetFields()
    }



    const columns = [

        {
            title: 'Student Id',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },

    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [section, setSection] = useState<any>();
    const [group, setGroup] = useState<any>();

    const onProcess = () => {

        if (selectedRowKeys.length === 0) {
            notification.error({ message: 'Select the table row first' });
            return;
        } else {
            setIsModalVisible(true);
        }
    }

    return (
        <>

            <Form
                layout="vertical"
                onFinish={formSubmit}
                id="basic-info"
                form={form}
            >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="academicYear"
                            label="Academic Year"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select year " },
                            ]}
                        >

                            <SelectAcademicYear />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="classConfigId"
                            label="Section"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please class section" },
                            ]}
                        >

                            <SelectClassConfig selected={section} onChange={e => { setSection(e); setGroup(null); fetchlistGroupbyClassConfig(e); form.setFieldsValue({ groupId: null }) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="groupId"
                            label="Group"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select group" },
                            ]}
                        >

                            <SelectGroupbySection selected={group} onChange={e => { console.log(e); setGroup(e.groupId) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="categoryId"
                            label="Student Category"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select category" },
                            ]}
                        >

                            <SelectCategory />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Space size="small" style={{ float: "right" }} >
                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                Search
                            </Button>
                        </Space>
                    </Col>


                </Row>
            </Form>

            {studentBasicDetails?.length > 0 &&
                <>
                    < Skeleton loading={loading} paragraph={{ rows: 10 }} />
                    <div className="datatable-responsive" style={{ marginTop: 20 }}>
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                        <Table
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: studentBasicDetails,
                                filterData: studentBasicDetails,
                                bordered: true,
                                rowKey: "studentId",
                                rowSelection: rowSelection,
                                pagination: false,
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                    <br />
                    <Space size="small" style={{ float: "right" }} >
                        <Button type="primary" onClick={onProcess} style={{ height: 40 }} icon={<SettingFilled />}>
                            Process
                        </Button>
                    </Space>
                </>
            }


            <Modal
                title="Student Waiver"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Save"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >

                    <Form.Item
                        name="feeHeadId"
                        label="Fee Head:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select fee head" },
                        ]}
                    >
                        <SelectFeeHead onChange={(e) => fetchfeeSubHeadConfigurationList(e)} />
                    </Form.Item>
                    <Form.Item
                        name="feeSubHeadIds"
                        label="Fee Sub Head:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select fee sub head" },
                        ]}
                    >
                        <SelectFeeSubHeadByFeeHead />
                    </Form.Item>

                    <Form.Item
                        name="feeWaiverId"
                        label="Fee Waiver:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select fee waiver" },
                        ]}
                    >
                        <SelectFeeWaiver />
                    </Form.Item>

                    <Form.Item
                        name="waiverAmount"
                        label="Amount:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please insert fee amount" },
                        ]}
                    >
                        <Input placeholder="Amount" />
                    </Form.Item>



                </Form>

            </Modal>
        </>
    )
}
