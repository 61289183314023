import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { defaultSignatureListUrl, deleteDefaultSignatureUrl, deleteMasterSettingUserUrl, fetchInstituteInfoUrl, masterSettingsUserList, saveDefaultSignatureUrl, saveMasterSettingUser, signatureImageUpdate, staffListUrl, updateInstituteInfoUrl, updateMasterSettingUserUrl, updatePhoto } from '../../../http/masterSettings/masterSettings';
import { paymentStatusWiseAdmissionReport, sectionWiseAdmissionReport } from '../../../http/onlineAdmission/onlineAdmission';
import { smsSearchReportUrl, searchClassStudentIthAcademicYear, sectionDueListUrl, sendDueListSms, sendInstiuteSms, sendStaffSms, sendStudentSms, smsBlanceurl, smsTemplate, staffListForSendSms, staffSmsCount, templateSaveUrl, templateUpdateUrl } from '../../../http/sms/sms';
require("dotenv").config();

export interface OnlineAdmission{
    fetchSectionWiseOnlineAdmissionList: Thunk<OnlineAdmission>;
    setSectionWiseOnlineAdmissionList: Action<OnlineAdmission, any>;
    sectionWiseOnlineAdmissionList:any;

    fetchGroupWiseOnlineAdmissionList: Thunk<OnlineAdmission>;
    setGroupWiseOnlineAdmissionList: Action<OnlineAdmission, any>;
    groupWiseOnlineAdmissionList:any;

    fetchPaymentStatusWiseOnlineAdmissionList: Thunk<OnlineAdmission>;
    setPaymentStatusWiseOnlineAdmissionList: Action<OnlineAdmission, any>;
    paymentStatusWiseOnlineAdmissionList:any;
}

export const onlineAdmissionStore: OnlineAdmission ={
    sectionWiseOnlineAdmissionList:[],
    groupWiseOnlineAdmissionList:[],
    paymentStatusWiseOnlineAdmissionList:[],
    fetchSectionWiseOnlineAdmissionList:thunk(async (actions, payload) => {
        const response = await sectionWiseAdmissionReport(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body?.item.length > 0){
                actions.setSectionWiseOnlineAdmissionList(body.item);
            }else{
                actions.setSectionWiseOnlineAdmissionList([]);
                notification.error({ message: "No Student Found" })
            }
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setSectionWiseOnlineAdmissionList: action((state, payload) => {
        state.sectionWiseOnlineAdmissionList = payload;
    }),

    fetchGroupWiseOnlineAdmissionList:thunk(async (actions, payload) => {
        const response = await sectionWiseAdmissionReport(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body?.item.length > 0){
                actions.setGroupWiseOnlineAdmissionList(body.item);
            }else{
                actions.setGroupWiseOnlineAdmissionList([]);
                notification.error({ message: "No Student Found" })
            }
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setGroupWiseOnlineAdmissionList: action((state, payload) => {
        state.groupWiseOnlineAdmissionList = payload;
    }),

    fetchPaymentStatusWiseOnlineAdmissionList:thunk(async (actions, payload) => {
        const response = await paymentStatusWiseAdmissionReport(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if(body?.item.length > 0){
                actions.setPaymentStatusWiseOnlineAdmissionList(body.item);
            }else{
                actions.setPaymentStatusWiseOnlineAdmissionList([]);
                notification.error({ message: "No Student Found" })
            }
            
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setPaymentStatusWiseOnlineAdmissionList: action((state, payload) => {
        state.paymentStatusWiseOnlineAdmissionList = payload;
    }),
}