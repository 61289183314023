import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectFeeHead } from '../../../select/SelectFeeHead';
import { SelectLedgerIncome } from '../../../select/SelectLedgerIncome';
import { SelectLedgerOFPS } from '../../../select/SelectLedgerOFPS';



export default function OFPS(props) {

    const [form] = Form.useForm();


    const ofpsconfigurationView = useStoreState((state) => state.saccount.ofpsconfigurationView);
    const fetchofpsconfigurationView = useStoreActions((state) => state.saccount.fetchofpsconfigurationView);
    const saveofpsconfigurationView = useStoreActions((state) => state.saccount.saveofpsconfigurationView);


    useEffect(() => {
        fetchofpsconfigurationView();
    }, [])

    const formSubmit = (value) => {
        let postData = {
            ledgerId: value.ledgerId
        }
        saveofpsconfigurationView(postData);
        form.resetFields();
    }
    return (
        <>
            <Card title="Online Fees Payment System Configuration" >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Form
                            layout="vertical"
                            onFinish={formSubmit}
                            id="basic-info"
                            form={form}
                        >

                            <Card title="Save" >
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        name="ledgerId"
                                        label="Ledger"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select ledger" },
                                        ]}
                                    >

                                        <SelectLedgerOFPS style={{ width: 250 }} />
                                    </Form.Item>

                                    <Button type="primary" htmlType="submit" style={{ height: 40, marginLeft: 20 }} icon={<SaveOutlined />}>
                                        Save
                                    </Button>
                                </div>
                            </Card>






                        </Form>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Card title="Configuration View" >
                            <div>
                                Account No: {ofpsconfigurationView?.accountNo} <br /> <br />
                                Ledger Name: {ofpsconfigurationView?.ledgerName}
                            </div>
                        </Card>
                    </Col>
                </Row>


            </Card>
        </>
    )
}
