import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag} from 'antd';
import TableView from '../../../../contents/AntTableResponsive';
import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import Column from 'rc-table/lib/sugar/Column';
import { render } from '@testing-library/react';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectClass } from '../../../select/SelectClass';
import { SelectSemesterYearByClass } from '../../../select/SelectSemesterYearByClass';
import { SelectSemesterYear } from '../../../select/SelectSemesterYear';
import TextArea from 'rc-textarea';

export default function AdmitCardInstruction() {
    const [createForm] = Form.useForm();
    const [instructionForm] = Form.useForm();
    const { Option } = Select;
    const fetchExamListForAdmitCardInstruction = useStoreActions((state) => state.exam.fetchExamListForAdmitCardInstruction);
    const examListForAdmitCardInstructionArray = useStoreState((state) => state.exam.examListForAdmitCardInstructionArray);
    const saveExamInstruction = useStoreActions((state) => state.exam.saveExamInstruction);
    const [classId, setClassId] = useState<any>("");

    const instructionFieldShow = () => {

    }

    const selectClass = (value) => {
        setClassId(value);
        fetchExamListForAdmitCardInstruction(value);
        
    }

    const selectExam = (value) => {

    }

    const examInstructionSubmit = (value) => {
        console.log('value', value);
        
        const instructionOne = value.instruction_one == undefined ? 'Examinee must entered into the exam hall at least 15 minutes before the exam start.' : value.instruction_one;
        const instructionTwo = value.instruction_two == undefined ? 'No extra papers will be allowed except the admit.' : value.instruction_two;
        const instructionThree = value.instruction_three == undefined ? 'Examinee must carry all necessary essentials for the exam.' : value.instruction_three;
        const instructionFour = value.instruction_four == undefined ? 'Examinee must carry all necessary essentials for the exam.' : value.instruction_four;

        let postData = {
            examConfigId: value.examId,
            instructionTwo: instructionTwo,
            instructionOne: instructionOne,
            instructionThree:instructionThree,
            instructionFour: instructionFour
        }
        saveExamInstruction(postData)
        
    }

    return(
        <>
            <Card title="Admit Card Instruction">
                <Form
                    layout="vertical"
                    id="instructionForm"
                    onFinish={examInstructionSubmit}
                    form={instructionForm}
                >
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                        
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                    <Form.Item
                                        name="Class"
                                        label="Select Class"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select class" },
                                        ]}
                                    >
                                        <SelectClass onChange={(e) => selectClass(e)}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                    <Form.Item
                                        name="examId"
                                        label="Select Exam"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select exam" },
                                        ]}
                                    >
                                        <Select placeholder="Class List" style={{ width: "100%" }} onChange={(e) => selectExam(e)}>
                                            {examListForAdmitCardInstructionArray ? (
                                                    examListForAdmitCardInstructionArray.map((type, idx) => (
                                                    <Option key={type.examConfigId} value={type.examConfigId}>
                                                        {type.examName}
                                                    </Option>
                                                    ))
                                                ) : (
                                                    <Option value="fetching">Fetching Exam</Option>
                                                )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                    </Col>
                </Row>
                {examListForAdmitCardInstructionArray.length > 0 ? 
                <div>
                    <br />
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                <Row>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12}} xl={{ span: 12}}>
                                        <Form.Item
                                                name="instruction_one"
                                                label="Instruction-1"
                                                className="title-Text"
                                            >
                                                <TextArea rows={4} placeholder="Examinee must entered into the exam hall at least 15 minutes before the exam start." style={{ width:"100%" }} />
                                            </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12}} xl={{ span: 12}}>
                                        <Form.Item
                                            name="instruction_two"
                                            label="Instruction-2"
                                            className="title-Text"
                                        >
                                                <TextArea rows={4} placeholder="No extra papers will be allowed except the admit." style={{ width:"100%" }}/>
                                            </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12}} xl={{ span: 12}}>
                                        <Form.Item
                                                name="instruction_three"
                                                label="Instruction-3"
                                                className="title-Text"
                                            >
                                                <TextArea rows={4} placeholder="Examinee must carry all necessary essentials for the exam." style={{ width:"100%" }}/>
                                            </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12}} xl={{ span: 12}}>
                                        <Form.Item
                                                name="instruction_four"
                                                label="Instruction-4"
                                                className="title-Text"
                                            >
                                                <TextArea rows={4} placeholder="Authority deserves all rights to expel the examinee due to unfair-means in the exam." style={{ width:"100%" }}/>
                                            </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                        <Button type="primary" htmlType="submit" className="mt-0" icon={<SaveOutlined />} style={{ float: "right" }}>
                                                Save
                                        </Button>
                                    </Col>
                                </Row>
                                
                            
                        </Col>
                    </Row>
                </div>
                : '' }
                </Form>
            </Card>
        </>
    )
}

