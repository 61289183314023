import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Radio, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import SessionYear from '../Students/settings/SessionYear.page';
import { SelectAcademicYear } from '../../select/SelectAcademicYear';
import { DownloadOutlined, EditOutlined, PlusOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import testimonialsOne from '../tesimonial/testimonialSVG/testimonial-1.svg';
import testimonialsTwo from '../tesimonial/testimonialSVG/testimonial-2.svg';
import testimonialsThree from '../tesimonial/testimonialSVG/testimonial-3.svg';
import testimonialsFour from '../tesimonial/testimonialSVG/testimonial-4.svg';
export default function TetimonialAddDownload() {
    const [searchForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [downloadForm] = Form.useForm();
    const { Option } = Select;
    const fetchTetimonilasList = useStoreActions((state) => state.testimonials.fetchTetimonilasList);
    const tetimonilasList = useStoreState((state) => state.testimonials.tetimonilasList);
    const updateTestimonials = useStoreActions((state) => state.testimonials.updateTestimonials)
    const fetchTestimonialsPdf = useStoreActions((state) => state.testimonials.fetchTestimonialsPdf);
    const [updateTestimonialId, setUpdateTestimonialId] = useState<any>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
    const [testimonialTemplateId, setTestimonialTemplateId] = useState<any>();
    const loading = useStoreState((state) =>  state.testimonials.loading);

    const searchStudentForm = (value) => {
        fetchTetimonilasList(value.academic_year);
    }

    const downloadTetimonials = (id) => {
        
        let postData:any = {
            "updateTestimonialId" : updateTestimonialId,
            "testimonialTemplateId" : testimonialTemplateId
        }
        fetchTestimonialsPdf(postData);
    }

    const updateSubmitForm = (value) => {
        console.log('value', value);
        let postData:any = {
            "boardRegNo": value.board_reg,
            "boardRoll": value.board_roll,
            "examBoard": value.board,
            "examGpa": value.gpa,
            "examGrade": value.grade,
            "examName": value.exam_name,
            "examSession": value.session,
            "passingYear": value.passing_year,
            "testimonialId": updateTestimonialId
        }
        
        updateTestimonials(postData)
    }

    const column = [
        { title: 'Serial', dataIndex: 'testimonialSerial', key: 'testimonialSerial', showOnResponse: true, showOnDesktop: true  },
        { title: 'Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true},
        { title: 'Grade', dataIndex: 'examGrade', key: 'examGrade', showOnResponse: true, showOnDesktop: true},
        { title: 'GPA', dataIndex: 'examGpa', key: 'examGpa', showOnResponse: true, showOnDesktop: true},
        { title: 'Passing Year', dataIndex: 'passingYear', key: 'passingYear', showOnResponse: true, showOnDesktop: true},
        { title: 'Board', dataIndex: 'examBoard', key: 'examBoard', showOnResponse: true, showOnDesktop: true},
        { title: 'Roll No.', dataIndex: 'boardRoll', key: 'boardRoll', showOnResponse: true, showOnDesktop: true},
        { title: 'Reg No.', dataIndex: 'boardregNo', key: 'boardregNo', showOnResponse: true, showOnDesktop: true},
        { title: 'Session', dataIndex: 'examSession', key: 'examSession', showOnResponse: true, showOnDesktop: true},
        { title: 'Action', dataIndex: 'testimonialId', key: 'testimonialId', showOnResponse: true, showOnDesktop: true,
          render: (text, record:any, index) => (
            <Space size="middle">
                <Tooltip title="Edit">
                    <Button type='primary' icon={<EditOutlined />} onClick={() => {
                        setIsModalVisible(true);
                        setUpdateTestimonialId(record.testimonialId);
                        updateForm.setFieldsValue({
                            exam_name:record.examName,
                            board_reg:record.boardregNo, 
                            gpa:record.examGpa,
                            board:record.examBoard,
                            board_roll: record.boardRoll,
                            passing_year: record.passingYear,
                            grade:record.examGrade,
                            session:record.examSession,
                        });
                    }} />
                </Tooltip>
                <Button type='primary' icon={<DownloadOutlined />} onClick={() => {
                    setIsDownloadModalVisible(true);
                    setUpdateTestimonialId(record.testimonialId);
                }}/>

            </Space>
          ),
        },
        
    ];

    
    return (
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                    <Form
                        layout="vertical"
                        id="sessionInfo"
                        onFinish={searchStudentForm}
                        form={searchForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8, offset:6 }} xl={{ span: 8, offset:6 }}>
                                <Form.Item
                                    name="academic_year"
                                    label="Academic Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select academic year" },
                                    ]}
                                >
                                    <SelectAcademicYear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Button type="primary" htmlType="submit" icon={<SearchOutlined />} style={{ float: "right" }}>
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            < Skeleton loading={loading} paragraph={{rows:10}} />
            <Row className={tetimonilasList.length > 0 ? '' : 'd-none'}>
                {
                    !loading &&
                    <Col span={24}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns:column,
                                rowKey:"testimonialId",
                                dataSource: tetimonilasList,
                                filterData: tetimonilasList,
                                pagination: true,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                    </Col>

                }
            </Row>
            <Modal
                title="Update"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateSubmitForm}
                    form={updateForm}
                >
                    <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                                name="exam_name"
                                label="Exam Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select exam name" },
                                ]}
                            >
                                <Select placeholder="exam name">
                                    <Option value="fetching">Fetching Semester</Option>
                                    <Option value="PEC">PEC</Option>
                                    <Option value="JSC">JSC</Option>
                                    <Option value="JDC">JDC</Option>
                                    <Option value="SSC">SSC</Option>
                                    <Option value="SSC (Dakhil)">SSC (Dakhil)</Option>
                                    <Option value="HSC">HSC</Option>
                                    <Option value="HSC (Alim)">HSC (Alim)</Option>
                                    <Option value="BA">BA</Option>
                                    <Option value="BBA">BBA</Option>
                                    <Option value="BBS">BBS</Option>
                                    <Option value="B.Com">B.Com</Option>
                                    <Option value="B.Sc">B.Sc</Option>
                                    <Option value="Fazil">Fazil</Option>
                                </Select>
                        </Form.Item>
                        <Form.Item
                            name="board_reg"
                            label="Board Reg"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please enter board reg" },
                            ]}
                        >
                            <InputNumber placeholder="board reg" />    
                        </Form.Item>
                        <Form.Item
                            name="gpa"
                            label="GPA"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please enter gpa" },
                            ]}
                        >
                            <InputNumber placeholder="enter gpa" />    
                        </Form.Item>
                        <Form.Item
                            name="board"
                            label="Board"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select board" },
                            ]}
                        >
                            <Select placeholder="select board">
                                <Option value=""> Select Board</Option>
                                <Option value="Dhaka">Dhaka</Option>
                                <Option value="Rajshahi">Rajshahi</Option>
                                <Option value="Jessore">Jessore</Option>
                                <Option value="Barisal">Barisal</Option>
                                <Option value="Dinajpur">Dinajpur</Option>
                                <Option value="Chattogram">Chattogram</Option>
                                <Option value="Cumilla">Cumilla</Option>
                                <Option value="Mymensingh">Mymensingh</Option>
                                <Option value="Sylhet">Sylhet</Option>
                                <Option value="Madrasah">Madrasah</Option>
                                <Option value="BTEB">BTEB</Option>
                                <Option value="DPE">DPE</Option>
                                <Option value="National University">National University</Option>
                            </Select>    
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                            name="board_roll"
                            label="Board Roll"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please enter board roll" },
                            ]}
                        >
                            <InputNumber placeholder="enter board roll" />    
                        </Form.Item>
                        <Form.Item
                            name="passing_year"
                            label="Passing Year"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select passing Year" },
                            ]}
                        >
                            <SelectAcademicYear />
                        </Form.Item>
                        <Form.Item
                            name="grade"
                            label="Grade"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select grade" },
                            ]}
                        >
                            <Select placeholder="select grade">
                                <Option value="A+">A+</Option>
                                <Option value="A">A</Option>
                                <Option value="A-">A-</Option>
                                <Option value="B">B</Option>
                                <Option value="C">C</Option>
                                <Option value="D">D</Option>
                                <Option value="F">F</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="session"
                            label="Session"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please enter session" },
                            ]}
                        >
                            <Input placeholder="enter session" />
                        </Form.Item>
                    </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                title="Select Testimonial"
                visible={isDownloadModalVisible}
                onOk={downloadTetimonials}
                onCancel={() => setIsDownloadModalVisible(false)}
                width={1000}
                className="testimonial-modal-wrapper"
                okText="Download"
            >
                <Radio.Group name="radiogroup" defaultValue={1} style={{ width: "100%"}}>
                    <Row>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }} className="text-center">
                            <Radio onChange={(e) => setTestimonialTemplateId(1)}>
                                <img src={testimonialsOne} alt="testimonials-1" />    
                                <p>Template One (Vertical)</p>
                            </Radio>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }} className="text-center">
                            <Radio value={2} onChange={(e) => setTestimonialTemplateId(e.target.value)}>
                                <img src={testimonialsTwo} alt="testimonials-1" />    
                                <p>Template Two (Vertical)</p>
                            </Radio>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }} className="text-center">
                            <Radio value={3} onChange={(e) => setTestimonialTemplateId(e.target.value)}>
                                <img src={testimonialsThree} alt="testimonials-1" />    
                                <p>Template One (Horizental)</p>
                            </Radio>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }} className="text-center">
                            <Radio value={4} onChange={(e) => setTestimonialTemplateId(e.target.value)}>
                                <img src={testimonialsFour} alt="testimonials-1" />    
                                <p>Template Two (Horizental)</p>
                            </Radio>
                        </Col>
                    </Row>
                </Radio.Group>
            </Modal>
        </>
    )
}