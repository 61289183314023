
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton, DatePicker } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import TableView from '../../../../contents/AntTableResponsive';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { FilePdfOutlined } from '@ant-design/icons';
import ReactExport from "react-export-excel";
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectFeeHeadMultiple } from '../../../select/SelectFeeHeadMultiple';
import moment from 'moment';
require('jspdf-autotable');

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

export default function FeeHeadCollectionStudentDateToDate() {

    const [studentWiseReportFormDateToDate] = Form.useForm();
    //const fetchshowStudentFeeHeadPaymentViewDateToDate = useStoreActions((state) => state.saccount.fetchshowStudentFeeHeadPaymentViewDateToDate);
    const showsectionTotalPaidDetailsDateToDate = useStoreActions((state) => state.saccount.showsectionTotalPaidDetailsDateToDate);
    const showStudentFeeHeadPaymentViewDateToDate = useStoreState((state) =>  state.saccount.showStudentFeeHeadPaymentViewDateToDate);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState<any>([])
    const formSubmit = (value) => {
        setLoading(true);
        let postData:any = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
        }
        showsectionTotalPaidDetailsDateToDate(postData);
        
    }
    useEffect(() => {
        setTableData(showStudentFeeHeadPaymentViewDateToDate?.studentList);
        setLoading(false);
    }, [showStudentFeeHeadPaymentViewDateToDate])


    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true  },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true  },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName1!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName1, dataIndex: 'feeHead1', key: 'feeHead1', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName2!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName2, dataIndex: 'feeHead2', key: 'feeHead2', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName3!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName3, dataIndex: 'feeHead3', key: 'feeHead3', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName4!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName4, dataIndex: 'feeHead4', key: 'feeHead4', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName5!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName5, dataIndex: 'feeHead5', key: 'feeHead5', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName6!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName6, dataIndex: 'feeHead6', key: 'feeHead6', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName7!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName7, dataIndex: 'feeHead7', key: 'feeHead7', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName8!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName8, dataIndex: 'feeHead8', key: 'feeHead8', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName9!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName9, dataIndex: 'feeHead9', key: 'feeHead9', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName10!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName10, dataIndex: 'feeHead10', key: 'feeHead10', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName11!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName11, dataIndex: 'feeHead11', key: 'feeHead11', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName12!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName12, dataIndex: 'feeHead12', key: 'feeHead12', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName13!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName13, dataIndex: 'feeHead13', key: 'feeHead13', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName14!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName14, dataIndex: 'feeHead14', key: 'feeHead14', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName15!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName15, dataIndex: 'feeHead15', key: 'feeHead15', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName16!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName16, dataIndex: 'feeHead16', key: 'feeHead16', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName17!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName17, dataIndex: 'feeHead17', key: 'feeHead17', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName18!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName18, dataIndex: 'feeHead18', key: 'feeHead18', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName19!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName19, dataIndex: 'feeHead19', key: 'feeHead19', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName20!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName20, dataIndex: 'feeHead20', key: 'feeHead20', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName21!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName21, dataIndex: 'feeHead21', key: 'feeHead21', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName22!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName22, dataIndex: 'feeHead22', key: 'feeHead22', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName23!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName23, dataIndex: 'feeHead23', key: 'feeHead23', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName24!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName24, dataIndex: 'feeHead24', key: 'feeHead24', showOnResponse: true, showOnDesktop: true },
        showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName25!==''&&  { title: showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName25, dataIndex: 'feeHead25', key: 'feeHead25', showOnResponse: true, showOnDesktop: true },
        { title: 'Total', dataIndex: 'total', key: 'total', showOnResponse: true, showOnDesktop: true  },
    ];

    return(
        <>
            <>
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={studentWiseReportFormDateToDate}
                        >
                            
                            <Row gutter={8}>
                                    <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>


                                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                        <Form.Item
                                            name="fromDate"
                                            label="From Date"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please enter from date" },
                                            ]}
                                        >

                                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                        <Form.Item
                                            name="toDate"
                                            label="To Date"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please enter to date" },
                                            ]}
                                        >

                                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                                        <Space size="small" >
                                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                                Search
                                            </Button>
                                        </Space>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }}>
                    <Row style={{ display: showStudentFeeHeadPaymentViewDateToDate !== null ? '' : 'none' }}>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}}>
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns:studentListColumn,
                                    rowKey:"identificationId",
                                    dataSource: tableData,
                                    filterData: tableData,
                                    pagination: true,
                                    bordered: true,                           
                                    exportable : true,                           
                                }}
                                mobileBreakPoint={768}
                            />
                            <br />
                            {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                        </Col>
                        <Col span={24}>
                            <Space size="small" style={{ float: "right" }}>
                            {/* <ExcelFile
                                    element={<Button type="primary" icon={<FileExcelOutlined />} >
                                        Download Excel
                                    </Button>}
                                    filename={`Student Fee Head Collection of Class ${$(".classSelect").text()} and Academic Year ${studentWiseReportFormDateToDate.getFieldValue("yearId")}`}
                                >
                                    <ExcelSheet data={tableData} name="Student Fee Head Collection">
                                        <ExcelColumn label="Student ID" value="customStudentId" />
                                        <ExcelColumn label="Student Name" value="studentName" />
                                        <ExcelColumn label="Roll" value="studentRoll" />
                                        {showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName1!==''&&<ExcelColumn label={showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName1} value="feeHead1" />}
                                        {showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName2!==''&&<ExcelColumn label={showStudentFeeHeadPaymentViewDateToDate?.feeHeadNames?.feeHeadName2} value="feeHead2" />}

                                        <ExcelColumn label="Total" value="studentRoll" />
                                    </ExcelSheet>
                                </ExcelFile> */}
                                {/* <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportPdf()} >PDF Download</Button> */}
                            </Space>
                        </Col>
                    </Row>
                </Skeleton>

            </>
        </>
    )
}
