import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Space } from 'antd'
import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
require('jspdf-autotable');

var getColumns = function () {
    return [
        { title: "Ledger Name", dataKey: "ledgerName" },
        { title: "Debit Amount", dataKey: "debit" },
        { title: "Credit Amount", dataKey: "credit" },
    ];
};

var getColumnsTotal = function () {
    return [
        { title: "Total", dataKey: "total" },
        { title: "", dataKey: "totalDebit" },
        { title: "", dataKey: "totalCredit" },
    ];
};

var getColumnsGrand = function () {
    return [
        { title: "Grand Total", dataKey: "grandTotal" },
        { title: "", dataKey: "grandTotalDebit" },
        { title: "", dataKey: "grandTotalCredit" },
    ];
};
let fd = '';
let td = '';
export default function CashSummary(props) {

    const cashSummary = useStoreState((state) => state.ledger.cashSummary);
    const check = useStoreState((state) => state.ledger.check);
    const fetchcashSummary = useStoreActions((state) => state.ledger.fetchcashSummary);
    const loading = useStoreState((state) =>  state.ledger.loading);

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD")
        }
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");
        fetchcashSummary(postData);
    };
    var negToBrac = function (value) {
        if (value == "0.0" || value == 0.0) {
            value = 0
        };
        if (value >= 0) {
            return value
        }
        if (value < 0) {
            return '(' + Math.abs(value) + ')'
        }
    }
    useEffect(() => {

        var table = $('#tbl')
        let option = "";
        let datas = cashSummary;
        if (cashSummary?.totalIncome!==undefined) {
            var incomelist = datas.incomeList;
            option = option + "<tbody>";
            option = option + "<tr><th colspan='2'>Income List</th></tr>";
            for (var i = 0; i < incomelist?.length; i++) {
                option = option + "<tr><td>&nbsp;&nbsp;&nbsp;" + incomelist[i].ledgerName + "</td><td>" + incomelist[i].amount + "</td></tr>";
            }

            option = option + "<tr><th style='text-align: right'>Total Income</th><th>" + datas.totalIncome + "</th></tr>";

            var expenselist = datas.expenseList;

            option = option + "<tr><th colspan='2'>Expense List</th></tr>";
            for (var i = 0; i < expenselist?.length; i++) {
                option = option + "<tr><td>&nbsp;&nbsp;&nbsp;" + expenselist[i].ledgerName + "</td><td>" + expenselist[i].amount + "</td></tr>";
            }

            option = option + "<tr><th style='text-align: right'>Total Expense</th><th>" + datas.totalExpense + "</th></tr>";

            option = option + "<tr><th></th><th></th></tr>";

            option = option + "<tr><th>Operating Surplus (Deficit)</th><th>" + negToBrac(datas.operatingSurplusDeficit) + "</th></tr>";

            option = option + "<tr><th></th><th></th></tr>";


            var assetLiabilitiesList = datas.assetLiabilitiesList;
            option = option + "<tr><th colspan='2'>Plus Non Operating Movements</th></tr>";
            for (var i = 0; i < assetLiabilitiesList?.length; i++) {
                option = option + "<tr><td>&nbsp;&nbsp;&nbsp;" + assetLiabilitiesList[i].ledgerName + "</td><td>" + negToBrac(assetLiabilitiesList[i].amount) + "</td></tr>";
            }

            option = option + "<tr><th style='text-align: right'>Total Non Operating Movement</th><th>" + negToBrac(datas.totalNonOperatingMovement) + "</th></tr>";


            option = option + "<tr><th></th><th></th></tr>";

            option = option + "<tr><th>Net Cash Movement</th><th>" + negToBrac(datas.netCashMovement) + "</th></tr>";

            option = option + "<tr><th></th><th></th></tr>";

            var openingBalanceList = datas.openingBalanceList;

            option = option + "<tr><th>Opening Balance</th><th>" + datas.totalOpeningBalance + "</th></tr>";

            for (var i = 0; i < openingBalanceList?.length; i++) {
                option = option + "<tr><td>&nbsp;&nbsp;&nbsp;" + openingBalanceList[i].ledgerName + "</td><td>" + openingBalanceList[i].amount + "</td></tr>";
            }

            var closingBalanceList = datas.closingBalanceList;

            option = option + "<tr><th>Closing Balance</th><th>" + datas.totalClosingBalance + "</th></tr>";

            for (var i = 0; i < closingBalanceList?.length; i++) {
                option = option + "<tr><td>&nbsp;&nbsp;&nbsp;" + closingBalanceList[i].ledgerName + "</td><td>" + closingBalanceList[i].amount + "</td></tr>";
            }

            option = option + "</tbody>";
            table.append(option);
        }
    }, [check])

    function exportPdf() {
        let assetLiabilitiesList = cashSummary.assetLiabilitiesList.map(function (item) {
            return {
                ledgerName: item.ledgerName,
                amount: negToBrac(item.amount),
            }
        })
        var doc = new jsPDF("p", "mm", "a4");
        pdfDataL(doc, "");
        var details = `Cash Summary from ${fd} to ${td}`;

        doc.text(details, 105, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        let first = doc.autoTable.previous;
        doc.autoTable([
            { title: "", dataKey: "ledgerName" },
            { title: "", dataKey: "amount" },
        ], cashSummary.incomeList, {
            startY: 45,
            showHeader: "never",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 9
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 100
                },
                amount: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 81.8
                },
            },
            drawRow: function (row, data) {
                if (row.index === 0) {
                    doc.setFontSize(10);
                    doc.rect(data.settings.margin.left, row.y, data.table.width, 7, "S");
                    doc.autoTableText(" " + "Income List", data.settings.margin.left, (row.y + row.height / 2) - 1,
                        {
                            halign: "left",
                            valign: "top",
                            fontSize: 9
                        }
                    );
                    data.cursor.y += 7;
                }
            },
            addPageContent: pageContent,
        });
        if (cashSummary?.incomeList?.length > 0) {
            doc.autoTable([
                { title: "", dataKey: "b1" },
                { title: "", dataKey: "b2" },
            ], [
                {
                    b1: "Total Income",
                    b2: cashSummary.totalIncome,
                }
            ], {
                startY: doc.autoTable.previous.finalY + 0,
                showHeader: "never",
                theme: 'grid',

                columnStyles: {
                    b1: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 100
                    },
                    b2: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 81.8
                    },

                },

                // addPageContent: pageContent,
            });

        }

        doc.autoTable([
            { title: "", dataKey: "ledgerName" },
            { title: "", dataKey: "amount" },
        ], cashSummary.expenseList, {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 9
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 100
                },
                amount: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 81.8
                },
            },
            drawRow: function (row, data) {
                if (row.index === 0) {
                    doc.setFontSize(10);
                    doc.rect(data.settings.margin.left, row.y, data.table.width, 7, "S");
                    doc.autoTableText(" " + "Expense List", data.settings.margin.left, (row.y + row.height / 2) - 1,
                        {
                            halign: "left",
                            valign: "top",
                            fontSize: 9
                        }
                    );
                    data.cursor.y += 7;
                }
            },
            // addPageContent: pageContent,
        });
        if (cashSummary?.expenseList?.length > 0) {
            doc.autoTable([
                { title: "", dataKey: "b1" },
                { title: "", dataKey: "b2" },
            ], [
                {
                    b1: "Total Expense",
                    b2: cashSummary.totalExpense,
                }
            ], {
                startY: doc.autoTable.previous.finalY + 0,
                showHeader: "never",
                theme: 'grid',

                columnStyles: {
                    b1: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 100
                    },
                    b2: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 81.8
                    },

                },

                // addPageContent: pageContent,
            });
        }

        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
        ], [
            {
                b1: "Operating Surplus (Deficit)",
                b2: negToBrac(cashSummary.operatingSurplusDeficit),
            }
        ], {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',

            columnStyles: {
                b1: {
                    halign: "left",
                    fontSize: 9,
                    columnWidth: 100,
                    fontStyle: 'bold'
                },
                b2: {
                    halign: "right",
                    fontSize: 9,
                    columnWidth: 81.8,
                    fontStyle: 'bold'
                },

            },

            // addPageContent: pageContent,
        });


        doc.autoTable([
            { title: "", dataKey: "ledgerName" },
            { title: "", dataKey: "amount" },
        ], assetLiabilitiesList, {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 9
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 100
                },
                amount: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 81.8
                },
            },
            drawRow: function (row, data) {
                if (row.index === 0) {
                    doc.setFontSize(10);
                    doc.rect(data.settings.margin.left, row.y, data.table.width, 7, "S");
                    doc.autoTableText(" " + "Plus Non Operating Movements", data.settings.margin.left, (row.y + row.height / 2) - 1,
                        {
                            halign: "left",
                            valign: "top",
                            fontSize: 9
                        }
                    );
                    data.cursor.y += 7;
                }
            },
            // addPageContent: pageContent,
        });
        if (assetLiabilitiesList?.length > 0) {
            doc.autoTable([
                { title: "", dataKey: "b1" },
                { title: "", dataKey: "b2" },
            ], [
                {
                    b1: "Total Non Operating Movement	",
                    b2: negToBrac(cashSummary.totalNonOperatingMovement),
                }
            ], {
                startY: doc.autoTable.previous.finalY + 0,
                showHeader: "never",
                theme: 'grid',

                columnStyles: {
                    b1: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 100
                    },
                    b2: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 81.8
                    },

                },

                // addPageContent: pageContent,
            });
        }

        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
        ], [
            {
                b1: "Net Cash Movement",
                b2: negToBrac(cashSummary.netCashMovement),
            }
        ], {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',

            columnStyles: {
                b1: {
                    halign: "left",
                    fontSize: 9,
                    columnWidth: 100,
                    fontStyle: 'bold'
                },
                b2: {
                    halign: "right",
                    fontSize: 9,
                    columnWidth: 81.8,
                    fontStyle: 'bold'
                },

            },

            // addPageContent: pageContent,
        });


        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
        ], [
            {
                b1: "Opening Balance",
                b2: cashSummary.totalOpeningBalance,
            }
        ], {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',

            columnStyles: {
                b1: {
                    halign: "left",
                    fontSize: 9,
                    columnWidth: 100,
                    fontStyle: 'bold'
                },
                b2: {
                    halign: "right",
                    fontSize: 9,
                    columnWidth: 81.8,
                    fontStyle: 'bold'
                },

            },

            // addPageContent: pageContent,
        });

        if (cashSummary?.openingBalanceList?.length > 0) {
            doc.autoTable([
                { title: "", dataKey: "ledgerName" },
                { title: "", dataKey: "amount" },
            ], cashSummary.openingBalanceList, {
                startY: doc.autoTable.previous.finalY + 0,
                showHeader: "never",
                theme: 'grid',

                columnStyles: {
                    ledgerName: {
                        halign: "left",
                        fontSize: 8,
                        columnWidth: 100
                    },
                    amount: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 81.8
                    },

                },

                // addPageContent: pageContent,
            });
        }

        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
        ], [
            {
                b1: "Closing Balance",
                b2: cashSummary.totalClosingBalance,
            }
        ], {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',

            columnStyles: {
                b1: {
                    halign: "left",
                    fontSize: 9,
                    columnWidth: 100,
                    fontStyle: 'bold'
                },
                b2: {
                    halign: "right",
                    fontSize: 9,
                    columnWidth: 81.8,
                    fontStyle: 'bold'
                },

            },

            // addPageContent: pageContent,
        });

        if (cashSummary?.closingBalanceList?.length > 0) {
            doc.autoTable([
                { title: "", dataKey: "ledgerName" },
                { title: "", dataKey: "amount" },
            ], cashSummary.closingBalanceList, {
                startY: doc.autoTable.previous.finalY + 0,
                showHeader: "never",
                theme: 'grid',

                columnStyles: {
                    ledgerName: {
                        halign: "left",
                        fontSize: 8,
                        columnWidth: 100
                    },
                    amount: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 81.8
                    },

                },

                // addPageContent: pageContent,
            });
        }




        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }

        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");
    }

    return (
        <>
            <Card title="Cash Summary" >
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>
                < Skeleton loading={loading} paragraph={{rows:10}}  />
                {cashSummary?.totalIncome!==undefined &&
                    <>
                        <div className="table-responsive">
                            <table id="tbl" className="table table-bordered custom-table table-striped text-left">
                            </table>
                        </div>
                        <Button type="primary" onClick={exportPdf} icon={<FilePdfOutlined />} style={{ float: "right" }}>Download PDF</Button>
                    </>
                }
            </Card>
        </>
    )
}
