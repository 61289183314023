
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Skeleton, Table } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SaveOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import TableView from '../../../../contents/AntTableResponsive';
// import { Table } from "ant-table-extensions-extended";
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectFeeHeadAttendance } from '../../../select/SelectFeeHeadAttendance';
import { SelectFeeSubHeadByFeeHeadSingle } from '../../../select/SelectFeeSubHeadByFeeHeadSingle';


export default function AttendanceFineAdd() {

    const [form2] = Form.useForm();
    const fetchfeeSubHeadConfigurationList = useStoreActions((state) => state.saccount.fetchfeeSubHeadConfigurationList);
    const absentFineRecordStudentListForInput = useStoreState((state) => state.saccount.absentFineRecordStudentListForInput);
    const fetchabsentFineRecordStudentListForInput = useStoreActions((state) => state.saccount.fetchabsentFineRecordStudentListForInput);
    const saveAbsentFineRecord = useStoreActions((state) => state.saccount.saveAbsentFineRecord);
    const loading = useStoreState((state) => state.student.loading);

    const [data, setData] = useState<any>('');

    const formSubmit = (value) => {
        setData(value);
        fetchabsentFineRecordStudentListForInput(value);
    }

    const [tableData, setTableData] = useState<any>([]);

    useEffect(() => {
        setTableData(absentFineRecordStudentListForInput)
    }, [absentFineRecordStudentListForInput])


    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Fee Head', dataIndex: 'feeHeadName', key: 'feeHeadName', showOnResponse: true, showOnDesktop: true },
        { title: 'Fee Sub Head', dataIndex: 'feeSubHeadName', key: 'feeSubHeadName', showOnResponse: true, showOnDesktop: true },
        {
            title: "Amount",
            render: (text: any, record: any, index) => (
                <InputNumber
                    min={0}
                    onChange={onchangeValue("amount", record, index)}
                    value={record.amount}
                    placeholder="Amount"
                    style={{ width: 100 }}
                ></InputNumber>
            ),
        },
        Table.SELECTION_COLUMN,
    ];

    const onchangeValue: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...tableData];
            newData[index][key] = e;
            setTableData(newData);
        };

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onProcess = () => {
        if (selectedValue.length === 0) {
            notification.error({ message: "Please select student" });
            return
        }
        let studentList = selectedValue.map(item => {
            return {
                amount: item.amount,
                identificationId: item.identificationId,
            }
        });

        let payload = {
            "feeHeadId": data.feeHeadId,
            "feeSubHeadId": data.feeSubHeadId,
            "studentList": studentList
        };

        setselectedRowKeys([]);
        setselectedValue([])

        saveAbsentFineRecord(payload)
        // setIsModalVisible(true);
      setTimeout(() => {
        fetchabsentFineRecordStudentListForInput(data);
      }, 500);
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const submitData = (value) => {
        saveAbsentFineRecord(value);
        setIsModalVisible(false);
        form.resetFields()
        setselectedRowKeys([]);;
        setTableData([]);
        setTimeout(() => {
            setTableData(absentFineRecordStudentListForInput)
        }, 200);
    }

    return (
        <>

            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 2 }} xl={{ span: 20, offset: 2 }}>
                    <Form
                        layout="vertical"
                        id="classConfigInfo"
                        onFinish={formSubmit}
                        form={form2}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="academicYear"
                                    label="Academic Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select year" },
                                    ]}
                                >
                                    <SelectAcademicYear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="classConfigId"
                                    label="Select Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectClassConfig />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="feeHeadId"
                                    label="Fee Head"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select fee" },
                                    ]}
                                >
                                    <SelectFeeHeadAttendance onChange={e => { fetchfeeSubHeadConfigurationList(e); form2.setFieldsValue({ feeSubHeadId: null }) }} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                    name="feeSubHeadId"
                                    label="Fee Sub Head"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select fee" },
                                    ]}
                                >
                                    <SelectFeeSubHeadByFeeHeadSingle />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                    </Form>
                </Col>
            </Row>
            {< Skeleton loading={loading} paragraph={{ rows: 10 }} />}
            {tableData?.length>0 &&
            <Row >
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                    {/* <TableView
                        antTableProps={{
                            showHeader: true,
                            columns: studentListColumn,
                            rowKey: "identificationId",
                            dataSource: absentFineRecordStudentListForInput,
                            filterData: absentFineRecordStudentListForInput,
                            pagination: false,
                            bordered: true,
                            rowSelection: rowSelection,
                        }}
                        mobileBreakPoint={768}
                    /> */}
                    <Table
                        columns={studentListColumn}
                        rowSelection={rowSelection}
                        dataSource={tableData}
                        rowKey={'identificationId'}
                    />
                    {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                </Col>
                <br />
                <Col span={24}>
                    <Space size="small" style={{ float: "right", marginTop: 15 }}>

                        <Button type="primary" icon={<SaveOutlined />} onClick={() => onProcess()} >Save</Button>
                    </Space>
                </Col>
            </Row>
}
            <Modal
                title="Fee Discard"
                visible={isModalVisible}
                // onOk={handleOk}
                // okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                centered
            >
                <Form
                    layout="vertical"
                    onFinish={submitData}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24}>
                            <Form.Item
                                name="feeSubHeadIds"
                                label="Fee Sub Head"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select fee sub head" },
                                ]}
                            >

                                <SelectFeeSubHeadByFeeHeadSingle />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                            Save
                        </Button>
                    </div>
                </Form>

            </Modal>

        </>
    )
}
