import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker, Skeleton } from 'antd';
import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';


export default function PhotoUpdate() {

    const [searchStudentForm] = Form.useForm();
    const fetchStudentListByClassConfigId = useStoreActions((state) => state.student.fetchStudentListByClassConfigId);
    const updateStudentPhoto = useStoreActions((state) => state.student.updateStudentPhoto);
    const deleteStudentPhoto = useStoreActions((state) => state.student.deleteStudentPhoto);
    const studentListByClassConfigId = useStoreState((state) => state.student.studentListByClassConfigId);

    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [selectedValue, setselectedValue] = useState([]);
    const loading = useStoreState((state) =>  state.student.loading);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [keyD, setkeyData] = useState<any>();

    const searchStudentList = (value) => {
        setkeyData(value);
        fetchStudentListByClassConfigId(value);
    }
    const columns = [
        {
            title: 'ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,

        },
        {
            title: 'Class',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Shift',
            dataIndex: 'shiftName',
            key: 'shiftName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Section',
            dataIndex: 'sectionName',
            key: 'sectionName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Group',
            dataIndex: 'groupName',
            key: 'groupName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Category',
            dataIndex: 'studentCategoryName',
            key: 'studentCategoryName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Mobile',
            dataIndex: 'guardianMobile',
            key: 'guardianMobile',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Photo',
            dataIndex: 'studentId',
            key: 'studentId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => {
                let imgData = "data:image/jpeg;base64, " + record.imageName;
                return (
                    <div style={{ display: "flex" }}>
                        <img src={imgData} height='25' width='25' />
                        <Input type="file" style={{ marginLeft: 10 }} value={''} onChange={(e) => onchangeFile(e, record.studentId)} />
                    </div>
                )
            },
        },
        {
            title: 'Delete',
            key: 'studentId',
            dataIndex: 'studentId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">

                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteStudentPhoto({
                            id: record.studentId,
                            key: keyD,
                        })}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        },


    ];

    const onchangeFile = (val, id) => {

        let image_as_files = val.target.files[0];
        let data = {
            file: image_as_files,
            key: keyD,
            id: id,
        }
        updateStudentPhoto(data)
    }

    const [section, setSection] = useState<any>();

    return (
        <>
            <Card title="Student Photo Update">
                <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={searchStudentList}
                            form={searchStudentForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="academicYear"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="classconfigid"
                                        label="Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig onChange={e => setSection(e)} style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{rows:10}} />
                {studentListByClassConfigId !== null &&
                    <>
                        <div className="datatable-responsive-demo" style={{ width: "100%", overflow: "auto" }}>

                            <Table
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: studentListByClassConfigId,
                                    filterData: studentListByClassConfigId,
                                    pagination: true,
                                    bordered: true,
                                    rowKey: "studentId",
                                }}
                                mobileBreakPoint={768}

                            />
                        </div>
                    </>
                }
            </Card>
        </>
    )
}