import React, { useEffect, useState } from 'react';
import { Card, Tabs } from 'antd';
import AttendanceFineAdd from './AttendanceFineAdd';
import AttendanceFineAddList from './AttendanceFineAddList';

export default function AttendanceFineWrapper() {
    const { TabPane } = Tabs;

    return (
        <>
            <Card title="Attendance Fine">
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Add" key="1">
                        <AttendanceFineAdd />
                    </TabPane>
                    <TabPane tab="List" key="2">
                    <AttendanceFineAddList />
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )
}