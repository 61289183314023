import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Descriptions, Form, Input, Modal, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import moment from 'moment';



export default function PayslipDateUpdate(props) {

    const [form] = Form.useForm();


    const viewByPayslipId = useStoreState((state) => state.payslip.viewByPayslipId);
    const fetchviewByPayslipId = useStoreActions((state) => state.payslip.fetchviewByPayslipId);
    const savepayslipUpdateDate = useStoreActions((state) => state.payslip.savepayslipUpdateDate);



    const formSubmit = (value) => {
        fetchviewByPayslipId(value.payslipId);
    }

    const onchangeValueDate: any =
        (data) => (e: any) => {
            let payload = {
                payslipDate: moment(e).format("YYYY-MM-DD"),
                payslipId: data
            }
            savepayslipUpdateDate(payload);

            setTimeout(() => {
                form.submit()
            }, 1000);
        }

    return (
        <>
            <Card title="Payslip Date Update" >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Form
                            layout="vertical"
                            onFinish={formSubmit}
                            id="basic-info"
                            form={form }
                        >

                            <Card  >
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "80%" }}>
                                        <Form.Item
                                            name="payslipId"
                                            label="Payslip Id"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please input payslip id" },
                                            ]}
                                        >
                                            <Input placeholder='Payslip Id' />

                                        </Form.Item>
                                    </div>

                                    <div>
                                        <Button type="primary" htmlType="submit" style={{ height: 40, marginLeft: 20 }} icon={<SearchOutlined />}>
                                            Search
                                        </Button>
                                    </div>

                                </div>
                            </Card>

                        </Form>
                    </Col>
                    {viewByPayslipId !== null &&
                        <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                            <Card title="Update Payment Date" >

                                <Descriptions
                                    // title="User Info"
                                    bordered
                                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                                >
                                    <Descriptions.Item label="Student Id">{viewByPayslipId?.studentCustomId}</Descriptions.Item>
                                    <Descriptions.Item label="Student Name">{viewByPayslipId?.studentName}</Descriptions.Item>
                                    <Descriptions.Item label="Section">{viewByPayslipId?.section}</Descriptions.Item>
                                    <Descriptions.Item label="Payable Amount">{viewByPayslipId?.total}</Descriptions.Item>
                                    <Descriptions.Item label="Payslip Status">{viewByPayslipId?.payslipStatus}</Descriptions.Item>
                                    <Descriptions.Item label="Payment Date">
                                        <>
                                            <DatePicker allowClear={false} defaultValue={(moment(viewByPayslipId?.paymentDate?.split('-').join('/')))} onChange={onchangeValueDate(viewByPayslipId?.payslipId)} style={{ width: 150 }} />
                                        </>
                                    </Descriptions.Item>

                                </Descriptions>

                            </Card>
                        </Col>
                    }
                </Row>


            </Card>
        </>
    )
}
