import { post, get, del, put, postFile} from "../http";


export const fetchDistrictList = () => get("/location/info/district-list");

//////////
export const fetchacademicYearList = () => get("/initial-setup/academic-year/list");
export const createAcademicYear = (payload) => post("/initial-setup/academic-year/create", payload);
export const updateAcademicYear = (payload) => post("/initial-setup/academic-year/update", payload);
export const deleteAcademicYear = (payload) => del("/initial-setup/academic-year/delete?id="+payload);
//////////////

//////////
export const fetchsectionList = () => get("/initial-setup/section/list");
export const createsectionList = (payload) => post("/initial-setup/section/create", payload);
export const updatesectionList = (payload) => post("/initial-setup/section/update", payload);
export const deletesectionList = (payload) => del("/initial-setup/section/delete?id="+payload);
//////////////

//////////
export const fetchgroupList = () => get("/initial-setup/group/list");
export const creategroupList = (payload) => post("/initial-setup/group/create", payload);
export const updategroupList = (payload) => post("/initial-setup/group/update", payload);
export const deletegroupList = (payload) => del("/initial-setup/group/delete?id="+payload);
//////////////

//////////
export const fetchstudentCategory = () => get("/initial-setup/student-category/list");
export const createstudentCategory = (payload) => post("/initial-setup/student-category/create", payload);
export const updatestudentCategory = (payload) => post("/initial-setup/student-category/update", payload);
export const deletestudentCategory = (payload) => del("/initial-setup/student-category/delete?id="+payload);
//////////////


export const fetchClass = () => get("/initial-setup/class/list");
export const classCreate = (payload) => post("/initial-setup/class/create", payload);
export const classUpdate = (payload) => post("/initial-setup/class/update", payload);
export const classDelete = (payload) => del("/initial-setup/class/delete?id="+payload);


//////////////

export const fetchDepartment = () => get("/initial-setup/department/list");
export const departmentCreate = (payload) => post("/initial-setup/department/create", payload);
export const departmentUpdate = (payload) => post("/initial-setup/department/update", payload);
export const departmentDelete = (payload) => del("/initial-setup/department/delete?id="+payload);

////////////

export const fetchSessionYear = () => get("/initial-setup/sessionyear/list");
export const sessionYearCreate = (payload) => post("/initial-setup/sessionyear/create", payload);
export const sessionYearUpdate = (payload) => post("/initial-setup/sessionyear/update", payload);
export const sessionYearDelete = (payload) => del("/initial-setup/sessionyear/delete?id="+payload);

////////////

export const fetchShift = () => get("/initial-setup/shift/list");
export const shiftCreate = (payload) => post("/initial-setup/shift/create", payload);
export const shiftUpdate = (payload) => post("/initial-setup/shift/update", payload);
export const shiftDelete = (payload) => del("/initial-setup/shift/delete?id="+payload);

////////////

export const fetchClassConfigurationlist = () => get("/class/configuration/list");
export const classConfigrationCreate = (payload) => post("/class/configuration/create", payload);
export const updateClassConfigurationSerial = (payload) => post("/class/configuration/serial/update", payload);
export const semesterYearById = (payload) => get("/class-semester-year/configuration/list/by/config-id?configId="+payload);
export const ClassConfigDelete = (payload) => del("/class/configuration/delete?id="+payload);


////////////

////////////

export const fetchGroupConfigList = () => get("/group/configuration/list");
export const createGroupConfiguration = (payload) => post("/group/configuration/create", payload);
export const updateGroupConfigurationSerial = (payload) => post("/group/configuration/serial/update", payload);
export const deleteGroupConfig = (payload) => del("/group/configuration/delete?id="+payload);


////////////

export const fetchClassSemesterConfigrationlist = () => get("/class-semester-year/configuration/list");
export const classSemesterConfigrationCreate = (payload) => post("/class-semester-year/configuration/save", payload);
export const classSemesterYearById = (payload) => get("/initial-setup/sessionyear/list/by/class-dept-configid?classDeptConfigId="+payload);
export const classSemesterConfigDelete = (payload) => del("/class-semester-year/configuration/delete?configId="+payload);

/////////// 

export const createRegistrationdatawithCustomIdUrl = (payload) => post('/student/registration/excel/with/student-id',payload);
export const createRegistrationdatawithAutoId = (payload) => post('/student/registration/multiple/save',payload);
export const getStudentListBySection = (payload) => get('/student/registration/list/by/class-configuration-id?classConfigurationId='+payload.classconfigid+'&academicYear='+payload.academicYear);

////////
export const updateStudentBasicInfoList = (payload) => put('/student/registration/basic/update',payload);
export const updateClassDepartmentList = (payload) => post('/student/update/class-department/info',payload);
export const updateSessionYearList = (payload) => post('/student/update/session/info',payload); 
export const updateShiftList = (payload) => post('/student/update/shift/info',payload);
export const updateStudentAddress = (payload) => post('/student/registration/address/update',payload);
export const updateStudentSection = (payload) => put('/student/registration/section/update',payload);
export const updateStudentGroupUrl = (payload) => put('/student/registration/group/update',payload);
export const updatestudentCategroyValue = (payload) => put('/student/registration/category/update',payload);
export const updateStudentPhoto = (payload, id) => postFile(`/student/registration/photo/update?studentId=${id}`, payload);
export const deleteStudentPhoto = (payload) => post("/student/registration/photo/delete?studentId="+payload);
export const studentRegistrationDelete = (payload) => get('/student/report/basic/info/list/by/class-configuration-id?classConfigurationId='+payload.classconfigid+'&academicYear='+payload.academicYear);
export const deleteRegistrationStudentUrl = (payload) => post("/student/registration/delete", payload);
//
export const fetchlistGroupbyClassConfig = (payload) => get(`/group/configuration/list/by/classconfig-id?id=${payload}`);
export const fetchstudentListForMigration = (payload) => get(`/student/registration/list/for-migration/by/class-configuration-id/group-id?classConfigurationId=${payload.classConfigurationId}&groupId=${payload.groupId}`);
export const fetchmigratedList = (payload) => get(`/student/registration/list/by/class-configuration-id/group-id/academic-year?classConfigurationId=${payload.classConfigurationId}&groupId=${payload.groupId}&groupId=${payload.groupId}&academicYear=${payload.academicYear}`);
export const studentMigration = (payload) => post('/student/migration/done',payload);

export const studentIdCardListUrl = ()  => get(`/student/id-card/template/view`);
export const studentIdCardSaveUrl = (payload)  => post(`/student/id-card/template/save`, payload);
export const studentIdCardFrontPartDownload = (payload) => get("/jasper/student/id-card/download?startRoll=" + payload.startRoll + "&endRoll=" + payload.endRoll + "&classConfigId=" + payload.classConfigId);
export const studentIdCardBackPartDownload = (payload) => get("/jasper/student/id-card/backpart/download?startRoll=" + payload.startRoll + "&endRoll=" + payload.endRoll + "&classConfigId=" + payload.classConfigId);
export const classWiseReportUrl = (payload) => get("/student/report/basic/info/list/by/class-id?classId=" + payload.classId + "&academicYear=" + payload.academicYear);
export const sectionWiseReportUrl = (payload) => get("/student/report/basic/info/list/by/class-configuration-id?classConfigurationId="+payload.classconfigid+"&academicYear="+payload.academicYear);
export const groupWiseReportUrl = (payload) => get("/student/report/basic/info/list/by/class-id/group-id?classId=" + payload.classId + "&groupId=" + payload.groupId + "&academicYear=" + payload.academicYear);
export const sectionWiseSummary = () => get("/student/report/section-wise/summary");
export const studentOverviewUrl = (payload) => get("/student/report/basic/info/list/by/class-configuration-id?classConfigurationId=" + payload.classconfigid + "&academicYear=" + payload.academicYear);
export const studentFeeReportUrl = (payload) =>  get("/student-fee/report/single-student/invoice/list?identificationId="+payload);
export const studentFeeReportDueUrl = (payload) =>  get("/student-fee/report/single/student/due-details?identificationId="+payload);
export const studentMoneyReciptListUrl = ()  => get(`/money-receipt/template/id/view`);
export const studentMoneyReciptTemplateSaveUrl = (payload)  => post('/money-receipt/template/save?templateId='+payload);
//
export const studentTakePeriod = ()  => get('/initial-setup/global/period/list');
export const studentAllPeriodList = ()  => get('/initial-setup/period/list');
export const studentTakePeriodCreate = (payload)  => post('/initial-setup/period/create', payload);
export const studentTakePeriodDelete = (payload)  => del('/initial-setup/period/delete?id='+payload);
export const periodTimeConfigUrl = (payload) => post('/attendance-period/time/configuration/save', payload);
export const timeConfigurationList = () => get('/attendance-period/time/configuration/list');
export const timeConfigUpdate = (payload) => post('/attendance-period/time/configuration/update', payload);
export const timeConfigDelete = (payload)  => del('/attendance-period/time/configuration/delete?periodConfigId='+payload); 
export const deviceIdMappingListUrl = (payload)  => get('/student/device-id/mapping/list?classConfigurationId='+payload);
export const downloadTaughtList = (payload)  => get('/student/report/taught/list/download?groupConfigId='+payload);
export const deviceIdMappingListSave = (payload)  => post('/student/device-id/mapping/batch/save',payload);
export const studentAttendanceListUrl = (payload)  => get('/student/registration/list/by/class-configuration-id?classConfigurationId='+payload);
export const studentAttendanceInputUrl = (payload) => post('/student/attendance/input', payload);
export const studentAttendanceUpdateListUrl = (payload) => get('/student/attendance/list/for/update?attendanceDate='+payload.attendanceDate+'&classConfigId='+payload.classConfigId+'&periodId='+payload.periodId);
export const studentAttendanceUpdateUrl = (payload) => post('/student/attendance/update', payload);
export const studentAttendanceDetailsUrl = (payload)  => get('/student/report/view/by/custom-student-id?customStudentId='+payload);
export const studentLeaveInput = (payload) => post('/student/leave/save', payload);
export const dailySectionReport = (payload) => get('/student/attendance/report/section/view/by/status?attendanceDate='+payload.attendanceDate+'&attendanceStatus='+payload.status+'&classConfigId='+payload.classConfigId+'&periodId='+payload.periodId);
export const sectionWiseDailySummaryReportUrl = (payload)  => get('/student/attendance/report/section/wise/daily/summary?date='+payload.date+'&periodId='+payload.periodId);
export const sectionWiseMonthlyDetailsUrl = (payload) => post('/student/attendance/report/monthly/section/details', payload);
export const fetchmonthlyBlankAttendanceSheet = (payload) => post('/student/attendance/report/monthly/blank/sheet/section/details', payload);
export const sectionWiseMonthlyReportUrl = (payload) => post('/student/attendance/report/monthly/section/summary', payload);
export const studentTxtFileUrl = (payload, date) => postFile(`/student/machine/attendance/read/txt-file?date=${date}`, payload);
export const studentTxtFileSaveUrl = (payload) => post(`/student/machine/attendance/input`, payload);
export const singleStudentSearchUrl = (payload) => get('/student/report/single/general/view?customStudentId='+payload); 
export const studentIdUpdateUrl = (payload) => get('/student/registration/list/by/class-configuration-id?classConfigurationId='+payload.classconfigid+'&academicYear='+payload.academicYear);
export const updateCustomIDUrl = (payload) => put('/student/registration/custom-id/update', payload);
export const studentEnableDisableListUrl = (payload)  => get('/student/registration/enable/disable/list?classConfigurationId='+payload.classconfigid+'&academicYear='+payload.academicYear);
export const studentEnableDisableUrl = (payload) => post('/student/registration/enable/disable', payload);
export const studentListGeneralMigrationList = (payload)  => get('/student/registration/list/for-migration/by/class-configuration-id/group-id?classConfigurationId='+payload.classConfigurationId+'&groupId='+payload.groupId);
export const fetchStudentListMeritMigration = (payload)  => get("/exam/report/section/merit/position/for/migration?examConfigId=" + payload.examconfigid + "&classConfigId=" + payload.classConfigId + "&academicYear=" + payload.academicYear);
export const saveStudentListGeneralMigration = (payload) => post('/student/migration/done', payload);

export const fetchmigratedStudentList = (payload)  => get('/student/registration/list/by/class-configuration-id/group-id/academic-year?classConfigurationId='+payload.classConfigurationId+'&groupId='+payload.groupId+ "&academicYear=" + payload.academicYear);
export const fetchCategoryWiseBasicInfoUrl = (payload)  => get('/student/report/basic/info/list/by/category?academicYear='+payload.academicYear+'&categoryId='+payload.categoryId);
export const pushbackmigratedStudentList = (payload) => post('/student/migration/push-back', payload);
export const fetchfeeSubHeadListByClassId = (payload) => get("/payslip/fee-head/sub-head/view/by/class-id?classId="+payload);

export const fetchgetStudentListBySubjectWise = (payload) => get("/student/report/basic/info/list/by/classconfig/groupid/subjectid?academicYear=" + payload.academicYear + "&classConfigId=" + payload.classConfigId + "&groupId=" + payload.groupId+ "&subjectId="+ payload.subjectId);

export const fetchsubjectConfigurationListByClasssIdAndGroupId = (payload)  => get('/subject/configuration/list/by/class-configuration-id/group-id?classConfigurationId='+payload.classConfigurationId+'&groupId='+payload.groupId);
export const fetchExamblankSheet = (payload)  => get('/exam/attendance/blanksheet/view?academicYear='+payload.yearId+'&classConfigId='+payload.sectionId+'&groupId='+payload.groupId);