import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag} from 'antd';


export default function Subject(props) {

    const createSubject = useStoreActions((state) => state.exam.createSubject);
    const fetchSubjectList = useStoreActions((state) => state.exam.fetchSubjectList);
    const subjectList = useStoreState((state) => state.exam.subjectList);
    const updateSubject = useStoreActions((state) => state.exam.updateSubject);
    const deleteSubject = useStoreActions((state) => state.exam.deleteSubject);
    const [subjectmForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const loading = useStoreState((state) =>  state.exam.loading);

    const subjectSettingForm = (value) => {
        let postData = {
            name: value.subjectName,
            serial: value.serialNumber,
            code: value.code,
            status: 1
        }
        createSubject(postData);
        subjectmForm.resetFields();
    }
    

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedStatus, setselectedStatus] = useState<any>();
    const [updateSubjectId, setupdateSubjectId] = useState<any>();

    const updateSubjectForm = (value) => {
        let updatePostData = {
            id:updateSubjectId,
            name: value.name,
            serial: value.serial,
            code: value.code,
            status: value.status == true ? 1 : 0
        }
        updateSubject(updatePostData);
        setIsModalVisible(false);
        updateForm.resetFields();
        fetchSubjectList();
    }

    useEffect(() => {
        fetchSubjectList();
        
    },[])

    const columns = [
        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Subject Name',
            dataIndex: 'name',
            key: 'name',
            showOnResponse: true,
            showOnDesktop: true
        },       
         {
            title: 'Subject Code',
            dataIndex: 'code',
            key: 'code',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <>
                   <span>{ record.status == 1 ? 'Enable' : 'Disable'}</span>
                </>
            )
        },
        {
            title: 'Action',
            key: 'ledgerId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                     <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setselectedStatus(record.status);
                            setupdateSubjectId(record.id);
                            updateForm.setFieldsValue({
                                serial: record.serial,
                                name: record.name,
                                status: record.status,
                                code: record.code,
                            });
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteSubject(record?.id)}
                    >
                        <Tooltip title="Delete">
                            <Button danger  icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        }
      ];

    return(
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}} lg={{ span: 24, offset: 3 }} xl={{ span: 24, offset: 3 }}>
                    <Form
                        layout="vertical"
                        id="subjectInfo"
                        onFinish={subjectSettingForm}
                        form={subjectmForm}
                    >
                        <Row>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 10}} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                        name="subjectName"
                                        label="Subject Name"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write subject name" },
                                        ]}
                                    >
                                    <Input placeholder="Enter subject name" />
                                </Form.Item>
                            </Col>                            
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 10}} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                        name="code"
                                        label="Subject Code"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write subject code" },
                                        ]}
                                    >
                                    <Input placeholder="Enter subject code" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 10}} lg={{ span: 5 }} xl={{ span: 5 }}>
                                <Form.Item
                                        name="serialNumber"
                                        label="Serial Number"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write serial number" },
                                        ]}
                                    >
                                    <InputNumber placeholder="Enter serial number" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 4}} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                        Save
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{rows:10}} />
            <Row className="m-t-mo-30">
                <Col span="24">
                    <div className="datatable-responsive-demo">
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}
                        <Table bordered={true} dataSource={subjectList} columns={columns} className="p-datatable-responsive-demo" />
                    </div>
                </Col>
            </Row>
            <Modal
                title="Edit"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
                destroyOnClose
            >
                <Form
                        layout="vertical"
                        id="update"
                        onFinish={updateSubjectForm}
                        form={updateForm}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                        name="name"
                                        label="Subject Name"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write subject name" },
                                        ]}
                                    >
                                    <Input placeholder="Enter Subject name" />
                                </Form.Item>
                            </Col>                            
                             <Col span={24}>
                             <Form.Item
                                        name="code"
                                        label="Subject Code"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write subject code" },
                                        ]}
                                    >
                                    <Input placeholder="Enter subject code" />
                                </Form.Item>
                            </Col> 
                            <Col span={24}>
                                <Form.Item
                                        name="serial"
                                        label="Serial Number"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write serial number" },
                                        ]}
                                    >
                                    <InputNumber placeholder="Enter serial number" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                    <Form.Item
                                        name="status"
                                        label="Status:"
                                        valuePropName="checked"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please input serial" },
                                        ]}
                                    >
                                    <Checkbox>Enable</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
            </Modal>
        </>
    )
}