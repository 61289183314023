import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker, Radio } from 'antd';
import idCardTemplateOne from "../../../../../assets/images/money-receipt-template-1.png";
import idCardTemplateTwo from "../../../../../assets/images/money-receipt-template-2.png";
import idCardTemplateThree from "../../../../../assets/images/money-receipt-template-4.png";
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';


export default function MoneyReciptTemplate() {
    const fetchMoneyReciptTemplate = useStoreActions((state) => state.student.fetchMoneyReciptTemplate);
    const moneyReciptTemplateList = useStoreState((state) => state.student.moneyReciptTemplateList);
    const moneyRecipySaveTemplate = useStoreActions((state) => state.student.moneyRecipySaveTemplate);
    const [templateDefaultValue, setTemplateDefaultValue] = useState<any>();
    useEffect( () => {
        fetchMoneyReciptTemplate();
    }, []);

    useEffect(() => {
        setTemplateDefaultValue(moneyReciptTemplateList);
    }, [moneyReciptTemplateList])

    const templateSaveMethod = (val) => {
        setTemplateDefaultValue(val);
        moneyRecipySaveTemplate(val);        
    }
   
    return(
        <>
            {templateDefaultValue !== '' ? 
                <Card title="Student Money Recipt">
                    <Row>
                        <Col span={24}>
                            <Radio.Group size="large" style={{ width: "100%" }} className='cardTemplateWrapper' onChange={(e) =>  templateSaveMethod(e.target.value)}> 
                                <Row>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6}}>
                                        <Radio.Button value={101} className={templateDefaultValue == 101 ? 'ant-radio-button-wrapper-checked' : ''}><img style={{ width: "100%" }} src={idCardTemplateOne} alt="" /></Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6}}>
                                        <Radio.Button value="102" className={templateDefaultValue == 102 ? 'ant-radio-button-wrapper-checked' : ''}><img style={{ width: "100%" }} src={idCardTemplateTwo} alt="" /></Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6}}>
                                        <Radio.Button value="103" className={templateDefaultValue == 103 ? 'ant-radio-button-wrapper-checked' : ''}><img style={{ width: "100%" }} src={idCardTemplateTwo} alt="" /></Radio.Button>
                                    </Col>
                                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 6}}>
                                        <Radio.Button value="104" className={templateDefaultValue == 104 ? 'ant-radio-button-wrapper-checked' : ''}><img style={{ width: "100%" }} src={idCardTemplateThree} alt="" /></Radio.Button>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Card>
            : ''}
        </>
    )
}