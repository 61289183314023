import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, DatePicker, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import TableView from '../../../contents/AntTableResponsive';
import { DeleteOutlined, DownloadOutlined, EditOutlined, Loading3QuartersOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';


require('jspdf-autotable');

export default function StaffAttendanceDateWiseRemark() {
    const { Option } = Select;
    const [form] = Form.useForm();

    const staffDateWiseAttReport2 = useStoreState((state) => state.staff.staffDateWiseAttReport2);
    const fetchstaffDateWiseAttReport2 = useStoreActions((state) => state.staff.fetchstaffDateWiseAttReport2);
    const updateStaffAttendanceComments = useStoreActions((state) => state.staff.updateStaffAttendanceComments);

    const [date, setDate] = useState<any>('')

    const examReportSearch = (value) => {
        setDate(moment(value?.attendanceDate).format("YYYY-MM-DD"))
        fetchstaffDateWiseAttReport2(moment(value?.attendanceDate).format("YYYY-MM-DD"));
    }

    const unassignedSubjectMarkListColumn = [
        { title: 'Id', dataIndex: 'staffId', key: 'staffId', showOnResponse: true, showOnDesktop: true },
        { title: 'Teachers & staff Name', dataIndex: 'staffName', key: 'staffName', showOnResponse: true, showOnDesktop: true },
        { title: 'Designation', dataIndex: 'designation', key: 'designation', showOnResponse: true, showOnDesktop: true },
        { title: 'Mobile', dataIndex: 'mobileNo', key: 'mobileNo', showOnResponse: true, showOnDesktop: true },
        {
            title: 'Attendance Status',
            dataIndex: 'attendanceStatus',
            key: 'attendanceStatus',
            showOnResponse: true,
            showOnDesktop: true,
            sorter: (a, b) => a.attendanceStatus.length - b.attendanceStatus.length,
        },
        { title: 'In Time', dataIndex: 'inTime', key: 'inTime', showOnResponse: true, showOnDesktop: true },
        { title: 'In Status', dataIndex: 'inStatus', key: 'inStatus', showOnResponse: true, showOnDesktop: true },
        { title: 'Out Time', dataIndex: 'outTime', key: 'outTime', showOnResponse: true, showOnDesktop: true },
        { title: 'Out Status', dataIndex: 'outStatus', key: 'outStatus', showOnResponse: true, showOnDesktop: true },
        { title: 'Remarks', dataIndex: 'comments', key: 'comments', showOnResponse: true, showOnDesktop: true },
        { title: 'Action', dataIndex: 'comments', key: 'comments', showOnResponse: true, showOnDesktop: true, render: (text, record) => <Button onClick={() => { setAttendanceId(record.attendanceId); setIsModalVisible(true); updateForm.setFieldsValue({comments:record.comments}) }} type='primary'>Remark</Button> },
    ];
    const [attendanceId, setAttendanceId] = useState<any>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const loading = useStoreState((state) =>  state.staff.loading);

    const [updateForm] = Form.useForm();
    const updateFormSubmit = (value) => {
        value.attendanceId = attendanceId;
        updateStaffAttendanceComments(value);
        setIsModalVisible(false);
        updateForm.resetFields();
        setTimeout(() => {
            fetchstaffDateWiseAttReport2(date)
        }, 1000);
    }

    return (
        <>
            <Card title="Date Wise Staff Remarks">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={examReportSearch}
                            form={form}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                    <Form.Item
                                        name="attendanceDate"
                                        label="Attendance Date"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select attendance date" },
                                        ]}
                                    >
                                        <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                < Skeleton loading={loading} paragraph={{rows:10}} />
                <Row style={{ display: staffDateWiseAttReport2.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: unassignedSubjectMarkListColumn,
                                rowKey: "attendanceId",
                                dataSource: staffDateWiseAttReport2,
                                filterData: staffDateWiseAttReport2,
                                pagination: true,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />

                    </Col>
                </Row>
            </Card>
            <Modal
                title="Remark"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateFormSubmit}
                    form={updateForm}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="comments"
                                label="Remarks"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please write remarks" },
                                ]}
                            >
                                <Input.TextArea placeholder="Enter remarks" style={{ height: 100 }} />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Modal>
        </>
    )

}