
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../select/SelectAcademicYear';
import { SelectClass } from '../../select/SelectClass';
import TableView from '../../../contents/AntTableResponsive';



export default function ClassWiseReport() {

    const [classWiseReportForm] = Form.useForm();
    const fetchClassWiseReport = useStoreActions((state) => state.student.fetchClassWiseReport2);
    const createBatchPayslip = useStoreActions((state) => state.payslip.createBatchPayslip);
    const fetchfeeSubHeadListByClassId = useStoreActions((state) => state.student.fetchfeeSubHeadListByClassId);
    const classWiseListReport = useStoreState((state) => state.student.classWiseListReport2);
    const feeSubHeadListByClassId = useStoreState((state) => state.student.feeSubHeadListByClassId);
    const loading = useStoreState((state) =>  state.student.loading);

    //console.log(feeSubHeadListByClassId);
    const [tableData, setTableData] = useState<any>([])

    useEffect(() => {
        setTableData(feeSubHeadListByClassId)
    }, [feeSubHeadListByClassId])

    const formSubmit = (value) => {
        let postData: any = {
            academicYear: value.yearId,
            classId: value.classId
        }
        fetchClassWiseReport(postData);
        fetchfeeSubHeadListByClassId(value.classId);
    }

    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Group', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true },
        { title: 'Father', dataIndex: 'fatherName', key: 'fatherName', showOnResponse: true, showOnDesktop: true },
        { title: 'Mother', dataIndex: 'motherName', key: 'motherName', showOnResponse: true, showOnDesktop: true },
        { title: 'Gender', dataIndex: 'studentGender', key: 'studentGender', showOnResponse: true, showOnDesktop: true },
        { title: 'Religion', dataIndex: 'studentReligion', key: 'studentReligion', showOnResponse: true, showOnDesktop: true },
        { title: 'Mobile No.', dataIndex: 'guardianMobile', key: 'guardianMobile', showOnResponse: true, showOnDesktop: true },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [selectedRowKeys2, setselectedRowKeys2] = useState<any>([]);
    const [selectedValue2, setselectedValue2] = useState<any>([]);

    const onSelectChange2 = (selectedRowKeys2, value2) => {
        setselectedRowKeys2(selectedRowKeys2);
        setselectedValue2(value2);
        // console.log(value)
    };

    const rowSelection2 = {
        selectedRowKeys:selectedRowKeys2,
        onChange: onSelectChange2,
    };

    const openModal = () => {

        if (selectedValue.length === 0) {
            notification.error({ message: "Please select student" });
            return;
        }

        setIsModalVisible(true)
    };
    const columns = [

        {
            title: 'Fee Head',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Fee Sub Head',
            key: 'feeSubHeadDetils',
            widtha: '40%',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Select disabled={selectedRowKeys.includes(record.key) ? true : false} placeholder="Select Fee Sub Head" mode='multiple' value={record?.feeSubHeadIdsNew} onChange={onchangeValueSubhead("feeSubHeadIdsNew", record?.feeSubHeadDetils, index)} style={{ width: "100%", height: "auto" }}>
                    {record?.feeSubHeadList ? (
                        record?.feeSubHeadList?.map((type, idx) => (
                            <Select.Option key={type.feeSubHeadId} value={type.feeSubHeadId} >
                                {type.feeSubHeadName}
                            </Select.Option>
                        ))
                    ) : (
                        <Select.Option value="fetching">No Sub Head</Select.Option>
                    )}
                </Select>
            ),
        },
    ];
    const onchangeValueSubhead: any = (key, data, index) => (e: any) => {
        const newData = [...tableData];
        newData[index][key] = e;
        setTableData(newData);
    }
    //console.log(tableData)
    const onsubmit = () => {
        if (selectedValue2.length === 0) {
            notification.error({ message: "Please select fee head" });
            return;
        }
       

        let feesDetails: any = selectedValue2.map((item) => {
        return {
            feeHeadId: item.feeHeadId,
            feeSubheadIds: item.feeSubHeadIdsNew,
        }

        })
        let payload: any = {
            feesDetails: feesDetails,
            identificationIds: selectedValue.map((item) => item.identificationId),
            classId: selectedValue.map((item) => item.classId)?.[0],
        }
        createBatchPayslip(payload);
        setselectedRowKeys([]);
        setselectedValue([]);       
        setselectedRowKeys2([]);
        setselectedValue2([]);
        setIsModalVisible(false);
        const newData = [...tableData];
        newData.forEach((item) => item.feeSubHeadIdsNew = []);
        setTableData(newData);
    };

    return (
        <>
            <Card title="Create Batch Payslip">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={classWiseReportForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="yearId"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="classId"
                                        label="Select Class"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select class" },
                                        ]}
                                    >
                                        <SelectClass />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                < Skeleton loading={loading} paragraph={{rows:10}} />
                <Row style={{ display: classWiseListReport.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: studentListColumn,
                                rowKey: "identificationId",
                                dataSource: classWiseListReport,
                                filterData: classWiseListReport,
                                pagination: false,
                                bordered: true,
                                rowSelection: rowSelection
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                        {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                    </Col>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>
                            <Button type="primary" icon={<SettingOutlined />} onClick={() => openModal()} >Process</Button>
                        </Space>
                    </Col>
                </Row>
                <Modal
                    title="Payslip"
                    visible={isModalVisible}
                    onOk={onsubmit}
                    onCancel={() => {
                        setIsModalVisible(false);
                        console.log("cancel")
                        setselectedRowKeys2([]);
                        setselectedValue2([]);
                        const newData = [...tableData];
                        newData.forEach((item) => item.feeSubHeadIdsNew = []);
                        setTableData(newData);
                    }}
                    cancelText="Close"
                    okText="Create"
                    width={'50%'}
                    centered
                >
                    < Skeleton loading={loading} paragraph={{rows:10}} />
                    {tableData?.length > 0 &&
                        <div>
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: tableData,
                                    filterData: tableData,
                                    pagination: false,
                                    bordered: true,
                                    rowKey: "feeHeadId",
                                    rowSelection: rowSelection2,
                                }}
                                mobileBreakPoint={768}
                            />
                        </div>
                    }
                </Modal>

            </Card>
        </>
    )
}
