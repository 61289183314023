import { post, get, del, put, delBulk} from "../http";

export const jumpInstiuteUrl = (payload) => get('/institute/jump?instituteId=' + payload);
export const instituteListUrl = () => get('/institute/list/by/owner?instituteOwner=SDL');
export const instituteListPag = (payload) => get('/ofps/configuration/institute/list?numberOfpage='+payload.numberOfpage+'&pageSize='+payload.pageSize);
export const instituteListPag2 = (payload) => get('/ofps/configuration/institute/list?instituteName='+payload.instituteName+'&numberOfpage='+payload.numberOfpage);
export const fetchStudentCount = () => get('/institute/wise/student/count');
export const deActiveInstiuteUrl = (payload) => get('/institute/inactive?instituteId='+payload);
export const activeInstiuteUrl = (payload) => get('/institute/active?instituteId='+payload);
export const classGradeListUrl = (payload) => get('/exam/grade/list/by/class-id?classId=' + payload);
export const solveOfpsTransactionProblem = (payload) => post('/ofps-transaction/problem/solve', payload);
export const solveOfpsTransactionProblemDoublePayment = (payload) => post('/ofps-transaction/problem/solve/double/payment', payload);
export const fetchofpsTransactionProblemCheck = (payload) => get('/ofps-transaction/problem/check?refTrnNo=' + payload);
export const fetchofpsTransactionProblemView = (payload) => get("/ofps-transaction/problem/view?customStudentId=" + payload.customStudentId + "&instituteId=" + payload.instituteId + "&trnDate=" + payload.trnDate);
export const instituteDataUpdateUrl = (payload) => put('/exam/grade/update/by/customer-support', payload)
export const gradeDelete = (payload) => del('/exam/grade/delete?gradeId=' + payload);
export const partnerInfoSaveUrl = (payload) => post('/partner/save', payload);
export const partnerInfoUpdateUrl = (payload) => post('/partner/update', payload);
export const partnerListUrl = () => get('/partner/list');
export const partnerDeleteUrl = (payload) => del('/partner/delete?partnerId='+payload);