import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../../store/hooks/easyPeasy';
import { Table, Tag} from 'antd';
import TableView from '../../../../../contents/AntTableResponsive';
import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import Column from 'rc-table/lib/sugar/Column';
import { render } from '@testing-library/react';
import { SelectClassConfig } from '../../../../select/SelectClassConfig';

export default function AssignedList() {
    const [searchStudentForm] = Form.useForm();
    const fetchForthSubjectAssignedList = useStoreActions((state) => state.exam.fetchForthSubjectAssignedList);
    const forthSubjectAssignedList = useStoreState((state) => state.exam.forthSubjectAssignedList); 
    const [studentForthSubjectAssignedList, setStudentForthSubjectAssignedList] = useState<any>([]);
    const [studentSelected, setStudentSelected] = useState([]);
    const [studentSelectedValue, setstudentSelectedValue] = useState<any>([]);
    const deleteForthSubjectAssigned = useStoreActions((state) => state.exam.deleteForthSubjectAssigned);
    const [selectSection, setSelectSection] = useState<any>("");
    const loading = useStoreState((state) =>  state.exam.loading);
    
    const searchStudentList = (value) => {
        var postData = value.classconfigid;
        
        setSelectSection(postData);
        setStudentForthSubjectAssignedList(forthSubjectAssignedList);
        fetchForthSubjectAssignedList(postData);
    }

    const assignTableColmn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true  },
        { title: 'Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true},
        { title: 'Roll', dataIndex: 'roll', key: 'roll', showOnResponse: true, showOnDesktop: true},
        { title: 'Group', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true},
        { title: 'Subject Name', dataIndex: 'subjectName', key: 'subjectName', showOnResponse: true, showOnDesktop: true},
        { title: 'Subject Status', dataIndex: 'subjectStatus', key: 'subjectStatus', showOnResponse: true, showOnDesktop: true},
    ]

    const onSelectChangeStudentSelect = (selectedRowKeys, value) => {
        setStudentSelected(selectedRowKeys);
        setstudentSelectedValue(value);
    };

    const studentRowSelection = {
        forthSubjectAssignedList,
        onChange: onSelectChangeStudentSelect,
    };

    const deleteStudent = () => {
        let postData:any = {
            studentSelected: studentSelected,
            section:selectSection
        }
        deleteForthSubjectAssigned(postData);
    }

    useEffect(() => {
        setStudentForthSubjectAssignedList(forthSubjectAssignedList);
    }, [forthSubjectAssignedList])

    return(
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                    <Form
                        layout="vertical"
                        id="sessionYearInfo"
                        onFinish={searchStudentList}
                        form={searchStudentForm}
                    >
                    <Row>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>

                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="classconfigid"
                                label="Section"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                                >
                                    <SelectClassConfig />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                <Button type="primary" id="searchButton" htmlType="submit" icon={<SearchOutlined />} >
                                    Search
                                </Button>

                            </Col>
                        </Row>
                    </Form>
                </Col>
                <br />
                <Skeleton loading={loading} paragraph={{rows:10}} />
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}} lg={{ span: 24}} xl={{ span: 24}} style={{ display: studentForthSubjectAssignedList.length > 0 ? "block" : "none" }}>
                    <TableView
                        antTableProps={{
                            rowSelection:studentRowSelection,
                            showHeader: true,
                            columns:assignTableColmn,
                            rowKey:"fourthSubjectAssignId",
                            dataSource: studentForthSubjectAssignedList,
                            filterData: studentForthSubjectAssignedList,
                            pagination: false,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                </Col>
                <br />
                {studentForthSubjectAssignedList.length > 0 ? 
                <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 24}} lg={{ span: 24 }} xl={{ span: 24 }}>
                    <Button danger icon={<DeleteOutlined />} style={{ float: "right" }} onClick={() => deleteStudent()} >
                        Delete
                    </Button>
                </Col>
                : ''}
            </Row>
        </>
    )
}

