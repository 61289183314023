import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker } from 'antd';
import { EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { SelectGroupbySection } from '../../../select/SelectGroupbySection';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectGroupbySectionSecond } from '../../../select/SelectGroupbySectionSecond';


export default function StudentMigration() {

    const [searchStudentForm] = Form.useForm();
    const fetchstudentListForMigration = useStoreActions((state) => state.student.fetchstudentListForMigration);
    const studentListForMigration = useStoreState((state) => state.student.studentListForMigration);
    const fetchlistGroupbyClassConfig = useStoreActions((state) => state.student.fetchlistGroupbyClassConfig);//
    const fetchlistGroupbyClassConfigsecond = useStoreActions((state) => state.student.fetchlistGroupbyClassConfigsecond);//
    const fetchlistGroupbyClassConfigthird = useStoreActions((state) => state.student.fetchlistGroupbyClassConfigthird);//
    const studentMigration = useStoreActions((state) => state.student.studentMigration);//

    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [selectedValue, setselectedValue] = useState([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const searchStudentList = (value) => {
        // console.log(value);
        value.groupId = value.groupId.groupId;
        fetchstudentListForMigration(value);
    }

    const [tableRowStore, setTableRowStore] = useState<any>([]);

    const onchangeValue: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData: any = [...tableRowStore];
            newData[index][key] = e.target.value;
            setTableRowStore(newData);
        };

    useEffect(() => {
        setTableRowStore(studentListForMigration);
    }, [studentListForMigration]);


    const columns = [
        {
            title: 'ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,

        },

        {
            title: 'Current Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <Input placeholder="Roll" style={{ width: 60 }} defaultValue={record.studentRoll} name="studentRoll" className="stdName" onChange={onchangeValue("newRoll", record, index)} />
            ),
        },

    ];

    const [section, setSection] = useState<any>();
    const [group, setgroup] = useState<any>();

    const [usection, setuSection] = useState<any>();
    const [ugroup, setugroup] = useState<any>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [updateMigration] = Form.useForm();

    const updateSectionFormSubmit = (value) => {
        value.groupId = value.groupId.groupId;

        let studentInfos= selectedValue.map((item:any) => {
            return {
                identificationId: item.identificationId,
                newRoll: item.newRoll===undefined?item.studentRoll:item.newRoll,
            }
        });
        value.studentInfos=studentInfos;
        studentMigration(value);
        setselectedRowKeys([]);
        setselectedValue([]);
        setIsModalVisible(false);
        updateMigration.resetFields();
    }

    return (
        <>
            <Card title="Migrate Student">
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24, }} xl={{ span: 24 }}>
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                                <Form
                                    layout="vertical"
                                    id="sessionYearInfo"
                                    onFinish={searchStudentList}
                                    form={searchStudentForm}
                                >
                                    <Row>

                                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                            <Form.Item
                                                name="classConfigurationId"
                                                label="Section"
                                                className="title-Text"
                                                rules={[
                                                    { required: true, message: "Please select section" },
                                                ]}
                                            >
                                                <SelectClassConfig onChange={e => { setSection(e); setgroup(null); fetchlistGroupbyClassConfig(e) }} style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                            <Form.Item
                                                name="groupId"
                                                label="Group"
                                                className="title-Text"
                                                rules={[
                                                    { required: true, message: "Please select group" },
                                                ]}
                                            >
                                                <SelectGroupbySection selected={group} onChange={(e) => { setgroup(e.groupName) }} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 2 }} xl={{ span: 2 }}>

                                            <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                                Search
                                            </Button>

                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        {studentListForMigration !== null &&
                            <>

                                <div className="datatable-responsive-demo" style={{ width: "100%", overflow: "auto" }}>

                                    <Table
                                        antTableProps={{
                                            showHeader: true,
                                            rowSelection: rowSelection,
                                            columns,
                                            dataSource: tableRowStore,
                                            filterData: tableRowStore,
                                            pagination: false,
                                            bordered: true,
                                            rowKey: "studentId",
                                        }}
                                        mobileBreakPoint={768}

                                    />
                                </div>

                                <Row >
                                    <Col span="24">
                                        <Button type="primary" icon={<EditOutlined />} style={{ float: 'right' }}
                                            onClick={() => {
                                                if (selectedRowKeys.length > 0) {
                                                    setIsModalVisible(true);
                                                } else {
                                                    message.error("Please select student");
                                                }
                                            }}>

                                            Update
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Col>
                </Row>
            </Card>
            <Modal
                title="Update"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateSectionFormSubmit}
                    form={updateMigration}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="migrationAcademicYear"
                                label="Academic Year"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Select Acaemic Year" },
                                ]}
                            >
                                <SelectAcademicYear />

                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="classConfigId"
                                label="Section"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Select Section" },
                                ]}
                            >
                                <SelectClassConfig onChange={e => { setuSection(e); setugroup(null); fetchlistGroupbyClassConfigsecond(e) }} />

                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="groupId"
                                label="Group"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Select Group" },
                                ]}
                            >
                                <SelectGroupbySectionSecond selected={ugroup} onChange={(e) => { setugroup(e.groupName) }} />

                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </>
    )
}