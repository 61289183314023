import { DeleteOutlined, EditOutlined, InfoCircleFilled, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, message, Modal, notification, Popconfirm, Row, Select, Skeleton, Space, Tooltip } from 'antd';
import { Link } from "react-router-dom";
import React, { useCallback, useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectSemesterYear } from '../../../select/SelectSemesterYear';
import { SelectFeeWaiverFee } from '../../../select/SelectFeeWaiverFee';
import moment from 'moment';
import { SelectLedgerCashEqui } from '../../../select/SelectLedgerCashEqui';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';

const { Option } = Select;

export default function FeesCollection(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [sendSms, setsendSms] = useState<boolean>(false)

    const studentCollecionView = useStoreState((state) => state.saccount.studentCollecionView);
    const checkmanualFee = useStoreState((state) => state.saccount.checkmanualFee);
    const fetchstudentCollecionView = useStoreActions((state) => state.saccount.fetchstudentCollecionView);
    const savestudentCollecionView = useStoreActions((state) => state.saccount.savestudentCollecionView);
    const waiverList = useStoreState((state) => state.saccount.waiverList);
    const fetchwaiverList = useStoreActions((state) => state.saccount.fetchwaiverList);
    const [tableData, setTableData] = useState<any>()
    const [dtableData, setDtableData] = useState<any>()

    const [formSubmitData, setformSubmitData] = useState<any>();
    const loading = useStoreState((state) => state.saccount.loading);

    const formSubmit = (value) => {
        console.log(value)
        setformSubmitData(value)
        fetchstudentCollecionView(value);

    }

    useEffect(() => {
        fetchwaiverList();
    }, [])

    useEffect(() => {

        let data = studentCollecionView?.feeHeadDetails?.map(function (item: any, key) {
            item.key = key;
            if (item.feeWaiverId === null) {
                item.feeWaiverId = item?.feeWaiverList?.[0]?.id
            }
            return item;
        });

        let ddata = studentCollecionView?.previousDueDetails?.map(function (item: any, key) {
            item.key = key;
            item.feeWaiverId = item?.feeWaiverList?.[0]?.id

            return item;
        })

        setTableData(data);
        setDtableData(ddata);

    }, [checkmanualFee])

    const onchangeValue: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...tableData];
            newData[index][key] = e;
            setTableData(newData);
        };



    const onchangeValueSubhead: any = useCallback((key, data, index) => (e: any) => {
        let temp = data.filter(item => e.includes(item.feeSubHeadId))
        //console.log(temp)
        let feeAmount = temp.reduce(function (acc, obj) {
            return acc + obj.feeAmount;
        }, 0);
      
        let fineAmount = temp.reduce(function (acc, obj) {
            return acc + obj.fineAmount;
        }, 0);
        let waiverAmount = temp.reduce(function (acc, obj) {
            return acc + obj.waiverAmount;
        }, 0);
        const newData = [...tableData];
        newData[index][key] = e;
        newData[index]['feeSubHeadIds'] = e;
        newData[index]['selectedSubHeadIds'] = e;
        newData[index]['feeAmount'] = feeAmount;
        newData[index]['fineAmount'] = fineAmount;
        newData[index]['waiverAmount'] = waiverAmount;
        newData[index]['payableAmount'] = (feeAmount + fineAmount) - waiverAmount;
        newData[index]['feeAmount'] = feeAmount;
        newData[index]['paidAmount'] = (feeAmount + fineAmount) - waiverAmount;
        newData[index]['dueAmount'] = 0;

        setTableData(newData);
    }, [tableData]);


    const onchangeValueWaiver: any =
        useCallback((key, data, index) => (e) => {
            if (e === '') {
                e = 0
            }
            if (e === null) {
                e = 0
            }

            const newData = [...tableData];
            if (e >= newData[index]['feeAmount']) {
                e = 0;
                message.info("Waiver Amount can't be greater or equal than Fee amount");
            }
            newData[index][key] = e;
            newData[index]['payableAmount'] = (newData[index]['feeAmount'] - e) + newData[index]['fineAmount'];
            newData[index]['paidAmount'] = (newData[index]['feeAmount'] - e) + newData[index]['fineAmount'];
            newData[index]['dueAmount'] = 0;
            setTableData(newData);
        }, [tableData]);

    const onchangeValuePaid: any =
        useCallback((key, data, index) => (e) => {
            if (e === '') {
                e = 0
            }
            if (e === null) {
                e = 0
            }
            if (e > data.payableAmount) {
                e = data.payableAmount
            }
            const newData = [...tableData];
            newData[index][key] = e;
            newData[index]['dueAmount'] = data.payableAmount - e;
            if (e === 0) {
                newData[index]['paidAmount'] = data.payableAmount;
                newData[index]['dueAmount'] = 0;
            }
            setTableData(newData);
        }, [tableData]);


    const columns = [

        {
            title: 'Fee Head',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Fee Sub Head',
            key: 'feeSubHeadDetils',
            width: "15%",
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Select disabled={selectedRowKeys.includes(record.key) ? true : false} placeholder="Select Fee Sub Head" mode='multiple' onChange={onchangeValueSubhead("feeSubHeadIds", record?.feeSubHeadDetils, index)} value={record?.selectedSubHeadIds} style={{ width: "100%", height: "auto" }}>
                    {record?.feeSubHeadDetils ? (
                        record?.feeSubHeadDetils?.map((type, idx) => (
                            <Option key={type.feeSubHeadId} value={type.feeSubHeadId} >
                                {type.feeSubHeadName}
                            </Option>
                        ))
                    ) : (
                        <Option value="fetching">No Sub Head</Option>
                    )}
                </Select>
            ),
        },
        {
            title: 'Fee Amount',
            dataIndex: 'feeAmount',
            key: 'feeAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <span>{record.feeAmount}</span>
            ),
        },
        {
            title: 'Fine Amount',
            dataIndex: 'fineAmount',
            key: 'fineAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <span>{record.fineAmount}</span>
            ),
        },
        {
            title: 'Waiver Amount',
            key: 'waiverAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <InputNumber
                    min={0}
                    max={record?.feeAmount}
                    onChange={onchangeValueWaiver("waiverAmount", record?.feeSubHeadDetils, index)}
                    value={record.waiverAmount}
                    placeholder="Waiver Amount"
                    disabled={selectedRowKeys.includes(record.key) ? true : false}
                // style={{ width: 250 }}
                ></InputNumber>
            ),
        },

        {
            title: 'Waiver Type',
            key: 'feeWaiverId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                // <SelectFeeWaiverFee disabled={selectedRowKeys.includes(record.key) ? true : false} selected={record.feeWaiverId} onChange={onchangeValue("feeWaiverId", record, index)} style={{ width: "100%" }} />
                <Select disabled={selectedRowKeys.includes(record.key) ? true : false} value={record.feeWaiverId} placeholder="Select Fee Waiver" onChange={onchangeValue("feeWaiverId", record, index)} style={{ width: "100%", height: "auto" }} >
                    {record?.feeWaiverList ? (
                        record?.feeWaiverList?.map((type, idx) => (
                            <Option key={type.id} value={type.id}>
                                {type.name}
                            </Option>
                        ))
                    ) : (
                        <Option value="fetching">No Waiver</Option>
                    )}
                </Select>
            ),
        },
        {
            title: 'Payable Amount',
            dataIndex: 'payableAmount',
            key: 'payableAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <span>{record.payableAmount}</span>
            ),
        },
        {
            title: 'Paid Amount',
            key: 'paidAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <InputNumber
                    min={0}
                    onChange={onchangeValuePaid("paidAmount", record, index)}
                    disabled={selectedRowKeys.includes(record.key) ? true : false}
                    value={record.paidAmount}
                    placeholder="Paid Amount"
                // style={{ width: 250 }}
                ></InputNumber>
            ),
        },
        {
            title: 'Due Amount',
            dataIndex: 'dueAmount',
            key: 'dueAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <span>{record.dueAmount}</span>
            ),
        }

    ];

    const onchangeValueWaiverDue: any =
        useCallback((key, data, index) => (e) => {
            if (e === '') {
                e = 0
            }
            if (e === null) {
                e = 0
            }
            if (e >= data.temporaryPreviousDuePayable) {
                e = 0
            }
            const newData = [...dtableData];
            newData[index][key] = e;
            newData[index]['previousDuePayable'] = data.temporaryPreviousDuePayable - e;
            newData[index]['paidAmount'] = data.temporaryPreviousDuePayable - e;
            newData[index]['dueAmount'] = 0;
            setDtableData(newData);
        }, [dtableData]);


    const onchangeValuedue: any =
        useCallback((key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...dtableData];
            newData[index][key] = e;
            setDtableData(newData);
        }, [dtableData]);


    const onchangeValuePaidDue: any =
        useCallback((key, data, index) => (e) => {
            if (e === '') {
                e = 0
            }
            if (e === null) {
                e = 0
            }
            const newData = [...dtableData];
            if (e > data.temporaryPreviousDuePayable || e == 0) {
                e = data.temporaryPreviousDuePayable
                newData[index]['dueAmount'] = 0;
                newData[index]['waiverAmount'] = 0;
                newData[index]['paidAmount'] = data.temporaryPreviousDuePayable;
            }
            newData[index][key] = e;
            newData[index]['dueAmount'] = data.previousDuePayable - e;

            setDtableData(newData);
        }, [dtableData]);


    const columns2 = [

        {
            title: 'Fee Head',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Waiver Amount',
            key: 'waiverAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <InputNumber
                    min={0}
                    disabled={selectedRowKeys2.includes(record.key) ? true : false}
                    onChange={onchangeValueWaiverDue("waiverAmount", record, index)}
                    value={record.waiverAmount}
                    placeholder="Waiver Amount"
                // style={{ width: 250 }}
                ></InputNumber>
            ),
        },

        {
            title: 'Waiver Type',
            key: 'feeWaiverId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Select disabled={selectedRowKeys2.includes(record.key) ? true : false} value={record.feeWaiverId} placeholder="Select Fee Waiver" onChange={onchangeValuedue("feeWaiverId", record, index)} style={{ width: "100%", height: "auto" }} >
                    {record?.feeWaiverList ? (
                        record?.feeWaiverList?.map((type, idx) => (
                            <Option key={type.id} value={type.id}>
                                {type.name}
                            </Option>
                        ))
                    ) : (
                        <Option value="fetching">No Waiver</Option>
                    )}
                </Select>
            ),
        },
        {
            title: 'Due Payable',
            dataIndex: 'previousDuePayable',
            key: 'previousDuePayable',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <span>{record.previousDuePayable}</span>
            ),
        },
        {
            title: 'Paid Amount',
            key: 'paidAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <InputNumber
                    min={0}
                    disabled={selectedRowKeys2.includes(record.key) ? true : false}
                    onChange={onchangeValuePaidDue("paidAmount", record, index)}
                    value={record.paidAmount}
                    placeholder="Due Paid"
                // style={{ width: 250 }}
                ></InputNumber>
            ),
        },
        {
            title: 'Due',
            dataIndex: 'dueAmount',
            key: 'dueAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <span>{record.dueAmount}</span>
            ),
        }

    ];


    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);

        let payableAmount = value.reduce(function (acc, obj) {
            return acc + obj.payableAmount;
        }, 0);
        settotalPayable(payableAmount);

        let paidAmount = value.reduce(function (acc, obj) {
            return acc + obj.paidAmount;
        }, 0);
        settotalPaidAmount(paidAmount);

        let dueAmount = value.reduce(function (acc, obj) {
            return acc + obj.dueAmount;
        }, 0);
        settotalDue(dueAmount)

        let waiverAmount = value.reduce(function (acc, obj) {
            return acc + obj.waiverAmount;
        }, 0);
        settotalWaiver(waiverAmount)
    };

    const rowSelection = {
        getCheckboxProps: (record) => {
            return {
                disabled: record.payableAmount === 0,
            };
        },
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [selectedRowKeys2, setselectedRowKeys2] = useState<any>([]);
    const [selectedValue2, setselectedValue2] = useState<any>([]);

    const onSelectChange2 = (selectedRowKeys2, value) => {
        setselectedRowKeys2(selectedRowKeys2);
        setselectedValue2(value);

        let previousDuePayable = value.reduce(function (acc, obj) {
            return acc + obj.previousDuePayable;
        }, 0);
        setdtotalPayable(previousDuePayable);

        let paidAmount = value.reduce(function (acc, obj) {
            return acc + obj.paidAmount;
        }, 0);
        setdtotalPaidAmount(paidAmount);

        let due = value.reduce(function (acc, obj) {
            return acc + obj.dueAmount;
        }, 0);
        settotaldDue(due)

        let waiverAmount = value.reduce(function (acc, obj) {
            return acc + obj.waiverAmount;
        }, 0);
        settotaldWaiver(waiverAmount)
    };

    const rowSelection2 = {
        getCheckboxProps: (record) => {
            return {
                disabled: record.paidAmount === 0,
            };
        },
        selectedRowKeys2,
        onChange: onSelectChange2,
    };

    const [totalPayable, settotalPayable] = useState<any>(0);
    const [totalPaidAmount, settotalPaidAmount] = useState<any>(0);
    const [totalDue, settotalDue] = useState<any>(0);
    const [totalWaiver, settotalWaiver] = useState<any>(0);

    const [dtotalPayable, setdtotalPayable] = useState<any>(0);
    const [dtotalPaidAmount, setdtotalPaidAmount] = useState<any>(0);
    const [totaldDue, settotaldDue] = useState<any>(0);
    const [totaldWaiver, settotaldWaiver] = useState<any>(0);
    const [paymentDate, setpaymentDate] = useState<any>(moment(new Date()).format("YYYY-MM-DD"));
    const [note, setnote] = useState<any>('');
    const [paymentLedgerId, setpaymentLedgerId] = useState<any>(null);


    return (
        <>
            <Card title="Fees Collection" >
                <Row>
                    <Col span={24}>
                        <Link className="link-wrapper" target="_blank" to="/student-report-basic-info-list"><InfoCircleFilled /> See Student List</Link>
                    </Col>
                </Row>
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>

                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="customStudentId"
                                label="Student ID"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input student id" },
                                ]}
                            >

                                <Input placeholder="Student ID" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="academicYear"
                                label="Academic Year"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select academic year" },
                                ]}
                            >

                                <SelectAcademicYear />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            {checkmanualFee &&
                                <>
                                    <Row gutter={8}>

                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}> <strong>Student ID:</strong> </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}> {studentCollecionView?.customStudentId} </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}> <strong>Student Name:</strong> </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}> {studentCollecionView?.studentName} </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}> <strong>Student Roll: </strong></Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}> {studentCollecionView?.studentRoll} </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}><strong> Section: </strong></Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}> {studentCollecionView?.section} </Col>

                                    </Row>
                                </>
                            }
                        </Col>

                    </Row>
                </Form>
                {checkmanualFee === null && < Skeleton loading={loading} paragraph={{ rows: 10 }} />}
                <div className="datatable-responsive">
                    {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}
                    {checkmanualFee &&
                        <>
                            {tableData?.length > 0 &&
                                <div>
                                    <span style={{ fontWeight: "bold", fontSize: 15 }}>Fee List:</span>
                                    <Table
                                        antTableProps={{
                                            showHeader: true,
                                            columns,
                                            dataSource: tableData,
                                            filterData: tableData,
                                            pagination: false,
                                            bordered: true,
                                            rowKey: "key",
                                            rowSelection: rowSelection,
                                        }}
                                        mobileBreakPoint={768}
                                    />
                                </div>
                            }
                            <br />
                            {dtableData?.length > 0 &&
                                <div>
                                    <span style={{ fontWeight: "bold", fontSize: 15 }}>Due List:</span>
                                    <Table
                                        antTableProps={{
                                            showHeader: true,
                                            columns: columns2,
                                            dataSource: dtableData,
                                            filterData: dtableData,
                                            pagination: false,
                                            bordered: true,
                                            rowKey: "key",
                                            rowSelection: rowSelection2,
                                        }}
                                        mobileBreakPoint={768}
                                    />
                                </div>
                            }

                            <br />

                            <Row gutter={8}>

                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>

                                </Col>

                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Row gutter={8}>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <span>Total Payable:</span> <br />
                                            <Input value={totalPayable + dtotalPayable} readOnly disabled style={{ marginBottom: 15, height: '40px' }} />
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <span>Total Paid:</span> <br />
                                            <Input value={totalPaidAmount + dtotalPaidAmount} readOnly disabled style={{ marginBottom: 15, height: '40px' }} />
                                        </Col>
                                    
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{marginTop:5}}>
                                            <span>Total Due:</span> <br />
                                            <Input value={totalDue + totaldDue} readOnly disabled style={{ height: '40px' }} />
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{marginTop:5}}>
                                            <span>Payment Date:</span> <br />
                                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} defaultValue={moment((new Date()), "DD/MM/YYYY")} onChange={e => setpaymentDate(moment(e).format("YYYY-MM-DD"))} />
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{marginTop:10}}>
                                            <span className="ant-form-item-required"> <span style={{color:"red"}}>*</span> Paid By:</span> <br />
                                            <SelectLedgerCashEqui selected={paymentLedgerId} onChange={e => setpaymentLedgerId(e)} style={{ width: "100%" }} />
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{marginTop:10}}>
                                            <span>Note:</span> <br />
                                            <Input style={{ marginBottom: 15, height: '40px' }} onChange={e => setnote(e.target.value)} />
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                            <Space style={{ float: "right" }} size={'middle'}>
                                <Checkbox value={sendSms} onChange={e => setsendSms(!sendSms)}>Send SMS</Checkbox>
                                <Button type="primary" onClick={() => {
                                    if (paymentLedgerId === null) {
                                        notification.error({ message: "Please select ledger" });
                                        return
                                    }

                                    let feeHeadInfos = selectedValue.map(function (item) {
                                        return {
                                            "feeHeadName": item.feeHeadName,
                                            "feeHeadId": item.feeHeadId,
                                            "feeAmount": item.feeAmount,
                                            "fineAmount": item.fineAmount,
                                            "paidAmount": item.paidAmount,
                                            "payableAmount": item.payableAmount,
                                            "waiverAmount": item.waiverAmount,
                                            "waiverId": item.feeWaiverId,
                                            "feeSubHeadIds": item.selectedSubHeadIds
                                        }
                                    })

                                    let dueInfos = selectedValue2.map(function (item) {
                                        return {
                                            "dueAmount": item.dueAmount,
                                            "feeHeadId": item.feeHeadId,
                                            "paidAmount": item.paidAmount,
                                            "waiver": item.waiverAmount,
                                            "waiverId": item.feeWaiverId,
                                            "previousDuePayable": item.temporaryPreviousDuePayable,

                                        }
                                    })

                                    let postdata = {
                                        totalPaid: totalPaidAmount + dtotalPaidAmount,
                                        totalPayable: totalPayable + dtotalPayable,
                                        feeHeadDetails: feeHeadInfos,
                                        feeDueDetails: dueInfos,
                                        note: note,
                                        sendSms: sendSms ? 1 : 0,
                                        paymentLedgerId: paymentLedgerId,
                                        identificationId: studentCollecionView?.identificationId,
                                        paymentDate: paymentDate,
                                    }


                                    // totalPaidAmount: totalPaidAmount + dtotalPaidAmount,
                                    // totalPayable: totalPayable + dtotalPayable,
                                    // totalWaiver: totalWaiver + totaldWaiver,

                                    if (postdata.totalPaid < 1) {
                                        notification.error({ message: "Paid amount cannot be zero" });
                                        return
                                    }
                                    savestudentCollecionView(postdata);
                                    setselectedRowKeys([]);
                                    setselectedValue([]);
                                    setselectedRowKeys2([]);
                                    setselectedValue2([]);
                                    settotalPayable(0);
                                    settotalPaidAmount(0);
                                    settotalDue(0);
                                    settotalWaiver(0);
                                    setdtotalPayable(0);
                                    setdtotalPaidAmount(0);
                                    settotaldDue(0);
                                    settotaldWaiver(0);
                                    setpaymentLedgerId(null);
                                    setTimeout(() => {
                                        fetchstudentCollecionView(formSubmitData);
                                    }, 500);
                                }}>Collect</Button>
                            </Space>

                        </>

                    }
                </div>

            </Card>


        </>
    )
}
