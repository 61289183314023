import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { dashboardClassWiseInfoUrl, dashboardInfoUrl, dashboardInstiuteInfoUrl, dashboardSmsInfoUrl } from '../../../http/dashboard/dashboard';
import FileSaver from 'file-saver'
import { fetchlistGroupbyClassConfig } from '../../../http/student/student';
export interface Dashboard {
    fetchDashboardInfoList: Thunk<Dashboard>;
    setDashboardInfoList: Action<Dashboard, any>;
    dashboardInfoList:any;

    ///

    fetchSmsInfoList: Thunk<Dashboard>;
    setSmsInfoList: Action<Dashboard, any>;
    smsInfoList:any;

    ///

    fetchClassWiseInfoList: Thunk<Dashboard>;
    setClassWiseInfoList: Action<Dashboard, any>;
    classWiseInfoList:any;

    ///
    fetchInstiuteInfoList: Thunk<Dashboard>;
    setInstiuteInfoList: Action<Dashboard, any>;
    instiuteInfoList:any;
}

export const dashboardStore: Dashboard = {
	dashboardInfoList: [],
    smsInfoList:[],
    classWiseInfoList:[],
    instiuteInfoList:[],

    fetchDashboardInfoList:thunk(async (actions) => {
        const response = await dashboardInfoUrl();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setDashboardInfoList(body.item);
            
        } else {
            const body = await response.json();
           // notification.error({ message: body.message })
        }
    }),
    setDashboardInfoList: action((state, payload) => {
        state.dashboardInfoList = payload;
    }),
    ////
    fetchSmsInfoList:thunk(async (actions) => {
        const response = await dashboardSmsInfoUrl();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setSmsInfoList(body.item);
            
        } else {
            const body = await response.json();
           // notification.error({ message: body.message })
        }
    }),
    setSmsInfoList: action((state, payload) => {
        state.smsInfoList = payload
    }),

    /////

    fetchClassWiseInfoList:thunk(async (actions) => {
        const response = await dashboardClassWiseInfoUrl();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setClassWiseInfoList(body.item);
            
        } else {
            const body = await response.json();
           // notification.error({ message: body.message })
        }
    }),
    setClassWiseInfoList: action((state, payload) => {
        state.classWiseInfoList = payload
    }),

    fetchInstiuteInfoList:thunk(async (actions) => {
        const response = await dashboardInstiuteInfoUrl();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setInstiuteInfoList(body.item);
            
        } else {
            const body = await response.json();
          //  notification.error({ message: body.message })
        }
    }),
    setInstiuteInfoList: action((state, payload) => {
        state.instiuteInfoList = payload
    }),
}
