import React, { useEffect, useState } from 'react';
import { Table, Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification } from 'antd';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from "uuid";
import _ from 'lodash';
import { SelectGroup } from '../../../select/SelectGroup';
import { SelectCategory } from '../../../select/SelectCategory';
import { SelectClassConfig } from '../../../select/SelectClassConfig';


export default function MultipleRegistration() {
    const { Option } = Select;
    const [form] = Form.useForm();
    const [tableRowStore, setTableRowStore] = useState([]);
    const createRegistrationdatawithAutoId = useStoreActions((state) => state.student.createRegistrationdatawithAutoId)
    const getCategoryData = (val) => {

        setStudentCategory(val);

        if (academicYear == undefined || academicYear == null || section == undefined || section == null || group == undefined || group == null) {
            message.error('Please select all the fields');
        } else {
            addTableRow();
        }

    }
    const [checked, setChecked] = useState<boolean>(false);
    const [academicYear, setAcademicYear] = useState<any>();
    const [section, setSection] = useState<any>();
    const [group, setGroup] = useState<any>();
    const [studentCategory, setStudentCategory] = useState<any>();

    const addTableRow = () => {
        let tableRow: any = [
            {
                key: uuidv4(),
                fatherName: "",
                studentGender: "",
                motherName: "",
                registrationId: "",
                studentReligion: "",
                guardianMobile: "",
                studentName: "",
                studentRoll: ""
            }
        ]
        let temp = tableRowStore.concat(tableRow);
        setTableRowStore(temp);
        let lastItem = tableRowStore[tableRowStore.length - 1]
    }


    const deleteMe = (val) => {
        let temp = tableRowStore.filter((item: any) => item.key != val.key)
        setTableRowStore(temp)
    }

    const storeRegNumberIntoObject = (text, record, val, fieldName, index) => {
        tableRowStore.map((item: any) => {
            if (item.key == record.key) {
                item[fieldName] = val
            }
        });
    }

    const onChangeFromDropdown = (record, value, fieldName) => {
        tableRowStore.map((item: any) => {
            if (item.key == record.key) {
                item[fieldName] = value
            }
        });
    }

    /****Registration Table Start*****/
    const columns = [
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            render: (text, record, index) => (
                <Input placeholder="Student name" name="studentName" className="stdName" onChange={(e) => storeRegNumberIntoObject(text, record, e.target.value, e.target.name, index)} />
            ),
        },
        {
            title: 'Student Mobile',
            dataIndex: 'guardianMobile',
            key: 'guardianMobile',
            render: (text, record, index) => (
                <Input placeholder="Student mobile" className="stdMobile" name="guardianMobile" onChange={(e) => storeRegNumberIntoObject(text, record, e.target.value, e.target.name, index)} />
            ),
        },
        {
            title: 'Student Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            render: (text, record, index) => (
                <InputNumber placeholder="Student roll" className="stdRoll" name="studentRoll" onChange={(e) => storeRegNumberIntoObject(text, record, e, 'studentRoll', index)} />
            ),
        },
        {
            title: 'Registration No',
            dataIndex: 'registrationId',
            key: 'registrationId',
            render: (text, record, index) => (
                <Input placeholder="Registration No" className="regRoll" name="registrationId" onChange={(e) => storeRegNumberIntoObject(text, record, e.target.value, e.target.name, index)} />
            ),
        },
        {
            title: 'Father Name',
            dataIndex: 'fatherName',
            key: 'fatherName',
            render: (text, record, index) => (
                <Input placeholder="Father name" className="fatherName" name="fatherName" onChange={(e) => storeRegNumberIntoObject(text, record, e.target.value, e.target.name, index)} />
            ),
        },
        {
            title: 'Mother Name',
            dataIndex: 'motherName',
            key: 'motherName',
            render: (text, record, index) => (
                <Input placeholder="Mother name" className="motherName" name="motherName" onChange={(e) => storeRegNumberIntoObject(text, record, e.target.value, e.target.name, index)} />
            ),
        },
        {
            title: 'Religion',
            dataIndex: 'studentReligion',
            key: 'studentReligion',
            render: (text, record, index) => (
                <Select
                    placeholder="Select studentReligion"
                    className="studentReligion"
                    onChange={(e) => onChangeFromDropdown(record, e, 'studentReligion')}
                >
                    <Option value="Islam">Islam</Option>
                    <Option value="Hinduism">Hinduism</Option>
                    <Option value="Christian">Christian</Option>
                    <Option value="Buddist">Buddist</Option>
                    <Option value="Other">Other</Option>
                </Select>
            ),
        },
        {
            title: 'Gender',
            dataIndex: 'studentGender',
            key: 'studentGender',
            render: (text, record, index) => (
                <Select
                    placeholder="Select Gender"
                    className="studentGender"
                    onChange={(e) => onChangeFromDropdown(record, e, 'studentGender')}
                >
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                </Select>
            ),
        },
        {
            title: 'Add/Del',
            dataIndex: 'key',
            key: 'key',
            render: (text, record, index) => (
                <Space size="small">
                    <Button danger onClick={() => deleteMe(record)} icon={<DeleteOutlined />} />
                    <Button type={'primary'} onClick={() => addTableRow()} icon={<PlusOutlined />} />
                </Space>
            ),
        },
    ];

    const submitRegForm = () => {
        let postData = {
            "academicYear": academicYear,
            "categoryId": studentCategory,
            "classConfigurationId": section,
            "groupId": group,
            "multipleStudentList": tableRowStore,
            "smsSend": checked
        }
        let empty = false;
        tableRowStore.map((item:any, index) => {
            if(item.fatherName == ""){
                notification.error({ message: "Father name is empty, row number "+(index+1) });
                empty = true;
            }else if(item.guardianMobile == ""){
                notification.error({ message: "Guardian mobile number is empty, row number "+(index+1) });
                empty = true;
            }else if(item.motherName == ""){
                notification.error({ message: "Mother name is empty, row number "+(index+1) });
                empty = true;
            }else if(item.registrationId == ""){
                notification.error({ message: "Registration ID is empty, row number "+(index+1) });
                empty = true;
            }else if(item.studentGender == ""){
                notification.error({ message: "Student gender is empty, row number "+(index+1) });
                empty = true;
            }else if(item.studentName == ""){
                notification.error({ message: "Student name is empty, row number "+(index+1) });
                empty = true;
            }else if(item.studentReligion == ""){
                notification.error({ message: "Student religion is empty, row number "+(index+1) });
                empty = true;
            }else if(item.studentRoll == ""){
                notification.error({ message: "Student roll is empty, row number "+(index+1) });
                empty = true;
            }else{
                empty = false;
            }
        })
        if(empty == false){
            createRegistrationdatawithAutoId(postData);
            form.resetFields();
            setChecked(false);
            setTableRowStore([])
        }
    }
    /****Registration Table End******/
    return (
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                    <Form
                        layout="vertical"
                        id="classConfigInfo"
                        form={form}
                    // onFinish={createSessionYearForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="academicYear"
                                    label="Academic Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select academic year" },
                                    ]}
                                >
                                    <SelectAcademicYear onChange={e => setAcademicYear(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="classConfigurationId"
                                    label="Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectClassConfig onChange={e => setSection(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="groupId"
                                    label="Group"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select group" },
                                    ]}
                                >
                                    <SelectGroup onChange={e => setGroup(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="categoryId"
                                    label="Student Category"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select student category" },
                                    ]}
                                >
                                    <SelectCategory onChange={e => getCategoryData(e)} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className="m-t-mo-30" style={{ display: tableRowStore.length > 0 ? 'block' : 'none' }}>
                <Col span="24">
                    <Table
                        columns={columns}
                        dataSource={tableRowStore}
                        bordered={true}
                        pagination={false}
                        className="p-datatable-responsive-demo"
                    />
                    <div style={{ float: 'right' }} >

                        <Checkbox checked={checked} onChange={e => setChecked(e.target.checked)}>Send SMS</Checkbox>
                        <Button type="primary" icon={<SaveOutlined />} onClick={(e) => submitRegForm()} className="m-t-mo-30 mt-30">
                            Save
                        </Button>

                    </div>

                </Col>
            </Row>
        </>
    )
}