import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, notification, Popconfirm, Row, Select, Space, Tooltip, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import moment from 'moment';
import TableView from '../../../contents/AntTableResponsive';
const { Option } = Select;
export default function TakeSingleLeave(props) {

    const [staff] = Form.useForm();
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const singleStuffView = useStoreState(state => state.staff.singleStuffView)
    const staffLeaveList = useStoreState(state => state.staff.staffLeaveList)
    const fetchsingleStuffView = useStoreActions((state) => state.staff.fetchsingleStuffView);
    const savestaffLeave = useStoreActions((state) => state.staff.savestaffLeave);
    const fetchstaffLeaveList = useStoreActions((state) => state.staff.fetchstaffLeaveList);
    const deleteStaffLeaveInfo = useStoreActions((state) => state.staff.deleteStaffLeaveInfo);


    const staffSubmit = (value) => {
        fetchsingleStuffView(value?.customStaffId)
    }

    useEffect(() => {
        if (singleStuffView!==null) {
        fetchstaffLeaveList(singleStuffView?.staffId)
        }
    }, [singleStuffView])
    const formSubmit = (value) => {

        let payload = {
            staffId: singleStuffView?.staffId,
            leaveFor: value?.leaveFor,
            leaveReason: value?.leaveReason,
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
        }
        savestaffLeave({ data: payload, id: singleStuffView?.staffId });
        form.setFieldsValue({fromDate:null,toDate:null,leaveReason:null,leaveFor:null})
    }


    useEffect(() => {
        form.setFieldsValue({
            ...singleStuffView
        })
    }, [singleStuffView])

    const columns = [
        {
            title: 'Leave Date',
            dataIndex: 'leaveDate',
            key: 'leaveDate',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Leave Reason',
            dataIndex: 'leaveReason',
            key: 'leaveReason',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Leave For',
            dataIndex: 'leaveFor',
            key: 'leaveFor',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Delete', dataIndex: 'leaveId', key: 'leaveId', showOnResponse: true, showOnDesktop: true,
            render: (text, record, index) => (<>
                <Popconfirm
                    title="Are you sure to delete this?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deleteStaffLeaveInfo({ data: record?.leaveId, id: singleStuffView?.staffId })}
                >
                    <Tooltip title="Delete">
                        <Button danger icon={<DeleteOutlined />} />
                    </Tooltip>
                </Popconfirm>
            </>
            )
        },

    ];




    return (
        <>
            <Card title="Staff Leave Input" >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={3} xl={8}> </Col>
                    <Col xs={24} sm={24} md={24} lg={3} xl={8}>
                        <Form
                            layout="vertical"
                            onFinish={staffSubmit}
                            id="basic-info"
                            form={staff}
                        >
                            <Row gutter={8}>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item
                                        name="customStaffId"
                                        label="Staff ID"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please input staff id" },
                                        ]}
                                    >
                                        <Input placeholder=" Enter Staff ID" />

                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={3} xl={8}> </Col>

                </Row>

                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="customStaffId"
                                label="Staff ID"
                                className="title-Text"
                            >
                                <Input placeholder="Staff ID" disabled readOnly />

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="staffName"
                                label="Staff Name"
                                className="title-Text"
                            >
                                <Input placeholder="Staff Name" disabled readOnly />

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="designationName"
                                label="Staff Designation"
                                className="title-Text"

                            >
                                <Input disabled readOnly />

                            </Form.Item>
                        </Col>



                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>

                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="leaveReason"
                                label="Leave Reason"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select leave reason" },
                                ]}
                            >
                                <Select placeholder="Select Leave Reason" showSearch allowClear>
                                    <Option value="Accidental Issue">Accidental Issue</Option>
                                    <Option value="Doctor Appointment">Doctor Appointment</Option>
                                    <Option value="Death Of Family Member">Death Of Family Member</Option>
                                    <Option value="Family Issue">Family Issue</Option>
                                    <Option value="Natural Disaster">Natural Disaster</Option>
                                    <Option value="Personal Issue">Personal Issue</Option>
                                    <Option value="Political">Political</Option>
                                    <Option value="Sick">Sick</Option>
                                    <Option value="Sports">Sports</Option>
                                    <Option value="Strike">Strike</Option>
                                    <Option value="Traffic Jam">Traffic Jam</Option>
                                    <Option value="Other">Other</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="leaveFor"
                                label="Leave For"
                                className="title-Text"

                            >
                                <TextArea placeholder='Leave For' />
                            </Form.Item>
                        </Col>



                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                        <Col xs={24} sm={24} md={24} lg={18} xl={18}>

                            <Space size="small" style={{ float: "right" }} >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                                    Save
                                </Button>
                            </Space>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>

                    </Row>


                </Form>
                {staffLeaveList?.length > 0 &&
                    <Row className="m-t-mo-30">
                        <Col span={24}>
                            <div className="datatable-responsive-demo">
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: staffLeaveList,
                                        filterData: staffLeaveList,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "leaveId",

                                    }}
                                    mobileBreakPoint={768}
                                />
                            </div>

                        </Col>
                    </Row>
}

            </Card>


        </>
    )
}
