import React from 'react'
import { Card, Tabs } from 'antd';
import StaffAttendanceMonthWiseSingleReport from './StaffAttendanceMonthWiseSingleReport';
import StaffAttendanceMonthWiseAllReport from './StaffAttendanceMonthWiseAllReport';


export default function StaffAttendanceMonthWiseReport(props) {

    const { TabPane } = Tabs;

    return (
        <>
            <Card title="Month Wise Attendance">
                <Tabs defaultActiveKey="1">
                    <TabPane tab="All" key="1">
                        <StaffAttendanceMonthWiseAllReport />
                    </TabPane>
                    <TabPane tab="Single" key="2">
                        <StaffAttendanceMonthWiseSingleReport />
                    </TabPane>

                </Tabs>
            </Card>
        </>
    )
}