import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Skeleton, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectTeacher } from '../../../select/SelectTeacher';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { Typography } from 'antd';

export default function StaffAssign(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const { Title } = Typography;

    const classTeacherAssignList = useStoreState((state) => state.staff.classTeacherAssignList);
    const fetchclassTeacherAssignList = useStoreActions((state) => state.staff.fetchclassTeacherAssignList);
    const saveclassTeacherAssignList = useStoreActions((state) => state.staff.saveclassTeacherAssignList);

    const deleteclassTeacherAssignList = useStoreActions((state) => state.staff.deleteclassTeacherAssignList);
    const loading = useStoreState((state) =>  state.staff.loading);

    useEffect(() => {
        fetchclassTeacherAssignList();
    }, [])

    const formSubmit = (value) => {
        console.log(value);
        saveclassTeacherAssignList(value);
        form.resetFields();
    }    
    


    const columns = [

        {
            title: 'ID',
            dataIndex: 'staffCustomId',
            key: 'staffCustomId',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Teachers Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Class',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Shift',
            dataIndex: 'shiftName',
            key: 'shiftName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Section',
            dataIndex: 'sectionName',
            key: 'sectionName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Action',
            key: 'id',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteclassTeacherAssignList(record?.classTeacherAssignId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <>
            <Card title="Class Teacher Assign" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="staffId"
                                label="Select Teacher"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select teacher" },
                                ]}
                            >

                                <SelectTeacher/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="classConfigId"
                                label="Select Section"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                            >

                               <SelectClassConfig/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                                    Save
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>
                {classTeacherAssignList === null && <Skeleton loading={loading} paragraph={{rows:10}}/>}
                {classTeacherAssignList!== null &&
                <div className="datatable-responsive">
                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: classTeacherAssignList,
                            filterData: classTeacherAssignList,
                            pagination: true,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                </div>
}
            </Card>

        </>
    )
}
