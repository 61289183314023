import { Select } from "antd";
import * as React from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
const { Option } = Select;

export interface SelectClassConfigMultiple {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  style?: any
}

export const SelectClassConfigMultiple = ({
  onChange,
  selected,
  defaultSelected,
  style
}: SelectClassConfigMultiple) => {
  const itemList = useStoreState((state) => state.student.classConfigList);
  const itemListFetch = useStoreActions((state) => state.student.fetchClassConfigList);

  if (itemList === null) {
    itemListFetch();
  }
  
  const onSelect = (id) => {
    if (itemList) {
      onChange(id);
    }
  };





  return (
    <Select
      onChange={onSelect}
      // loading={loading}
      showSearch
      // allowClear
      defaultValue={defaultSelected}
      value={selected}
      style={style}
      mode="multiple"
      placeholder="Select Section"
      filterOption={(input, option:any) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {itemList ? (
        itemList.map((type, idx) => (
          <Option key={type.id} value={type.id}  >
            {type.classConfigName}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching Section</Option>
      )}
    </Select>
  );
};
