import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Space, Table } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import TableResponsive from "../../../../contents/AntTableResponsive";
import ReactExport from "react-export-excel";
import Text from 'antd/lib/typography/Text';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf, ldatepdf, pdfGenerate } from '../../../utils/pdf';
require('jspdf-autotable');
const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;


let fd = '';
let td = '';

let finaltransactionAmount = 0;
let finalfeeAmount = 0;
export default function SpgStatement(props) {
    const spgStatement = useStoreState((state) => state.saccount.spgStatement);
    const fetchspgStatement = useStoreActions((state) => state.saccount.fetchspgStatement);
    const loading = useStoreState((state) => state.saccount.loading);



    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
        }
        fetchspgStatement(postData);
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");
    };

    const columns = [

        {
            title: 'Tran. Number',
            dataIndex: 'transactionId',
            key: 'transactionId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Req. Number',
            dataIndex: 'refTranNo',
            key: 'refTranNo',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Req. Date',
            dataIndex: 'transactionDate',
            key: 'transactionDate',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Applicant',
            dataIndex: 'applicantName',
            key: 'applicantName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Tran. Amount',
            dataIndex: 'transactionAmount',
            key: 'transactionAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Fees Amount',
            dataIndex: 'feeAmount',
            key: 'feeAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Pay Mode',
            dataIndex: 'payMode',
            key: 'payMode',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Status',
            dataIndex: 'requestStatus',
            key: 'requestStatus',
            showOnResponse: true,
            showOnDesktop: true,
        },
    ];


    function exportPdf() {
        var details = `SPG Statement from ${fd} to ${td}`;

        var doc = new jsPDF("l", "mm", "a4");


        pdfDataL(doc, details);

        var col = ["Tran. Number", "Req. Number", "Req. Date", "Applicant", "Tran. Amount", "Fees Amount", "Pay Mode", "Status"];

        var rows: any = [];

        var temp: any = []


        spgStatement.forEach(element => {
            var temp = [element.transactionId, element.refTranNo, element.transactionDate, element.applicantName,
            element.transactionAmount, element.feeAmount, element.payMode, element.requestStatus];
            rows.push(temp);
        });

        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };

        doc.autoTable(col, rows, {
            headerStyles: {
                lineWidth: .01,
                lineColor: [224, 224, 224],
                overflow: 'linebreak'
            },
            columnStyles: {
                0: { overflow: 'linebreak', columnWidth: 38, halign: "center" },
                1: { overflow: 'linebreak', columnWidth: 40, halign: "center" },
                2: { overflow: 'linebreak', columnWidth: 25, halign: "center" },
                3: { overflow: 'linebreak', columnWidth: 62, halign: "center" },
                4: { overflow: 'linebreak', columnWidth: 30, halign: "center" },
                5: { overflow: 'linebreak', columnWidth: 30, halign: "center" },
                6: { overflow: 'linebreak', columnWidth: 20, halign: "center" },
                7: { overflow: 'linebreak', columnWidth: 25, halign: "center" },
            },
            theme: "grid",
            startY: 45,
            addPageContent: pageContent
        });
        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
            { title: "", dataKey: "b3" },
            { title: "", dataKey: "b4" },
            { title: "", dataKey: "b5" },
            { title: "", dataKey: "b6" },
            { title: "", dataKey: "b7" },
            { title: "", dataKey: "b8" },
        ], [
            {
                b1: "",
                b2: "",
                b3: "",
                b4: "Total",
                b5: finaltransactionAmount,
                b6: finalfeeAmount,
                b7: "",
                b8: "",
            }
        ], {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',

            columnStyles: {
                b1: {
                    halign: "left",
                   
                    columnWidth: 38
                },
                b2: {
                    halign: "left",
                    columnWidth: 40
                },
                b3: {
                    halign: "right",
                    columnWidth: 25
                },
                b4: {
                    fontStyle: 'bold',
                    halign: "right",
                    columnWidth: 62
                },
                b5: {
                    fontStyle: 'bold',
                    halign: "center",
                    columnWidth: 30
                },
                b6: {
                    fontStyle: 'bold',
                    halign: "center",
                    columnWidth: 30
                },
                b7: {
                    halign: "right",
                    columnWidth: 20
                },
                b8: {
                    halign: "right",
                    columnWidth: 25
                },
            },

            // addPageContent: pageContent,
        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }

        doc.save(details + ".pdf");

    }


    return (
        <>
            <Card title="SPG Statement">
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>


                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>

                {< Skeleton loading={loading} paragraph={{ rows: 10 }} />}
                {spgStatement?.length > 0 && !loading &&
                    <>
                        <TableResponsive
                            antTableProps={{
                                showHeader: true,
                                columns: columns,
                                dataSource: spgStatement,
                                filterData: spgStatement,
                                pagination: true,
                                bordered: true,
                                rowKey: "requestLogId",
                                summary: function () {
                                    finaltransactionAmount = 0;
                                    finalfeeAmount = 0;

                                    spgStatement?.forEach(({ transactionAmount, feeAmount }) => {
                                        finaltransactionAmount += transactionAmount;
                                        finalfeeAmount += feeAmount;
                                    });

                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0}> </Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}>

                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={2}>

                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={3}>
                                                    <div style={{ textAlign: "right" }}>
                                                        <Text type="danger" strong>Total</Text>
                                                    </div>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={4}>
                                                    <Text type="danger" strong>{finaltransactionAmount}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={5}>
                                                    <Text type="danger" strong>{finalfeeAmount}</Text>

                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={6}>

                                                </Table.Summary.Cell>

                                            </Table.Summary.Row>

                                        </>
                                    );
                                }
                            }}
                            mobileBreakPoint={768}

                        />
                        <Space style={{ float: 'right' }} size="middle">

                            <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`SPG statment from ${fd} to ${td}`}
                            >
                                <ExcelSheet data={spgStatement} name="SPG Statement">
                                    <ExcelColumn label="Tran. Number" value="transactionId" />
                                    <ExcelColumn label="Req. Number" value="refTranNo" />
                                    <ExcelColumn label="Req. Date" value="transactionDate" />
                                    <ExcelColumn label="Applicant" value="applicantName" />
                                    <ExcelColumn label="Tran. Amount" value="transactionAmount" />
                                    <ExcelColumn label="Fees Amount" value="feeAmount" />
                                    <ExcelColumn label="Pay Mode" value="payMode" />
                                    <ExcelColumn label="Status" value="requestStatus" />

                                </ExcelSheet>
                            </ExcelFile>
                            <Button type="primary" icon={<FilePdfOutlined />} onClick={exportPdf} > Download PDF </Button>
                        </Space>
                    </>
                }
            </Card>
        </>
    )
}
