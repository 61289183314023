import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, DatePicker, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Column from 'rc-table/lib/sugar/Column';
import { DeleteOutlined, FilePdfOutlined, SaveOutlined, SwitcherOutlined, UploadOutlined } from '@ant-design/icons';
import TableView from '../../../../contents/AntTableResponsive';
import moment from 'moment';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectPeriod } from '../../../select/SelectPeriod';
import { SelectClassConfigMultiple } from '../../../select/SelectClassConfigMultiple';

export default function StudentFileUpload() {

    const [form] = Form.useForm();
    const fetchStudentAttendanceAllPeriod = useStoreActions((state) => state.student.fetchStudentAttendanceAllPeriod);
    const studentTxtFile = useStoreActions((state) => state.student.studentTxtFile);
    const studentTxtFileList = useStoreState((state) => state.student.studentTxtFileList);
    const studentTxtFileSave = useStoreActions((state) => state.student.studentTxtFileSave);
     const [inputData, setInputData] = useState<any>();
    const [isFile, setIsFile] = useState<boolean>(false);
    const [fileContent, setfileContent] = useState<any>('');
    const [attendanceDate, setattendanceDate] = useState<any>(moment(new Date(), 'DD/MM/YYYY'));
    const loading = useStoreState((state) =>  state.student.loading);

    const columns = [
        { title: 'User Id', dataIndex: 'userId', key: "userId", showOnResponse: true, showOnDesktop: true },
        { title: 'Punch Date', dataIndex: 'punchDate', key: "punchDate", showOnResponse: true, showOnDesktop: true },
        { title: 'Punch Time', dataIndex: 'punchTime', key: "punchTime", showOnResponse: true, showOnDesktop: true },
        { title: 'Device Serial', dataIndex: 'deviceSerial', key: "deviceSerial", showOnResponse: true, showOnDesktop: true },

    ];

    useEffect(() => {
        fetchStudentAttendanceAllPeriod()
    }, [])

    async function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    

    function clearFileInput(ctrl) {
        try {
            ctrl.value = null;
        } catch (ex) { }
        if (ctrl.value) {
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    }
    const uploadFile = (file: any) => {
        if (file.target.files[0]?.size > 1000000) {
            notification.error({ message: 'Text size should be less than 1MB' })
            // file.target.value = null;
            clearFileInput(document.getElementById("upload-file"));
            setIsFile(false);
            setfileContent('');
            return;
        } else {
            setIsFile(true);
            setfileContent(file.target.files[0]);
        }
    }

   
    const formSubmit = (value) => {
        if (isFile === false) {
            notification.error({ message: 'Please upload a file' })
            return;
        } else {
            let payload = {
                "file": fileContent,
                "date": moment(attendanceDate).format("YYYY-MM-DD")
            }
            let storeData:any ={
                "date": moment(attendanceDate).format("YYYY-MM-DD"),
                "selectPeriod" : value.selectPeriod,
                "selectSection" : value.selectSection
            }
            setInputData(storeData);
            studentTxtFile(payload);
            clearFileInput(document.getElementById("upload-file"));
            setIsFile(false);
            form.resetFields();
        }
    }

    const saveData = () => {
        let details:any = [];
        studentTxtFileList.map(item => {
            details.push({
                "punchDateString": item.punchDate,
                "punchTimeString": item.punchTime,
                "userId": item.userId
            })
        })
        let postData:any = {
            attendanceDate: moment(attendanceDate).format("YYYY-MM-DD"),
            classConfigIds: inputData.selectSection,
            periodId: inputData.selectPeriod,
            details: details
        };        
        studentTxtFileSave(postData);
    }

    

    return (
        <>
            <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="attendanceDate"
                                label="Attendance Date"
                                className="title-Text"
                            >
                                <DatePicker
                                    defaultValue={attendanceDate}
                                    style={{ width: "100%"}}
                                    placeholder="Select Date"
                                    format={"DD/MM/YYYY"}
                                    onChange={(e) => setattendanceDate(e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <div>
                                <div className="ant-col ant-form-item-label"><label className="ant-form-item-required" >Upload Text File</label></div>
                                <input className='ant-input' type="file" required accept="text/plain" id="upload-file" onChange={uploadFile} />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="selectPeriod"
                                label="Select period"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select period" },
                                ]}
                            >
                                <SelectPeriod />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                                name="selectSection"
                                label="Select section"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                            >
                                <SelectClassConfigMultiple />
                            </Form.Item>
                        </Col>                        
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Space size="small" style={{ float: "right" }}>
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} className='mt-0'  icon={<UploadOutlined />}>
                                    Upload
                                </Button>
                                <Button type="primary"  onClick={saveData} className='mt-0' icon={<SaveOutlined />} >
                                    Save
                                </Button>
                            </Space>
                        </Col>

                    </Row>
            </Form>
            <Skeleton loading={loading} paragraph={{rows:10}}/>
            <Row className='mt-30' style={{ display: studentTxtFileList?.length > 0 ? "" : "none" }}>
                <Col span={24}>
                    <div className="datatable-responsive-demo">
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: studentTxtFileList,
                                filterData: studentTxtFileList,
                                pagination: true,
                                bordered: true,
                                rowKey: "routineId",
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                </Col>
            </Row>

        </>
    )
}