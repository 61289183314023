import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Space, Table } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf, ldatepdf, pdfGenerate } from '../../../utils/pdf';
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import TableView from "../../../../contents/AntTableResponsive";
import ReactExport from "react-export-excel";
import { SelectLedgerDFPSMulti } from '../../../select/SelectLedgerDFPSMulti';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import Text from 'antd/lib/typography/Text';
require('jspdf-autotable');



let fd = '';
let td = '';
let totalsum = '';
export default function SectionDueDetailsDetails(props) {
    const sectionDueReport = useStoreState((state) => state.saccount.sectionDueReport2);
    const fetchsectionDueReport = useStoreActions((state) => state.saccount.fetchsectionDueReport2);
    const loading = useStoreState((state) => state.saccount.loading);

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        fetchsectionDueReport(value);
    };

    const columns = [

        {
            title: 'Id',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Details',
            dataIndex: 'details',
            key: 'details',
            width: "60%",
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Total Due',
            dataIndex: 'totalDue',
            key: 'totalDue',
            showOnResponse: true,
            showOnDesktop: true,
        }

    ];

    function exportPdf() {
        let secName = $('.classConfigId .ant-select-selection-item').text();
        let acYear = $('.academicYear .ant-select-selection-item').text();
        var details = `Section Due Details of ${secName} and Academic Year ${acYear}`;
        totalsum = sectionDueReport.reduce(function (acc, obj) { return acc + obj?.totalDue }, 0)
        var doc = new jsPDF("l", "mm", "a4");


        pdfDataL(doc, details);

        var col = ["Student ID", "Name", "Roll", "Due Details", "Total Due"];

        var rows: any = [];

        var temp: any = []


        sectionDueReport.forEach(element => {
            var temp = [element.customStudentId, element.studentName, element.studentRoll,
            element.details, element.totalDue];
            rows.push(temp);
        });

        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };

        doc.autoTable(col, rows, {
            columnStyles: {
                3: { columnWidth: 120, overflow: 'linebreak' },
                0: { overflow: 'linebreak' },
                1: { overflow: 'linebreak' },
                2: { overflow: 'linebreak' },
                4: { overflow: 'linebreak' },
            },
            headerStyles: {
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },

            theme: "grid",
            startY: 45,
            addPageContent: pageContent
        });

        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
        ], [
            {
                b1: "Total Due Amount:",
                b2: totalsum,
            }
        ], {
            startY: doc.autoTable.previous.finalY + 5,
            showHeader: "never",
            theme: 'grid',
            styles: { fillColor: [255, 255, 255], lineColor: 255, },
            columnStyles: {
                b1: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 250,
                    fontStyle: 'bold'
                },
                b2: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 19
                },

            },

            // addPageContent: pageContent,
        });



        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }

        doc.save(details + ".pdf");

    }

    return (
        <>
            <>
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>



                        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                            <Form.Item
                                name="academicYear"
                                label="Academic Year:"
                                className="academicYear"
                                rules={[
                                    { required: true, message: "Please select year" },
                                ]}
                            >

                                <SelectAcademicYear />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                            <Form.Item
                                name="classConfigId"
                                label="Section"
                                className="classConfigId"
                                rules={[
                                    { required: true, message: "Please select section" },
                                ]}
                            >

                                <SelectClassConfig />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                    </Row>
                </Form>
                {sectionDueReport === null && < Skeleton loading={loading} paragraph={{ rows: 10 }} />}
                {sectionDueReport?.length > 0 &&
                    <>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: columns,
                                dataSource: sectionDueReport,
                                filterData: sectionDueReport,
                                pagination: true,
                                bordered: true,
                                rowKey: "identificationId",
                                summary: function () {
                                    let ttotalDue = 0;

                                    sectionDueReport?.forEach(({ totalDue }) => {
                                        ttotalDue += totalDue;
                                    });

                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0}><Text type="danger" strong>Total</Text> </Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={2}>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={3}>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={4}>
                                                    <Text type="danger" strong>{ttotalDue}</Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>

                                        </>
                                    );
                                }
                            }}
                            mobileBreakPoint={768}

                        />
                        <Space style={{ float: 'right' }} size="middle">
                            <Button type="primary" icon={<FilePdfOutlined />} onClick={exportPdf} > Download PDF </Button>
                        </Space>
                    </>
                }
            </>
        </>
    )
}
