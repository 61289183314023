import React from 'react'
import { Card, Tabs } from 'antd';
import StudentFileUpload from './StudentFileUpload';
import StudentAttendanceAutoUpload from './StudentAttendanceAutoUpload';


export default function AttendanceDevice(props) {

    const { TabPane } = Tabs;
    const [activeTab, setActiveTab] = React.useState<any>("1");

    return (
        <>
            <Card title="Attendance Device">
                <Tabs defaultActiveKey="1"  onChange={(e) => { setActiveTab(e) }}>
                    <TabPane tab="Machine Upload" key="1">
                        {activeTab === "1" && <StudentFileUpload />}
                    </TabPane>
                    <TabPane tab="Auto Process" key="2">
                        {activeTab === "2" && <StudentAttendanceAutoUpload />}
                    </TabPane>
                    
                </Tabs>
            </Card>
        </>
    )
}