import React, { useEffect } from 'react'
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import TableView from '../../../contents/AntTableResponsive';
import { DeleteOutlined, DownloadOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';

import { v4 as uuidv4 } from "uuid";
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf, ldatepdf } from '../../utils/pdf';
import { FilePdfOutlined } from '@ant-design/icons';
require('jspdf-autotable');

export default function StaffAttendanceTimeConfigReport() {
    const fetchstaffTimeConfigReport = useStoreActions((state) => state.staff.fetchstaffTimeConfigReport);
    const staffTimeConfigReport =  useStoreState((state) => state.staff.staffTimeConfigReport);
    const loading = useStoreState((state) =>  state.staff.loading);

    useEffect(() => {
        fetchstaffTimeConfigReport();
    },[]);

    const coloums = [
        { title: 'Id', dataIndex: 'staffId', key: 'staffId', showOnResponse: true, showOnDesktop: true  },
        { title: 'Teacher Name', dataIndex: 'staffName', key: 'staffName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Designation', dataIndex: 'designation', key:'designation', showOnResponse: true, showOnDesktop: true  },
        { title: 'Saturday', dataIndex: 'saturdayTime', key: 'saturdayTime', showOnResponse: true, showOnDesktop: true  },
        { title: 'Sunday', dataIndex: 'sundayTime', key: 'sundayTime', showOnResponse: true, showOnDesktop: true  },
        { title: 'Monday', dataIndex: 'mondayTime', key: 'mondayTime', showOnResponse: true, showOnDesktop: true  },
        { title: 'Tuesday', dataIndex: 'tuesdayTime', key: 'tuesdayTime', showOnResponse: true, showOnDesktop: true  },
        { title: 'Wednesday', dataIndex: 'wednesdayTime', key: 'wednesdayTime', showOnResponse: true, showOnDesktop: true  },
        { title: 'Thursday', dataIndex: 'thursdayTime', key: 'thursdayTime', showOnResponse: true, showOnDesktop: true  },
        { title: 'Friday', dataIndex: 'fridayTime', key: 'fridayTime', showOnResponse: true, showOnDesktop: true  },
    ];

    function exportPdf() {

        var details = "Staff Attendance Time Configuration Report";

        var doc = new jsPDF("l", "mm", "a4");

        pdfDataL(doc, details);

        var col = ["Id", "Name", "Designation", "Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

        var rows:any = [];

        staffTimeConfigReport?.forEach(element => {
            var temp:any = [element.staffId, element.staffName, element.designation, element.saturdayTime, element.sundayTime, element.mondayTime, element.tuesdayTime, element.wednesdayTime, element.thursdayTime, element.fridayTime,];
            rows.push(temp);
        });

        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };

        doc.autoTable(col, rows, {

            headerStyles: {
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            theme: "grid",
            styles: { fontSize: 7 },
            startY: 45,
            addPageContent: pageContent
        });

        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }


        doc.save(details + ".pdf");

    }

    return(
        <>
            <Card title="Staff Attendance Time Configuration Report">
             <Skeleton loading={loading} paragraph={{rows:10}} />
               <Row style={{ display: staffTimeConfigReport.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns:coloums,
                                rowKey:"subjectSerial",
                                dataSource: staffTimeConfigReport,
                                filterData: staffTimeConfigReport,
                                pagination: true,
                                bordered: true
                            }}
                            mobileBreakPoint={768}
                        />
                        <br />
                        <Button type="primary" htmlType="submit" icon={<DownloadOutlined />} onClick={() => exportPdf()} className="float-right">Download</Button>
                    </Col>
                </Row>
            </Card>
        </>
    )

}