import React, { useCallback, useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Column from 'rc-table/lib/sugar/Column';
import { DeleteOutlined, EditOutlined, ReloadOutlined, SearchOutlined, SwitcherOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import { SelectClassConfig } from '../../select/SelectClassConfig';
import { SelectClass } from '../../select/SelectClass';

export default function ExamGradeUpdate() {

    const [searchClassGradeForm] = Form.useForm();
    const fetchClassGradeList = useStoreActions((state) => state.customerSupport.fetchClassGradeList);
    const classGradeList = useStoreState((state) => state.customerSupport.classGradeList);
    const instituteDataUpdate = useStoreActions((state) => state.customerSupport.instituteDataUpdate);
    const gradeDelete = useStoreActions((state) => state.customerSupport.gradeDelete);
    const [classGradeUpdateList, setClassGradeUpdateList] = useState<any>([]);
    const [classConfigId, setClassConfigId] = useState<any>("");
    const searchClassGrade = (value) => {
        fetchClassGradeList(value.classConfigId);
        setClassConfigId(value.classConfigId);
    }

    useEffect(() => {
        setClassGradeUpdateList(classGradeList.map((item, index) => ({ ...item, index: index })));
    }, [classGradeList]);

    // const updateGradeNameChange = useCallback((record, index, val) => {
    //     classGradeUpdateList.map((item, i) => {
    //         if (item.id == record.id) {
    //             item.gradeName = val
    //         }
    //     });
    //     setClassGradeUpdateList(classGradeUpdateList);
    // }, [classGradeUpdateList]);

    const onchangeValue: any =
    useCallback((key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newData: any = [...classGradeUpdateList];
        newData[index][key] = e.target.value;
        setClassGradeUpdateList(newData);
    }, [classGradeUpdateList])

    const updateGradePointChange = useCallback((record, index, val) => {
        classGradeUpdateList.map((item, i) => {
            if (item.id == record.id) {
                item.gradePoint = val
            }
        });
        setClassGradeUpdateList(classGradeUpdateList);
    }, [classGradeUpdateList]);

    const updateRangePointChange = useCallback((record, index, val) => {
        classGradeUpdateList.map((item, i) => {
            if (item.id == record.id) {
                item.gradeRange = val
            }
        });
        setClassGradeUpdateList(classGradeUpdateList);
    }, [classGradeUpdateList]);

    const updateNumberHighChange = useCallback((record, index, val) => {
        classGradeUpdateList.map((item, i) => {
            if (item.id == record.id) {
                item.numberHigh = val
            }
        });
        setClassGradeUpdateList(classGradeUpdateList);
    }, [classGradeUpdateList]);

    const updateNumberLowChange = useCallback((record, index, val) => {
        classGradeUpdateList.map((item, i) => {
            if (item.id == record.id) {
                item.numberHigh = val
            }
        });
        setClassGradeUpdateList(classGradeUpdateList);
    }, [classGradeUpdateList]);

    const updatePointHighChange = useCallback((record, index, val) => {
        classGradeUpdateList.map((item, i) => {
            if (item.id == record.id) {
                item.pointHigh = val
            }
        });
        setClassGradeUpdateList(classGradeUpdateList);
    }, [classGradeUpdateList]);

    const updatePointLowChange = useCallback((record, index, val) => {
        classGradeUpdateList.map((item, i) => {
            if (item.id == record.id) {
                item.pointLow = val
            }
        });
        setClassGradeUpdateList(classGradeUpdateList);
    }, [classGradeUpdateList]);

    const updateGrade = (id) => {
        let gradeData;
        classGradeUpdateList.map((item, i) => {
            if (item.id == id) {
                gradeData = item
            }
        });
        let postData: any = {
            classConfig: classConfigId,
            data: gradeData
        }
        instituteDataUpdate(postData);
    }


    const columns = [
        {
            title: 'Grade', dataIndex: 'gradeName', key: "gradeName", showOnResponse: true, showOnDesktop: true, render: ((text, record: any, index) => {
                return (<Input value={record?.gradeName} onChange={onchangeValue("gradeName", record, record.index)} />);
            })
        },
        {
            title: 'Point', dataIndex: 'gradePoint', key: "gradePoint", showOnResponse: true, showOnDesktop: true, render: ((text, record: any, index) => {
                return (<Input value={record?.gradePoint} onChange={onchangeValue("gradePoint", record, record.index)} />);
            })
        },
        {
            title: 'Range', dataIndex: 'gradeRange', key: "gradeRange", showOnResponse: true, showOnDesktop: true, render: ((text, record: any, index) => {
                return (<Input value={record?.gradeRange} onChange={onchangeValue("gradeRange", record, record.index)} />);
            })
        },
        {
            title: 'Number High', dataIndex: 'numberHigh', key: "numberHigh", showOnResponse: true, showOnDesktop: true, render: ((text, record: any, index) => {
                return (<Input value={record?.numberHigh} onChange={onchangeValue("numberHigh", record, record.index)} />);
            })
        },
        {
            title: 'Number Low', dataIndex: 'numberLow', key: "numberLow", showOnResponse: true, showOnDesktop: true, render: ((text, record: any, index) => {
                return (<Input value={record?.numberLow} onChange={onchangeValue("numberLow", record, record.index)} />);
            })
        },
        {
            title: 'Point High', dataIndex: 'pointHigh', key: "pointHigh", showOnResponse: true, showOnDesktop: true, render: ((text, record: any, index) => {
                return (<Input value={record?.pointHigh} onChange={onchangeValue("pointHigh", record, record.index)} />);
            })
        },
        {
            title: 'Point Low', dataIndex: 'pointLow', key: "pointLow", showOnResponse: true, showOnDesktop: true, render: ((text, record: any, index) => {
                return (<Input value={record?.pointLow} onChange={onchangeValue("pointLow", record, record.index)} />);
            })
        },
        {
            title: 'Action', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record: any, index) =>
                <>
                    <Space size="middle">
                        <Popconfirm
                            title="Are you sure to update grade?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => updateGrade(record?.id)}
                        >
                            <Tooltip title="Update">
                                <Button type="primary" icon={<ReloadOutlined />} />
                            </Tooltip>
                        </Popconfirm>
                        <Popconfirm
                            title="Are you sure to delete this?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => 
                               gradeDelete({
                                    classConfig: classConfigId,
                                    data: record?.id
                               })
                            }
                        >
                            <Tooltip title="Delete">
                                <Button danger icon={<DeleteOutlined />} />
                            </Tooltip>
                        </Popconfirm>
                    </Space>
                </>
        },
    ];

    return (
        <>
            <Card title="Grade Update">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                        <Form
                            layout="vertical"
                            id="classConfigId"
                            onFinish={searchClassGrade}
                            form={searchClassGradeForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} xl={{ span: 5 }}>

                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                    <Form.Item
                                        name="classConfigId"
                                        label="Class"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClass />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row className={classGradeUpdateList.length > 0 ? 'm-t-mo-30' : 'm-t-mo-30 d-none'}>
                    <Col span={24}>
                        <div className="datatable-responsive-demo">
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: classGradeUpdateList,
                                    filterData: classGradeUpdateList,
                                    pagination: true,
                                    bordered: true,
                                    rowKey: "id",
                                }}
                                mobileBreakPoint={768}
                            />
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    )
}