import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { v4 as uuidv4 } from "uuid";
import Item from 'antd/lib/list/Item';

export default function DeviceIdMapping() {
    const [searchStudentForm] = Form.useForm();
    const fetchDeviceIdMappingList = useStoreActions((state) => state.student.fetchDeviceIdMappingList);
    const deviceIdMappingList = useStoreState((state) => state.student.deviceIdMappingList);
    const saveDeviceIdMappingList = useStoreActions((state) => state.student.saveDeviceIdMappingList);
    const [deviceMappingList, setDeviceMappingList] = useState<any>();
    const [checked, setChecked] = useState<boolean>(false);
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [selectedValue, setselectedValue] = useState<any>();
    const [classconfigidStore, setClassconfigidStore] = useState<any>();
    const loading = useStoreState((state) =>  state.student.loading);

    const searchStudentList = (value) => {
        fetchDeviceIdMappingList(value.classconfigid);
        setClassconfigidStore(value.classconfigid);
    }

    useEffect(() => {
        setDeviceMappingList(deviceIdMappingList);
    }, [deviceIdMappingList])

    
    const deviceIDColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true  },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true  },
        { title: 'Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Device ID', dataIndex: 'deviceId', key: 'deviceId', showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
            <InputNumber placeholder={record.deviceId} onChange={(e) => updateOwnDeviceId(e, record)} />
        )   },
    ];

    const studentIDasDeviceID = (value) => {
        setChecked(value);
        if (value == true) {
            deviceMappingList.map((item, i) => {
                item.deviceId = item.customStudentId;
            });
            setDeviceMappingList(deviceMappingList);
        }else{
            deviceMappingList.map((item, i) => {
                item.deviceId = "";
            });
            setDeviceMappingList(deviceMappingList);
            fetchDeviceIdMappingList(classconfigidStore);
        }
    } 

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const updateOwnDeviceId = (e, record) => {
        deviceMappingList.map((item, i) => {
            if(item.customStudentId == record.customStudentId){
                item.deviceId = e
            }
        });
        setDeviceMappingList(deviceMappingList);
    }   

    const updateDeviceList = (e) => {
        let data:any = []
        console.log('selectedValue', selectedValue);
        if(selectedValue !== undefined){
            selectedValue.map(item => {
                data.push({
                    studentId: item.studentId,
                    userId: item.deviceId,
                })
            })
            let postData:any = {
                payload : data,
                payloadSecond : classconfigidStore,
            }
            saveDeviceIdMappingList(postData);
        }else{
            notification.error({ message: "Select row first" })
        }
        
    }

    return <>
        <Card title="Device Id Mapping">
            <Row>
                <Col span={24}>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                            <Form
                                layout="vertical"
                                id="searchStudentForm"
                                onFinish={searchStudentList}
                                form={searchStudentForm}
                            >
                                <Row>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>

                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                        <Form.Item
                                            name="classconfigid"
                                            label="Section"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select section" },
                                            ]}
                                        >
                                        <SelectClassConfig style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>

                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{rows:10}}  />
            <div className="m-t-mo-30" style={{ display: deviceIdMappingList.length > 0 ? 'block' : 'none' }}>
                <Row>
                    <Col span="24">
                        <div style={{ float: 'right' }} >
                            <Checkbox className='mb-30' checked={checked} onChange={e => studentIDasDeviceID(e.target.checked)}>Check for use Student Id as Machine Id)<br /><br /></Checkbox>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: deviceIDColumn,
                                rowKey:"customStudentId",
                                dataSource: deviceMappingList,
                                filterData: deviceMappingList,
                                pagination: false,
                                bordered: true,
                                rowSelection: rowSelection,
                            }}
                            mobileBreakPoint={768}
                        />
                        <div style={{ float: 'right' }} >
                            <Button type="primary" icon={<EditOutlined />} onClick={(e) => updateDeviceList(e)} className="m-t-mo-30 mt-30">
                                Update
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Card>
    </>
}