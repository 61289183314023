import React, { useEffect } from 'react'
import { Card, Tabs } from 'antd';
import { useState } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Exam from "./Exam";
import Subject from './Subject';
import ExamShortCode from './ExamShortCode';
import ExamGrade from './ExamGrade';
const { TabPane } = Tabs;

export default function ExamSettingsWrapper(props) {

    return(
        <>
            <Card title="Initial Exam Setup" >
                <Tabs defaultActiveKey="1" size={"large"}>
                    <TabPane tab="Exam" key="1">
                        <Exam />
                    </TabPane>
                    <TabPane tab="Subject" key="2">
                        <Subject />
                    </TabPane>
                    <TabPane tab="Exam Short-code" key="3">
                        <ExamShortCode />
                    </TabPane>
                    <TabPane tab="Exam Grade" key="4">
                        <ExamGrade />
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )

}