import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { SelectClass } from '../../../select/SelectClass';
import { SelectSection } from '../../../select/SelectSection';
import { SelectShift } from '../../../select/SelectShift';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag } from 'antd';
import TableView from '../../../../contents/AntTableResponsive';


export default function CalssConfiguration() {
    const fetchClassConfigList = useStoreActions((state) => state.student.fetchClassConfigList);
    const createClassConfiguration = useStoreActions((state) => state.student.createClassConfiguration);
    const updateClassConfigurationSerial = useStoreActions((state) => state.student.updateClassConfigurationSerial);
    const classConfigList = useStoreState((state) => state.student.classConfigList);
    const deleteClassConfig = useStoreActions((state) => state.student.deleteClassConfig);
    const [classConfigForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const loading = useStoreState((state) =>  state.student.loading);



    const classConfigFormStore = (value) => {
        console.log(value)
        createClassConfiguration(value)
        classConfigForm.resetFields();
    }
    const [tableData, setTableData] = useState<any>([])

    useEffect(() => {
        fetchClassConfigList();
    }, []);

    useEffect(() => {
        setTableData(classConfigList)
    }, [classConfigList])

    const onchangeValue: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...tableData];
            newData[index][key] = e;
            setTableData(newData);
        };

    const columns = [
        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <InputNumber
                    min={0}
                    onChange={onchangeValue("serial", record, index)}
                    value={record.serial}
                    placeholder="Serial"
                // style={{ width: 250 }}
                ></InputNumber>
            ),
        },
        {
            title: 'Class Name',
            dataIndex: 'classConfigName',
            key: 'classConfigName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Shift Name',
            dataIndex: 'shiftName',
            key: 'shiftName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Section Name',
            dataIndex: 'sectionName',
            key: 'sectionName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <>
                    <span>{record.status == 1 ? 'Enable' : 'Disable'}</span>
                </>
            )
        },
        {
            title: 'Action',
            key: 'configId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteClassConfig(record?.id)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </Space>
            ),
        }
    ];

    const updateSerial = () => {
        let postdata = tableData.map(i => ({ serial: i?.serial, id: i?.id }));
        updateClassConfigurationSerial(postdata)
    }

    return <>
        <Card title="Class Configuration">
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }}>
                    <Form
                        layout="vertical"
                        id="classConfigInfo"
                        onFinish={classConfigFormStore}
                        form={classConfigForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="classId"
                                    label="Class"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select class" },
                                    ]}
                                >
                                    <SelectClass />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="sectionId"
                                    label="Section"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select section" },
                                    ]}
                                >
                                    <SelectSection />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="shiftId"
                                    label="Shift"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select shift" },
                                    ]}
                                >
                                    <SelectShift />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                        Save
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Skeleton loading={loading} paragraph={{rows:10}}/>
            <Row className="m-t-mo-30">
                <Col span="24">
                    <div className="datatable-responsive-demo">
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: tableData,
                                filterData:tableData,
                                pagination: false,
                                bordered: true,
                                rowKey: "id",
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                </Col>
            </Row>
            <Button style={{ float: "right" }} type="primary" onClick={() => updateSerial()}>Update Serial</Button>
        </Card>
    </>
}